import React, { useEffect, useState, Fragment } from 'react'
import { Button, Card, Dropdown, Menu, Typography, Alert, Tooltip, Modal, InputNumber } from 'antd'
import { ExclamationCircleOutlined, EditOutlined, QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import {
  format,
  getDayOfYear,
  getDate,
  getMonth,
  isWithinInterval,
  addYears,
  isAfter,
  isBefore,
  isEqual,
  isSameYear
} from 'date-fns'
import isNumber from 'lodash/isNumber'
import isNaN from 'lodash/isNaN'

import getValidityStart from '@vacationtracker/shared/functions/get-validity-start'
import getvalidityStartForQuota from '@vacationtracker/shared/functions/get-validity-start-for-senioirty-enitlement'

import UserLeaveTypeForm from '../user-leave-type-form'
import QuotaBox from '../quota-box'
import QuotaBoxLock from '../quota-box-lock'
import { DisplayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'

import { AccrualTypeEnum } from '@vacationtracker/shared/types/leave-policy'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IUserLeaveQuotas } from './types'
import {
  IUserLeaveTypeResult,
  IUserQuota,
  IUserLocationLeavePolicies,
  IMyProfileQuota,
  IMyProfileLeaveType
} from '../../../frontend/src/types/custom-queries'
import FormattedDate from '../formatted-date'
import RolloverExpiry from './rollover-expiry'
import { isFullDayInterval } from '../utils/interval'
import { getDaysOrHours, setDaysOrHours } from '../../functions/get-days-or-hours'
import { getLeaveDaysNumber } from '../utils/leave-quotas'
import { InitialBroughtForwardTooltip } from './quota-tooltips'
import { AccruedTooltip } from './accrued-tooltip'

const { Text, Paragraph } = Typography


const UserLeaveQuotas = ({
  userId,
  quotas,
  amIAdmin,
  onQuotaUpdate,
  IntlMessages,
  location,
  userName,
  setActiveTab,
  hourlyLeaveAccounting,
  locale,
  userStartDate,
  userStatus,
}: IUserLeaveQuotas): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [selectedQuotaPeriod, setSelectedQuotaPeriod] = useState<IUserQuota | IMyProfileQuota>(quotas[quotas.length - 1])
  const [currentPeriod, setCurrentPeriod] = useState<IUserQuota | IMyProfileQuota>(quotas[0])
  const [dateRanges, setDateRanges] = useState<(IUserQuota & {leaveDayName: string})[]>([])
  const [selectedDateRangeName, setSelectedDateRangeName] = useState<string>('')
  const [visibleUserEditQuotaForm, setVisibleUserEditQuotaForm] = useState<boolean>(false)
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveTypeResult | IMyProfileLeaveType>(() => {
    return quotas[quotas.length - 1].leaveTypes.filter(lt => lt.isActive).sort((a, b) => a.position < b.position ? -1 : 1)[0]
  })
  const [isAccrualInfoVisible, setIsAccrualInfoVisible] = useState(false)
  const [selectedLeavePolicy, setSelectedLeavePolicy] = useState<IUserLocationLeavePolicies>()
  const [isInitialPeriod, setIsInitialPeriod] = useState(false)
  const [rolloverQuotas, setRolloverQuotas] = useState(0)
  const [isCurrentYearSelected, setIsCurrentYearSelected] = useState(true)
  const [isNextYearSelected, setIsNextYearSelected] = useState(false)
  const [leaveTypeWithAccruals, setLeaveTypeWithAccruals] = useState<IUserLeaveTypeResult[]>([])
  const [showAccrualsCalculator, setShowAccrualsCalculator] = useState(false)
  const [showInitialBroughtForwardModal, setShowInitialBroughtForwardModal] = useState(false)
  const initalBroughtForward = selectedLeaveType ? getLeaveDaysNumber(
    selectedLeaveType.accrualType,
    selectedLeaveType.allowHalfDays,
    selectedLeaveType?.shortestLeaveInterval,
    rolloverQuotas,
    hourlyLeaveAccounting
  ) : 0

  const setQuotas = (allQuotas: IUserQuota[]) => {
    setDateRanges(allQuotas.map((quota, index) => {
      let leaveDayRangeName: string
      if (isWithinInterval(Date.now(), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        leaveDayRangeName = formatYearDisplay('app.currentYear', quota.yearStart, quota.yearEnd)
        setLeaveTypeWithAccruals(quota.leaveTypes.filter(lt => lt.accrualType !== AccrualTypeEnum.none))
        setSelectedDateRangeName(leaveDayRangeName)
        setSelectedQuotaPeriod(quota)
        if (index === 0) {
          setIsInitialPeriod(true)
        }
        if (quota.leaveTypes.length > 0 && selectedLeaveType) {
          const leavePolicy = location.leavePolicies.find(lp => lp.leaveType.id === selectedLeaveType.leaveTypeId)
          if (leavePolicy) {
            setSelectedLeavePolicy(leavePolicy)
          }
        }
        setCurrentPeriod(quota)
      }
      else if (isWithinInterval(addYears(Date.now(), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd)} )) {
        leaveDayRangeName = formatYearDisplay('app.nextYear', quota.yearStart, quota.yearEnd)
      }
      else if (
        // We can't check only is year start 1. January, becasue location can have short year. Ex. from 1. Jan to 1. Jul
        getDayOfYear(new Date(quota.yearStart)) === 1 &&
        getDate(new Date(quota.yearEnd.split('T')[0])) === 31 &&
        getMonth(new Date(quota.yearEnd.split('T')[0])) === 11
      ) {
        leaveDayRangeName = format(new Date(quota.yearStart), 'yyyy')
      }
      else if (format(new Date(quota.yearStart), 'yyyy') === format(new Date(quota.yearEnd), 'yyyy')) {
        leaveDayRangeName = `${format(new Date(quota.yearStart.slice(0, -1)), 'MMM dd')} - ${format(new Date(quota.yearEnd.slice(0, -1)), 'MMM dd, yyyy')}`
      }
      else {
        //We use slice here for the date because we need a date without the timezone.
        leaveDayRangeName = `${format(new Date(quota.yearStart.slice(0, -1)), 'MMM dd, yyyy')} - ${format(new Date(quota.yearEnd.slice(0, -1)), 'MMM dd, yyyy')}`
      }

      return ({
        ...quota,
        leaveDayName: leaveDayRangeName,
      })
    }).filter(Boolean))

  }

  useEffect(() => {
    const filteredQuotas = quotas.map(quota => {
      return {
        ...quota,
        leaveTypes: quota.leaveTypes.filter(lt => lt.isActive).sort((a, b) => a.position < b.position ? -1 : 1),
      }
    })
    setQuotas(filteredQuotas)
  }, [quotas])

  useEffect(() => {
    const previouslySelectedLeaveType = selectedLeaveType && selectedQuotaPeriod.leaveTypes.find(lt => lt.leaveTypeId === selectedLeaveType.leaveTypeId)
    if (previouslySelectedLeaveType) {
      setSelectedLeaveType(previouslySelectedLeaveType)
    } else {
      setSelectedLeaveType(selectedQuotaPeriod.leaveTypes[0])
    }
  }, [selectedQuotaPeriod])

  useEffect(() => {
    if (selectedLeaveType) {
      setRolloverQuotas(selectedLeaveType.rolloverFromPreviousYear)
    }
  }, [selectedLeaveType])

  const selectLeaveType = (id: string | number): void => {
    const quota = selectedQuotaPeriod.leaveTypes.find(type => type.leaveTypeId === id)
    if (quota) {
      setSelectedLeaveType(quota)
      if (selectedQuotaPeriod.leaveTypes.length > 0) {
        const leavePolicy = location.leavePolicies.find(lp => lp.leaveType.id === quota.leaveTypeId)

        if (leavePolicy) {
          setSelectedLeavePolicy(leavePolicy)
        }
      }
    }

  }

  const formatYearDisplay = (message: string, yearStart: string, yearEnd: string) => {
    //Get me a date without the timezone
    const universalYearStart = new Date(yearStart.slice(0, -1))
    const universalYearEnd = new Date(yearEnd.slice(0, -1))
    return `${formatMessage({
      id: message,
    })} (${format(
      new Date(universalYearStart),
      `${
        isSameYear(new Date(universalYearStart), new Date(universalYearEnd))
          ? ' MMM dd'
          : ' MMM dd, yyyy'
      }`
    )} - ${format(new Date(universalYearEnd), 'MMM dd, yyyy')} )`
  }

  const handleYearChange = (event) => {
    const userLeaveType = dateRanges[event.key]
    userLeaveType.leaveTypes.sort((a, b) => a.position < b.position ? -1 : 1)

    const hasLeaveTypeInSelectedQuotaPeriod = userLeaveType.leaveTypes.find(type => type.leaveTypeId === selectedLeaveType?.leaveTypeId)
    if (!hasLeaveTypeInSelectedQuotaPeriod) {
      setSelectedLeaveType(userLeaveType.leaveTypes[0])
      const leavePolicy = location.leavePolicies.find(lp => lp.leaveType.id === userLeaveType.leaveTypes[0]?.leaveTypeId)

      if (leavePolicy) {
        setSelectedLeavePolicy(leavePolicy)
      }
    }
    setSelectedQuotaPeriod(userLeaveType)
    setIsInitialPeriod(() => {
      if (userLeaveType.yearStart === quotas[0].yearStart && userLeaveType.yearEnd === quotas[0].yearEnd) {
        return true
      } else {
        return false
      }
    })
    setSelectedDateRangeName(userLeaveType.leaveDayName)

    setIsCurrentYearSelected(false)
    setIsNextYearSelected(false)
    if (isWithinInterval(Date.now(), { start: new Date(userLeaveType.yearStart), end: new Date(userLeaveType.yearEnd) })) {
      setIsCurrentYearSelected(true)
    } else if (isWithinInterval(addYears(Date.now(), 1), { start: new Date(userLeaveType.yearStart), end: new Date(userLeaveType.yearEnd)} )) {
      setIsNextYearSelected(true)
    }
  }

  const calculateRemainingDays = (quota?: IUserLeaveTypeResult | IMyProfileLeaveType): number | string => {
    if (!quota) {
      return 0
    }
    if (quota.hasUnlimitedDays) {
      return '∞'
    }

    return quota.currentDays
  }

  const menu = () => {
    return (
      <Menu onClick={handleYearChange}>
        {dateRanges.map((lt, index: number) => (
          <Menu.Item key={index}>{lt.leaveDayName}</Menu.Item>)
        )}
      </Menu>
    )
  }

  const handleUserQuotaUpdate = async (data) => {
    const validityStart = getValidityStart(selectedQuotaPeriod as IUserQuota, currentPeriod as IUserQuota)
    const validityStartForQuota = getvalidityStartForQuota(selectedQuotaPeriod as IUserQuota, currentPeriod as IUserQuota)

    const proratedLeaveTypes: string[] = data.shouldProrate
      ? [...new Set([ ...selectedLeaveType.userProratedLeaveTypes as string[], data.leaveTypeId ])] as string[]
      : selectedLeaveType?.userProratedLeaveTypes?.filter(leaveType => leaveType !== data.leaveTypeId) as string[]

    const updates = {
      eventType: 'USER_LEAVE_TYPES_UPDATED',
      eventGroup: 'USER',
      userId,
      leaveTypeId: data.leaveTypeId,
      daysPerYear: data.defaultDaysPerYear,
      ...((isNumber(data.seniorityEntitlementDaysForYear) && !isNaN(data.seniorityEntitlementDaysForYear)) &&
        {seniorityEntitlementDaysForYear: data.seniorityEntitlementDaysForYear}
      ),
      ...((isNumber(data.entitlementByRoleDaysForYear) && !isNaN(data.entitlementByRoleDaysForYear)) &&
        {entitlementByRoleDaysForYear: data.entitlementByRoleDaysForYear}
      ),
      hasUnlimitedDays: Boolean(data.hasUnlimitedDays),
      currentYearOnly: !data.applyToFutureYears,
      ignoreRolloverFromPreviousYear: data.ignoreRolloverFromPreviousYear,
      shouldProrate: isInitialPeriod ? proratedLeaveTypes?.length > 0 : undefined,
      proratedLeaveTypes: isInitialPeriod ? proratedLeaveTypes : undefined,
      // We use the date of the migration as a validity start date for the migrated teams,
      // as that was at the end of the year, we need to be creative here.
      // Instead of using this hour, minute, second and milliseconds in the selected period,
      // We put everything in the last day of the fiscal year, but we use "percentage" of the year progress to diff the dates
      // But the base for "percentages" is 3600000 so we can set minutes (60), seconds (60) and milliseconds (1000).
      // For example, 50% of the year will produce: 00:30:00:000, etc.
      validityStart,
      // For editing seniority entitlement in currentYear we use the current time
      // For editing seniority entitlement in the past we use the endDate of selectedQuotaPeriod and added in SK timestamp
      // To get senioirty entitlement we sort by validityDate and if have two or more events with same validityDate we sort by timestamp and take the last one
      validityStartForQuota,
    }

    setIsInitialPeriod(false)

    if (onQuotaUpdate) {
      await onQuotaUpdate(updates)
    }

    setVisibleUserEditQuotaForm(false)
  }

  const handleUserRolloverQuotaUpdate = async () => {
    const validityStart = getValidityStart(quotas[0] as IUserQuota, currentPeriod as IUserQuota)

    const updates = {
      eventType: 'USER_LEAVE_TYPES_ROLLOVER_UPDATED',
      eventGroup: 'USER',
      userId,
      leaveTypeId: selectedLeaveType.leaveTypeId,
      initialRollover: true,
      customRolloverDays: rolloverQuotas,
      validityStart: validityStart,
    }

    setIsInitialPeriod(false)

    if (onQuotaUpdate) {
      await onQuotaUpdate(updates)
    }
    setShowInitialBroughtForwardModal(false)
  }

  const handleGoToUserLogPage = () => {
    setIsAccrualInfoVisible(false)
    if (setActiveTab) {
      setActiveTab({key: 'logs'})
    }
  }

  const getExpiredRolloverFooter = (rolloverExpiryDate: string) => {
    if (rolloverExpiryDate === '') {
      return ''
    }

    let isExpired = false
    if (isAfter(new Date(), new Date(rolloverExpiryDate)) && !isEqual(new Date(), new Date(rolloverExpiryDate))) {
      isExpired = true
    }

    if (!isExpired) {
      return ''
    }

    return (
      <Paragraph>
        <IntlMessages id="components.userLeaveQuotas.expiredDateInfo" values={{
          date: <FormattedDate value={rolloverExpiryDate?.slice(0,10)} format="MMMM Do YYYY." locale={locale} />,
        }}/>
      </Paragraph>
    )
  }

  const getQuotaTitle = (): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeavePolicy?.firstEarningDate) {
      return <>
        {hourlyLeaveAccounting ? <IntlMessages id="components.userLeaveQuotas.accruedHours" /> : <IntlMessages id="components.userLeaveQuotas.accruedDays" />}
        <Tooltip
          visible={isAccrualInfoVisible}
          title={
            <AccruedTooltip
              selectedLeaveType={selectedLeaveType}
              userName={userName}
              policyFirstEarningDate={selectedLeavePolicy?.firstEarningDate}
              handleGoToUserLogPage={handleGoToUserLogPage}
              formatMessage={formatMessage}
              IntlMessages={IntlMessages}
              yearStartDay={location.yearStartDay as number}
              accrualPeriodStart={selectedLeavePolicy?.accrualPeriodStart as string}
              isCurrentYearSelected={isCurrentYearSelected}
              hourlyLeaveAccounting={hourlyLeaveAccounting}
              userLocale={locale}
              userStatus={userStatus}
              capHit={Boolean(selectedLeaveType?.capHit)}
            />
          }
          onVisibleChange={() => setIsAccrualInfoVisible(!isAccrualInfoVisible)}
        >
          <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
        </Tooltip>
      </>
    }
    else {
      return <IntlMessages id="components.userLeaveQuotas.quota" />
    }
  }

  const getQuotaFooter = (selectedLeaveType: IUserLeaveTypeResult | IMyProfileLeaveType): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none) {
      return <Paragraph data-testid="quota-default-days-per-year">
        <IntlMessages id="app.of" />&nbsp;
        <DisplayLeaveInDaysAndHours
          value={selectedLeaveType?.quota}
          format={LeaveDisplayFormatEnum.short}
        />
        {amIAdmin &&
          <EditOutlined
            style={{ color: '#7f00ff', marginLeft: 3 }}
            onClick={() => setVisibleUserEditQuotaForm(true)}
          />
        }
      </Paragraph>
    } else if (amIAdmin) {
      return <Button type="link" key="change-quota" onClick={() => setVisibleUserEditQuotaForm(true)} disabled={selectedQuotaPeriod.leaveTypes.length === 0}>
        <IntlMessages id="components.userLeaveQuotas.editQuotaButton" />
      </Button>
    } else {
      return <></>
    }
  }

  const openCalculator = (event) => {
    event.preventDefault()
    setShowAccrualsCalculator(true)
  }

  return (
    <>
      <Card
        title={<IntlMessages id="components.userLeaveQuotas.sectionTitle" />}
        className='user-leave-quotas'
        style={{ marginBottom: 30 }}
        bodyStyle={{ padding: 8 }}
        extra={[
          <Fragment key="menu">
            <IntlMessages id="components.userLeaveQuotas.selectPeriod" />
            <Dropdown overlay={menu}>
              <Button style={{ minWidth: 220, marginLeft: 5, textAlign: 'left' }}>{selectedDateRangeName}</Button>
            </Dropdown>
          </Fragment>,
        ]}
      >
        {selectedQuotaPeriod.leaveTypes.length === 0 && !selectedLeaveType &&
          <Alert
            message={<><ExclamationCircleOutlined style={{ color: 'red' }} /> <IntlMessages id="components.userLeaveQuotas.leavePoliciesNotExistInfo" /></>}
            type="error"
            style={{margin: 25}}
          />
        }
        {selectedLeaveType &&
          <div>
            <Text style={{ padding: 16, marginTop: 16 }} strong><IntlMessages id="app.leaveTypes" />:</Text>
            <Menu mode="horizontal" selectedKeys={[selectedLeaveType?.leaveTypeId || '']}>
              {selectedQuotaPeriod.leaveTypes.map(type => (
                <Menu.Item key={type.leaveTypeId} onClick={selected => selectLeaveType(selected.key)}>{type.leaveTypeName}</Menu.Item>
              ))}
            </Menu>
            <div className="quota-box-container">
              {!selectedLeaveType?.hasUnlimitedDays &&
                <>
                  { isInitialPeriod && amIAdmin &&
                  <>
                    <QuotaBoxLock
                      isLocked={!selectedLeavePolicy?.maxRolloverDays}
                      title={<><IntlMessages id="components.userLeaveQuotas.initialBroughtForward" />
                        <InitialBroughtForwardTooltip hourlyLeaveAccounting={hourlyLeaveAccounting} value={initalBroughtForward} />
                      </>}
                      lockedTooltip={<IntlMessages
                        id="components.userLeaveQuotas.unlockBroughtForwardInfo"
                        values={{ leaveTypeName: selectedLeaveType.leaveTypeName, locationName: location.name }}/>}
                      dataTestId={'quota-brought-forward-days'}
                      value={initalBroughtForward}
                      footer={<Button type="link" key="change-quota" onClick={() => setShowInitialBroughtForwardModal(true)} ><IntlMessages id="app.change" /></Button>}
                    />
                    <div className="quota-formula">
                      <Text type="secondary" strong>+</Text>
                    </div>
                  </>
                  }
                  {isInitialPeriod && !amIAdmin &&
                  <>
                    <QuotaBox
                      title={<><IntlMessages id="components.userLeaveQuotas.initialBroughtForward" />
                        <InitialBroughtForwardTooltip value={initalBroughtForward} hourlyLeaveAccounting={hourlyLeaveAccounting}/>
                      </>}
                      doNotShowDisplayInDaysOrHoursTooltip
                      dataTestId='quota-brought-forward-days'
                      value={rolloverQuotas}
                      footer={''}
                      hourlyLeaveAccounting={hourlyLeaveAccounting}
                    />
                    <div className="quota-formula">
                      <Text type="secondary" strong>+</Text>
                    </div>
                  </>
                  }
                  {!isInitialPeriod && (selectedLeavePolicy?.maxRolloverDays !== 0 || selectedLeaveType?.rolloverFromPreviousYear !== 0) &&
                    <>
                      <QuotaBox
                        title={<IntlMessages id="app.broughtForward" />}
                        dataTestId={'quota-brought-forward-days'}
                        value={selectedLeaveType?.rolloverFromPreviousYear}
                        footer={<RolloverExpiry
                          IntlMessages={IntlMessages}
                          locale={locale}
                          isCurrentYearSelected={isCurrentYearSelected}
                          yearEndDate={typeof selectedQuotaPeriod.yearEnd === 'string' ? new Date(selectedQuotaPeriod.yearEnd) : selectedQuotaPeriod.yearEnd}
                          rolloverExpiryDate={selectedLeaveType.rolloverExpiryDate}
                          isNextYearSelected={isNextYearSelected}
                          maxRolloverDays={selectedLeavePolicy?.maxRolloverDays || 0}
                        />}
                        hourlyLeaveAccounting={hourlyLeaveAccounting}
                        leaveTypeName={selectedLeaveType.leaveTypeName}
                        locationName={location.name}
                        broughtForwardLimit={selectedLeavePolicy?.maxRolloverDays}
                      />
                      <div className="quota-formula">
                        <Text type="secondary" strong>+</Text>
                      </div>
                    </>
                  }
                </>
              }
              <QuotaBox
                title={getQuotaTitle()}
                dataTestId={'quota-value'}
                value={selectedLeaveType?.hasUnlimitedDays ? '∞' : selectedLeaveType?.earnedDaysWithoutRollover}
                quota={{
                  baseQuota: selectedLeaveType.baseQuota,
                  toilDays: selectedLeaveType.toilDays,
                  toilDaysLapsed: selectedLeaveType.toilDaysLapsed,
                  seniorityEntitlementDaysForYear: selectedLeaveType?.seniorityEntitlementDaysForYear,
                  hasUnlimitedDays: selectedLeaveType.hasUnlimitedDays,
                  entitlementByRoleDaysForYear: selectedLeaveType.entitlementByRoleDaysForYear,
                }}
                footer={getQuotaFooter(selectedLeaveType)}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                doNotShowDisplayInDaysOrHoursTooltip={Boolean(selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeavePolicy?.firstEarningDate)}
                broughtForwardLimit={selectedLeavePolicy?.maxRolloverDays || 0}
              />
              {/* If rollover never expired in that case we return an empty string or we add 1000 years,
                  that's why we check is rollover Expiry Date emptry string and is rolloverExpiryDate is before the current year plus 5 year */}
              {selectedLeaveType?.rolloverExpiryDate !== '' && isBefore(new Date(selectedLeaveType?.rolloverExpiryDate), addYears(new Date(), 5)) &&
                <>
                  <div className="quota-formula" data-testid="formula">
                    <Text type="secondary" strong>-</Text>
                  </div>
                  <QuotaBox
                    title={<IntlMessages id="app.expired" />}
                    dataTestId={'quota-expired'}
                    value={selectedLeaveType?.expiredDays}
                    footer={getExpiredRolloverFooter(selectedLeaveType.rolloverExpiryDate)}
                    hourlyLeaveAccounting={hourlyLeaveAccounting}
                    locale={locale}
                    expirationDate={selectedLeaveType.rolloverExpiryDate}
                  />
                </>
              }
              <div className="quota-formula">
                <Text type="secondary" strong>-</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id="components.userLeaveQuotas.used" />}
                dataTestId={'quota-used-days'}
                value={selectedLeaveType?.usedDays}
                scheduledDays={selectedLeaveType.scheduledDays}
                footer={''}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                leaveTypeName={selectedLeaveType.leaveTypeName}
              />
              <div className="quota-formula">
                <Text type="secondary" strong>=</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id="components.userLeaveQuotas.remaining" />}
                dataTestId={'quota-remaining-days'}
                value={calculateRemainingDays(selectedLeaveType)}
                footer={''}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                broughtForwardLimit={selectedLeavePolicy?.maxRolloverDays || 0}
              />
            </div>
            {/* {selectedLeaveType.accrualType !== AccrualTypeEnum.none &&
              <Text>
                <IntlMessages id="user.accrualsCalculatorInfo" /> <Link to="/" onClick={openCalculator}><IntlMessages id="user.accrualsCalculator" /></Link>
              </Text>
            } */}
            {selectedLeaveType && selectedLeaveType.capHit && <Alert 
              message={<IntlMessages id="components.leavePolicyForm.accrualCapWarning2" values={{ leaveTypeName: selectedLeaveType.leaveTypeName }} />}
              type="warning" showIcon closable 
            />}
          </div>
        }
      </Card>
      {visibleUserEditQuotaForm && selectedLeaveType &&
        <UserLeaveTypeForm
          onSave={handleUserQuotaUpdate}
          handleCancel={() => { setVisibleUserEditQuotaForm(false)}}
          visible={visibleUserEditQuotaForm}
          leaveType={selectedLeaveType}
          IntlMessages={IntlMessages}
          dateRange={{
            name: selectedDateRangeName,
          }}
          hourlyLeaveAccounting={hourlyLeaveAccounting}
          userStartDate={userStartDate}
          locationResetQuotas={location.resetQuotas}
          userName={userName}
          period={{
            start: selectedQuotaPeriod.yearStart as string,
            end: selectedQuotaPeriod.yearEnd as string,
          }}
          locale={locale}
          isInitialPeriod={isInitialPeriod}
        />
      }
      {selectedLeaveType && 
        <Modal
          title={<IntlMessages id="components.userLeaveQuotas.editInitialQuotaTitle" values={{leaveTypeName: selectedLeaveType.leaveTypeName}} />}
          visible={showInitialBroughtForwardModal}
          closeIcon={<CloseCircleOutlined />}
          bodyStyle={{ padding: 12 }}
          okText={<IntlMessages id="app.save"/>}
          onCancel={() => {setShowInitialBroughtForwardModal(false); setRolloverQuotas(selectedLeaveType.rolloverFromPreviousYear)}}
          onOk={handleUserRolloverQuotaUpdate}
        >
          <div><IntlMessages id="components.userLeaveQuotas.editInitialQuotaTooltip"/></div>
          <div style={{ marginTop: '30px' }}>
            <IntlMessages id="components.userLeaveQuotas.initialBroughtForward"/>
            &nbsp;
            <InputNumber
              step={isFullDayInterval(selectedLeaveType) || hourlyLeaveAccounting ? 1 : 0.5}
              value={getDaysOrHours(initalBroughtForward, hourlyLeaveAccounting)}
              onChange={(days) => {
                const quotaDays = hourlyLeaveAccounting ? Math.round(days) : Math.round(days * 100) / 100
                setRolloverQuotas(
                  setDaysOrHours(quotaDays, hourlyLeaveAccounting)
                )
              }}
            />
            &nbsp;
            <span className='quota-box-hours' data-testid={'quota-brought-forward-days'}>
              {(hourlyLeaveAccounting && selectedLeavePolicy?.maxRolloverDays) ?
                formatMessage({ id: 'app.hours' })
                : formatMessage({ id: 'app.days' })
              }
            </span>
          </div>
        </Modal>
      }
      {/* {showAccrualsCalculator &&
        <AccrualsCalculator
          leaveTypes={leaveTypeWithAccruals}
          onCancel={() => setShowAccrualsCalculator(false)}
          currentPeriodEndYear={currentPeriod.yearEnd}
        />
      } */}
    </>
  )
}

export default UserLeaveQuotas
