/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Timeline, Typography } from 'antd'
import { utcToZonedTime, format } from 'date-fns-tz'
import { filterXSS } from 'xss'
import { IUserLogs } from '../../types/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import { EventTypeEnum } from '@vacationtracker/shared/types/core-event'
import { IDecoratorWords } from '../../routes/Reports/CompanyLogsPage'
import WorkWeek from '../work-week'
import moment from 'moment'
const { Text } = Typography
import { dayOfWeekMap } from '../../constants'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

interface ICompanyLogLine extends IUserLogs {
  timezone: string
  paramsJson: any
  decoratorWords: IDecoratorWords
  hourFormat: HourFormatEnum
}

type Periods = {
  days: number
  years: number
}[]

const decorateResponse = (value: any) => {
  if (value === '') {
    return words.notSet
  }
  if (typeof value == 'boolean') {
    return value ? words.yes : words.no
  }
  return value ?? words.notSet
}

const decorateMonth = (monthNumber: number, withDay?: number) => {
  if (!monthNumber) {
    return words.notSet
  }
  const monthName = moment().month(monthNumber - 1).locale('en-US').format('MMMM').toLowerCase()
  return <><IntlMessages id={`app.${monthName}`} ></IntlMessages>{withDay ? ' ' : ''}{withDay}</>
}

const decorateDay = (dayNumber: number) => {
  if (!dayNumber) {
    return words.notSet
  }
  const dayName = moment().day(dayNumber).locale('en-US').format('dddd').toLowerCase()
  return <IntlMessages id={`app.${dayName}`} ></IntlMessages>
}

const decorateDate = (date: Date) => {
  if (!date) {
    return words.notSet
  }
  return moment(date).format('MM-DD-YYYY')
}

const decorateEntitlementPeriods = (periodIntervals: Periods) => {
  //period intervals are given in the following form
  //"periods":[{"days":2,"years":5},{"days":3,"years":10}]
  //we need to convert it to the following form
  //2 days every 5 years, 3 days every 10 years
  let result = ''
  periodIntervals.forEach((periodInterval, index: number) => {
    if (index > 0) {
      result += ', '
    }
    result += `${periodInterval.days} ${words.days} ${words.every} ${periodInterval.years} ${words.years}`
  })
  return result
}

let words: IDecoratorWords

const CompanyLogLine = ({
  decoratorWords,
  type,
  timestamp,
  creatorName = '<Unknown>',
  paramsJson,
  timezone,
  hourFormat,
}: ICompanyLogLine): React.ReactElement | null => {

  words = decoratorWords
  
  const dateInTimezone = creatorName !== 'system' ? utcToZonedTime(new Date(timestamp), timezone) : new Date(new Date(timestamp).toISOString().slice(0, -1))
  const dateTimeFormat = `MMM d, yyyy ${hourFormat === HourFormatEnum.twentyFour ? 'HH' : 'h'}:mm zzz ${hourFormat === HourFormatEnum.twentyFour ? '' : 'a'}`
  
  const [time, setTime] = useState(format(dateInTimezone, dateTimeFormat, { timeZone: timezone }))
  const [text, setText] = useState<string | JSX.Element>('')
  const [color, setColor] = useState<string>('blue')

  useEffect(() => {
    setLogs()
  }, [])

  useEffect(() => {
    setTime(format(dateInTimezone, dateTimeFormat, { timeZone: timezone }))
  }, [timezone])

  const setLogs = () => {
    try {
      switch (type) {
        case EventTypeEnum.COMPANY_CREATED:
          setText(<IntlMessages id="components.logs.companyCreated" values={{
            name: filterXSS(paramsJson.name as string), br: () => <br />, b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.COMPANY_SETTINGS_UPDATED:
          setText(
            <IntlMessages id="components.logs.companySettingsUpdated" values={{
              user: creatorName, announceNewUsers: decorateResponse(paramsJson.announceNewUsers),
              hourlyLeaveAccounting: decorateResponse(paramsJson.hourlyLeaveAccounting),
              userEndDate: decorateResponse(paramsJson.userEndDate),
              calculationStartYear: decorateResponse(paramsJson.calculationStartYear),
              importUsersAutomatically: decorateResponse(paramsJson.importUsersAutomatically),
              br: () => <br/>,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} />
          )
          break
        case EventTypeEnum.BILLING_UPDATED:
          setText(<IntlMessages id="components.logs.billingUpdated" values={{
            paymentProcessor: paramsJson.paymentProcessor === 'microsoft-billing' ? 'Microsoft' : paramsJson.paymentProcessor,
            br: () => <br/>,
          }} />)
          break
        case EventTypeEnum.BILLING_CANCELLED:
          setText(<IntlMessages id="components.logs.billingCancelled" values={{paymentProcessor: paramsJson.paymentProcessor, br: () => <br/>}} />)
          break
        case EventTypeEnum.SUBSCRIPTION_CHANGED:
          setText(<IntlMessages id="components.logs.subscriptionChanged" values={{
            plan: paramsJson.plan, period: paramsJson.subscriptionPeriod, paymentProcessor: paramsJson.paymentProcessor, status: paramsJson.subscriptionStatus, br: () => <br/>,
          }} />)
          break
        case EventTypeEnum.SUBSCRIPTION_CANCELED:
          setColor('red')
          setText(<IntlMessages id="components.logs.subscriptionCancelled" values={{
            reason: paramsJson.cancelReason,
            comment: paramsJson.cancelReasonComment,
            user: creatorName,
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.IMPORT_USERS:
          setText(<IntlMessages id="components.logs.importUsers" values={{
            user: creatorName, totalUsers: paramsJson.importAllUsers ? paramsJson.totalUsers : paramsJson.users.length, br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.IMPORT_LEAVES:
          setText(<IntlMessages id="components.logs.importLeaves" values={{
            user: creatorName, totalLeaves: paramsJson.totalLeaves, br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.LEAVE_POLICY_DISABLED:
          setColor('red')
          setText(
            <IntlMessages
              id='components.logs.leavePolicyDisabled'
              values={{
                user: creatorName,
                leaveTypeName: paramsJson.leaveTypeName,
                locationName: paramsJson.locationName,
                br: () => <br />,
                b: (...chunks) => (
                  <Text strong>{chunks}</Text>
                ),
              }}
            />
          )
          break
        case EventTypeEnum.LEAVE_POLICY_ENABLED:
          setColor('green')
          setText(<IntlMessages id="components.logs.leavePolicyEnabled" values={{
            user: creatorName, leaveTypeName: paramsJson.leaveTypeName, locationName: paramsJson.locationName,
            daysPerYear: paramsJson.daysPerYear, maxRolloverDays: paramsJson.maxRolloverDays, isApprovalRequired: decorateResponse(paramsJson.isApprovalRequired),
            hasUnlimitedDays: decorateResponse(paramsJson.hasUnlimitedDays), shortestLeaveInterval: paramsJson.shortestLeaveInterval,
            isReasonRequired: decorateResponse(paramsJson.isReasonRequired), accrualType: paramsJson.accrualType,
            negativeBallanceAllowed: decorateResponse(paramsJson.negativeBallanceAllowed), allowLeaveInPast: decorateResponse(paramsJson.allowLeaveInPast),
            hideLeaveType: decorateResponse(paramsJson.hideLeaveType),
            enableRolloverExpiry: decorateResponse(paramsJson.broughtForwardExpirationSettings.enabled),
            rolloverExpiryDate: decorateMonth(paramsJson.broughtForwardExpirationSettings.month as number, paramsJson.broughtForwardExpirationSettings.day as number),
            rolloverExpiryAfterDays: decorateResponse(paramsJson.broughtForwardExpirationSettings.afterDays),
            accrualCapRate: paramsJson.accrualCapRate ?? 0,
            toil: decorateResponse(paramsJson.toil),
            toilShortestInterval: decorateResponse(paramsJson.toilShortestInterval),
            toilRequestsAllowedForUsers: decorateResponse(paramsJson.toilRequestsAllowedForUsers),
            toilExpiration: decorateResponse(paramsJson.toilExpiration),
            toilExpirationDate: decorateResponse(paramsJson.toilExpirationDate),
            toilExpirationMonths: decorateResponse(paramsJson.toilExpirationMonths && paramsJson.toilExpirationMonths > 0 ? paramsJson.toilExpirationMonths : undefined),
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.LEAVE_TYPE_CREATED:
          setColor('green')
          setText(
            <IntlMessages
              id='components.logs.leaveTypeCreated'
              values={{
                user: creatorName,
                color: paramsJson.color,
                name: filterXSS(paramsJson.name as string),
                locations: paramsJson.locations?.join(', '),
                daysPerYear: paramsJson.leavePolicy.daysPerYear,
                maxRolloverDays: paramsJson.leavePolicy.maxRolloverDays,
                isApprovalRequired: decorateResponse(
                  paramsJson.leavePolicy.isApprovalRequired
                ),
                hasUnlimitedDays: decorateResponse(
                  paramsJson.leavePolicy.hasUnlimitedDays
                ),
                shortestLeaveInterval:
                  paramsJson.leavePolicy.shortestLeaveInterval,
                isReasonRequired: decorateResponse(
                  paramsJson.leavePolicy.isReasonRequired
                ),
                accrualType: decorateResponse(paramsJson.leavePolicy.accrualType),
                negativeBallanceAllowed: decorateResponse(
                  paramsJson.leavePolicy.negativeBallanceAllowed
                ),
                allowLeaveInPast: decorateResponse(
                  paramsJson.leavePolicy.allowLeaveInPast
                ),
                hideLeaveType: decorateResponse(
                  paramsJson.leavePolicy.hideLeaveType
                ),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.LEAVE_TYPE_UPDATED:
          if (!paramsJson.isActive) {
            setColor('red')
          }
          setText(
            <IntlMessages
              id='components.logs.leaveTypeUpdated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                color: paramsJson.color,
                isActive: decorateResponse(paramsJson.isActive),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.LEAVE_TYPE_DELETED:
          setColor('red')
          setText(
            <IntlMessages
              id='components.logs.leaveTypeDeleted'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                br: () => <br />,
                b: (...chunks) => (
                  <Text strong>{chunks}</Text>
                ),
              }}
            />
          )
          break
        case EventTypeEnum.LOCATION_CREATED:
          setColor('green')
          setText(
            <IntlMessages
              id='components.logs.locationCreated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                yearStartMonth: decorateMonth(paramsJson.yearStartMonth as number),
                yearStartDay: paramsJson.yearStartDay,
                isDefault: decorateResponse(paramsJson.isDefault),
                firstDayOfWeek: decorateDay(paramsJson.firstDayOfWeek as number),
                resetQuotas: paramsJson.resetQuotas.split('_').join(' '),
                rolloverExpiryMonth: decorateMonth(paramsJson.rolloverExpiryMonth as number),
                rolloverExpiryDay: decorateResponse(paramsJson.rolloverExpiryDay),
                rolloverExpiryAfterDays: decorateResponse(paramsJson.rolloverExpiryAfterDays),
                timezone: paramsJson.timezone,
                users: paramsJson.users.join(', ') || 'Not set',
                days: <WorkWeek  days={paramsJson.days} firstDayOfWeek={paramsJson.firstDayOfWeek} separator=", "/>,
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.LOCATION_UPDATED:
          setText(<IntlMessages id="components.logs.locationUpdated" values={{
            user: creatorName, name: filterXSS(paramsJson.name as string), yearStartMonth: decorateMonth(paramsJson.yearStartMonth as number),
            yearStartDay: paramsJson.yearStartDay, isDefault: paramsJson.isDefault, firstDayOfWeek: paramsJson.firstDayOfWeek, resetQuotas:paramsJson.resetQuotas,
            rolloverExpiryMonth: decorateMonth(paramsJson.rolloverExpiryMonth as number),
            rolloverExpiryDay: paramsJson.rolloverExpiryDay, rolloverExpiryAfterDays: paramsJson.rolloverExpiryAfterDays,
            timezone: paramsJson.timezone, users: paramsJson.users.join(', ') || 'Not set',
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.LOCATION_DELETED:
          setText(<IntlMessages id="components.logs.locationDeleted" values={{user: creatorName, br: () => <br/>}} />)
          break
        case EventTypeEnum.LOCATION_WORK_WEEK_UPDATED:
          setText(<IntlMessages id="components.logs.locationWorkWeekUpdated" values={{
            user: creatorName, name: paramsJson.name, days: <WorkWeek  days={paramsJson.days} firstDayOfWeek={paramsJson.firstDayOfWeek || 1} separator=", "/>,
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.LOCATION_YEAR_HOLIDAYS_CHANGED:
          setText(<IntlMessages id="components.logs.locationYearHolidaysChanged" values={{
            user: creatorName, name: filterXSS(paramsJson.name as string), year: paramsJson.year,
            holidays: paramsJson.holidays.map((h) => `${h.name}: ${new Date(h.date).toDateString()}`).join(', '),
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED:
          paramsJson.holidays?.length > 0
            ? setText(<IntlMessages id="components.logs.locationHolidaysAutoimportON" values={{
              user: creatorName, location: paramsJson.name, holidays: paramsJson.holidays.map((h) => `${h.name}: ${new Date(h.date).toDateString()}`).join(', '),
              br: () => <br/>,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} />)
            : setText(<IntlMessages id="components.logs.locationHolidaysAutoimportOFF" values={{
              user: creatorName, location: paramsJson.name,
              br: () => <br/>,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} />)
          break
        case EventTypeEnum.LOCATION_YEAR_ROLLOVER:
          setText(
            <IntlMessages
              id='components.logs.locationYearRollover'
              values={{ name: filterXSS(paramsJson.name as string), br: () => <br />, b: (...chunks) => (
                <Text strong>{chunks}</Text>
              )}}
            />
          )
          break
        case EventTypeEnum.NOTIFICATION_CREATED:
          setColor('green')
          setText(
            <IntlMessages
              id={ paramsJson.frequency === 'DAILY' ? 'components.logs.notificationDetailsDaily' : 'components.logs.notificationDetailsMonthly' }
              values={{
                action: 'created',
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                frequency: paramsJson.frequency,
                enabled: decorateResponse(paramsJson.enabled),
                locations: decorateResponse(paramsJson.locations?.join(', ')),
                teams: decorateResponse(paramsJson.teams.join(', ')),
                labels: decorateResponse(paramsJson.labels.join(', ')),
                firstDayOfWeek: <IntlMessages id={paramsJson.firstDayOfWeek === 0 ? 'app.sunday' : 'app.monday' } />,
                timezone: paramsJson.timezone,
                groupBy: paramsJson.groupBy,
                currentWeek: decorateResponse(paramsJson.currentWeek),
                sendEmpty: decorateResponse(paramsJson.sendEmpty),
                minute: paramsJson.minute,
                hour: paramsJson.hour,
                locale: paramsJson.locale,
                day: paramsJson.day && <IntlMessages id={dayOfWeekMap.find((day) => day.value === paramsJson.day)?.name} />,
                sendOnDays: (
                  paramsJson.sendOnDays ?
                    <WorkWeek
                      days={paramsJson.sendOnDays}
                      firstDayOfWeek={paramsJson.firstDayOfWeek || 1}
                      separator=', '
                    /> : <IntlMessages id="app.notSet" />
                ),
                destinations: paramsJson.destinations.map((d) => {
                  if (d.platform === 'email') {
                    return `${d.platform}(s) ${d.addresses?.join(', ')}`
                  }
                  const platform = d.platform.replace(/^\w/, s => s.toUpperCase())
                  return `${platform} channel`
                }).join(' & '),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.NOTIFICATION_UPDATED:
          setText(
            <IntlMessages
              id={ paramsJson.frequency === 'DAILY' ? 'components.logs.notificationDetailsDaily' : 'components.logs.notificationDetailsMonthly' }
              values={{
                action: 'updated',
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                frequency: paramsJson.frequency,
                enabled: decorateResponse(paramsJson.enabled),
                locations: decorateResponse(paramsJson.locations?.join(', ')),
                teams: decorateResponse(paramsJson.teams.join(', ')),
                labels: decorateResponse(paramsJson.labels.join(', ')),
                firstDayOfWeek: <IntlMessages id={paramsJson.firstDayOfWeek === 0 ? 'app.sunday' : 'app.monday' } />,
                timezone: paramsJson.timezone,
                groupBy: paramsJson.groupBy,
                currentWeek: decorateResponse(paramsJson.currentWeek),
                sendEmpty: decorateResponse(paramsJson.sendEmpty),
                minute: paramsJson.minute,
                hour: paramsJson.hour,
                locale: paramsJson.locale,
                day: paramsJson.day && <IntlMessages id={dayOfWeekMap.find((day) => day.value === paramsJson.day)?.name} />,
                sendOnDays: (
                  paramsJson.sendOnDays ?
                    <WorkWeek
                      days={paramsJson.sendOnDays}
                      firstDayOfWeek={paramsJson.firstDayOfWeek || 1}
                      separator=', '
                    /> : <IntlMessages id="app.notSet" />
                ),
                destinations: paramsJson.destinations.map((d) => {
                  if (d.platform === 'email') {
                    return `${d.platform}(s) ${d.addresses?.join(', ')}`
                  }
                  const platform = d.platform.replace(/^\w/, s => s.toUpperCase())
                  return `${platform} channel`
                }).join(' & '),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.NOTIFICATION_DELETED:
          setColor('red')
          setText(<IntlMessages id="components.logs.notificationDeleted" values={{user: creatorName,  br: () => <br/>}} />)
          break
        case EventTypeEnum.TEAM_APPROVER_STATUS_CHANGED:
          setText(<IntlMessages id="components.logs.teamApproverStatusChanged" values={{
            user: creatorName, approvers: paramsJson.approvers.join(', '), team: paramsJson.teamName,  br: () => <br/>,
          }} />)
          break
        case EventTypeEnum.TEAM_CREATED:
          setColor('green')
          setText(<IntlMessages id="components.logs.teamCreated" values={{
            user: creatorName, team: filterXSS(paramsJson.name as string), isDefault: decorateResponse(paramsJson.isDefault),
            users: decorateResponse(paramsJson.users.join(', ')), approvers: decorateResponse(paramsJson.approvers.join(', ')),
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.TEAM_UPDATED:
          setText(<IntlMessages id="components.logs.teamUpdated" values={{
            user: creatorName, team: filterXSS(paramsJson.name as string), isDefault: decorateResponse(paramsJson.isDefault),
            users: decorateResponse(paramsJson.users.join(', ')), approvers: decorateResponse(paramsJson.approvers.join(', ')),
            br: () => <br/>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />)
          break
        case EventTypeEnum.TEAM_DELETED:
          setText(<IntlMessages id="components.logs.teamDeleted" values={{user: creatorName,  br: () => <br/>}} />)
          break
        case EventTypeEnum.BLACKOUT_PERIOD_CREATED:
          setColor('green')
          setText(
            <IntlMessages
              id='components.logs.blackoutPeriodCreated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                enabled: decorateResponse(paramsJson.isActive),
                recurring: decorateResponse(paramsJson.recurring),
                startDate: decorateDate(new Date(paramsJson.startDate)),
                endDate: decorateDate(new Date(paramsJson.endDate)),
                leaveTypes: paramsJson.leaveTypes?.join(', '),
                locations: Array.isArray(paramsJson.locations) ? paramsJson.locations?.join(', ') : paramsJson.locations,
                teams: Array.isArray(paramsJson.teams) ? paramsJson.teams?.join(', ') : paramsJson.teams,
                labels: Array.isArray(paramsJson.labels) ? paramsJson.labels?.join(', ') : paramsJson.labels,
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break    
        case EventTypeEnum.BLACKOUT_PERIOD_UPDATED:
          setColor('green')
          setText(
            <IntlMessages
              id='components.logs.blackoutPeriodUpdated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                enabled: decorateResponse(paramsJson.isActive),
                recurring: decorateResponse(paramsJson.recurring),
                startDate: decorateDate(new Date(paramsJson.startDate)),
                endDate: decorateDate(new Date(paramsJson.endDate)),
                leaveTypes: paramsJson.leaveTypes?.join(', '),
                locations: Array.isArray(paramsJson.locations) ? paramsJson.locations?.join(', ') : paramsJson.locations,
                teams: Array.isArray(paramsJson.teams) ? paramsJson.teams?.join(', ') : paramsJson.teams,
                labels: Array.isArray(paramsJson.labels) ? paramsJson.labels?.join(', ') : paramsJson.labels,
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.BLACKOUT_PERIOD_DELETED:
          setColor('red')
          setText(<IntlMessages id="components.logs.blackoutPeriodDeleted" values={{user: creatorName,  br: () => <br/>}} />)
          break
        case EventTypeEnum.SENIORITY_ENTITLEMENT_CREATED:
          setColor('green')
          setText(
            <IntlMessages
              id='components.logs.seniorityEntitlementCreated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                enabled: decorateResponse(paramsJson.isActive),
                prorated: decorateResponse(paramsJson.prorate),
                calculateFrom: (...chunks) => (paramsJson.calculateFrom === 'USER_EMPLOYMENT_DATE' ? chunks : ''),
                startDate: (...chunks) => (paramsJson.calculateFrom !== 'USER_EMPLOYMENT_DATE' ? decorateDate(new Date(timestamp)) : ''),
                leaveType: paramsJson.leaveType,
                locations: Array.isArray(paramsJson.locations) ? paramsJson.locations?.join(', ') : paramsJson.locations,
                teams: Array.isArray(paramsJson.teams) ? paramsJson.teams?.join(', ') : paramsJson.teams,
                labels: Array.isArray(paramsJson.labels) ? paramsJson.labels?.join(', ') : paramsJson.labels,
                entitlementPeriods: decorateEntitlementPeriods(paramsJson.periods),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.SENIORITY_ENTITLEMENT_UPDATED:
          setText(
            <IntlMessages
              id='components.logs.seniorityEntitlementUpdated'
              values={{
                user: creatorName,
                name: filterXSS(paramsJson.name as string),
                enabled: decorateResponse(paramsJson.isActive),
                prorated: decorateResponse(paramsJson.prorate),
                calculateFrom: (...chunks) => (paramsJson.calculateFrom === 'USER_EMPLOYMENT_DATE' ? chunks : ''),
                startDate: (...chunks) => (paramsJson.calculateFrom !== 'USER_EMPLOYMENT_DATE' ? decorateDate(new Date(timestamp)) : ''),
                leaveType: paramsJson.leaveType,
                locations: Array.isArray(paramsJson.locations) ? paramsJson.locations?.join(', ') : paramsJson.locations,
                teams: Array.isArray(paramsJson.teams) ? paramsJson.teams?.join(', ') : paramsJson.teams,
                labels: Array.isArray(paramsJson.labels) ? paramsJson.labels?.join(', ') : paramsJson.labels,
                entitlementPeriods: decorateEntitlementPeriods(paramsJson.periods),
                br: () => <br />,
                b: (...chunks) => <Text strong>{chunks}</Text>,
              }}
            />
          )
          break
        case EventTypeEnum.SENIORITY_ENTITLEMENT_DELETED:
          setColor('red')
          setText(<IntlMessages id="components.logs.seniorityEntitlementDeleted" values={{user: creatorName,  br: () => <br/>}} />)
          break
        case EventTypeEnum.AUTOMATION_ADDONS_UPDATED:
          if (!paramsJson.isActive) {
            setColor('red')
          }
          setText(<IntlMessages id={`components.logs.automationAddonsUpdated${paramsJson.isActive ? 'Purchased' : 'Unsubscribed'}`} values={{
            user: creatorName,
            automationType: <IntlMessages id={`automations.${paramsJson.automationType}`} />,
          }} />)
          break
        default:
          setText(type)
          break
      }
    } catch (e) {
      setText(<IntlMessages id="components.logs.failedToLoadLog" />)
    }
  }


  return (
    <Timeline.Item label={time} color={color}>{text}</Timeline.Item>
  )
}

export default CompanyLogLine