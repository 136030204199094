import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Descriptions, Tag, Tooltip, Typography } from 'antd'
import { EyeOutlined, WarningFilled } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'

import { ILocationUser } from '../../types/locations'

const { Paragraph, Text } = Typography

interface ILocationCard {
  id: string
  title: string
  users: ILocationUser[]
  isDefault: boolean
  quickTourLocation: boolean
}

const LocationCard = ({ id, title, users, isDefault, quickTourLocation }: ILocationCard): React.ReactElement => {
  const [showRenameWarning, setShowRenameWarning] = useState(false)

  useEffect(() => {
    if (title.startsWith('<Autodetected for Departments')) {
      setShowRenameWarning(true)
    }
  }, [title])

  const getFewUsers = (usersData: ILocationUser[], locationId: string) => {
    return (<>
      {usersData.map((user: ILocationUser, index: number) => {
        if (index < 4 && user) {
          return (
            <span className="user-avatar" key={user.id}>
              <UserAvatar id={user.id} name={user.name} avatar={user.imageUrl} isTooltip={true} isLinkToPage={true} shape="circle" />
            </span>
          )
        }
        return ''
      })}
      {usersData.length > 4 ? <Link to={`/app/settings/locations/${locationId}/general`}>+{usersData.length - 4}</Link> : ''}
    </>)
  }

  return (
    <Card
      key={id}
      style={{ width: 300, marginBottom: 20, marginRight: 20, display: 'inline-block' }}
      actions={[
        <Link key={`${id}-view`} to={`/app/settings/locations/${id}/general`}>
          <EyeOutlined /> <IntlMessages id="locations.viewLocation" />
        </Link>,
      ]}
      extra={[
        showRenameWarning && <Tooltip title={
          <Fragment>
            <Paragraph key="pleaseRenameLocation" style={{ color: 'white' }}><IntlMessages id="locations.pleaseRenameLocation" /></Paragraph>
            <Paragraph key="automaticallyGeneratedName" style={{ color: 'white' }}>
              <IntlMessages id="locations.automaticallyGeneratedName"
                values={{
                  team: title.replace('<Autodetected for Departments: ', '').replace('>', ''),
                  // eslint-disable-next-line
                  t: (...chunks) => (
                    <Text type="warning" strong>{chunks}</Text>
                  ),
                }}
              />
            </Paragraph>
            <Paragraph key="unableToNameThisLocation" style={{ color: 'white' }}><IntlMessages id="locations.unableToNameThisLocation" /></Paragraph>
            <Paragraph key="automaticallyTransferredLeavePoliciesAndHolidays" style={{ color: 'white' }}>
              <IntlMessages id="locations.automaticallyTransferredLeavePoliciesAndHolidays"
                values={{
                  not: title.replace('<Autodetected for Departments: ', '').replace('>', ''),
                  // eslint-disable-next-line
                  b: (...chunks) => (
                    <Text style={{ color: 'white' }} strong>{chunks}</Text>
                  ),
                }}
              />
            </Paragraph>
          </Fragment>
        }>
          <Tag key="warning" color="orange"><WarningFilled /></Tag>
        </Tooltip>,
        isDefault && <Tag key="default" color="purple"><IntlMessages id="locations.default"/></Tag>,
      ]}
      title={
        <Link key="general" to={`/app/settings/locations/${id}/general`}>{title}</Link>
      }
    >
      <Descriptions layout="horizontal" size="small" column={1}>
        <Descriptions.Item key="users" label={<IntlMessages id="app.users" />}>
          {users.length > 0 ? getFewUsers(users, id) : <p style={{ marginBottom: 10 }}><IntlMessages id="locations.hasNoUsers" /></p>}
        </Descriptions.Item>
        <Descriptions.Item key="holidays" label={<IntlMessages id="app.holidays" />} className={ quickTourLocation ? 'joyride-location-holidays' : '' }>
          <Link to={`/app/settings/locations/${id}/holidays`}><IntlMessages id="locations.viewHolidays" /></Link>
        </Descriptions.Item>
        <Descriptions.Item key='leave-policies' label={<IntlMessages id="locations.leavePolicies" />} className={ quickTourLocation ? 'joyride-location-leave-policies' : '' }>
          <Link to={`/app/settings/locations/${id}/leave-policies`}><IntlMessages id="locations.viewLeavePolicies" /></Link>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default LocationCard
