import { UNIVERSAL_USER_ID_PREFIX, allPlatforms } from '@vacationtracker/shared/data/platforms'
import { PlatformEnum } from '@vacationtracker/shared/data/platforms'
/*
 * cf563174-5c6d-4b96-bcb6-0581ea498bdd -> slack-cf563174-5c6d-4b96-bcb6-0581ea498bdd
 * email-cf563174-5c6d-4b96-bcb6-0581ea498bdd -> email-cf563174-5c6d-4b96-bcb6-0581ea498bdd
 */
export function vtUserId(originalId = '', platform: PlatformEnum ): string {
  const prefixes = [...allPlatforms, UNIVERSAL_USER_ID_PREFIX]
  if (originalId.match(new RegExp(`^(${prefixes.join('|')})-`, 'gi'))) {
    return originalId
  }
  return `${platform}-${originalId}`
}

/*
 * slack-cf563174-5c6d-4b96-bcb6-0581ea498bdd -> cf563174-5c6d-4b96-bcb6-0581ea498bdd
 */
export function originalPlatformVtId(vtId: string, platform?: PlatformEnum | PlatformEnum[]): string {
  if (Array.isArray(platform)) {
    return vtId.replace(new RegExp(`^(${platform.join('|')})-`, 'gi'), '')
  }
  if (platform) {
    return vtId.replace(`${platform}-`, '')
  } else {
    return vtId.replace(/^[^-]+-/, '')
  }
}
