import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Alert, Badge, Button, Dropdown, Layout, Menu, Typography, Tooltip } from 'antd'
import { QuestionOutlined, MenuOutlined, NotificationOutlined, BulbOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { API, graphqlOperation } from 'aws-amplify'
import { getWhatsNew as getWhatsNewQuery } from '../../graphql/custom-queries'
import { setWhatsNewAsReaded } from '../../graphql/custom-mutation'

import { wait } from '@vacationtracker/shared/functions/wait'
import { track } from '../../services/analytics/analytics'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice, isMicrosoftPayment } from '../../store/auth-company-slice'
import { selectOnboardingSlice, handleOnboardingVisible } from '../../store/onboarding-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectLocaleSlice } from '../../store/locale-slice'
import { BadgeFeaturesEnum, selectFeatureBadgeSlice, setBadgeFeature, setAllBadgeFeatures } from '../../store/badge-features-slice'
import { ThemeContext } from '../../context/themeContext'
import { OnboardingContext } from '../../context/onboardingContext'
import { TAB_SIZE } from '../../constants/ThemeSetting'

import IntlMessages from '../../util/IntlMessages'
import SetupMSBot from '../../components/setup-ms-bot'
import { AnnouncementModal } from '../../components/announcement-modal'
import { HelpDrawer } from '../../components/help-drawer'
import { WhatsNew } from '../../components/whats-new'
import { OnboardingAdmin } from '../../components/onboarding-admin'

import { IWhatsNewComponent, IWhatsNewQuery } from '@vacationtracker/shared/types/whats-new'
import { FrontendUrls } from '../../types/urls'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { IData } from '../../types/data'
import { selectWhatsNewsSlice, setWhatsNewId as setReduxWhatsNewData } from '../../store/whats-news-slice'
import difference from 'lodash/difference'

const { Header } = Layout
const { Text } = Typography

interface ITopBar {
  onLogout: Function
}

const Topbar = ({ onLogout }: ITopBar): React.ReactElement => {
  const { navCollapsed, setNavCollapsed, width, setChangeSelectedKeys } = useContext(ThemeContext)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { badgeFeatures } = useAppSelector(selectFeatureBadgeSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { visibleOnboardingDrawer, hideOnboardingFlow } = useAppSelector(selectOnboardingSlice)
  const { whatsNewsIds } = useAppSelector(selectWhatsNewsSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const dispatch = useAppDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ onboardingConfig, setOnboardingConfig ] = useContext(OnboardingContext)

  const [ showSetupMSBot, setShowSetupMSBot ] = useState(false)
  const [ showAnnouncement, setShowAnnouncement ] = useState(false)
  const [ isHelpDrawerVisible, setShowHelpDrawer ] = useState(false)
  const [ isReadingFromStorage, setIsReadingFromStorage ] = useState(true)
  const [ showWhatsNew, setShowWhatsNew ] = useState(false)
  const [ whatsNewData, setWhatsNewData ] = useState<IWhatsNewComponent[] | []>([])
  const [ unreadWhatsNews, setUnreadWhatsNews ] = useState(0)

  const location = useLocation()

  useEffect(() => {
    if (location.search.includes('tour=true') && authUser && hideOnboardingFlow) {
      onQuickTourClick()
    }
  }, [location.search, authUser, hideOnboardingFlow])

  useEffect(() => {
    const badgeFeaturesFromStorage = localStorage.getItem('badgeFeatures')
    badgeFeaturesFromStorage && dispatch(setAllBadgeFeatures(JSON.parse(badgeFeaturesFromStorage)))
    setIsReadingFromStorage(false)
  }, [])

  useEffect(() => {
    if (authUser.role !== 'User') {
      getWhatsNew()
    }
  }, [authUser])

  useEffect(() => {
    if (whatsNewsIds.length > 0 && difference(whatsNewsIds, whatsNewData.map(item => item.messageId)).length > 0 && authUser.role !== 'User') {
      getWhatsNew()
    }
  }, [whatsNewsIds])

  const getWhatsNew = async () => {
    const response = await API.graphql(graphqlOperation(getWhatsNewQuery, { limit: 5 })) as IData<IWhatsNewQuery>
    setWhatsNewData(response.data?.getWhatsNew?.news)
    setUnreadWhatsNews(response.data?.getWhatsNew?.news.filter(news => news.unread).length)
    dispatch(setReduxWhatsNewData(response.data?.getWhatsNew?.news.map(item => item.messageId)))
  }

  const onToggleCollapsedNav = () => {
    setNavCollapsed(!navCollapsed)
  }

  const handleAnnouncement = () => {
    setShowAnnouncement(!showAnnouncement)
  }

  const handleWhatsNew = () => {
    setShowWhatsNew(!showWhatsNew)
    handleUnreadNews()
  }

  const handleUnreadNews = async () => {
    await Promise.all(whatsNewData.map(async (news: IWhatsNewComponent) => {
      if (news.unread) {
        await API.graphql(graphqlOperation(setWhatsNewAsReaded, {
          messageId: news.messageId,
        }))
        setUnreadWhatsNews((news => {
          return news - 1
        }))
        wait(3000)
      }
      return Promise.resolve()
    }))
  }

  const onShowHelpDrawer = () => {
    setShowHelpDrawer(true)
  }

  const closeDrawer = () => {
    setShowHelpDrawer(false)
    setShowWhatsNew(false)
  }

  const onQuickTourClick = () => {
    setShowHelpDrawer(false)
    setChangeSelectedKeys(true)
    if (width < TAB_SIZE && !navCollapsed) {
      setNavCollapsed(true)
      setTimeout(() => {
        setOnboardingConfig({
          run: true,
          restart: true,
          role: authUser.role.toLowerCase(),
        })
        setChangeSelectedKeys(false)
      }, 800)
    } else {
      setOnboardingConfig({
        run: true,
        restart: true,
        role: authUser.role.toLowerCase(),
      })
      setTimeout(() => {
        setChangeSelectedKeys(false)
      }, 800)
    }
  }

  const onProfileMenuClick = (visible: boolean): void => {
    if (!visible) {
      setTimeout(() => { // A short timeout to wait for the close animation to be finished
        dispatch(setBadgeFeature({ feature: BadgeFeaturesEnum.profileMenuNotificationSeen, value: true }))
      }, 500)
    }
  }

  const menu = (
    <Menu className="header-my-profile-menu">
      {!badgeFeatures[BadgeFeaturesEnum.profileMenuNotificationSeen] &&
        <Alert
          message={<IntlMessages id='containers.topbar.profile.info'  />}
          type="info"
          style={{ margin: 6, maxWidth: 300 }}
          showIcon
        />
      }
      <Menu.Item key="my-profile">
        {authUser.role === 'Admin' ?
          <Link to={`/app/users/${authUser.id}`}>
            <IntlMessages id="app.myProfile" />
          </Link> :
          <Link to="/app/my-profile">
            <IntlMessages id="app.myProfile" />
          </Link>
        }
      </Menu.Item>

      <Menu.Item key="account-settings">
        <Link to={FrontendUrls.accountSettings}>
          <IntlMessages id="app.accountSettings" />
        </Link>
      </Menu.Item>

      <Menu.Divider />
      {hideOnboardingFlow && // Onboarding A/B testing, for B test show quick tour
        <>
          <Menu.Item key="tour" onClick={onQuickTourClick}>
            <IntlMessages id="app.takeQuickTour" />
          </Menu.Item>
          <Menu.Divider />
        </>
      }
      <Menu.Item key="logout">
        <Button
          onClick={() => {
            dispatch(setBadgeFeature({ feature: BadgeFeaturesEnum.profileMenuNotificationSeen, value: true }))
            onLogout()
          }}
          className="menu-link-button"
          type="link"
          size="small"
          style={{ paddingLeft: 0 }}
          danger
          block
        >
          <IntlMessages id="app.logout" />
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Header>
      {width < TAB_SIZE &&
        <Button className="btn-collapse-menu" type="link" onClick={onToggleCollapsedNav}>
          <MenuOutlined />
        </Button>
      }
      <ul className="header-notifications">
        {(authUser.platform === 'microsoft' && !authCompany?.msIsBotInstalled) &&
          <li>
            <Button type="primary" onClick={() => setShowSetupMSBot(!showSetupMSBot)}>
              <IntlMessages id="containers.topbar.setUpTheBot" />
            </Button>
          </li>
        }
        {(authUser.role === 'Admin') &&
          <li>
            <Button onClick={() => {
              track('ANNOUNCEMENT_CLICKED', { platform: authUser.platform })
              handleAnnouncement()
            }} shape="circle">
              <NotificationOutlined />
            </Button>
          </li>
        }
        {(authUser.role !== 'User' && whatsNewData.length > 0) &&
          <li>
            <Tooltip placement="bottom"
              title={unreadWhatsNews > 0 ?
                <IntlMessages id="containers.topbar.whatsNewUnread" values={{ unread: unreadWhatsNews }} /> : ''
              }
            >
              <Badge count={unreadWhatsNews > 0 ? unreadWhatsNews : ''}>
                <Button onClick={handleWhatsNew} shape="circle">
                  <BulbOutlined />
                </Button>
              </Badge>
            </Tooltip>
          </li>
        }
        <li>
          <Button onClick={onShowHelpDrawer} shape="circle">
            <QuestionOutlined />
          </Button>
        </li>
        <li className="joyride-start">
          <Dropdown overlay={menu} trigger={['click']} onVisibleChange={onProfileMenuClick}>
            <Button type="link" size="large" shape="round" className="profile-button">
              <Badge count={!(isReadingFromStorage || badgeFeatures[BadgeFeaturesEnum.profileMenuNotificationSeen]) ? 1 : 0}>
                <div className="profile-button-wrapper">
                  <div className="profile-button-name-wrapper">
                    <Text className="profile-button-text profile-button-name">{authUser.name}</Text>
                    <Text className="profile-button-text profile-button-company-name" type="secondary">{authCompany?.name}</Text>
                  </div>
                  <UserAvatar id={authUser.id} name={authUser.name} className="profile-button-avatar" shape="circle" avatarSize="default" avatar={authUser.imageUrl}></UserAvatar>
                </div>
              </Badge>
            </Button>
          </Dropdown>
        </li>
      </ul>
      {showSetupMSBot &&
        <SetupMSBot
          visibleModal={showSetupMSBot}
          handleCancel={() => setShowSetupMSBot(false)}
        />
      }
      {showAnnouncement &&
        <AnnouncementModal
          visible={showAnnouncement}
          onCancel={handleAnnouncement}
          platform={authCompany?.platform || ''}
        />
      }
      <HelpDrawer
        isHelpDrawerVisible={isHelpDrawerVisible}
        onHelpDrawerClose={closeDrawer}
        startQuickTour={onQuickTourClick}
        userRole={authUser.role}
        platform={authUser.platform}
      />
      <WhatsNew
        isDrawerVisible={showWhatsNew}
        onDrawerClose={closeDrawer}
        whatsNew={whatsNewData}
        locale={locale.locale}
      />
      <OnboardingAdmin
        isOnboardingAdminVisible={visibleOnboardingDrawer}
        onboardingAdminClose={() => dispatch(handleOnboardingVisible())}
        platform={authUser.platform}
        isMicrosoftBillingPayment={isMicrosoftBillingPayment}
      />
    </Header>
  )
}

export default Topbar
