import { ICoreEvent } from './core-event'
import { SubscriptionPlan, SubscriptionStatus } from './company'
import { MicrosoftBillingStatus } from './microsoft-billing'
import { StripePaymentStatus } from './stripe'


export type PaymentProcessor = 'stripe' | 'microsoft-billing'

export enum CancelReasonEnum {
  weSiwtchedToAnHrSolution = 'We have switched to an HR software',
  anotherLeaveTrackingTool = 'We have found another leave tracking tool',
  missingFeatures = 'The tool does not have the features my team needs',
  priceOverBudget = 'The price is above our price budget',
  other = 'Other',
  cancelTrial = 'Cancel Trial',
  weAreMovingToPayroll = 'We are moving to a Payroll system',
  canceledByPaymentProcessor = 'Canceled by payment processor webhook event',
}

export type SubscriptionPeriod = 'monthly' | 'annual'

export type AddonsType = 'Automations' | 'Addons' // 'Integrations' - latter

export interface IBillingAddress {
  city: string
  country: string
  state?: string
  address: string
  addressLine2?: string
  postalCode?: string
}

export interface IBillingTax {
  vatCountryId: string
  vatId: string
}

export interface IBilling {
  companyId: string
  paymentProcessor: PaymentProcessor
  paymentProcessorId: string
  subscriptionPlan: SubscriptionPlan
  subscriptionPeriod: SubscriptionPeriod
  subscriptionStatus?: SubscriptionStatus // don't rely on this field yet, it was intoduced later and doesn't have value for every company
}

export enum BillingTypesEnum {
  payAsYouGo = 'PAY_AS_YOU_GO',
  seatBased = 'SEAT_BASED',
}

export interface IBillingInfoStripe extends IBilling {
  paymentProcessor: 'stripe'
  billingName: string
  billingEmails: string[]
  address?: IBillingAddress
  tax?: IBillingTax
  sendInvoice?: boolean
  paymentMethodAdded?: boolean
  nextSubscriptionPlan?: SubscriptionPlan
  nextSubscriptionPeriod?: SubscriptionPeriod
  hasScheduledChange?: boolean
  cancelAtPeriodEnd?: boolean
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  stripeSubscriptionItemId?: string
  stripeAutomationSubscriptionItemId?: string
  stripePriceId?: string
  stripeCurrentPeriodEnd: number
  stripePaymentStatus: StripePaymentStatus
  couponCodes?: string[]
  billingType?: BillingTypesEnum
  seats?: number
}

export interface IBillingInfoMicrosoft extends IBilling {
  paymentProcessor: 'microsoft-billing'
  msSubscriptionId: string
  quantity: number
  msPlanId: string
  msSubscriptionStatus: MicrosoftBillingStatus
  msPeriodStartDate?: string
  msPeriodEndDate?: string
}

export interface IBillingCountItem {
  companyId: string
  count: number
  timestamp: string
  maxSeats?: number
}

export interface IBillingUpdatedEvent extends ICoreEvent {
  eventGroup: 'BILLING'
  eventType: 'BILLING_UPDATED'
  companyId: string
  paymentProcessor: PaymentProcessor
  billingName: string
  billingEmails: string[]
  address?: IBillingAddress
  tax?: IBillingTax
  sendInvoice?: boolean
}

export interface IBillingUpdatedEventStripe extends IBillingUpdatedEvent {
  paymentProcessor: 'stripe'
  stripePaymentMethodId?: string
  stripePriceId?: string
  stripeCouponId?: string
  trial?: boolean
  trialEnd?: number
}

export interface IBillingUpdatedV2Event extends ICoreEvent {
  eventGroup: 'BILLING'
  eventType: 'BILLING_UPDATED'
  companyId: string
  paymentProcessor: PaymentProcessor
  subscriptionPlan: SubscriptionPlan
  subscriptionPeriod: SubscriptionPeriod
}

export interface IBillingUpdatedV2EventStripe extends IBillingUpdatedV2Event {
  paymentProcessor: 'stripe'
  keepLegacyPlan: boolean
  billingName: string
  billingEmails: string[]
  address?: IBillingAddress
  stripePaymentMethodId?: string
  stripeCouponId?: string
  trialEnd?: number
  billingType?: BillingTypesEnum
  seats?: number
  activeUsers?: number
}

export interface IBillingUpdatedV2EventMicrosoft extends IBillingUpdatedV2Event {
  paymentProcessor: 'microsoft-billing'
  msPurchaseToken?: string
  quantity?: number
}

export interface IPaymentProcessorUpdatedEvent extends ICoreEvent {
  eventGroup: 'BILLING'
  eventType: 'PAYMENT_PROCESSOR_UPDATED'
  billingName?: string
  address?: IBillingAddress
}

export interface IPaymentProcessorUpdatedEventStripe extends IPaymentProcessorUpdatedEvent {
  paymentProcessor: 'stripe'
  stripeCustomerId: string
  activeUsers?: number
  stripeSubscriptionId?: string
  stripeSubscriptionItemId?: string
  stripeAutomationSubscriptionItemId?: string
  stripePriceId?: string
  stripeCurrentPeriodEnd?: number
  stripePaymentStatus?: StripePaymentStatus
  seats?: number
}

export interface ISubscriptionChangedEvent extends ICoreEvent {
  eventGroup: 'BILLING'
  eventType: 'SUBSCRIPTION_CHANGED'
  companyId: string
  paymentProcessor: PaymentProcessor
  plan: SubscriptionPlan
  activeUsers?: number
  subscriptionPeriod?: SubscriptionPeriod
  hasScheduledChange: boolean
  cancelAtPeriodEnd: boolean
  subscriptionStatus: SubscriptionStatus
}

export interface ISubscriptionChangedEventStripe extends ISubscriptionChangedEvent {
  paymentProcessor: 'stripe'
  stripeCurrentPeriodEnd: number
  stripePaymentStatus: StripePaymentStatus
}

export interface ISubscriptionChangedEventMicrosoft extends ISubscriptionChangedEvent {
  paymentProcessor: 'microsoft-billing'
  quantity: number
  msPlanId: string
  msSubscriptionStatus: MicrosoftBillingStatus
  isTrial: boolean
  msPeriodStartDate?: string
  msPeriodEndDate?: string
}

export interface ISubscriptionCanceledEvent extends ICoreEvent {
  paymentProcessor: PaymentProcessor
  cancelReason: CancelReasonEnum
  cancelReasonComment?: string
  integrationWithHr?: string
  weAreMovingToPayroll?: string
  activeUsers?: number
  isInTrial?: boolean
  currentPlan?: SubscriptionPlan
  currentPeriod?: SubscriptionPeriod
}

export interface IBillingCountNewRecordParams {
  TableName: string
  Item: {
    companyId: string
    timestamp: string
    count: number
    maxSeats?: number
  }
}

export type ExistingBillingInfo = IBillingInfoStripe | null

export enum PriceChangeEnum {
  noChange = 'noChange',
  downgrade = 'downgrade',
  upgrade = 'upgrade',
}
export enum SeatChangeEnum {
  noChange = 'noChange',
  decrease = 'decrease',
  increase = 'increase',
}


export const USER_PLAN_LIMIT = 25
