export interface IQuestion {
  id: string
  name: string
  options?: IQuestionOption[]
  textarea?: boolean
}

export interface IQuestionOption {
  id: string
  name: string
  subQuestion?: IQuestion
  textarea?: boolean
  defaultLabel?: boolean
}

export interface ISurvey {
  id: string
  name: string
  questions: IQuestion[]
}

const aboutUsSurvey: ISurvey = {
  id: 'surveyAboutUs',
  name: 'About us survey',
  questions: [
    {
      id: '1',
      name: 'How did you find out about Vacation Tracker?',
      options: [
        {
          id: '1-1',
          name: 'Google Search',
          subQuestion: {
            id: '1-1-1',
            name: 'What did you search for on Google (e.g. "vacation tracking")?',
            textarea: true,
          },
        },
        {
          id: '1-2',
          name: 'App Store (Slack - App Directory, MS Teams - App Source, etc...)',
        },
        {
          id: '1-3',
          name: 'Recommendation',
          subQuestion: {
            id: '1-3-1',
            name: 'That\'s great! Who recommended us?',
            options: [
              {
                id: '1-3-1-1',
                name: 'A colleague',
              },
              {
                id: '1-3-1-2',
                name: 'A friend',
              },
              {
                id: '1-3-1-3',
                name: 'I read about Vacation Tracker on a website',
                subQuestion: {
                  id: '1-3-1-3-1',
                  name: 'Can you tell us which website that was?',
                  textarea: true,
                },
              },
              {
                id: '1-3-1-4',
                name: 'Other',
                textarea: true,
                defaultLabel: true,
              },
            ],
          },
        },
        {
          id: '1-4',
          name: 'Other',
          textarea: true,
          defaultLabel: true,
        },
      ],
    },
    
  ],
}

export default aboutUsSurvey
  