import { AccrualType, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { IGetLocationsShort } from './locations'
import { IGetLeaveTypeList } from './custom-queries'
import { LeaveTypeCalendarStatusesEnum } from '@vacationtracker/shared/types/calendar'

export enum RolloverStatus {
  no = 'no',
  limited = 'limited',
  all = 'all',
}

export interface IGetLeaveTypesListAndLocationsData {
  data: {
    getLocationList: IGetLocationsShort[]
    getLeaveTypeList: IGetLeaveTypeList[]
  }
}

export interface IGetLeaveTypesList {
  data: {
    getLeaveTypeList: IGetLeaveTypeList[]
  }
}
export interface ILeaveTypes {
  id: string
  isActive: boolean
  leaveType: {
    name: string
    isActive: boolean
    color: string
    position: number
  }
}

export interface ILeavePolicy {
  id: string
  daysPerYear: number
  maxRolloverDays: number
  isApprovalRequired: boolean
  hasUnlimitedDays: boolean
  allowHalfDays?: boolean
  broughtForwardExpirationSettings?: {
    enabled: boolean
    day: number | null
    month: number | null
    afterDays: number | null
  }
  shortestLeaveInterval?: ShortestLeaveIntervalEnum
  hideLeaveType: boolean
  isReasonRequired: boolean
  isActive: boolean
  negativeBallanceAllowed: boolean
  accrualType: AccrualType
  firstEarningDate?: string
  accrualPeriodStart?: string
  accrualsLocked?: boolean
  accrualCapRate?: number
  leaveType: {
    id: string
    name: string
    isActive: boolean
    position: number
    deleted: boolean
  }
  locationId: string
  enableRolloverExpiry?: boolean
  rolloverExpiryAfterDays?: number | null
  rolloverExpiryDay?: number | null
  rolloverExpiryMonth?: number | null
  toil: boolean
  toilShortestInterval?: ShortestLeaveIntervalEnum
  toilRequestsAllowedForUsers?: boolean
  toilExpiration?: boolean
  toilExpirationDate?: string
  toilExpirationMonths?: number
}

export interface ILeavePolicyForm {
  accrualPeriod?: Date[]
  accrualType: AccrualType
  isApprovalRequired: boolean
  hasUnlimitedDays: boolean
  shortestLeaveInterval: ShortestLeaveIntervalEnum
  hideLeaveType: boolean
  negativeBallanceAllowed: boolean
  daysPerYear: number
  isReasonRequired: boolean
  maxRolloverDays: number
  cancelUserSettings?: boolean
  firstEarningDate?: Date
  accrualPeriodStart?: Date
  allowLeaveInPast?: Date
  enableRolloverExpiry?: boolean
  rolloverExpiryAfterDays?: number | null
  rolloverExpiryDay?: number | null
  rolloverExpiryMonth?: number | null
  allowAdvanceAccrualUsage?: boolean
  pauseAccrualsWhenUserIsDeactivated?: boolean
  toil: boolean
  toilShortestInterval?: ShortestLeaveIntervalEnum
  toilRequestsAllowedForUsers?: boolean
  toilExpiration?: boolean
  toilExpirationDate?: string
  toilExpirationMonths?: number
}

export interface ILeavePolicyShort {
  id: string
  location: {
    id: string
    name: string
  }
  isActive: boolean
  locationId?: string
}

export interface ILeaveType {
  leavePolicies?: ILeavePolicyShort[]
  id: string
  name: string
  isActive: boolean
  color: string
  position: number
  calendarStatus?: LeaveTypeCalendarStatusesEnum.busy
  awayStatus?: boolean
  awayStatusIcon?: string
  awayStatusMessage?: string
}

export interface ILeaveTypeUpdates extends ILeaveType {
  leaveTypeId: string
}

export interface ILeaveTypeWithAssignLocations extends IGetLeaveTypeList {
  index: number
  key: number
  name: string
  id: string
  assignedToLocations: {
    id: string
    name: string
  }[]
}

export interface ILeaveTypeCreate {
  eventType: string
  eventGroup: string
  name: string
  color: string
  isActive: boolean
  calendarStatus: LeaveTypeCalendarStatusesEnum
  locationIds: string[]
  leavePolicy: {
    negativeBallanceAllowed: boolean
    daysPerYear: number
    maxRolloverDays: number
    hasUnlimitedDays: boolean
    shortestLeaveInterval: ShortestLeaveIntervalEnum
    hideLeaveType: boolean
    isApprovalRequired: boolean
    isReasonRequired: boolean
    allowLeaveInPast: boolean
  }
  version: number
  awayStatus?: boolean
  awayStatusIcon?: string
  awayStatusMessage?: string
}
