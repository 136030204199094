import React from 'react'
import { Button, Form, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { PaymentMethodTypeEnum } from '@vacationtracker/shared/types/payment-information'

const { Title, Text } = Typography

interface IPaymentInformation {
  customerPortalLink?: string
  card: string
  expirationDate: string
  type?: PaymentMethodTypeEnum
}

const PaymentInformation = ({
  customerPortalLink,
  card,
  expirationDate,
  type = PaymentMethodTypeEnum.card,
}: IPaymentInformation): React.ReactElement => {

  return (
    <>

      <Title level={4}>
        <IntlMessages id="billing.paymentInformation" />
        {customerPortalLink && (
          <Button type="link" size="large" href={customerPortalLink} target="_blank" rel="noopener noreferrer">
            <IntlMessages id="app.edit" />
          </Button>
        )}
      </Title>

      {type === PaymentMethodTypeEnum.card && <>
        <Form.Item
          label={<>
            <span style={{color: 'red'}}>* </span>
            <IntlMessages id="app.creditCard" />
          </>}
        >
          <Text strong>**** **** **** {card}</Text>
        </Form.Item>
        <Form.Item label={<IntlMessages id="components.paymentInformation.expiryDate" />}>
          <Text strong>{expirationDate}</Text>
        </Form.Item>
      </>}

      {type === PaymentMethodTypeEnum.link && <Form.Item label={<IntlMessages id="billing.paymentMethod" />}>
        <Text strong italic>
          <IntlMessages
            id="billing.linkPaymentMethod"
          />
        </Text>
      </Form.Item>}

    </>
  )
}

export default PaymentInformation
