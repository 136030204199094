import React from 'react'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import isNull from 'lodash/isNull'

import IntlMessages from '../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { IPaymentInfoResponseDiscount } from '@vacationtracker/shared/types/payment-information'

interface IBillingCouponCode {
  subscriptionCanceled?: boolean
  isApplingCoupon: boolean
  isCouponDirty: boolean
  setIsCouponDirty: (e) => void
  discount?: IPaymentInfoResponseDiscount
}

const { Title } = Typography

const BillingCouponCode = ({ subscriptionCanceled, isApplingCoupon, isCouponDirty, setIsCouponDirty, discount }: IBillingCouponCode): React.ReactElement => {
  
  return (
    <>
      <Title level={4}>
        <IntlMessages id="components.billingCouponCode.title" />
      </Title>
      {discount ?
        <Form.Item 
          wrapperCol={{ span: 24 }} 
          labelCol={{ md: { span: 4}, sm: { span: 6 }}}
          label={<IntlMessages id="components.billingCouponCode.activeCouponInfoTitle" />}
        >
          <IntlMessages
            id="components.billingCouponCode.activeCouponInfo"
            values={{ 
              start: <FormattedDate value={new Date(discount.start * 1000).toISOString()} format="MMMM Do YYYY." />,
              off: discount?.percent_off || (discount?.amount_off as number / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              duration: discount?.duration_in_months || discount?.duration,
              percentOffCover: (...chunks) => isNull(discount.percent_off) ? '' : <strong>{chunks}</strong>,
              amountOffCover: (...chunks) => isNull(discount?.amount_off) ? '' : <strong>{chunks}</strong>,
              durationCover: (...chunks) => isNull(discount?.duration_in_months) ? <strong>{chunks}</strong> : '',
              durationInMonthsCover: (...chunks) => isNull(discount?.duration_in_months) ? '' : <strong>{chunks}</strong>,
            }}
          />
        </Form.Item> :
        <Form.Item 
          wrapperCol={{ span: 24 }} 
          labelCol={{ md: { span: 4}, sm: { span: 6 }}}
          label={<IntlMessages id="components.billingCouponCode.couponCode" />}
        >
          <Row gutter={8}>
            <Col xxl={6} xl={8} lg={10} md={12} sm={{ span: 24 }} xs={{ span: 24 }}>
              <Form.Item name="promoCode" rules={[{ required: false }]} >
                <Input size='middle' onChange={v => setIsCouponDirty(Boolean(v.target.value))} />
              </Form.Item>
            </Col>
            <Col xxl={18} xl={16} lg={14} md={12} sm={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isApplingCoupon}
                  disabled={isApplingCoupon || !isCouponDirty}
                >
                  {subscriptionCanceled ?
                    <IntlMessages id="components.billingCouponCode.applyCodeAndResubscribe" /> :
                    <IntlMessages id="components.billingCouponCode.applyCode" />
                  }
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      }
    </>
  )
}

export default BillingCouponCode
