import React, { useEffect, useState } from 'react'
import { ISlackPostInstallationProps } from './types'
import { SeoTags } from '../../components/seo-tags'
import { Button, Col, Row, Skeleton, Typography, notification } from 'antd'
import { SlackOutlined } from '@ant-design/icons'
import VtLogo from '../../assets/images/logo-purple.svg'
import IntlMessages from '../../util/IntlMessages'
import { getUserId as getSlackUserId } from '../../services/api/slack'
import qs, { ParsedQs } from 'qs'
import { SlackAuth } from '../../services/auth/slack/slack'
import { track } from '../../services/analytics/analytics'
import { prepareUtmTags } from '../../util/get-and-parse-cookie'
import { SLACK_APPSTORE_INSTALL_BUTTON_UTM_TAGS } from '@vacationtracker/shared/data/utm-tags'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router'
import { updateSlackToken } from '../../services/api/slack'
import { FrontendUrls } from '../../types/urls'
import { getCompanyAndUser } from '../../services/api/companies'
import { useAppDispatch } from '../../store/hooks'
import { setUserId } from '../../store/user-id-slice'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'
import { useIntl } from 'react-intl'
import { ISlackTokenFromCodeResponse } from '@vacationtracker/shared/types/slack'

const { Paragraph, Title } = Typography
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)

const SlackPostInstallation = (props: ISlackPostInstallationProps): React.ReactElement => {
  const { onStateChange } = props

  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [userName, setUserName] = useState<string | null>(null)
  const [slackCode, setSlackCode] = useState<string | null>(null)
  const [userToken, setUserToken] = useState<string | null>(null)
  const [slackUserData, setSlackUserData] = useState<ISlackTokenFromCodeResponse | null>(null)
  const [botToken, setBotToken] = useState<string | null>(null)
  const [userSlackId, setUserSlackId] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoadingStatus] = useState(true)
  const [queryParams] = useState<ParsedQs>(qs.parse(location.search, { ignoreQueryPrefix: true }))

  const resolveSlackCode = async (code: string) => {
    setError(null)
    try {
      const tokenResponse = await slackAuth.getTokenFromCode(
        code as string,
        `${window.location.origin}/slack/post-installation?${SLACK_APPSTORE_INSTALL_BUTTON_UTM_TAGS}`
      )

      setSlackUserData(tokenResponse)

      const userIdFromCode = tokenResponse.authed_user.id
      const userTokenFromCode = tokenResponse.authed_user.access_token
      const botTokenFromCode = tokenResponse.access_token

      setUserSlackId(userIdFromCode)
      setUserToken(userTokenFromCode)
      setBotToken(botTokenFromCode)

      slackAuth.setBotToken(botTokenFromCode)
      slackAuth.setUserToken(userTokenFromCode)

      const user = await slackAuth.getUser(userIdFromCode, userTokenFromCode)

      setUserName(user.real_name as string || user.name as string)
      setLoadingStatus(false)
    } catch (error) {
      console.log('error', error)
      setError(typeof error === 'string' ? error : error.message as string)
      setLoadingStatus(false)
    }
  }

  const installSlackApp = async () => {
    setError(null)
    setLoadingStatus(true)
    try {
      const slackInstallResponse = await slackAuth.signin(
        SLACK_REQUIRED_BOT_SCOPES,
        SLACK_REQUIRED_USER_SCOPES
      )

      setUserSlackId(slackInstallResponse.authed_user.id)
      setUserToken(slackInstallResponse.authed_user.access_token)
      setBotToken(slackInstallResponse.access_token)

      const user = await slackAuth.getUser(slackInstallResponse.authed_user.id, slackInstallResponse.authed_user.access_token)

      setUserName(user.real_name as string || user.name as string)
      setLoadingStatus(false)
    } catch (error) {
      console.log('error', error)
      setError(typeof error === 'string' ? error : error.message as string)
      setLoadingStatus(false)
    }
  }

  const connectWithSlack = async (slackId: string, token: string, botToken?: string) => {
    try {
      setLoadingStatus(true)
      await slackAuth.connect()
      const userData = await getSlackUserId(slackId, token)
      
      if (userData.type === 'COMPANY_NOT_FOUND') {
        setLoadingStatus(false)
        localStorage.setItem('vtCreateUser', JSON.stringify({
          name: userData.name,
          id: `slack-${userData.id}`,
          mail: userData.mail,
          userEmail: userData.mail,
          platform: 'slack',
          slackBotToken: botToken,
          slackUserToken: token,
          slackUserId: slackId,
          teamName: slackUserData?.team.name,
          slackTeamId: slackUserData?.team.id,
          slackOrgTeamId: slackUserData?.enterprise?.id,
          slackBotId: slackUserData?.bot_user_id,
          imageUrl: userData?.slackUser?.imageUrl,
          isOwner: userData?.slackUser?.isOwner,
          isAdmin: userData?.slackUser?.isAdmin,
          timezone: userData?.slackUser?.timezone,
        }))
        onStateChange('signUp')
        history.push(FrontendUrls.createCompanyStep1)
        return
      }

      if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
        notification.error({
          message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
          description: formatMessage({ id: 'error.subscriptionExpired' }),
          duration: 0,
        })
        return
      }

      // TODO:@filip remove prefix
      const signInResponse = await Auth.signIn(`slack-${userData.vacationTrackerId}`)
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
        const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: 'slack' })
        localStorage.setItem('userId', cognitoResponse.username)

        try {
          await updateSlackToken(token, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setLoadingStatus(false)
            setError('bot_token_not_valid')
            return
          }
        }
        dispatch(setUserId(cognitoResponse.username))
        await getCompanyAndUser(cognitoResponse.username, dispatch, onStateChange, history)
        onStateChange('signedIn')
        history.push(FrontendUrls.dashboard)
      }
    } catch (error) {
      console.log('error', error)
      setLoadingStatus(false)
      setError(typeof error === 'string' ? error : error.message as string)
    }
  }

  useEffect(() => {
    if (slackCode !== null) {
      resolveSlackCode(slackCode)
    }
  }, [slackCode])

  useEffect(() => {
    track('SIGNUP_STARTED', {
      platform: 'slack',
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      utmCookie: prepareUtmTags(queryParams as { [key: string]: string }) as any,
    })
    if (!queryParams || !queryParams.code || typeof queryParams.state !== 'string' || !queryParams.state?.includes('slack-')) {
      setError('no_code')
      setLoadingStatus(false)
      return
    }
    setSlackCode(queryParams.code as string)
  }, [queryParams])

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signup'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48 },{ xs: 16, lg: 48 }]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 24 }}>
            {
              error === null ? (
                <Skeleton loading={loading} paragraph={{ rows: 2, width: 320 }} title>
                  <Title level={3} style={{ textAlign: 'center' }}>{
                    <IntlMessages id='connect.welcome' values={{
                      name: userName,
                    }} />
                  }</Title>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation1' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation2' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation3' />
                  </Paragraph>
                </Skeleton>
              ) : (
                <>
                  <Title level={3} style={{ textAlign: 'center' }}>
                    Install Vacation Tracker bot
                  </Title>
                  {
                    error === 'invalid_code' ? (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.invalidCode' />
                      </Paragraph>
                    ) : (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.generic' />
                      </Paragraph>
                    )
                  }
                  <Paragraph style={{ textAlign: 'center' }}>
                    Click the button below to install the Vacation Tracker bot to your Slack workspace.
                  </Paragraph>
                </>
              )
            }
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 12 }}>
            { loading ? (
              <Skeleton.Button />
            ) : (
              (error === null && userSlackId && userToken) ? (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={
                  () => connectWithSlack(userSlackId, userToken, botToken ?? undefined)
                }>
                  <IntlMessages id="connect.signInWithSlack" />
                </Button>
              ) : (
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => installSlackApp()}>
                  <IntlMessages id="connect.signInWithSlackInstallBot" />
                </Button>
              )
            ) }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SlackPostInstallation
