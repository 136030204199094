import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Table, Dropdown, Menu, Tabs, Modal, Button, notification, Typography, Row, Col, Tooltip, Tag } from 'antd'
import { EllipsisOutlined, ExclamationCircleOutlined, LoadingOutlined, CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { sortBy, take, isEqual, filter, find } from 'lodash'
import { useSelector } from 'react-redux'
import { API, graphqlOperation } from 'aws-amplify'
import {
  getUsersFiltered,
  getLocationsTeamsAndLabelsShort,
  getLocationsApproverTeamsAndLabels,
  getGoogleScopes,
  getInvitations,
  getNumberOfActiveUsers,
  getMicrosoftUserId
} from '../../../graphql/custom-queries'
import * as Sentry from '@sentry/react'

import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../../services/auth/slack/slack'
import { GoogleAuth } from '../../../services/auth/google/google'
import { useAppSelector } from '../../../store/hooks'
import { isMicrosoftPayment, selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { roleAdmin, roleApprover, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectDateFormatSlice } from '../../../store/date-format-slice'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { notificationStore } from '../../../context/notificationsContext/store'
import { invertHexColor } from '../../../util/invert-color-wrapper'

import { ITeamShort } from '@vacationtracker/shared/types/team'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

import IntlMessages from '../../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import ChangeTeamForm from '../../../components/change-team-form'
import ChangeLocationForm from '../../../components/change-location-form'
import CircularProgress from '../../../components/circular-progress'
import { Search } from '../../../components/search'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import { InviteAndManageUsersWithEmail } from '../../../components/invite-and-manage-users-with-email'
import { isAfter } from 'date-fns'

import { IGetUsersFiltered, IUserFilteredInfo, IUserStatusChangedBody } from '../../../types/users'
import { IGetTeamsShort } from '../../../types/teams'
import {
  IGetGoogleScopes,
  IGetInvitations,
  IGetLocationsTeamsAndLabelsShort,
  IGetLocationsApproverTeamsAndLabelsShort,
  IGetMicrosoftId
} from '../../../types/custom-queries'
import { IGetLabelsShort } from '../../../types/labels'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { IData } from '../../../types/data'
import { ColumnsType } from 'antd/lib/table/interface'
import { IGetLocationsShort } from '../../../types/locations'
import { IEmailInvite } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { IUsersFilter, ActiveTabEnum, IUserInvitedEventParams } from './types'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { GOOGLE_READ_USER_PERMISSION } from '../../../constants'

const { TabPane } = Tabs
const { confirm } = Modal
const { Paragraph } = Typography
const supportLink = 'https://vacationtracker.crisp.help/en/article/google-workspace-editing-permissions-15pdkie/'

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const UsersPage = (): React.ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser: {
    id,
    platform,
    email,
    locale,
  } } = useAppSelector(selectAuthUserSlice)

  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const { actionNotifications, setActionNotifications, eventsNotifications } = useContext(notificationStore)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const amIAdmin = useSelector(roleAdmin)
  const amIApprover = useSelector(roleApprover)

  if (!authCompany?.platform) {
    throw new Error('Company is required!')
  }

  const [loadingUsers, setLoadingUsers] = useState(true)
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState<IUserFilteredInfo[]>([])
  const [userCount, setUserCount] = useState(0)
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [showLocationsModal, setShowLocationsModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUserFilteredInfo>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [teams, setTeams] = useState<IGetTeamsShort[] | ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showSyncExistingUsersModal, setShowSyncExistingUsersModal] = useState(false)
  const [isSubmittingSyncExistingUsersRequest, setIsSubmittingSyncExistingUsersRequest] = useState(false)
  const [disablePrev, setDisablePrev] = useState(true)
  const [disableNext, setDisableNext] = useState(true)
  const [usersPages, setUsersPages] = useState<string[]>([])
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [showEnableGoogleApiModal, setShowEnableGoogleApiModal] = useState(false)
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false)
  const [showReInviteUsersModal, setShowReInviteUsersModal] = useState(false)
  const [invites, setInvites] = useState<IEmailInvite[]>([])
  const [invitationLocale, setInvitationLocale] = useState<LocaleEnum>(locale || LocaleEnum.en)
  const [invited, setInvited] = useState<IEmailInvite[]>([])
  const [areInvitesLoading, setAreInvitesLoading] = useState(true)
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [filters, setFilters] = useState<IUsersFilter>({
    status: 'ACTIVE',
    name: '',
    locationIds: [],
    teamIds: [],
    labelIds: [],
    limit: 100,
    nextToken: '',
  })
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.active)
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [isInitialLoad, setIsInitialLoad] =  useState(true)
  const [showGoogleLoginRequiredModal, setShowGoogleLoginRequiredModal] = useState(false)
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const saveFilters = new URLSearchParams(location.search).get('saveFilters') !== 'false'

  useEffect(() => {
    if (isInitialLoad) {
      const [ locationIds = [], teamIds = [], labelIds = [] ] = ['locations', 'departments', 'labels'].map(name => new URLSearchParams(location.search).get(name)?.split(','))

      setFilters(prevValues => {
        return {
          ...prevValues,
          locationIds,
          teamIds,
          labelIds,
        }
      })

      setInitialFilterValues(() => {
        const data: ISelected[] = []

        if (locationIds?.length) {
          data.push({ type: 'Locations', values: locationIds as string[] })
        }
        if (teamIds?.length) {
          data.push({ type: 'Departments', values: teamIds as string[] })
        }
        if (labelIds?.length) {
          data.push({ type: 'Labels', values: labelIds as string[]})
        }

        if(data.length === 0) {
          return [{}]
        }
        return data
      })

      fetchUserList({
        ...filters,
        locationIds,
        teamIds,
        labelIds,
      })
    }
  }, [])

  useEffect(() => {
    if (platform === 'email' || activeTab === ActiveTabEnum.invites) {
      fetchInvitations()
    }
  }, [actionNotifications, activeTab])

  useEffect(() => {
    if (!isInitialLoad) {
      fetchUserList(filters)
    }
  }, [filters])

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  useEffect(() => {
    if (isInitialLoad) {
      return
    }

    if (eventsNotifications[correlationId]) {
      setCorrelationExist(true)
      fetchUserList(filters)
    } else if (!eventsNotifications[correlationId] && correlationExist) {
      setCorrelationExist(false)
      setCorrelationId('')
      fetchUserList(filters)
    }
  }, [eventsNotifications])

  useEffect(() => {
    if (location.search.includes('openImportUsers=true')) {
      importNewUsers()
    }
  }, [location.search])

  useEffect(() => {
    fetchUserCount()
  }, [])

  const fetchInvitations = async () => {
    setAreInvitesLoading(true)
    const response = await API.graphql(graphqlOperation(getInvitations)) as IData<IGetInvitations>
    setInvited(response.data.getInvitations.filter(invitation => invitation.status !== 'accepted'))
    setAreInvitesLoading(false)
  }

  const addInvite = (data) => {
    const invited = find(invites, invite => invite.email === data.email)
    setInvites(invited ? invites : [...invites, data])
  }

  const removeInvite = (data) => {
    const invited = find(invites, invite => invite.email === data.email)
    setInvites(invited ? filter(invites, invite => invite.email !== invited.email) : invites)
  }

  const changeLocale = (newLocale: LocaleEnum) => {
    setInvitationLocale(newLocale)
  }

  const errorNotificationHandler = (error, platform, onClickHandler) => {
    const isTokenError = error.message === 'no_tokens'
    const errorMessageDescription = isTokenError ? formatMessage({ id: 'error.token.description' }, {platform} ) : error.message
    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError &&
        <Button onClick={() => {
          onClickHandler()
          notification.close('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, {platform} )}
        </Button>,
      duration: 0,
    })
  }

  const fetchData = async () => {
    try {
      let response
      if (amIAdmin) {
        response = await API.graphql(graphqlOperation(getLocationsTeamsAndLabelsShort)) as IGetLocationsTeamsAndLabelsShort
        setTeams(response.data.getTeamList as IGetTeamsShort[])
      }
      if (amIApprover) {
        response = await API.graphql(graphqlOperation(getLocationsApproverTeamsAndLabels, { id } )) as IGetLocationsApproverTeamsAndLabelsShort
        setTeams(response.data.getUser.approverToTeams as ITeamShort[])
      }
      if (authCompany.platform === 'google') {
        try {
          const googleScopesResponse = await API.graphql(graphqlOperation(getGoogleScopes, { accessToken: googleAuth.getAccessToken()})) as IData<IGetGoogleScopes>
          const googleApiScopes = googleScopesResponse.data.getGoogleTokenInfo.scopes as string[]
          const vtGooogleIntegrationScopes = googleScopesResponse.data.getGoogleIntegrationSettings?.scopes
          if (googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION) && !vtGooogleIntegrationScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
            const userInfo = googleAuth.getSignedInUser()
            await API.post('CoreEvent', '/core/event', {
              body: {
                eventType: 'GOOGLE_INTEGRATION',
                eventGroup: 'INTEGRATION',
                scope: googleApiScopes.join(' '),
                domainName: userInfo.hd,
              },
            })
          }
        } catch (error) {
          console.log(error)
        }
      }

      setLocations(response.data.getLocationList as IGetLocationsShort[])
      setLabels(response.data.getLabels as IGetLabelsShort[])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('ERROR FETCH DATA', error)
    }
  }

  const fetchUserList = async (filters: IUsersFilter) => {
    try {
      setLoadingUsers(true)
      const response = await API.graphql(graphqlOperation(getUsersFiltered, filters)) as IGetUsersFiltered

      if (response.data.getUsersFiltered.nextToken && response.data.getUsersFiltered.users.length === filters.limit) {
        setDisableNext(false)
      } else {
        setDisableNext(true)
      }

      if (response.data.getUsersFiltered.nextToken) {
        setUsersPages(prevState => {
          return [...prevState, response.data.getUsersFiltered.nextToken]
        })
      }

      if (filters.nextToken === '') {
        setDisablePrev(true)
      } else {
        setDisablePrev(false)
      }

      const filteredUsers = response.data.getUsersFiltered.users
        .filter(user => user.location && user.team && user.name)

      setUserList(sortBy(filteredUsers, [(user) => user.name.toUpperCase()]))
      setLoadingUsers(false)
      setLoading(false)
      setIsInitialLoad(false)
    } catch (error) {
      console.log('ERROR FETCH USER LIST', error)
    }
  }

  const fetchUserCount = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getNumberOfActiveUsers)) as IData<{getCompany: {numberOfActiveUsers: number}}>
      setUserCount(response.data.getCompany.numberOfActiveUsers)
    } catch (error) {
      console.log('ERROR FETCH USER COUNT', error)
      setUserCount(0)
    }
  }

  const syncFromMsApi = () => {    
    setIsSubmittingSyncExistingUsersRequest(true)
    try {
      msAuth.getTokens()
      msAuth.refreshTokens()
        .then(async (token) => {
          await syncUsers(token.accessToken)
        })
        .catch(error => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (JSON.parse(error)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const err = JSON.parse(error)
            if (err.error === 'invalid_grant' || err.error === 'Authorization_RequestDenied') {
              setShowMsLoginRequiredModal(true)
              return
            }
          }
        })
    } catch (error) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const syncFromGoogleApi = async (): Promise<void> => {    
    setIsSubmittingSyncExistingUsersRequest(true)
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      setShowEnableGoogleApiModal(true)
    } else {
      setShowEnableGoogleApiModal(false)
      try {
        await googleAuth.tokenInfo()
        syncUsers(googleAuth.getAccessToken())
      } catch (error) {
        Sentry.captureException(error)
        setShowEnableGoogleApiModal(true)
        

        errorNotificationHandler(error, 'Google', getGoogleTokens)
      }
    }
  }

  const syncFromSlackApi = () => {
    try {
      setIsSubmittingSyncExistingUsersRequest(true)

      syncUsers(slackAuth.getUserToken())
    } catch (error) {
      console.log('ERROR FETCH FROM SLACK API', error)
      setShowSyncExistingUsersModal(false)
      errorNotificationHandler(error, 'Slack', getSlackTokens)
    }
  }

  const getBasicMsToken = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(async ([tokens, msUser, tenantId]) => {
        if (tenantId !== authCompany.msTeamId || `microsoft-${msUser.id}` !== id) {
          // if user doesn't match let's try to find him in the the microsoft ids table
          const microsoftUserId = await API.graphql(graphqlOperation(getMicrosoftUserId, { id } )) as IGetMicrosoftId
          if (microsoftUserId.data.getMicrosoftId?.microsoftUserId !== msUser.id) {
            wrongMicrosoftAccount()
            return
          }
        }
        setShowMsLoginRequiredModal(false)
        return syncFromMsApi()
      })
      .catch((error) => {
        Sentry.captureException(JSON.stringify(error))
        setShowMsLoginRequiredModal(false)
        setShowSyncExistingUsersModal(false)
        setIsSubmittingSyncExistingUsersRequest(false)
        errorNotificationHandler(error, 'Microsoft', getBasicMsToken)
      })

  }

  const wrongMicrosoftAccount = () => {
    msAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.microsoft.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({id: 'errors.microsoft.wrongMicrosoftAccountDescription'}, { email })}</Paragraph>
        <Button onClick={() => getBasicMsToken()}>{formatMessage({id: 'error.microsoft.logInWithDifferentAccount'})}</Button>
      </div>,
      duration: 0,
    })
  }

  const wrongSlackAccount = () => {
    slackAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.slack.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({id: 'errors.slack.wrongSlackAccountDescription'})}</Paragraph>
        <Button onClick={() => getSlackTokens()}>{formatMessage({id: 'error.slack.logInWithDifferentAccount'})}</Button>
      </div>,
      duration: 0,
    })
    setShowSlackLoginRequiredModal(false)
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      if ((!response.enterprise && response.team.id !== authCompany.organizationId) || (response.enterprise && response.enterprise?.id !== authCompany.organizationId)) {
        wrongSlackAccount()
        return
      }
      slackAuth.setUserToken(response.authed_user.access_token as string)
      try {
        await API.post('SlackApi', '/update-tokens', {
          body: {
            token: response.authed_user.access_token,
            botToken: response.access_token,
          },
        })
      } catch (error) {
        console.log('UPDATE TOKEN ERROR', error.response)
      }
      setShowSlackLoginRequiredModal(false)
      syncFromSlackApi()
    }).catch(error => {
      console.log('SLACK SIGNIN ERROR', error)
      handleErrorNotification({message: 'To proceed, please login to your Slack account'}, undefined, 'Missing Slack Scopes')
    })
  }

  const getGoogleTokens = () => {
    googleAuth.signin(true).then(() => {
      syncFromGoogleApi()
    })
  }

  const syncUsers = async (token: string) => {
    setIsSubmittingSyncExistingUsersRequest(true)
    const body = {
      eventType: 'SYNC_EXISTING_USERS',
      eventGroup: 'BULK_ACTIONS',
      organizationId: authCompany.organizationId,
      token,
    }

    const response = await API.post('CoreEvent', '/core/event', { body })
    setIsSubmittingSyncExistingUsersRequest(false)
    setShowSyncExistingUsersModal(false)
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'users.syncExistingUsers.InProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const importNewUsers = () => {
    if (platform === 'email') {
      setShowInviteUsersModal(true)
      return
    } else if (isMicrosoftBillingPayment) {
      return history.push('/app/users/manage-licences')
    } else {
      return history.push('/app/users/import')
    }
  }

  const updateUser = async (userId, data) => {
    try {
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'USER_STATUS_CHANGED',
          eventGroup: 'USER',
          userId,
          ...data,
        },
      })

      setUserList(userList.filter(user => user.id !== userId))
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.status.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setLoading(false)
    } catch (err) {
      console.log('ERROR UPDATE USER', err)
      setLoading(false)
    } finally {
      setSelectedRowKeys([])
    }
  }

  const changeTeam = async (teamId) => {
    try {
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'TEAM_USER_MOVED',
          eventGroup: 'USER',
          teamId,
          userId: selectedUser?.id,
        },
      })

      const teamName = (teams.find((team) => team.id === teamId) as ITeamShort).name
      setUserList(userList.map(user => {
        if (selectedUser?.id === user.id) {
          return {
            ...user,
            team: {
              id: teamId,
              name: teamName,
            },
          }
        } else {
          return user
        }
      }))
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.team.moveInProgress' }, { name: teamName }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setLoading(false)
      setShowTeamModal(false)
    } catch (error) {
      console.log('ERROR CHANGE TEAM', error)
      setLoading(false)
      setShowTeamModal(false)
    }
  }

  const changeLocation = async (locationId) => {
    try {
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LOCATION_USER_MOVED',
          eventGroup: 'USER',
          locationId,
          userId: selectedUser?.id,
        },
      })
      const locationName = (locations.find(location => location.id === locationId) as ILocationShort).name

      setUserList(userList.map(user => {
        if (selectedUser?.id === user.id) {
          return {
            ...user,
            location: {
              id: locationId,
              name: locationName,
            },
          }
        } else {
          return user
        }
      }))

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.location.moveInProgress' }, { name: locationName }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setLoading(false)
      setShowLocationsModal(false)
    } catch (error) {
      console.log('ERROR CHANGE LOCATION', error)
      setLoading(false)
      setShowLocationsModal(false)
    }
  }

  const changeUsersStatus = async (status) => {
    try {
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'BULK_USER_STATUS',
          eventGroup: 'BULK_ACTIONS',
          users: selectedRowKeys.map(userId => {
            return ({
              userId,
              status,
            })
          }),
        },
      })

      const newUsersData: IUserFilteredInfo[] = []
      userList.forEach(user => {
        if(!selectedRowKeys.find(userId => userId === user.id)) {
          newUsersData.push(user)
        }
      })

      setUserList(newUsersData)

      setSelectedRowKeys([])
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.bulkChangeStatusInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setLoading(false)
    } catch (error) {
      console.log('ERROR CHANGE USERS STATUS', error)
      setLoading(false)
    }
  }

  const changePage = (type) => {
    setLoading(true)
    let nextToken
    if (type === 'next') {
      nextToken = usersPages[usersPages.length - 1]
    }
    if (type === 'prev') {
      if (disableNext) {
        nextToken = usersPages[usersPages.length - 2]
        const newPagination = [...usersPages]
        newPagination.splice(-1)
        setUsersPages(newPagination)
      } else {
        nextToken = usersPages[usersPages.length - 3]
        const newPagination = [...usersPages]
        newPagination.splice(-2)
        setUsersPages(newPagination)
      }
    }

    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: nextToken ? nextToken : '',
      }
    })
  }

  const handleMenuClick = (event, userId, user: IUserFilteredInfo) => {
    const userData: IUserStatusChangedBody = {
      status: 'ACTIVE',
      platform: user.platform,
    }

    if (event.key === 'deactivateUser') {
      userData.status = 'INACTIVE'
      confirm({
        title: formatMessage({ id: 'users.deactivate' }),
        content: formatMessage({ id: 'users.deactivateUserConfirm' }, { name: user.name }),
        icon: <ExclamationCircleOutlined />,
        okText: formatMessage({ id: 'users.deactivate' }),
        onOk() { updateUser(userId, userData ) },
      })
    }
    if (event.key === 'activeUser') {
      updateUser(userId, userData)
    }
    if (event.key === 'changeUserTeam') {
      setShowTeamModal(true)
      setSelectedUser(user)
    }
    if (event.key === 'changeUserLocation') {
      setShowLocationsModal(true)
      setSelectedUser(user)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.handleSubmitError' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.generic' }),
      description,
      duration: 0,
    })
  }

  const handleChangeTab = (status: ActiveTabEnum) => {
    setActiveTab(status)
    setUsersPages([])
    setSelectedRowKeys([])
    setDisablePrev(true)
    setDisableNext(true)

    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: '',
        status,
      }
    })
  }

  const syncExistingUsers = () => {
    if (authCompany.platform === 'microsoft') {
      syncFromMsApi()
    }
    if (authCompany.platform === 'slack') {
      syncFromSlackApi()
    }
    if (authCompany.platform === 'google') {
      getGoogleTokens()
    }
  }

  const menu = (userId, user: IUserFilteredInfo) => (
    <Menu onClick={(event) => handleMenuClick(event, userId, user)}>
      <Menu.Item>
        <Link to={`/app/users/${userId}`}><IntlMessages id="users.viewUser" /></Link>
      </Menu.Item>
      {amIAdmin &&
        <>
          {teams.length > 1 &&
            <Menu.Item key="changeUserTeam">
              <IntlMessages id="users.changeUserTeam" />
            </Menu.Item>
          }
          {locations.length > 1 &&
            <Menu.Item key="changeUserLocation">
              <IntlMessages id="users.changeUserLocation" />
            </Menu.Item>
          }
          {!isMicrosoftBillingPayment &&
            <>
              {(user.status === 'INACTIVE' && user.role !== 'Admin') &&
                <Menu.Item key="activeUser">
                  <IntlMessages id="app.activate" />
                </Menu.Item>
              }
              {(user.status === 'ACTIVE' && user.role !== 'Admin') &&
                <Menu.Item key="deactivateUser">
                  <IntlMessages id="users.deactivate" />
                </Menu.Item>
              }
            </>
          }
        </>
      }
    </Menu>
  )

  const columns = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    // eslint-disable-next-line react/display-name
    render: (name, row) => (
      <UserAvatar id={row.id} avatar={row.imageUrl} name={name} isShowingName={true} isLinkToPage={true} shape="circle" />
    ),
  }, {
    title: <IntlMessages id="app.department" />,
    dataIndex: 'team',
    className: 'team',
    key: 'team',
    render: (team) => (
      <Link to={`/app/settings/departments/${team.id}`}>{team.name}</Link>
    ),
  }, {
    title: <IntlMessages id="app.location" />,
    dataIndex: 'location',
    key: 'location',
    render: (location) => (
      <Link to={`/app/settings/locations/${location.id}/general`}>{location.name}</Link>
    ),
  }, {
    title: <IntlMessages id="app.users.role" />,
    dataIndex: 'role',
    key: 'role',
    render: (role) => {
      if (role === 'User') {
        return <IntlMessages id='app.user' />
      } else if (role === 'Admin') {
        return <IntlMessages id='app.role.administrator' />
      } else {
        return <IntlMessages id='app.role.approver' />
      }
    },
  },
  shouldEnableFeatures && {
    title: <IntlMessages id="app.labels" />,
    dataIndex: 'labels',
    key: 'labels',
    // eslint-disable-next-line react/display-name
    render: (labels: IGetLabelsShort[], {id}) => {
      const userLabelsStringified = labels.length > 3 ? labels.map(l => l.name).join(', ') : ''
      return <Tooltip placement="topLeft" title={userLabelsStringified}>
        {take(labels, 3).map((label) => {
          return <Tag key={label.id} color={label.color}>
            <span style={{color: invertHexColor(label.color, true)}}>
              {label.name}
            </span>
          </Tag>
        })}
        {labels.length > 3 &&
          <Link to={`/app/users/${id}`}>
            {`+${labels.length - 3}`}
          </Link>
        }
      </Tooltip>
    },
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    render: (id: string, row: IUserFilteredInfo) => (
      <Dropdown overlay={() => menu(id, row)} trigger={['click']} placement="bottomRight">
        <EllipsisOutlined />
      </Dropdown>
    ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }].filter(Boolean) as ColumnsType<any>

  const sendInvites = async (code?: string) => {
    try {
      const body: Partial<IUserInvitedEventParams> = {
        eventType: 'USERS_INVITED',
        eventGroup: 'USER',
        userId: id,
        emails: invites.map(invite => invite.email.toLowerCase()),
        locale: invitationLocale,
      }

      if (code) {
        body.code = code
      }
      const response = await API.post('CoreEvent', '/core/event', { body })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      handleChangeTab(ActiveTabEnum.invites)
      setShowInviteUsersModal(false)
      setShowReInviteUsersModal(false)
      setInvites([])
    } catch (error) {
      console.error(error)
    }
  }

  const deleteInvite = async (email: string) => {
    try {
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'USER_INVITATION_DELETED',
          eventGroup: 'USER',
          email,
          userId: id,
        },
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      console.error(error)
    }
  }

  const showConfirmDeleteInvite = (email: string) => {
    confirm({
      title: formatMessage({ id: 'users.deleteInvite' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'users.deleteInviteConfirm' }, { email }),
      okText: formatMessage({ id: 'users.deleteInvite' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteInvite(email)
      },
    })
  }

  const columnInvites = [{
    title: <IntlMessages id="app.email" />,
    dataIndex: 'email',
    width: '20%',
    key: 'email',
    // eslint-disable-next-line react/display-name
    render: (email: string) => email,
  },
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    // eslint-disable-next-line react/display-name
    render: (status: string) => {
      return status === 'pending' || status === 'expired' ?
        <Tag color={status === 'pending' ? 'orange' : status === 'expired' ? 'red' : ''}>{status}</Tag> : null
    },
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (data, row) => (
      <Button type='link' onClick={() => {
        setInvites([row])
        setShowReInviteUsersModal(true)
      }}>
        <IntlMessages id="users.reinvite" />
      </Button>
    ),
  },
  {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'i',
    key: 'id',
    render: (data, row) => (
      <Button  type='link' onClick={() => showConfirmDeleteInvite(row.email as string)}>
        <IntlMessages id="app.delete" />
      </Button>
    ),
  }]

  const columnsDeleted = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    // eslint-disable-next-line react/display-name
    render: (name, row) => (
      <UserAvatar id={row.id} avatar={row.imageUrl} name={name} isShowingName={true} isLinkToPage={true} shape="circle" />
    ),
  }, {
    title: <IntlMessages id="app.department" />,
    dataIndex: 'team',
    className: 'team',
    key: 'team',
    render: (team) => (
      team.name
    ),
  }, {
    title: <IntlMessages id="app.location" />,
    dataIndex: 'location',
    key: 'location',
    render: (location) => (
      location.name
    ),
  }, {
    title: <IntlMessages id="app.users.role" />,
    dataIndex: 'role',
    key: 'role',
  }, {
    title: <IntlMessages id="users.startDate" />,
    dataIndex: 'startDate',
    key: 'startDate',
    // eslint-disable-next-line react/display-name
    render: (startDate) => (
      <FormattedDate value={startDate} format={dateFormat} />
    ),
  }, {
    title: '',
    className: 'action',
    width: 54,
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (id) => (
      <Link to={`/app/users/${id}`}><IntlMessages id="users.viewUser" /></Link>
    ),
  }]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRows) => {
      setSelectedRowKeys(selectedRows as string[])
    },
    getCheckboxProps: (record) => ({
      disabled: record.role === 'Admin', // Column configuration not to be checked
      name: record.name,
    }),
  }

  const filterChanged = (data) => {
    let reject = true
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    for (const [key] of Object.entries(data)) {
      if(!isEqual(filters[key], data[key])) {
        reject = false
      }
    }
    if (reject) return

    const { locationIds, teamIds, labelIds } = data
    let url = location.pathname
    let searchParams = ''
    if (!saveFilters) {
      searchParams = '&saveFilters=false'
    }
    if (locationIds?.length) {
      searchParams += `&locations=${locationIds.join(',')}`
    }
    if (teamIds?.length) {
      searchParams += `&departments=${teamIds.join(',')}`
    }
    if (labelIds?.length) {
      searchParams += `&labels=${labelIds.join(',')}`
    }
    url += searchParams.replace('&', '?')
    window.history.pushState({}, '', url)

    setUsersPages([])
    setFilters(prevState => {
      return {
        ...prevState,
        nextToken: '',
        ...data,
      }
    })
  }

  const onSearch = (name: string) => {
    setUsersPages([])
    setFilters((prevState) => {
      return {
        ...prevState,
        nextToken: '',
        name,
      }
    })
  }

  const adminTable = amIAdmin && !isMicrosoftBillingPayment ? { rowSelection } : {}

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <div className='main-content'>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span>
                <IntlMessages id="app.users" />
                {amIAdmin &&
                  <Button onClick={importNewUsers} style={{ marginLeft: 10 }}>
                    <PlusCircleOutlined style={{ marginRight: 5 }} />
                    <IntlMessages id={isMicrosoftBillingPayment ? 'app.microsoft.manageLicenses' : platform === 'email' ? 'users.inviteUsers' : 'users.importNewUsers'} />
                  </Button>
                }
              </span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Search
                displaySpinner={true}
                onLoad={loadingUsers}
                onSearch={onSearch}
                placeholder={formatMessage({ id: 'users.search' })}
              />
            </div>
          </div>
          <div className="main-content-body">
            <Row>
              <Col flex={1}>
                {(selectedRowKeys.length > 0 && filters.status === 'ACTIVE') &&
                  <Button danger onClick={() => { changeUsersStatus('INACTIVE') }}><IntlMessages id="users.deactivate" /></Button>
                }
                {(selectedRowKeys.length > 0 && filters.status === 'INACTIVE') &&
                  <Button type="primary" onClick={() => { changeUsersStatus('ACTIVE') }}><IntlMessages id="app.activate" /></Button>
                }
              </Col>
              <Col className='vt-col-sm-24'>
                {!isInitialLoad &&
                  <FilterAdvanced
                    page='users'
                    data={{
                      Locations: locations,
                      Departments: teams,
                      Labels: labels,
                    }}
                    onChangeFilter={filterChanged}
                    showLabels={shouldEnableFeatures}
                    initialValues={initialFilterValues}
                    saveFilters={saveFilters}
                    currentUserId={id}
                  />
                }
              </Col>
            </Row>
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => handleChangeTab(tab as ActiveTabEnum)}
              style={{ marginBottom: 10 }}
            >
              <TabPane tab={<><IntlMessages id="app.active" /> <Tag color="default">{amIAdmin ? userCount : userList.length}</Tag></>} key="ACTIVE">
                <Table
                  loading={loadingUsers || loading}
                  {...adminTable}
                  dataSource={userList}
                  columns={columns}
                  rowKey={record => record.id}
                  showSorterTooltip={false}
                  pagination={false}
                />
              </TabPane>
              {amIAdmin && platform === 'email' && <TabPane tab={<IntlMessages id="users.invitations" />} key="INVITES">
                <Table
                  loading={areInvitesLoading || loadingUsers || loading}
                  dataSource={invited}
                  columns={columnInvites}
                  rowKey={record => record.email}
                  showSorterTooltip={false}
                  pagination={false}
                />
              </TabPane>
              }
              {amIAdmin &&
                <>
                  <TabPane tab={<IntlMessages id="users.inactiveUsers" />} key="INACTIVE">
                    <Table
                      loading={loadingUsers || loading}
                      {...adminTable}
                      dataSource={userList}
                      columns={columns}
                      rowKey={record => record.id}
                      showSorterTooltip={false}
                      pagination={false}
                    />
                  </TabPane>
                </>
              }
              {amIAdmin && platform !== 'email' &&
                <>
                  <TabPane tab={<IntlMessages id="users.deletedUsers" />} key="DELETED">
                    <Table
                      loading={loadingUsers || loading}
                      dataSource={userList}
                      columns={columnsDeleted}
                      rowKey={record => record.id}
                      showSorterTooltip={false}
                      pagination={false}
                    />
                  </TabPane>
                </>
              }
            </Tabs>
            <Row>
              <Col flex={1}>
                <Button onClick={() => changePage('prev')} style={{ marginRight: 5 }} disabled={disablePrev}><IntlMessages id="app.previous"/></Button>
                <Button onClick={() => changePage('next')} disabled={disableNext}><IntlMessages id="app.next"/></Button>
              </Col>
              {amIAdmin && platform !== 'email' &&
                <Col>
                  <Button onClick={() => setShowSyncExistingUsersModal(!showSyncExistingUsersModal)} style={{ marginRight: 5 }}>
                    <IntlMessages id="users.syncExistingUsers"/>
                  </Button>
                </Col>
              }
            </Row>
            {showSyncExistingUsersModal &&
              <Modal
                title={<IntlMessages id="users.syncExistingUsers" />}
                visible={showSyncExistingUsersModal}
                closeIcon={<CloseCircleOutlined />}
                footer={null}
                onCancel={() => setShowSyncExistingUsersModal(false)}
              >
                <p><IntlMessages id="users.aboutToSync" /></p>
                <p style={{ textAlign: 'right', marginTop: 20, marginBottom: 0 }}>
                  {authCompany.platform === 'google' ?
                    <Button onClick={syncExistingUsers} style={{ marginRight: 5 }}>
                      <IntlMessages id="users.syncWithoutGoogleConsent"/>
                    </Button> :
                    <Button type="primary" loading={isSubmittingSyncExistingUsersRequest} onClick={syncExistingUsers} style={{ marginRight: 5 }}>
                      <IntlMessages id="app.ok"/>
                    </Button>
                  }
                  <Button onClick={() => setShowSyncExistingUsersModal(false)} style={{ marginRight: 5 }}>
                    <IntlMessages id="app.cancel"/>
                  </Button>
                </p>
              </Modal>
            }
            {showInviteUsersModal &&
              <Modal
                title={formatMessage({ id: 'users.inviteUsers' })}
                visible={showInviteUsersModal}
                onOk={() => {
                  sendInvites()
                }}
                okButtonProps={{ disabled: invites.length === 0 }}
                onCancel={() => {
                  setShowInviteUsersModal(false)
                  setInvites([])
                }}
              >
                <InviteAndManageUsersWithEmail
                  invitedUsers={invites}
                  addInvite={addInvite}
                  selectedLocale={invitationLocale}
                  removeInvite={removeInvite}
                  changeLocale={changeLocale}
                  authUserEmail={email}
                />
              </Modal>
            }
            {showReInviteUsersModal &&
              <Modal
                title={formatMessage({ id: 'users.inviteUsers' })}
                visible={showReInviteUsersModal}
                onOk={() => {
                  sendInvites(invites[0].code)
                }}
                onCancel={() => {
                  setShowReInviteUsersModal(false)
                  setInvites([])
                }}
              >
                <IntlMessages id='users.reInviteConfirm' values={{ email: invites[0]?.email }} />
              </Modal>
            }
            {showTeamModal &&
              <ChangeTeamForm
                teams={teams}
                visibleModal={showTeamModal}
                handleCancel={() => (setShowTeamModal(false))}
                onSave={(teamId: string) => {
                  changeTeam(teamId)
                }}
                selectedUser={selectedUser}
              />
            }
            {showLocationsModal &&
              <ChangeLocationForm
                locations={locations}
                visibleModal={showLocationsModal}
                handleCancel={() => (setShowLocationsModal(false))}
                onSave={(locationId) => (changeLocation(locationId))}
                selectedUser={selectedUser}
              />
            }
            {showMsLoginRequiredModal &&
              <Modal
                title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
                visible={showMsLoginRequiredModal}
                onOk={() => getBasicMsToken()}
                onCancel={() => { 
                  setIsSubmittingSyncExistingUsersRequest(false)
                  setShowMsLoginRequiredModal(false) 
                  setShowSyncExistingUsersModal(false)
                }}
              >
                <p><IntlMessages id="microsoft.usersLoginRequiredDescription1" /></p>
                <p><IntlMessages id="microsoft.usersLoginRequiredDescription2" /></p>
              </Modal>
            }
            {showSlackLoginRequiredModal &&
              <Modal
                title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
                visible={showSlackLoginRequiredModal}
                onOk={getSlackTokens}
                onCancel={() => {
                  setShowSlackLoginRequiredModal(false)
                }}
              >
                <p><IntlMessages id="slack.usersLoginRequiredDescription1" /></p>
                <p><IntlMessages id="slack.usersLoginRequiredDescription2" /></p>
              </Modal>
            }
            {showGoogleLoginRequiredModal &&
              <Modal
                title={formatMessage({ id: 'error.google.loginRequiredTitle' })}
                visible={showGoogleLoginRequiredModal}
                onOk={() => getGoogleTokens()}
                onCancel={() => {
                  setShowGoogleLoginRequiredModal(false)
                  history.push('/app/dashboard')
                }}
              >
                <p><IntlMessages id="error.google.usersLoginRequiredDescription1" /></p>
                <p><IntlMessages id="error.google.usersLoginRequiredDescription2" /></p>
              </Modal>
            }
            {showEnableGoogleApiModal &&
              <Modal
                title={formatMessage({ id: 'error.google.directoryApiDisabledTitle' })}
                visible={showEnableGoogleApiModal}
                footer={null}
                onCancel={() => {
                  setShowEnableGoogleApiModal(false)
                }}
              >
                <Paragraph><IntlMessages id="error.google.directoryApiDisabledP1" /></Paragraph>
                <Paragraph><IntlMessages id="error.google.directoryApiDisabledP2" /></Paragraph>
                <Paragraph copyable={{text: supportLink}}><a href={supportLink} target="_blank" rel="noopener noreferrer">{supportLink}</a></Paragraph>
                <Paragraph><IntlMessages id="error.google.directoryApiDisabledP4" /></Paragraph>
              </Modal>
            }
          </div>
        </div>
      }
    </>
  )
}

export default UsersPage
