import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { useRouteMatch } from 'react-router-dom'
import { CopyrightCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { History } from 'history'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { logout } from '../../services/auth/logout-handler'

import Sidebar from '../Sidebar/index'
import Topbar from '../Topbar/index'
import App from '../../routes/index'

import CircularProgress from '../../components/circular-progress'
import AlertTrial from '../../components/alert-trial'
import AlertPastDue from '../../components/alert-past-due'
import IntlMessages from '../../util/IntlMessages'

import { IUserInfo } from '../../types/users'

const { Content, Footer } = Layout

interface IMainApp {
  onStateChange: Function
  user: IUserInfo
  history: History
}

const MainApp = ({ onStateChange, user, history }: IMainApp): React.ReactElement  => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const dispatch = useAppDispatch()

  const [daysToTrialEnd, setDaysToTrialEnd] = useState(0)
  const [showTrialEnd, setShowTrialEnd] = useState(false)
  const [showPastDue, setShowPastDue] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    document.body.classList.remove('flow-b-theme')    
    if (
      authCompany?.subscriptionStatus === 'trialing' &&
      authCompany?.billing?.stripeCurrentPeriodEnd &&
      !authCompany?.billing?.address &&
      !authCompany?.billing?.paymentMethodAdded &&
      authCompany?.billing?.paymentProcessor !== 'microsoft-billing'
    ) {
      const days = moment.unix(Number(authCompany.billing.stripeCurrentPeriodEnd)).diff(moment(), 'days')
      setDaysToTrialEnd(days+1)
      if(days > 0) { 
        setShowTrialEnd(true)
      }
    } else if (authCompany?.subscriptionStatus === 'past_due') {
      setShowPastDue(true)
    }
  }, [authCompany])

  const match = useRouteMatch()

  const onLogout = () => {
    logout({
      onStateChange,
      history,
      reduxDispatch: dispatch,
      userId: user.id,
    })
  }

  return (
    <Layout className="app-layout">
      <Sidebar/>
      <Layout className="content-layout">
        <Topbar onLogout={onLogout} />
        <Content>
          {(showTrialEnd && pathname !== '/app/my-profile' && !pathname.startsWith('/app/users/')) &&
            <AlertTrial daysToTrialEnd={daysToTrialEnd} />
          }
          {(showPastDue && pathname !== '/app/my-profile' && !pathname.startsWith('/app/users/')) &&
            <AlertPastDue />
          }
          {!user ?
            <CircularProgress /> :
            <App match={match} onStateChange={onStateChange} />
          }
          <Footer>
            <IntlMessages id="app.copyright" /> <CopyrightCircleOutlined /> {moment().format('YYYY')}
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
}
export default MainApp

