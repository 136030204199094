import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb, Form, Button, notification, Alert } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import isEqual from 'lodash/isEqual'

import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { useAppSelector } from '../../../../store/hooks'
import { getTeamById, getTotalTeamsAndUsers } from '../../../../graphql/custom-queries'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import TeamForm from '../../../../components/team-form'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

import { IGetTeamByIdData, IGetTotalTeamsAndUsers, IGetUserListShort, ITeamEvent, IEditTeamById } from '../../../../types/teams'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { selectFeatureFlagsSlice } from '../../../../store/feature-flags-slice'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

interface ITeamsFormPage {
  match: {
    params: {
      id: string
    }
  }
}

const TeamsFormPage = ({ match }: ITeamsFormPage): React.ReactElement => {
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const history = useHistory()

  const [team, setTeam] = useState<IEditTeamById>({
    id: '',
    name: '',
    timestamp: '',
    users: [],
    approvers: [],
    isDefault: false,
  })
  const [isTeamLoading, setTeamLoading] = useState(true)
  const [users, setUsers] = useState<IGetUserListShort[]>([])
  const [isLoading, setIsloading] = useState(true)
  const [totalTeams, setTotalTeam] = useState<number>(1)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [correlationId, setCorrelationId] = useState('')
  const [notificationCorrelationId, setNotificationCorrelationIds] = useState<string[]>([])
  const [correlationExist, setCorrelationExist] = useState(false)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)

  useEffect(() => {
    if (match.params.id) {
      fetchTeam(match.params.id)
    }
    fetchTotalTeamsAndUsers()
  }, [match.params.id])

  useEffect(() => {
    if (match.params.id && !isLoading && !notificationCorrelationId.some(collerationId => actionNotifications.includes(collerationId))) {
      setNotificationCorrelationIds([])
      fetchTeam(match.params.id)
    }
  }, [match.params.id, actionNotifications])

  useEffect(() => {
    if (!correlationExist) {
      const collerationStatus = actionNotifications.includes(correlationId)
      setCorrelationExist(collerationStatus)
      setSubmitLoader(collerationStatus)
    }
    if (correlationExist && actionNotifications && !actionNotifications.includes(correlationId)) {
      setCorrelationId('')
      backToTeams()
    }
  }, [actionNotifications, correlationExist, correlationId])

  const fetchTotalTeamsAndUsers = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getTotalTeamsAndUsers)) as IGetTotalTeamsAndUsers

      setTotalTeam(response.data.getTeamList.length)
      setUsers(response.data.getUsersFiltered.users)
      setIsloading(false)
    } catch (err) { console.log('error fetching total teams and users', err) }
  }

  const fetchTeam = async (teamId: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getTeamById, { id: teamId })) as IGetTeamByIdData
      const team = response.data.getTeam
      const users = team.users.map(({ id }) => id)
      const approvers = team.approvers.map(({ id }) => id)

      setTeam({
        id: team.id,
        name: team.name,
        timestamp: team.timestamp,
        isDefault: team.isDefault,
        users,
        approvers,
      })
      form.resetFields()
      setTeamLoading(false)
    } catch (err) { console.log('error fetching team', err) }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.handleSubmitError' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields()

      setSubmitLoader(true)
      const correlationIds: string[] = []

      if (team.name !== values.name || !isEqual(team.approvers, values.approvers) || !isEqual(team.users, values.users) || !isEqual(team.isDefault, values.isDefault)) {
        let response
        try {
          const body: ITeamEvent = {
            eventType: match.params.id ? 'TEAM_UPDATED' : 'TEAM_CREATED',
            eventGroup: 'TEAM',
            name: values.name,
            approvers: values.approvers,
            isDefault: values.isDefault,
            users: values.users,
          }

          if (match.params.id) {
            body.teamId = match.params.id
          }
          response = await API.post('CoreEvent', '/core/event', {
            body,
          })

          notification.open({
            key: response.correlationId,
            message: match.params.id ?
              formatMessage({ id: 'team.updateInProgress' }, { teamName: response.name }) :
              formatMessage({ id: 'team.createInProgress' }, { teamName: response.name }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })

          correlationIds.push(response.correlationId)
          setCorrelationId(response.correlationId)
        } catch (error) {
          handleErrorNotification(error, response?.correlationId)
        }
      }

      setActionNotifications([
        ...actionNotifications,
        ...correlationIds,
      ])
    } catch (error) {
      console.log('error', error)
      setSubmitLoader(false)
      handleErrorNotification(error, undefined, formatMessage({ id: 'error.somethingWentWrong' }))
    }
  }

  const backToTeams = () => {
    history.push('/app/settings/departments')
  }

  const shouldDisableCreatingNewTeam = () => {
    if (featureFlags.includes(FeatureFlagEnum.noLimitTeams)) {
      return false
    }
    return authCompany?.plan === SubscriptionPlanEnum.core && totalTeams >= NUMBER_OF_TEAMS_LIMIT
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  }
  const tailLayout = {
    wrapperCol: {xs:{ offset: 0, span: 18 }, md:{ offset: 6, span: 18 }},
  }
  

  return (
    <div className='main-content'>
      {((match.params.id && isTeamLoading) || isLoading) ?
        <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title" data-qa='create-department-title'>
              <span>
                {team.id ? team.name : <IntlMessages id="teamForm.createTeam" />}
              </span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/app/settings/departments"><IntlMessages id="app.departments" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {team.id ? <IntlMessages id="teams.editTeam" /> : <IntlMessages id="teamForm.createTeam" />}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="main-content-body">
            {!team.id && shouldDisableCreatingNewTeam() &&
              <Alert style={{ marginBottom: 20 }} 
                message={
                  <IntlMessages 
                    id="teamForm.teamLimitReached" 
                    values={{ limitNumber: NUMBER_OF_TEAMS_LIMIT, billingPage: (...chunks) => (<Link to="/app/settings/billing">{chunks}</Link>) }}
                  />
                }
                type="warning" 
              />
            }
            <Form
              {...formItemLayout}
              form={form}
              name="teamForm"
              initialValues={team}
              className="team-from"
              onFinish={onFinish}
              disabled={!team.id && shouldDisableCreatingNewTeam()}
              scrollToFirstError
            >
              <TeamForm team={team} totalTeams={totalTeams} users={users} />

              <Form.Item {...tailLayout}>
                <Button type="default" danger style={{ marginRight: 10 }} onClick={backToTeams}>
                  <IntlMessages id="app.cancel" />
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={submitLoader}
                  data-qa='submit-new-department'
                >
                  {team.id ? <IntlMessages id="teamForm.updateTeam" /> : <IntlMessages id="teamForm.createTeam" />}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      }
    </div>
  )
}

export default TeamsFormPage
