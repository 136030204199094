import React from 'react'
import Icon, {
  DashboardOutlined,
  CalendarOutlined,
  AppstoreAddOutlined,
  EnvironmentFilled,
  RocketOutlined,
  ThunderboltOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  PartitionOutlined,
  DollarCircleOutlined,
  HddOutlined,
  HistoryOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
  DeploymentUnitOutlined,
  TableOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { Notification } from '../../components/custom-svg-icons'
import { allPlatforms } from '@vacationtracker/shared/data/platforms'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

const menus = [{
  path: '/app/leaves',
  isLink: false,
  icon: <ThunderboltOutlined />,
  title: 'sidebar.leaves',
  roles: ['Admin', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-request-leave',
  children: [
    {
      path: '/request-leave',
      isLink: false,
      icon: <RocketOutlined />,
      title: 'app.requestLeave',
      roles: ['Admin', 'Approver'],
      platform: allPlatforms,
    },
    {
      path: '/add-leave',
      isLink: false,
      icon: <RocketOutlined />,
      title: 'app.addLeave',
      roles: ['Admin', 'Approver'],
      platform: allPlatforms,
    },
    {
      path: '/request-toil',
      isLink: false,
      icon: <RocketOutlined />,
      title: 'app.requestToil',
      roles: ['Admin', 'Approver'],
      plan: SubscriptionPlanEnum.complete,
      platform: allPlatforms,
    },
  ],
}, {
  path: '/app/request-leave',
  isLink: false,
  icon: <RocketOutlined />,
  title: 'app.requestLeave',
  roles: ['User'],
  platform: allPlatforms,
  joyRideClass: 'joyride-request-leave-user',
}, {
  path: '/app/request-toil',
  isLink: false,
  icon: <RocketOutlined />,
  title: 'app.requestToil',
  roles: ['User'],
  plan: SubscriptionPlanEnum.complete,
  platform: allPlatforms,
}, {
  path: '/app/dashboard',
  isLink: false,
  icon: <DashboardOutlined />,
  title: 'sidebar.dashboard',
  roles: ['Admin', 'User', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-dashboard-menu',
}, {
  path: '/app/calendar',
  isLink: false,
  icon: <CalendarOutlined />,
  title: 'app.calendar',
  roles: ['Admin', 'User', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-calendar-menu',
}, {
  path: '/app/wallchart',
  isLink: false,
  icon: <TableOutlined />,
  title: 'app.wallchart',
  roles: ['Admin', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-calendar-menu',
}, {
  path: '/app/users',
  isLink: false,
  icon: <UserOutlined />,
  title: 'app.users',
  roles: ['Admin', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-users-menu',
}, {
  path: '/app/holidays',
  isLink: false,
  icon: <RocketOutlined />,
  title: 'app.holidays',
  roles: ['User', 'Approver'],
  platform: allPlatforms,
  joyRideClass: 'joyride-holidays-menu-user',
}, {
  path: '/app/my-profile',
  isLink: false,
  icon: <UserOutlined />,
  title: 'app.myProfile',
  joyRideClass: 'joyride-my-profile-menu',
  roles: ['User', 'Approver'],
  platform: allPlatforms,
}, {
  path: '/app/settings',
  isLink: false,
  icon: <SettingOutlined />,
  title: 'sidebar.settings',
  roles: ['Admin'],
  platform: allPlatforms,
  children: [
    {
      path: '/general',
      isLink: false,
      icon: <HddOutlined />,
      title: 'app.general',
      roles: ['Admin'],
      platform: allPlatforms,
    },
    {
      path: '/leave-types',
      isLink: false,
      icon: <PartitionOutlined />,
      title: 'app.leaveTypes',
      roles: ['Admin'],
      platform: allPlatforms,
      joyRideClass: 'joyride-leave-types-menu',
    }, {
      path: '/locations',
      isLink: false,
      icon: <EnvironmentFilled />,
      title: 'app.locations',
      roles: ['Admin'],
      platform: allPlatforms,
      joyRideClass: 'joyride-location-menu',
    },
    {
      path: '/departments',
      isLink: false,
      icon: <AppstoreAddOutlined />,
      title: 'app.departments',
      roles: ['Admin'],
      platform: allPlatforms,
      joyRideClass: 'joyride-teams-menu',
    },
    {
      path: '/billing',
      isLink: false,
      icon: <DollarCircleOutlined />,
      title: 'sidebar.billing',
      roles: ['Admin'],
      platform: allPlatforms,
    },
  ],
}, {
  path: '/app/notifications',
  isLink: false,
  icon: <Icon component={Notification} />,
  title: 'app.notifications',
  roles: ['Admin', 'Approver'],
  joyRideClass: 'joyride-notifications',
  tooltip: {
    tagTitle: 'New',
    hideAfter: new Date('2022-01-01T00:00:00.000'),
  },
  platform: allPlatforms,
}, {
  path: '/app/automations',
  isLink: false,
  icon: <DeploymentUnitOutlined />,
  title: 'app.automations',
  roles: ['Admin', 'Approver'],
  paymentProcessor: 'stripe',
  tooltip: {
    tagTitle: 'New',
    hideAfter: new Date('2023-01-31T00:00:00.000'),
  },
  platform: allPlatforms,
}, {
  path: '/app/import',
  isLink: false,
  icon: <UploadOutlined />,
  title: 'app.import',
  roles: ['Admin'],
  joyRideClass: 'joyride-notifications',
  tooltip: {
    tagTitle: 'New',
    hideAfter: new Date('20224-01-01T00:00:00.000'),
  },
  platform: allPlatforms,
}, {
  path: '/app/reports',
  isLink: false,
  icon: <ContainerOutlined />,
  title: 'sidebar.reports',
  roles: ['Admin'],
  platform: allPlatforms,
  children: [
    {
      path: '/export',
      isLink: false,
      icon: <HddOutlined />,
      title: 'app.export',
      roles: ['Admin'],
      platform: allPlatforms,
    },
    {
      path: '/scheduled-reports',
      isLink: false,
      icon: <ReconciliationOutlined />,
      title: 'app.scheduledReports',
      roles: ['Admin'],
      platform: allPlatforms,
      featureFlag: true,
      featureFlagName: FeatureFlagEnum.scheduledReports,
    },
    {
      path: '/company-logs',
      isLink: false,
      icon: <HistoryOutlined />,
      title: 'app.companyLogs',
      roles: ['Admin'],
      platform: allPlatforms,
    },
  ],
}, {
  path: 'https://vacationtracker.crisp.help/en/',
  isLink: true,
  icon: <QuestionCircleOutlined />,
  title: 'app.helpdesk',
  roles: ['Admin', 'User', 'Approver'],
  platform: allPlatforms,
}]

export default menus