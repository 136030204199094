import { useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { IGetCalendarInfo } from '../../../types/custom-queries'
import { getCalendarInfo } from '../../../graphql/custom-queries'
import { IData } from '../../../types/data'

const useGetCalendarInfo = () => {

  const [calendarInfo, setCalendarInfo] = useState<IGetCalendarInfo>()

  const getCalendarInfoData = async (userId: string) => {
    const response = await API.graphql(graphqlOperation(getCalendarInfo, {
      id: userId,
    })) as IData<IGetCalendarInfo>
    setCalendarInfo(response.data)
  }

  return {calendarInfo, getCalendarInfoData}
}

export default useGetCalendarInfo