const test: string[] = [
  'price_1O5OMyCZGLYlCm3DTddTdH12',
  'price_1NxsqdCZGLYlCm3DVVhTf8h9',
]

const prod: string[] = [
  'price_1O1mGWCZGLYlCm3DC9Advl1K',
  'price_1O1mUVCZGLYlCm3DWOPnkmSa',
]

export function newCompletePriceIds(env: string): string[] {
  if (env === 'prod') {
    return prod
  } else {
    return test
  }
}
