import React from 'react'
import { Badge, Button, Card, Drawer, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { IWhatsNewComponent } from '@vacationtracker/shared/types/whats-new'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

interface IWhatsNewProp {
  isDrawerVisible: boolean
  locale: LocaleEnum
  onDrawerClose: () => void
  whatsNew: IWhatsNewComponent[]
}

const { Paragraph, Title } = Typography

export const WhatsNew = ({
  isDrawerVisible,
  onDrawerClose,
  whatsNew,
  locale,
}: IWhatsNewProp): React.ReactElement => {
  return (
    <Drawer
      title={<IntlMessages id="components.whatsNew.whatsNew" />}
      className="whats-new"
      placement="right"
      onClose={onDrawerClose}
      visible={isDrawerVisible}
      width={Math.min(450, window.innerWidth)}
    >
      <div className="help-drawer-inner">
        {whatsNew.map((news: IWhatsNewComponent, index) => {
          return (
            <Badge.Ribbon key={`${news.title.replace(' ', '-')}-${index}`} text={<IntlMessages id="components.whatsNew.newMessage" />}
              style={news.unread ? {} : { display: 'none' }} placement="start"
            >
              <Card
                className={news.unread && !news.imageUrl ? 'help-drawer-card unread' : 'help-drawer-card'}
                cover={news.imageUrl ? <img src={news.imageUrl} alt={news.title} title={news.title}/> : ''}
              >
                <Title level={5}>{news.title}</Title>
                <Paragraph style={{ whiteSpace: 'break-spaces' }}>
                  <div dangerouslySetInnerHTML={{__html: news.description}}></div>
                </Paragraph>
                {news.linkUrl &&
                  <div>
                    <Button type="default" href={news.linkUrl} target="_blank">
                      {news.linkText ? news.linkText : <IntlMessages id="app.learnMore" />}
                    </Button>
                    <span className="timestamp">
                      <FormattedDate value={new Date(Number(news.timestamp)).toISOString()} format="MMM DD, YYYY" locale={locale} />
                    </span>
                  </div>
                }
              </Card>
            </Badge.Ribbon>
          )
        })}
      </div>
    </Drawer>
  )
}
