import React from 'react'
import { Checkbox, Col, Form, FormInstance, Input, InputNumber, Row, Select, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import countries, { ICountry } from '@vacationtracker/shared/data/countries'
import aboutUsSurvey from '@vacationtracker/shared/data/survey'

import { Platform } from '@vacationtracker/shared/types/core-event'
import { UserSignUpForm } from '../user-signup-form'
import SurveyFormPart from '../survey'

const { Option } = Select
const { Title } = Typography

interface IColSize {
  span: number
  offset: number
}

interface ICreateCompanyForm {
  onBlurContactEmail: () => void
  onCountryChange: (countryId: string) => void
  selectedCountry?: ICountry
  hasUnlimitedDays: boolean
  onChangeDaysPerYear: (days: number) => void
  onUnlimitedPTOChange: () => void
  validation: () => void
  onAgreeToTermsChange: () => void
  col?: {
    xxl: IColSize
    xl: IColSize
    lg: IColSize
    md: IColSize
    xs: IColSize
  }
  userEmail?: string
  userPassword?: string
  userRepeatPassword?: string
  platform: Platform
  form: FormInstance
  showLeaveQuotas?: boolean
}

const CreateCompanyForm = ({
  onBlurContactEmail,
  onCountryChange,
  selectedCountry,
  hasUnlimitedDays,
  onChangeDaysPerYear,
  onUnlimitedPTOChange,
  validation,
  onAgreeToTermsChange,
  col = {
    xxl: { span: 14, offset: 5 },
    xl: { span: 16, offset: 4 },
    lg: { span: 20, offset: 2 },
    md: { span: 22, offset: 1 },
    xs: { span: 24, offset: 0},
  },
  platform,
  form,
  showLeaveQuotas = true,
}: ICreateCompanyForm): React.ReactElement => {
  return (
    <Row>
      { platform === 'email' && <>
        <Col xxl={col.xxl} xl={col.xl} lg={col.lg} md={col.md} xs={col.xs}>
          <Title
            style={{ marginBottom: 15, marginTop: 15 }}
            level={5}
          >
            <IntlMessages id="app.user" />
          </Title>
        </Col>
        <UserSignUpForm addAvatar={false} col={col} form={form} />
      </> }
      <Col xxl={col.xxl} xl={col.xl} lg={col.lg} md={col.md} xs={col.xs}>
        <Title style={{ marginBottom: 15, marginTop: 15 }} level={5}>
          <IntlMessages id="createCompany.steps.companyForm.companyInfoSection" />
        </Title>
        <Form.Item
          className='wrap-label'
          label={<IntlMessages id="createCompany.steps.companyForm.email" />}
          extra={<IntlMessages id="createCompany.steps.companyForm.contactEmailExtra" />}
          name="contactEmail"
          rules={[{
            required: true, message: <IntlMessages id="createCompany.steps.companyForm.contactEmail.validation.required" />,
          }, {
            type: 'email', message: <IntlMessages id="form.validEmailRequired" />,
          }]}
        >
          <Input type="email" data-qa='company-contact-email' onBlur={onBlurContactEmail} className="data-hj-allow" />
        </Form.Item>
        <Form.Item
          className='wrap-label'
          label={<IntlMessages id="general.companyName" />}
          name="name"
          rules={[{
            required: true, message: <IntlMessages id="error.fieldIsRequired" />,
          }]}
        >
          <Input className="data-hj-allow" data-qa='company-name-input' />
        </Form.Item>
        <Title style={{ marginBottom: 15, marginTop: 15 }} level={5}>
          <IntlMessages id="app.location" />
        </Title>
        <Form.Item
          className='wrap-label'
          label={<IntlMessages id="app.country" />}
          name="country"
          rules={[{ required: true, message: (<IntlMessages id="error.fieldIsRequired" />) }]}
        >
          <Select
            onChange={onCountryChange}
            filterOption={filterOptions}
            className="data-hj-allow"
            showSearch
          >
            {countries.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
              <Option value={country.iso} key={country.iso}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedCountry && selectedCountry.states && selectedCountry.states.length > 0 && (
          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="app.state" />}
            name="state"
            rules={[{ required: true, message: (<IntlMessages id="error.fieldIsRequired" />) }]}
          >
            <Select
              filterOption={filterOptions}
              className="data-hj-allow"
              showSearch
            >
              {selectedCountry.states
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map((state) => (
                  <Option value={state.iso} key={state.iso}>
                    {state.name}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        )}
        <SurveyFormPart survey={aboutUsSurvey} form={form} />
        {showLeaveQuotas && <div>
          <Title style={{ marginBottom: 15, marginTop: 15 }} level={5}>
            <IntlMessages id="createCompany.steps.companyForm.companyLeaveSection" />
          </Title>
          <Row>
            <Col span={12}>
              <Form.Item
                className='wrap-label'
                labelCol={{ span: 16 }}
                style={{ marginBottom: 5 }}
                label={<IntlMessages id="createCompany.steps.companyForm.defaultPtoQuota" />}
                name="daysPerYear"
                rules={[
                  { required: !hasUnlimitedDays, message: (<IntlMessages id="createCompany.steps.companyForm.defaultPtoQuota.validation" />) },
                  { type: 'number', min: 0, message: (<IntlMessages id="createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota" />) },
                  { type: 'number', max: 366, message: (<IntlMessages id="createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota" />) },
                ]}
              >
                <InputNumber min={0} max={365} style={{ width: 70 }} onChange={onChangeDaysPerYear} disabled={hasUnlimitedDays} className="data-hj-allow" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className='wrap-label'
                labelCol={{ span: 16 }}
                style={{ marginBottom: 5 }}
                label={<IntlMessages id="createCompany.steps.companyForm.unlimitedLeavePolicy" />}
                name="hasUnlimitedDays"
                valuePropName="checked"
                labelAlign='right'
              >
                <Checkbox onChange={onUnlimitedPTOChange}/>
              </Form.Item>
            </Col>
          </Row>
          <p style={{ textAlign: 'center' }} className="ant-typography ant-typography-secondary">
            <IntlMessages id="createCompany.steps.companyForm.defaultPtoQuotaExtra" />
          </p>
        </div>}
        <div style={{ textAlign: 'center', marginBottom: 30, marginTop: 30 }}>
          <Form.Item
            className='wrap-label'
            name="agreeToTerms"
            valuePropName="checked"
            rules={[{ validator: validation }]}
            wrapperCol={{ offset: 0, span: 24 }}
          >
            <Checkbox onChange={onAgreeToTermsChange}>
              <IntlMessages id="createCompany.steps.companyForm.agreeTerms"
                values={{
                  // eslint-disable-next-line
                  aTOS: (...chunks) => (
                    <a href="https://vacationtracker.io/terms-of-service/" target="_blank" rel="noopener noreferrer">
                      {chunks}
                    </a>
                  ),
                  // eslint-disable-next-line
                  aPP: (...chunks) => (
                    <a href="https://vacationtracker.io/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      {chunks}
                    </a>
                  ),
                  // eslint-disable-next-line
                  aDPA: (...chunks) => (
                    <a href="https://vacationtracker.io/dpa/" target="_blank" rel="noopener noreferrer">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Checkbox>
          </Form.Item>
        </div>
      </Col>
    </Row>
  )
}

export default CreateCompanyForm