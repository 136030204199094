/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Form, Button, Typography, Row, Col, Radio, Select, notification as antdNotification, Modal, Alert } from 'antd'

import IntlMessages from '../../../util/IntlMessages'
import { filterOptions } from '../../../util/filter-options'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { track } from '../../../services/analytics/analytics'
import { actions } from '../actions'

import { IMicrosoftGroup } from '../../../types/microsoft'
import { IMicrosoftSaasCreateCompanyState } from '../types'
import { API, graphqlOperation } from 'aws-amplify'
import { getMicrosoftUserId } from '../../../graphql/custom-queries'
import { IGetMicrosoftId } from '../../../types/custom-queries'

interface IChannel {
  id: string
  name?: string
}

interface ISetupMsBotProps {
  state: IMicrosoftSaasCreateCompanyState
  dispatch: Function
  msAuth: MicrosoftAuth
  platform: string
  variation: string
  paymentProcessor: string
}

const { Title, Text, Paragraph } = Typography

export const SetupMsBot = ({ state, dispatch, msAuth, platform, variation, paymentProcessor }: ISetupMsBotProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const history = useHistory()
  const [groups, setGroups] = useState<IMicrosoftGroup[]>([])
  const [channels, setChannels] = useState<IChannel[]>([])
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()
  const [showChannelAndTeamForm, setShowChannelAndTeamForm] = useState(true)
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState<boolean>(false)
  const [openLoginModalWithMoreScopes, setOpenLoginModalWithMoreScopes] = useState<boolean>(false)
  const [showErrorForExtendScopesLogin, setShowErrorForExtendScopesLogin] = useState<boolean>(false)
  const [installingInProgress, setInstallingInProgress] = useState<boolean>(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertErrorTitle, setAlertErrorTitle] = useState<React.ReactElement>()
  const [alertErrorDescription, setAlertErrorDescription] = useState<React.ReactElement>()
  const [alertErrorType, setAlertErrorType] = useState<'error' | 'success' | 'info' | 'warning' | undefined>('error')

  const { createUser, createCompany } = state

  const initialValues = {
    areYouAdmin: 'yes',
  }

  useEffect(() => {
    track('SIGNUP_SETUP_BOT_FORM_VIEWED', {
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    fetchMsData()
  }, [])

  const fetchMsData = async() => {
    try {
      const tokens = msAuth.getTokens()
      await fetchJoinedTeams(tokens.accessToken)
    } catch(err) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const fetchJoinedTeams = async (msToken) => {
    try {
      const response = await msAuth.getJoinedTeams(msToken)

      if (!response) {
        throw new Error('No groups')
      }

      setGroups(response)
    } catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification({ message: formatMessage({ id: 'components.selectChannelForm.errorNotInTeams' }) })
      } else if (error.data.error.code === 'InvalidAuthenticationToken' || error.data.error.code === 'Unauthorized') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
      }
      setGroups([])
    }
  }

  const fetchMsChannels = async (msTeamId: string) => {
    try {
      const response = await msAuth.getChannels(msTeamId)

      const channelsData = response
      // Exclude private channels, as bot can't be a member
        .filter(channel => channel.membershipType === 'standard')
        .map((channel): IChannel => {
          // An empty channel ID is not possible, we add an empty string just to make TypeScript happy
          return {
            id: channel.id || '',
            name: channel.displayName,
          }
        })
      setChannels(channelsData)

    } catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification(
          { message: formatMessage({ id: 'error.microsoft.notMemberOfTeam' }, { msTeamId }) },
          undefined,
          formatMessage({ id: 'error.microsoft.channelNotFound' })
        )
      } else if (error.data.error.code === 'InvalidAuthenticationToken' || error.data.error.code === 'Unauthorized') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
      }
    }
  }

  const logInWithDifferentAccount = () => {
    track('MS_SAAS_SETUP_ERROR_WRONG_ACCOUNT_CLICK', {
      platform,
      variation,
      paymentProcessor,
      button: 'Log in with different account',
    })
    getMsTokens()
  }

  const wrongMicrosoftAccount = () => {
    track('MS_SAAS_SETUP_ERROR_WRONG_ACCOUNT', {
      platform,
      variation,
      paymentProcessor,
    })
    msAuth.removeTokens()
    setAlertErrorTitle(<IntlMessages id="error.microsoft.wrongAccount" />)
    setAlertErrorDescription(() => {
      return (<>
        <Paragraph><IntlMessages id="errors.microsoft.wrongMicrosoftAccountDescription" values={{ email: createUser.mail}} /></Paragraph>
        <Button onClick={logInWithDifferentAccount}><IntlMessages id="error.microsoft.logInWithDifferentAccount" /></Button>
      </>)
    })
    setAlertErrorType('error')
    setShowAlertError(true)
  }

  const getMsTokens = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'])
      .then(async ([tokens, msUser, tenantId]) => {
        setShowMsLoginRequiredModal(false)
        if (tenantId !== state.tenantId || msUser.id !== createUser.id) {
          const microsoftUserId = await API.graphql(graphqlOperation(getMicrosoftUserId, { id: createUser.id } )) as IGetMicrosoftId
          if (microsoftUserId.data.getMicrosoftId?.microsoftUserId !== msUser.id) {
            wrongMicrosoftAccount()
            return
          }
        }
        return fetchJoinedTeams(tokens.accessToken)
      })
      .catch(err => {
        if (err === 'access-denied-cancel') {
          const consentLink = `https://login.microsoftonline.com/${msAuth.getTenantId()}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`
          antdNotification.error({
            key: err,
            message: formatMessage({ id: 'microsoft.adminConsentErrorTitle' }),
            description: (<>
              <Paragraph>{formatMessage({ id: 'microsoft.adminConsentErrorDescription1'})}</Paragraph>
              <Paragraph copyable={{ text: consentLink }}>
                <a
                  href={consentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-word' }}
                >
                  {consentLink}
                </a>
              </Paragraph>
              <p>{formatMessage({ id: 'microsoft.adminConsentErrorDescription2'})}</p>
            </>),
            btn: (<Button onClick={() => {
              getMsTokens()
              antdNotification.close(err)
            }}>
              {formatMessage({ id: 'microsoft.adminConsentErrorButton'})}
            </Button>),
            duration: 0,
          })
        }
        setShowMsLoginRequiredModal(false)
      })
  }

  const installBotAndTabs = async () => {
    const values = await form.validateFields()
    try {
      await msAuth.installBot(values.teamId)
      track('SIGNUP_SETUP_BOT_FORM_BOT_INSTALLED', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
    } catch (error) {
      if (error?.data?.error?.code && error.data.error.code !== 'Conflict') {
        antdNotification.error({
          key: error.data.error.code,
          message: error.data.error.message,
          duration: 0,
        })
      }
    }
    try {
      Promise.all([
        await msAuth.installTabs(values.teamId, values.channelId, 'Dashboard'),
        await msAuth.installTabs(values.teamId, values.channelId, 'Calendar'),
      ])
      track('SIGNUP_SETUP_BOT_FORM_TAB_INSTALLED', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
    } catch (error) {
      if (error?.data?.error?.code) {
        antdNotification.error({
          key: error.data.error.code,
          message: error.data.error.message,
          duration: 0,
        })
      }
    }
    dispatch(actions.setBotAndTabsInstalled())
    backToWelcomePage()
    setInstallingInProgress(false)
  }

  const getMsTokenWihtExtendScopes = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All', 'TeamsAppInstallation.ReadWriteForTeam', 'TeamsTab.ReadWriteForTeam'])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(([tokens, msUser, tenantId]) => {
        setOpenLoginModalWithMoreScopes(false)
        if (tenantId !== state.tenantId || msUser.id !== createUser.msUserId) {
          wrongMicrosoftAccount()
          return
        }
        setInstallingInProgress(true)
        return installBotAndTabs()
      })
      .catch(err => {
        setOpenLoginModalWithMoreScopes(false)
        setShowErrorForExtendScopesLogin(true)
      })
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    antdNotification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const handleInstallBotAndTabs = async () => {
    try {
      const values = await form.validateFields()
      if (values.areYouAdmin === 'yes') {
        setOpenLoginModalWithMoreScopes(true)
      } else {
        backToWelcomePage()
      }

    } catch(error) {
      console.log('ERROR HANDLE INSTALL BOT AND TEAMS', error)
    }
  }

  const backToWelcomePage = (isBack = false) => {
    if (isBack) {
      track('SIGNUP_SETUP_BOT_FORM_BACK', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
    } else {
      track('SIGNUP_SETUP_BOT_FORM_COMPLETED', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
    }

    dispatch(actions.backtToWelcomePage())
    history.push('/microsoft-saas/create-company/welcome')
  }

  const setGroup = (group: string) => {
    setSelectedGroup(group)
    fetchMsChannels(group)
  }

  const handleAreYouAdmin = (e) => {
    let isAdmin = false
    if (e.target.value === 'yes') {
      isAdmin = true
    }
    setShowChannelAndTeamForm(isAdmin)

    track('SIGNUP_SETUP_BOT_FORM_IS_ADMIN', {
      isAdmin,
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
  }

  const contactSupport = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:open'])
    }
  }

  const layout = {
    labelCol: { span: 15 },
    wrapperCol: { span: 9 },
  }

  const xxl = { span: 18, offset: 0 }
  const xl = { span: 19, offset: 0 }
  const lg = { span: 20, offset: 0 }
  const md = { span: 24, offset: 0 }
  const xs = { span: 24, offset: 0}

  return (
    <>
      <Title level={5} style={{ marginBottom: 25 }}><IntlMessages id="microsoftSass.createCompany.setupMsBotAndTabs" /></Title>

      {showAlertError && <>
        <Alert
          message={alertErrorTitle}
          description={alertErrorDescription}
          type={alertErrorType}
          showIcon
        />
        <div className="footer">
          <Button onClick={() => backToWelcomePage(true)}>
            <IntlMessages id="app.back" />
          </Button>
        </div>
      </>}

      <Text>
        <IntlMessages id="microsoftSass.createCompany.setupMsBotAndTabsInfo" />
      </Text>

      <Form
        form={form}
        layout="horizontal"
        onFinish={handleInstallBotAndTabs}
        initialValues={initialValues}
        style={{ marginTop: 20 }}
        {...layout}
      >
        <Row>
          <Col xxl={xxl} xl={xl} lg={lg} md={md} xs={xs}>

            <Form.Item label={<IntlMessages id="microsoftSass.createCompany.setupMsBot.areYouAdmin" />} name="areYouAdmin">
              <Radio.Group onChange={handleAreYouAdmin}>
                <Radio.Button value="yes"><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value="no"><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </Form.Item>

            {showChannelAndTeamForm && <>
              <Form.Item
                label={<IntlMessages id="microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot" />}
                name="teamId"
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" />}]}
              >
                <Select
                  placeholder={<IntlMessages id="microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder" />}
                  showSearch
                  filterOption={filterOptions}
                  onSelect={(event) => {
                    setGroup(event ? event.toString() : '')
                    if (event !== selectedGroup) {
                      form.setFieldsValue({'channelId': null})
                    }
                  }}
                  value={selectedGroup}
                >
                  {groups.map(group => <Select.Option key={group.id} value={group.id}>{group.displayName}</Select.Option>)}
                </Select>
              </Form.Item>

              <Form.Item
                name="channelId"
                label={<IntlMessages id="microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs" />}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              >
                <Select
                  placeholder={<IntlMessages id="microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder" />}
                  showSearch
                  filterOption={filterOptions}
                  disabled={!channels.length}
                  loading={!channels.length && Boolean(selectedGroup)}
                >
                  {channels.map(channel => <Select.Option key={channel.id} value={channel.id}>{channel.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </>}
          </Col>
        </Row>
        {!showChannelAndTeamForm &&
          <Alert
            style={{ marginBottom: 20 }}
            message={<IntlMessages id="microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle" />}
            showIcon
            description={<>
              <Paragraph><IntlMessages id="microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent" /></Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                <IntlMessages
                  id="microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription"
                  values={{
                    // eslint-disable-next-line
                    helpdesk: (...chunks) => (
                      // eslint-disable-next-line max-len
                      <a href="https://vacationtracker.crisp.help/en/article/microsoft-teams-how-do-i-add-the-vacation-tracker-bot-to-microsoft-teams-ixvmky" target="_blank" rel="noopener noreferrer">
                        {chunks}
                      </a>
                    ),
                    // eslint-disable-next-line
                    support: (...chunks) => <a onClick={contactSupport}>{chunks}</a>,
                  }} />
              </Paragraph>
            </>}
            type="warning"
          />
        }
        {showErrorForExtendScopesLogin && <>
          <Alert
            style={{ marginBottom: 20 }}
            message={<>
              <Paragraph><IntlMessages id="microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin" /></Paragraph>
              <Paragraph><IntlMessages id="microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip" /></Paragraph>
              <Paragraph style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => backToWelcomePage()}>
                  <IntlMessages id="app.skip" />
                </Button>
              </Paragraph>
            </>}
            type="warning"
          />
        </>}
        <Row>
          <Col xxl={xxl} xl={xl} lg={lg} md={md} xs={xs} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => backToWelcomePage(true)}>
              <IntlMessages id="app.back" />
            </Button>
            <Button type="primary" htmlType="submit" loading={installingInProgress} disabled={installingInProgress}>
              {showChannelAndTeamForm ?
                <IntlMessages id="microsoftSass.createCompany.setupMsBot.installBotAndTabs" /> :
                <IntlMessages id="microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs" />
              }
            </Button>
          </Col>
        </Row>
      </Form>
      {showMsLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'microsoft.universalLoginRequiredTitle' })}
          visible={showMsLoginRequiredModal}
          onOk={getMsTokens}
          onCancel={() => {
            setShowMsLoginRequiredModal(false)
            backToWelcomePage()
          }}
        >
          <Paragraph><IntlMessages id="microsoft.universalLoginRequiredDescription" /></Paragraph>
        </Modal>
      }
      {openLoginModalWithMoreScopes &&
        <Modal
          title={formatMessage({ id: 'microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin' })}
          visible={openLoginModalWithMoreScopes}
          okText={<IntlMessages id="app.proceed" />}
          cancelText={<IntlMessages id="app.cancel" />}
          onOk={getMsTokenWihtExtendScopes}
          onCancel={() => {
            setOpenLoginModalWithMoreScopes(false)
            setShowErrorForExtendScopesLogin(true)
          }}
        >
          <Paragraph><IntlMessages id="microsoftSass.createCompany.setupMsBot.morePermissionInfo" /></Paragraph>
        </Modal>
      }
    </>
  )
}