import { GetTeamsForApproverData, IUserLeaveRequestExtended } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { getTeamsForApprover, getSuggestedSubstituteApprovers } from '../../graphql/custom-queries'
import { areIntervalsOverlapping, isBefore, isSameDay } from 'date-fns'
import { IGetUsersForAdminAddLeaveData, IUserIdAndName } from '../../types/custom-queries'
import { IData } from '../../types/data'
import dayjs from 'dayjs'


export const useSubstituteApprover = (userId?: string, startDate?: string, endDate?: string) => {
  const [checkIfSubstituteNeeded, setCheckIfSubstituteNeeded] = useState(false) 
  const [isSubstituteListLoading, setIsSubstituteListLoading] = useState(false) 
  const [shouldShowSubstituteApprovers, setShouldShowSubstituteApprovers] = useState(false)
  const [suggestedSubstituteApprovers, setSuggestedSubstituteApprovers] = useState<IUserIdAndName[]>([])

  useEffect(() => {
    if (checkIfSubstituteNeeded && startDate && endDate) {
      getUsersList(startDate, endDate)
    }
  }, [checkIfSubstituteNeeded, userId])

  useEffect(() => {
    if (checkIfSubstituteNeeded && userId) {
      checkForSubstitutes(userId)
    }
  }, [checkIfSubstituteNeeded, startDate, endDate, userId])

  const checkForSubstitutes = async (userId?: string) => {
    // if no start or end date, or end date is in the past
    if (!startDate || !endDate || (isBefore(new Date(endDate), new Date()) && !isSameDay(new Date(endDate), new Date())) || !userId) {
      setShouldShowSubstituteApprovers(false)
      setCheckIfSubstituteNeeded(false)
      return
    }
    //first check if user requesting leave is approver
    const teamsForApprover = (await API.graphql(
      graphqlOperation(getTeamsForApprover, {id: userId, date: new Date()})
    )) as GetTeamsForApproverData
    const teams = teamsForApprover.data.getUser.approverToTeams
  
    // check if there are teams where user is only approver
    const teamsWithUserAsOnlyApprover = teams.filter(
      (team) => team.approvers.length === 1 && team.approvers[0].id === userId
    )
    const overlappingApproversData: {
      teamId: string
      teamName: string
      approverId: string
      name: string
    }[] = []
  
    if (startDate && endDate) {
      teams.forEach((team) => {
        team.approvers.forEach((approver) => {
          const hasOverlap = approver.upcomingLeaves.some(
            (leave) =>
              leave.status === 'APPROVED' &&
              areIntervalsOverlapping(
                {start: new Date(leave.startDate), end: new Date(leave.endDate)},
                {start: new Date(startDate), end: new Date(endDate)},
                {inclusive: true}
              )
          )
  
          if (hasOverlap) {
            overlappingApproversData.push({
              teamId: team.id,
              teamName: team.name,
              approverId: approver.id,
              name: approver.name,
            })
          }
        })
      })
    }
    if (overlappingApproversData.length || teamsWithUserAsOnlyApprover.length) {
      setShouldShowSubstituteApprovers(true)
    }
    setCheckIfSubstituteNeeded(false)
  }

  const getUsersList = async (startDate: string, endDate: string) => {
    try {
      setIsSubstituteListLoading(true)
      const users = await getUsersWithPagination(300, dayjs(startDate).format('YYYY-MM-DD'))

      const sortedAndFilteredUsers: IUserIdAndName[] = users
        .sort((a: IUserLeaveRequestExtended, b: IUserLeaveRequestExtended) =>
          a.name < b.name ? -1 : 1
        )
        .filter(
          // filter out users that are on leave
          (user) => {
            const hasOverlap = user.upcomingLeaves.some(
              (leave) =>
                areIntervalsOverlapping(
                  {start: new Date(leave.startDate), end: new Date(leave.endDate)},
                  {start: new Date(startDate), end: new Date(endDate)},
                  {inclusive: true}
                )
            )
            return !hasOverlap
          }
        )
        .filter((user) => user.id !== userId)

      // sort and filter out the requesting user
      setSuggestedSubstituteApprovers(sortedAndFilteredUsers)
      setIsSubstituteListLoading(false)
    } catch (err) { 
      setIsSubstituteListLoading(false)
      setSuggestedSubstituteApprovers([])
      console.log('error fetching users list', err) 
    }
  }


  return {
    isSubstituteListLoading,
    setCheckIfSubstituteNeeded,
    shouldShowSubstituteApprovers,
    setShouldShowSubstituteApprovers,
    suggestedSubstituteApprovers,
  }
}

const getUsersWithPagination = async (
  limit: number,
  date?: string,
  nextToken?: string,
  accumulatedResults: IUserIdAndName[] = []
) => {
  const response = (await API.graphql(
    graphqlOperation(getSuggestedSubstituteApprovers, {limit, nextToken, date})
  )) as IData<IGetUsersForAdminAddLeaveData>

  const {users, nextToken: newNextToken} =
    response.data.getActiveUsersWithPagination

  const updatedResults = [...accumulatedResults, ...users]

  if (newNextToken) {
    return getUsersWithPagination(limit, date, newNextToken, updatedResults)
  } else {
    return updatedResults
  }
}
