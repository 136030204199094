import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Checkbox, Select, Tooltip } from 'antd'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import isEqual from 'lodash/isEqual'

import { selectLocaleSlice } from '../../store/locale-slice'
import { useAppSelector } from '../../store/hooks'
import { numberOfDaysInMonth } from '../../util/number-of-days-in-month'
import { filterOptions } from '../../util/filter-options'
import timezone from '@vacationtracker/shared/data/timezone'

import IntlMessages from '../../util/IntlMessages'
import { WorkWeek } from '../../util/WorkWeek'

import { IGetLocationById, IGetUserListShort } from '../../types/locations'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

const { Option } = Select

interface ILocationForm {
  location: IGetLocationById
  users: IGetUserListShort[]
  totalLocation: number
  form: {
    getFieldValue: Function
    setFieldsValue: Function
  }
}

interface IDays {
  value: number
  label: string
}

const LocationForm = ({ location, users, totalLocation, form }: ILocationForm): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { locale } = useAppSelector(selectLocaleSlice)
  const generateDaysForMonth = (numberOfDays: number) => {
    const days: IDays[] = []
    for (let i = 1; i <= numberOfDays; i++) {
      days.push({ value: i, label: `${i}` })
    }
    return days
  }
  const [fiscalYearSelectDays, setFiscalYearSelectDays] = useState(generateDaysForMonth(numberOfDaysInMonth(location.yearStartMonth)))
  const [isFiscalYear, setResetQuotesStatus] = useState(() => {
    if (location.resetQuotas === 'USER_YEAR') {
      return 'USER_YEAR'
    } else {
      return 'FISCAL_YEAR'
    }
  })

  const [showWarningInitialRolloverUsers, setShowWarningInitialRolloverUsers] = useState(false)
  const [showWarningInitialRolloverResetQuotes, setShowWarningInitialRolloverResetQuotes] = useState(false)
  const [showWarningInitialRolloverFiscalYear, setShowWarningInitialRolloverFiscalYear] = useState(false)

  const months = [
    { label: 'app.january', value: 1 },
    { label: 'app.february', value: 2 },
    { label: 'app.march', value: 3 },
    { label: 'app.april', value: 4 },
    { label: 'app.may', value: 5 },
    { label: 'app.june', value: 6 },
    { label: 'app.july', value: 7 },
    { label: 'app.august', value: 8 },
    { label: 'app.september', value: 9 },
    { label: 'app.october', value: 10 },
    { label: 'app.november', value: 11 },
    { label: 'app.december', value: 12 },
  ]

  const onChangeMonthFiscalYear = (month: number) => {
    const numberOfDays = numberOfDaysInMonth(month)
    const days = generateDaysForMonth(numberOfDays)

    const yearStartDay = form.getFieldValue('yearStartDay')
    if (yearStartDay > numberOfDays) {
      form.setFieldsValue({yearStartDay: 1})
    }
    setFiscalYearSelectDays(days)

    if (location.id) {
      setShowWarningInitialRolloverFiscalYear(month !== location.yearStartMonth || yearStartDay !== location.yearStartDay  ? true : false)
    }
  }

  const onChangeDayFiscalYear = (day: number) => {
    if (location.id) {
      const yearStartMonth = form.getFieldValue('yearStartMonth')
      setShowWarningInitialRolloverFiscalYear(day !== location.yearStartDay || yearStartMonth !== location.yearStartMonth  ? true : false)
    }
  }

  const onChangeResetQuotes = (status) => {
    setShowWarningInitialRolloverResetQuotes(status !== location.resetQuotas && location.id ? true : false)
    setResetQuotesStatus(status)
  }

  const onChangeUsers = (users) => {
    if (Array.isArray(location.users) && location.users.length > 0) {
      let diferentUsers
      if (typeof location.users[0] === 'string') {
        diferentUsers = isEqual(location.users.sort() as string[], users.sort())
      } else {
        const userList = location.users.map(user => user.id)
        diferentUsers = isEqual(userList.sort(), users.sort())
      }
      setShowWarningInitialRolloverUsers(!diferentUsers)
    } else {
      if (users.length > 0) {
        setShowWarningInitialRolloverUsers(true)
      } else {
        setShowWarningInitialRolloverUsers(false)
      }
    }
  }

  return (
    <>
      <Form.Item
        name="name"
        className='wrap-label'
        label={<IntlMessages id="app.name" />}
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Input maxLength={100} style={{ width: 300 }} placeholder={`${formatMessage({ id: 'components.locationForm.pleaseEnterLocationName' })}`} />
      </Form.Item>

      <Form.Item
        name="workWeek"
        className='wrap-label'
        label={<IntlMessages id="app.workweek" />}
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Checkbox.Group options={WorkWeek} />
      </Form.Item>
      <Form.Item
        name="resetQuotas"
        className='wrap-label'
        label={(<>
          <IntlMessages id="locations.resetQuotas" />
          <Tooltip className="info-tooltip" title={<IntlMessages id="components.locationForm.resetQuotasInfo" />} ><InfoCircleOutlined /></Tooltip>
        </>)}
        extra={showWarningInitialRolloverResetQuotes ?
          <span style={{ color: 'red', whiteSpace: 'break-spaces'}}><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRollover" /></span> : ''
        }
      >
        <Select onChange={onChangeResetQuotes} style={{ width: locale.locale === LocaleEnum.en ? 200 : 300 }}>
          <Select.Option key="fiscalYear" value="FISCAL_YEAR"><IntlMessages id="components.locationForm.fiscalYear" /></Select.Option>)
          <Select.Option key="userYear" value="USER_YEAR"><IntlMessages id="users.employmentDate" /></Select.Option>)
        </Select>
      </Form.Item>

      {isFiscalYear === 'FISCAL_YEAR' &&
        <>
          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="location.fiscalYearStart" />}
            style={{ marginBottom: 0 }}
            extra={showWarningInitialRolloverFiscalYear ? <span><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRollover" /></span> : ''}
          >
            <Form.Item name="yearStartMonth" style={{ display: 'inline-block', width: 140 }}>
              <Select<number> style={{ width: 130, marginRight: 10 }} onChange={(month) => onChangeMonthFiscalYear(month)}>
                {months.map(month => <Select.Option key={month.value} value={month.value}><IntlMessages id={month.label} /></Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item name="yearStartDay" className='vr-md-ml-10' style={{ display: 'inline-block', width: 80}}>
              <Select<number> style={{ width: 80 }} onChange={(day) => onChangeDayFiscalYear(day)}>
                {fiscalYearSelectDays.map(day => <Select.Option key={day.value} value={day.value}>{day.label}</Select.Option>)}
              </Select>
            </Form.Item>
          </Form.Item>
        </>
      }

      <Form.Item
        className='wrap-label'
        name="firstDayOfWeek"
        label={<IntlMessages id="notifications.weekStartsOn"/>}
      >
        <Select style={{ width: 130 }}>
          <Select.Option key="0" value={0}><IntlMessages id="app.sunday" /></Select.Option>)
          <Select.Option key="1" value={1}><IntlMessages id="app.monday" /></Select.Option>)
        </Select>
      </Form.Item>

      <Form.Item
        className='wrap-label'
        name="timezone"
        label={<>
          <IntlMessages id="app.timezone" />
          <Tooltip
            className="info-tooltip"
            title={<IntlMessages id="components.locationForm.timezoneInfo" />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
        }
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Select style={{ width: 300 }}
          showSearch
          filterOption={filterOptions}
        >
          {timezone.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="users"
        className='wrap-label'
        label={<IntlMessages id="app.users" />}
        extra={showWarningInitialRolloverUsers ?
          <span><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRolloverUsers" /></span> : ''
        }
      >
        <Select
          mode="multiple"
          disabled={(location.id && totalLocation === 1) ? true : false}
          filterOption={filterOptions}
          onChange={onChangeUsers}
        >
          {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        className='wrap-label'
        label={(<>
          <IntlMessages id="components.locationForm.defaultLocation" />
          <Tooltip className="info-tooltip" title={<IntlMessages id="components.locationForm.defaultLocationInfo" />} ><InfoCircleOutlined /></Tooltip>
        </>)}
        name='isDefault'
        valuePropName="checked"
      >
        <Checkbox disabled={((location.id && totalLocation === 1) ? true : false) || (location.id && location.isDefault ? true : false)} />
      </Form.Item>

      {location.id &&
        <Form.Item
          className='wrap-label'
          label={<IntlMessages id="components.locationForm.overrideUserSettings" />}
          name='cancelUserSettings'
          style={{ fontWeight: 600 }}
          valuePropName="checked"
          extra={<span style={{ fontWeight: 400 }} ><IntlMessages id="components.locationForm.overrideUserSettingsInfo" /></span>}
        >
          <Checkbox />
        </Form.Item>
      }
    </>
  )
}

export default LocationForm
