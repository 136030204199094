import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Breadcrumb, Form, Button, notification, Typography, Row, Col, Switch, Space, Input, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { getCompanyBillingInfo } from '../../../graphql/custom-queries'
import { API, graphqlOperation } from 'aws-amplify'
import { isNumber } from 'lodash'

import { PlanPricePerUser } from '../../../util/get-price-plan-bucket-info'
import { BillingCancelResubscribeActionAndInfo } from '../../../components/billing-cancel-actions-and-info'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { setAuthCompanyPlan } from '../../../store/auth-company-slice'
import { getDateFnsLocale } from '@vacationtracker/shared/i18n'
import {
  selectCompanySettingsBillingUpdatedActionEventSlice,
  setCompanySettingsBillingUpdatedActionEvent
} from '../../../store/company-settings-billing-updated-action-event-slice'
import { track } from '../../../services/analytics/analytics'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import PricePlanCard from '../../../components/price-plan-card'
import BillingForm from '../../../components/billing-form'
import BillingInvoice from '../../../components/billing-invoice'
import PaymentInformation from '../../../components/payment-information'
import CancelSubscriptionModal from '../../../components/cancel-subscription-modal'
import CancelTrialSubscriptionModal from '../../../components/cancel-trial-subscription-modal'
import BillingCouponCode from '../../../components/billing-coupon-code'
import SwitchPlanModal from '../../../components/switch-plan-modal'
import LegacyPlanAlert from '../../../components/legacy-plan-alert'
import DowngradeAlert from '../../../components/downgrade-alert'
import BillingResubscribeAlert from '../../../components/billing-resubscribe-alert'
import BillingInformation from '../../../components/billing-information'
import { UpdateSeats } from '../../../components/update-seats'

import { IGetCompanyBillingInfoData, IBillingInfo } from '../../../types/company'
import { IPaymentInfoResponse, PaymentMethodTypeEnum } from '@vacationtracker/shared/types/payment-information'
import { newCompletePriceIds } from '@vacationtracker/shared/data/stripe/new-complete-price-ids'
import { BillingTypesEnum, SubscriptionPeriod, USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { LEGACY_PLAN_IDS } from '../../../constants'
import { IData } from '../../../types/data'
import { NUMBER_OF_LOCATIONS_LIMIT, NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

const { Title } = Typography

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = process.env.REACT_APP_STRIPE_KEY ? loadStripe(process.env.REACT_APP_STRIPE_KEY) : Promise.reject('No Stripe Key')


const CheckoutForm: React.FC = () => {
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { locale } = useAppSelector(selectLocaleSlice)
  const { companySettingsBillingUpdatedActionEvent } = useAppSelector(selectCompanySettingsBillingUpdatedActionEventSlice)

  const [isLoading, setIsLoading] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [isExistPaymentInfo, setIsExistPaymentInfo] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfoResponse>({
    card: '',
    expirationDate: '',
    stripePaymentMethodId: '',
    type: PaymentMethodTypeEnum.card,
  })
  const [isLoadingPaymentInfo, setIsLoadingPaymentInfo] = useState(true)
  const [showCardField, setShowCardField] = useState(false)
  const [customerPortalLink, setCustomerPortalLink] = useState<string | undefined>()
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false)
  const [openCancelTrialSubscriptionModal, setOpenCancelTrialSubscriptionModal] = useState(false)
  const [isTrialCanceled, setIsTrialCanceled] = useState(Boolean(localStorage.getItem('isTrialCanceled')))
  const [billing, setBilling] = useState<IBillingInfo>({
    paymentProcessor: '',
    paymentProcessorId: '',
    billingName: '',
    billingEmails: [],
    address: {
      city: '',
      country: '',
      address: '',
    },
    stripeCustomerId: '',
    stripePaymentMethodId: '',
    stripeSubscriptionId: '',
    stripeSubscriptionItemId: '',
    stripePriceId: '',
    stripeCurrentPeriodEnd: '',
    stripePaymentStatus: '',
    subscriptionPeriod: 'monthly',
    subscriptionPlan: 'Core',
    nextSubscriptionPeriod: 'monthly',
    nextSubscriptionPlan: 'Core',
  })
  const [isApplingCoupon, setIsApplingCoupon] = useState<boolean>(false)
  const [isCouponDirty, setIsCouponDirty] = useState<boolean>(false)
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlan>('Core')
  const [currentPeriod, setCurrentPeriod] = useState<SubscriptionPeriod>('monthly')
  const [newPlan, setNewPlan] = useState<SubscriptionPlan>('Core')
  const [newPeriod, setNewPeriod] = useState<SubscriptionPeriod>('monthly')
  const [isLegacyPlan, setIsLegacyPlan] = useState<boolean>(true)
  const [numberOfUsers, setNumberOfUsers] = useState<number>(0)
  const [openSiwtchPlanModal, setOpenSiwtchPlanModal] = useState(false)
  const [correlationId, setCorrelationId] = useState('')
  const [isTrialPeriod, setIsTrialPeriod] = useState(false)
  const [showBilllingForm, setShowBillingForm] = useState(false)
  const [numberOfSeats, setNumberOfSeats] = useState<number | undefined>(undefined)
  const [isLoadingUpcomingInvoiceInfo, setIsLoadingUpcomingInvoiceInfo] = useState(false)
  const [upcomingInvoiceInfoMessage, setUpcomingInvoiceInfoMessage] = useState<React.ReactElement | string>('')
  const [disableTeamsLimit, setDisableTeamsLimit] = useState<boolean>(false)
  const [disableLocationsLimit, setDisableLocationsLimit] = useState<boolean>(false)


  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (companySettingsBillingUpdatedActionEvent) {
      fetchBilling()
      fetchPaymentInformation()
      dispatch(setCompanySettingsBillingUpdatedActionEvent(null))
    }
  }, [companySettingsBillingUpdatedActionEvent])

  useEffect(() => {
    if (stripeCustomerId) {
      getCustomerPortalLink()
        .then((link: string) => {
          setCustomerPortalLink(link)
        })
    }
  }, [stripeCustomerId])

  useEffect(() => {
    if (Array.isArray(actionNotifications) &&
      (
        correlationId &&
        !actionNotifications.includes(correlationId)
      )) {
      fetchBilling()
      fetchPaymentInformation()
      setIsApplingCoupon(false)
      setIsCouponDirty(false)
    }
  }, [actionNotifications])

  useEffect(() => {
    fetchPaymentInformation()
    fetchBilling()
  }, [])


  const isNewPlan = (): boolean => {
    // Old Complete monthly teams should see the old Complete Annual prices until May 6th, 2024
    // (we need to implement the new logic before May 6th, 2024)
    if (
      billing.stripePaymentStatus === 'active' &&
      billing.subscriptionPlan === 'Complete' &&
      billing.subscriptionPeriod === 'monthly' &&
      !newCompletePriceIds(process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test').includes(billing.stripePriceId) &&
      newPeriod === 'annual'
    ) {
      return false
    }

    return billing.subscriptionPlan === 'Core' ||
      isTrialPeriod ||
      newCompletePriceIds(process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test').includes(billing.stripePriceId) ||
      newPeriod !== currentPeriod
  }

  const fetchPaymentInformation = async () => {
    try {
      const paymentInfo = await API.get('StripeApi', '/payment-information', {}) as IPaymentInfoResponse
      setPaymentInfo(paymentInfo)
      setIsExistPaymentInfo(true)
      setIsLoadingPaymentInfo(false)
      setShowCardField(false)
      localStorage.removeItem('isTrialCanceled')
      setIsTrialCanceled(false)
    } catch (e) {
      setIsLoadingPaymentInfo(false)
      setShowCardField(true)
    }
  }

  const fetchUpcomingInvoiceInfo = async (activeUsers: number) => {
    setIsLoadingUpcomingInvoiceInfo(true)
    const info = await API.post('StripeApi', '/upcoming-invoice-information', {
      body: {
        quantity: activeUsers,
        date: Math.ceil(new Date().getTime() / 1000),
      }})
    if (info) {
      setUpcomingInvoiceInfoMessage(formatMessage({ id: 'app.nextInvoice' }, {
        amount: `$${info?.nextInvoiceAmount}`,
        startDate: format(new Date(info?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale.locale)}),
      }))
      setIsLoadingUpcomingInvoiceInfo(false)
    }
  }

  const fetchBilling = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyBillingInfo)) as IData<IGetCompanyBillingInfoData>
      const billingResponse = response.data.getCompany.billing
      const noOfActiveUsersOrSeats = billingResponse?.billingType === BillingTypesEnum.seatBased && isNumber(billingResponse?.seats)
        ? billingResponse?.seats
        : response.data.getCompany.numberOfActiveUsers || 1
      fetchUpcomingInvoiceInfo(noOfActiveUsersOrSeats)
      setNumberOfUsers(response.data.getCompany.numberOfActiveUsers || 1)
      setIsTrialPeriod(response.data.getCompany.trialPeriod === 'in_trial' ? true : false)
      setDisableLocationsLimit(() => {
        if (response.data.getCompany.plan === 'Complete' && response.data.getLocationList.length > NUMBER_OF_LOCATIONS_LIMIT) {
          return true
        } else {
          return false
        }
      })
      setDisableTeamsLimit(() => {
        if (response.data.getCompany.plan === 'Complete' && response.data.getTeamList.length > NUMBER_OF_TEAMS_LIMIT) {
          return true
        } else {
          return false
        }
      })

      const billing = response.data.getCompany.billing

      if (billing) {
        dispatch(setAuthCompanyPlan(response.data.getCompany.billing.subscriptionPlan))
        setIsLegacyPlan(LEGACY_PLAN_IDS.includes(billing.stripePriceId))
        setBilling(billing)
        if (billing.billingType === BillingTypesEnum.seatBased) {
          isNumber(billing.seats) && setNumberOfSeats(billing.seats)
        }
        setCurrentPlan(billing.nextSubscriptionPlan || billing.subscriptionPlan || 'Core')
        setNewPlan(billing.nextSubscriptionPlan || billing.subscriptionPlan || 'Core')
        setCurrentPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
        setNewPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
        // Only for the legacy plan, because we don't have info about period for legacy companies.
        if(LEGACY_PLAN_IDS.includes(billing.stripePriceId) && billing.stripePriceId === 'price_1HyIJYCZGLYlCm3DSBaYeqVA') {
          setCurrentPeriod('annual')
          setNewPeriod('annual')
        }
        setStripeCustomerId(billing.stripeCustomerId)
        setSubmitLoader(false)

        form.setFieldsValue({
          billingName: billing.billingName,
          billingEmails: billing.billingEmails,
          city: billing.address && billing.address.city,
          country: billing.address && billing.address.country,
          state: billing.address && billing.address.state,
          address: billing.address && billing.address.address,
          addressLine2: billing.address && billing.address.addressLine2,
          sendInvoice: billing.sendInvoice ? billing.sendInvoice : false,
          promoCode: '',
        })
      }

      setIsLoading(false)
      setShowBillingForm(false)
    } catch (err) {
      console.log('error fetching billing', err)
      setSubmitLoader(false)
    }
  }

  const submitBilling = async (seats: number | undefined) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let response
    try {
      const values = await form.validateFields()

      // Submit loader
      setSubmitLoader(true)
      if (values.promoCode) {
        setIsApplingCoupon(true)
      }

      // Get payment method from Stripe Card field
      let stripePaymentMethodId: string | undefined

      if (showCardField) {
        const cardElement = elements.getElement(CardElement)
        if (!cardElement) {
          return
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error || !paymentMethod) {
          console.log('[error]', error)
          throw error
        } else {
          console.log('[PaymentMethod]', paymentMethod)
        }

        stripePaymentMethodId = paymentMethod.id
      } else if (paymentInfo.stripePaymentMethodId) {
        stripePaymentMethodId = paymentInfo.stripePaymentMethodId
      }

      // Get address from form
      let address
      if (values.country || billing.address) {
        address = values.country ?
          {
            city: values.city,
            country: values.country,
            state: values.state,
            address: values.address,
            ...(values.addressLine2 && {addressLine2: values.addressLine2}),
          } :
          billing.address
      }

      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'BILLING_UPDATED',
          eventGroup: 'BILLING',
          paymentProcessor: 'stripe',
          billingName: values.billingName ?? billing.billingName,
          billingEmails: values.billingEmails ?? billing.billingEmails,
          address,
          keepLegacyPlan: isLegacyPlan && (currentPlan === newPlan),
          activeUsers: numberOfUsers,
          subscriptionPlan: currentPlan,
          subscriptionPeriod: currentPeriod,
          stripeCouponId: values.promoCode,
          stripePaymentMethodId,
          ...(isNumber(seats) && { seats }),
        },
      })
      track('BILLING_PAGE_CARD_ADDED', {})
      track('CARD_ADDED_TOTAL', {})
      setCorrelationId(response.correlationId)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: getSubmitLoaderText(!!values.promoCode, !!paymentInfo.subscriptionCancelAtPeriodEnd) }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
    }
  }

  const updateBillingPlan = async (plan?: SubscriptionPlan, period?: SubscriptionPeriod) => {
    let response
    try {
      const values = form.getFieldsValue(true)

      // Submit loader
      setSubmitLoader(true)

      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'BILLING_UPDATED',
          eventGroup: 'BILLING',
          paymentProcessor: 'stripe',
          billingName: values.billingName ?? billing.billingName,
          billingEmails: values.billingEmails ?? billing.billingEmails,
          activeUsers: numberOfUsers,
          keepLegacyPlan: isLegacyPlan && (currentPlan === newPlan && currentPeriod === newPeriod),
          subscriptionPlan: plan || newPlan,
          subscriptionPeriod: period || newPeriod,
          ...(isNumber(numberOfSeats) && { seats: numberOfSeats }),
        },
      })

      setCorrelationId(response.correlationId)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: getSubmitLoaderText(!!values.promoCode, !!paymentInfo.subscriptionCancelAtPeriodEnd) }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
    }
  }

  const getSubmitLoaderText = (applyingCoupon: boolean, resubscribing: boolean): string => {
    if (applyingCoupon && resubscribing) {
      return 'billing.applyingCouponAndResubscribing'
    } else if (resubscribing) {
      return 'billing.resubscribingUpdateInProgress'
    } else if (applyingCoupon) {
      return 'billing.applyingCoupon'
    } else {
      return 'billing.updateInProgress'
    }
  }
  const handleOnCancelSubscription = async (isInTrial: boolean, cancelReason: string, cancelReasonComment?: string, integrationWithHr?: string, movingToPayroll?: string) => {
    let response
    try {
      setSubmitLoader(true)
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'SUBSCRIPTION_CANCELED',
          eventGroup: 'BILLING',
          paymentProcessor: 'stripe',
          activeUsers: numberOfUsers,
          cancelReason,
          cancelReasonComment,
          integrationWithHr,
          movingToPayroll,
          isInTrial,
          currentPeriod,
          currentPlan,
        },
      })
      setOpenCancelSubscriptionModal(false)
      setOpenCancelTrialSubscriptionModal(false)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'billing.cancelUpdateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setCorrelationId(response.correlationId)
      setActionNotifications([...actionNotifications, response.correlationId])
      setSubmitLoader(false)
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
    }
  }

  const handleBillingUpdateError = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.error' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'billing.updatedFaild' }),
      description,
      duration: 0,
    })
    setSubmitLoader(false)
    setIsApplingCoupon(false)
  }

  const getCustomerPortalLink = async (): Promise<string> => {
    const portalSession = await API.post('CoreEvent', '/stripe/create-customer-portal-session', {
      body: {
        stripeCustomerId,
        redirectUrl: window.location.href,
      },
    })

    return portalSession.url
  }

  const changePlanHandler = (plan: SubscriptionPlan | 'support') => {
    if (plan === 'support' || plan == 'Enterprise') {
      // eslint-disable-next-line max-len
      const text = plan === 'Enterprise' ? 'Hi there! I\'m interested in your Enterprise plan. Tell me more!' : 'Hi there! I am interested in switching from our Core Annual plan to the Complete Monthly plan. Tell me more!'
      window.$crisp.push(['do', 'chat:open'])
      window.$crisp.push(['do', 'message:send', ['text', text]])
    } else {
      setNewPlan(plan)
      if(isTrialPeriod) {
        updateBillingPlan(plan)
      } else {
        setOpenSiwtchPlanModal(!openSiwtchPlanModal)
      }
    }
  }

  const changePeriod = (period) => {
    const newPeriod = period ? 'annual' : 'monthly'
    setNewPeriod(newPeriod)
  }

  const cancelPlanChange = () => {
    setNewPlan(currentPlan)
    setOpenSiwtchPlanModal(false)
  }

  const switchPlan = () => {
    updateBillingPlan()
    setOpenSiwtchPlanModal(false)
  }

  const cancelChangePlan = () => {
    setNewPlan(billing.subscriptionPlan)
    setNewPeriod(billing.subscriptionPeriod)
    updateBillingPlan(billing.subscriptionPlan, billing.subscriptionPeriod)
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  }

  const computeTotalPrice = (plan: SubscriptionPlan): number => {
    if (billing.billingType === BillingTypesEnum.seatBased && isNumber(numberOfSeats)) {
      return plan === 'Complete' ? numberOfSeats * PlanPricePerUser.Complete : numberOfSeats
    } else {
      if (plan === 'Complete')
        return Math.max(numberOfUsers, USER_PLAN_LIMIT as number) * (isNewPlan() ? PlanPricePerUser.CompleteFull : PlanPricePerUser.Complete)
      else if (plan === 'Enterprise')
        return numberOfUsers
      else
        return numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers : USER_PLAN_LIMIT
    }
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="sidebar.billing" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.billing" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="main-content-body">
        <div className="billing-body">
          {isLoading || isLoadingPaymentInfo ?
            <CircularProgress /> :
            <Form
              form={form}
              layout="horizontal"
              name="billingForm"
              onFinish={() => {
                submitBilling(undefined)
              }}
              {...layout}
            >

              <Title level={4}>
                <IntlMessages id="billing.pricingPlansTitle" />
              </Title>

              {isExistPaymentInfo  && !paymentInfo.subscriptionCancelAtPeriodEnd && !isTrialPeriod &&
                <DowngradeAlert
                  currentPlan={billing.subscriptionPlan}
                  currentPeriod={billing.subscriptionPeriod}
                  nextPlan={billing.nextSubscriptionPlan}
                  nextPeriod={billing.nextSubscriptionPeriod}
                  endDatePeriod={format(new Date(Number(billing.stripeCurrentPeriodEnd) * 1000), 'MMMM do yyyy',  {locale: getDateFnsLocale(locale.locale)})}
                  cancelChangePlan={cancelChangePlan}
                />
              }

              <Row justify='center' style={{ marginBottom: 20 }}>
                <Col>
                  <Space>
                    <IntlMessages id="app.monthly" />
                    <Switch
                      style={{ backgroundColor: 'grey' }}
                      checked={newPeriod === 'annual'}
                      onChange={changePeriod}
                    />
                    <IntlMessages id="app.billing.periodAnnual" />
                  </Space>
                </Col>
              </Row>

              <Row justify='center' style={{ marginBottom: 10 }} gutter={{ lg: 16, xl: numberOfUsers > 300 ? 4 : 8 }}>
                <Col xxl={{ span: 7, offset: 0 }} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                  <PricePlanCard
                    plan='Core'
                    totalPrice={computeTotalPrice('Core')}
                    totalUsers={billing?.billingType === BillingTypesEnum.seatBased && isNumber(numberOfSeats) ? numberOfSeats : numberOfUsers}
                    pricePerUser={PlanPricePerUser.Core}
                    currentPeriod={currentPeriod}
                    newPeriod={newPeriod}
                    currentPlan={newPlan}
                    showRevertingInfo={true}
                    isSignup={false}
                    onSelectPLan={changePlanHandler}
                    isTrialPeriod={isTrialPeriod}
                    forceDisable={billing.stripePaymentStatus === 'past_due'}
                    billingType={billing.billingType}
                    disiableLimitTeam={disableTeamsLimit}
                    disableLimitLocation={disableLocationsLimit}
                  />
                </Col>
                <Col xxl={7} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                  <PricePlanCard
                    plan='Complete'
                    totalPrice={computeTotalPrice('Complete')}
                    totalUsers={billing?.billingType === BillingTypesEnum.seatBased && isNumber(numberOfSeats) ? numberOfSeats : numberOfUsers}
                    pricePerUser={isNewPlan() ? PlanPricePerUser.CompleteFull : PlanPricePerUser.Complete}
                    currentPeriod={currentPeriod}
                    isNewPlan={isNewPlan()}
                    newPeriod={newPeriod}
                    currentPlan={newPlan}
                    showRevertingInfo={true}
                    isSignup={false}
                    onSelectPLan={changePlanHandler}
                    isTrialPeriod={isTrialPeriod}
                    forceDisable={billing.stripePaymentStatus === 'past_due'}
                    billingType={billing.billingType}
                  />
                </Col>
              </Row>

              {isLegacyPlan &&
                <LegacyPlanAlert />
              }

              <div style={{ marginTop: 30 }}>
                {paymentInfo.subscriptionCancelAtPeriodEnd && paymentInfo.subscriptionCurrentPeriodEnd &&
                  <BillingResubscribeAlert
                    stripePaymentStatus={billing.stripePaymentStatus}
                    subscriptionCurrentPeriodEnd={paymentInfo.subscriptionCurrentPeriodEnd}
                    resubscribe={() => {
                      submitBilling(undefined)
                    }}
                  />
                }

                {billing.billingType === BillingTypesEnum.seatBased
                  && billing.seats
                  && <>
                    <Title level={4}>
                      <IntlMessages id="billing.seats.manage" />
                    </Title>
                    <UpdateSeats
                      showLabel
                      onSubmit={(seats: number) => submitBilling(seats)}
                      numberOfSeats={billing.seats}
                      numberOfActiveUsers={numberOfUsers}
                      locale={locale.locale}
                      inputLayout={{
                        xl: { span: 3 },
                        lg: { span: 3 },
                        md: { span: 3 },
                        sm: { span: 24 },
                        xs: { span: 24 },
                      }}
                      isAnnualBilling={billing.subscriptionPeriod === 'annual'}
                    />
                  </>}

                <Title level={4}>
                  <IntlMessages id="components.billingInfo.title" />
                  { Boolean(billing.address) &&
                    <Button size="large" type="link" onClick={() => setShowBillingForm(!showBilllingForm)}>
                      {(showBilllingForm) ? <IntlMessages id="app.close" /> : <IntlMessages id="app.edit" />}
                    </Button>
                  }
                </Title>

                {(showBilllingForm || !billing.address) ?
                  <BillingForm form={form} showPayment={showCardField} disabled={paymentInfo.subscriptionCancelAtPeriodEnd} /> :
                  <BillingInformation billingInfo={billing} />
                }

                {!paymentInfo.subscriptionCancelAtPeriodEnd && (showBilllingForm || !billing.address) &&
                  <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                    <Button type="primary" loading={submitLoader} htmlType="submit" disabled={submitLoader}>
                      <IntlMessages id="billing.updateBillingInfo" />
                    </Button>
                  </Form.Item>
                }

                <BillingCouponCode
                  subscriptionCanceled={paymentInfo.subscriptionCancelAtPeriodEnd}
                  isApplingCoupon={isApplingCoupon}
                  isCouponDirty={isCouponDirty}
                  setIsCouponDirty={setIsCouponDirty}
                  discount={paymentInfo.discount}
                />

                {!showCardField &&
                  <PaymentInformation
                    customerPortalLink={customerPortalLink}
                    card={paymentInfo?.card}
                    expirationDate={paymentInfo?.expirationDate}
                    type={paymentInfo.type}
                  />
                }
                {isExistPaymentInfo &&
                  <BillingInvoice
                    customerPortalLink={customerPortalLink}
                    upcomingInvoiceInfoMessage={upcomingInvoiceInfoMessage}
                    isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
                  />
                }
              </div>
            </Form>
          }

          <BillingCancelResubscribeActionAndInfo
            stripeCurrentPeriodEnd={billing.stripeCurrentPeriodEnd}
            subscriptionCancelAtPeriodEnd={paymentInfo.subscriptionCancelAtPeriodEnd}
            hasPaymentInfo={isExistPaymentInfo}
            isTrialPeriod={isTrialPeriod}
            isTrialCanceled={isTrialCanceled}
            setOpenCancelTrialSubscriptionModal={setOpenCancelTrialSubscriptionModal}
            setOpenCancelSubscriptionModal={setOpenCancelSubscriptionModal}
            resubscribe={() => {
              submitBilling(undefined)
            }}
          />
        </div>
      </div>

      {openSiwtchPlanModal &&
        <SwitchPlanModal
          openSiwtchPlanModal={openSiwtchPlanModal}
          currentPlan={currentPlan}
          cancelPlanChange={cancelPlanChange}
          switchPlan={switchPlan}
          newPlan={newPlan}
          newPeriod={newPeriod}
          isLegacyPlan={isLegacyPlan}
          currentPeriod={currentPeriod}
          endDatePeriod={format(new Date(Number(billing.stripeCurrentPeriodEnd) * 1000), 'MMMM do yyyy', {locale: getDateFnsLocale(locale.locale)})}
        />
      }

      {openCancelSubscriptionModal &&
        <CancelSubscriptionModal
          openCancelSubscriptionModal={openCancelSubscriptionModal}
          handleOnCancelSubscription={
            (cancelReason, cancelReasonComment, integrationWithHr, movingToPayroll) =>
              handleOnCancelSubscription(false, cancelReason, cancelReasonComment, integrationWithHr, movingToPayroll)
          }
          closeModal={() => setOpenCancelSubscriptionModal(false)}
        />
      }

      {openCancelTrialSubscriptionModal &&
        <CancelTrialSubscriptionModal
          openCancelSubscriptionModal={openCancelTrialSubscriptionModal}
          handleOnCancelSubscription={
            (cancelReason, cancelReasonComment, integrationWithHr, movingToPayroll) =>
              handleOnCancelSubscription(true, cancelReason, cancelReasonComment, integrationWithHr, movingToPayroll)
          }
          closeModal={() => setOpenCancelTrialSubscriptionModal(false)}
          setOpenCancelTrialSubscriptionModal={setOpenCancelTrialSubscriptionModal}
          setIsTrialCanceled={setIsTrialCanceled}
          hasPaymentInfo={isExistPaymentInfo}
        />
      }
    </div>
  )
}

const StripeBillingPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}

export default StripeBillingPage
