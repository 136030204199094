import React, { Dispatch, SetStateAction } from 'react'
import { Alert, Button } from 'antd'
import { format } from 'date-fns'

import IntlMessages from '../../util/IntlMessages'

interface IBillingCancelResubscribeActionAndInfoProps {
  stripeCurrentPeriodEnd: string
  subscriptionCancelAtPeriodEnd: boolean | undefined
  hasPaymentInfo: boolean
  isTrialPeriod: boolean
  isTrialCanceled: boolean
  setOpenCancelTrialSubscriptionModal: Dispatch<SetStateAction<boolean>>
  setOpenCancelSubscriptionModal: Dispatch<SetStateAction<boolean>>
  resubscribe: () => void
}

export const BillingCancelResubscribeActionAndInfo = ({
  stripeCurrentPeriodEnd,
  subscriptionCancelAtPeriodEnd,
  hasPaymentInfo,
  isTrialPeriod,
  isTrialCanceled,
  setOpenCancelTrialSubscriptionModal,
  setOpenCancelSubscriptionModal,
  resubscribe,
}: IBillingCancelResubscribeActionAndInfoProps) => {
  return (
    <div style={{ marginTop: 40, textAlign: 'center' }}>
      {hasPaymentInfo && subscriptionCancelAtPeriodEnd &&
        <Button type="primary" onClick={() => resubscribe()}>
          <IntlMessages id="components.billing.resubscribe" />
        </Button>
      }
      {isTrialPeriod && !hasPaymentInfo && !isTrialCanceled && 
        <>
          <Alert
            message={<IntlMessages id="billing.cancelTrial" values={{
              stripeCurrentPeriodEnd: format(new Date(Number(stripeCurrentPeriodEnd) * 1000), 'MMMM do yyyy'),
            }} />}
            type="info"
            showIcon
            style={{ marginBottom: '20px' }}
          />
          <Button type="primary" danger onClick={() => setOpenCancelTrialSubscriptionModal(true)}>
            <IntlMessages id="billing.cancelTrialButton" />
          </Button>
        </>
      }
      {isTrialCanceled && !hasPaymentInfo &&
        <Alert
          style={{ textAlign: 'center' }}
          message={<>
            <IntlMessages
              id='components.billingInfo.canceledTrialDescription'
              values={{ periodEnd: stripeCurrentPeriodEnd && format(new Date(Number(stripeCurrentPeriodEnd) * 1000), 'yyyy-MM-dd') }}
            />
          </>}
          type='warning'
        />
      }
      {hasPaymentInfo && !subscriptionCancelAtPeriodEnd &&
        <Button type="primary" danger onClick={() => {isTrialPeriod ? setOpenCancelTrialSubscriptionModal(true) : setOpenCancelSubscriptionModal(true)}}>
          {isTrialPeriod ? <IntlMessages id="billing.cancelTrialButton" /> : <IntlMessages id="billing.cancelSubscription" />}
        </Button>
      }
    </div>
  )
}