import React from 'react'
import { Tag, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { capitalize } from 'lodash'

import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'

import IntlMessages from '../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { DisplayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import dayjs from 'dayjs'

// Stupid fix but works https://stackoverflow.com/questions/60519460/ts2322-type-string-is-not-assignable-to-type-left-center-right-un
declare type AlignType = 'left' | 'center' | 'right'

const columnsIndex = {
  title: '#',
  dataIndex: 'id',
  key: 'id',
  width: 60,
  align: 'center' as AlignType,
  render: (date, row, index) => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return (index + 1)
  },
}

const columnsStartDate = (locale: LocaleEnum) => ({
  title: <IntlMessages id="components.leavesColumns.dates" />,
  dataIndex: 'startDate',
  key: 'startDate',
  // eslint-disable-next-line react/display-name
  render: (startDate) => {
    return (<>
      <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
    </>)
  },
})

const columnsStartEndDate = (locale: LocaleEnum, hourFormat: HourFormatEnum) => ({
  title: <IntlMessages id="components.leavesColumns.dates" />,
  dataIndex: 'startDate',
  key: 'startDate',
  // eslint-disable-next-line react/display-name
  render: (startDate: string, row) => {
    let formattedDate = <>
      <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} /> - <FormattedDate value={row.endDate} format="MMMM Do YYYY." locale={locale} />
    </>
    if (moment(startDate).format('YYYY-MM-DD') === moment(row.endDate as string).format('YYYY-MM-DD') && row.isPartDay) {
      const { value: startHour, amOrPm: startHourAmOrPm} = convertHourFormats(hourFormat, row.partDayStartHour)
      const { value: endHour, amOrPm: endHourAmOrPm} = convertHourFormats(hourFormat, row.partDayEndHour)
      formattedDate = <>
        <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
        ({startHour}{startHourAmOrPm} - {endHour}{endHourAmOrPm})
      </>
    }
    if (moment(startDate).format('YYYY-MM-DD') === moment(row.endDate as string).format('YYYY-MM-DD') && !row.isPartDay) {
      formattedDate = <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
    }

    return <>
      {formattedDate} {row.isEdited && <Tag color="#7f00ff"><IntlMessages id="app.edited" /></Tag>}
    </>
  },
})

const columnsLeaveTypeName = {
  title: <IntlMessages id="app.leaveType" />,
  dataIndex: 'leaveType',
  key: 'leaveType',
  width: 180,
  render: (leaveType, row) => {
    return <>
      {leaveType.name}
      &nbsp;
      {isToilLeave(row.id) && <Tag color={'green'}><IntlMessages id="app.toil" /></Tag>}
    </>
  },
}

const columnsDuration = {
  title: <IntlMessages id="components.leavesColumns.duration" />,
  dataIndex: 'workingDays',
  key: 'workingDays',
  width: 90,
  render: (workingDays, row) => {
    if (isToilLeave(row.id)) {
      workingDays = row.daysList ? row.daysList.length : dayjs(row.endDate).diff(dayjs(row.startDate), 'day') || 1
      const workingHours = row.partDayEndHour - row.partDayStartHour
      if (workingHours > 0) {
        workingDays = workingHours / WORKING_HOURS_IN_DAY
      }
    }
    return <DisplayLeaveInDaysAndHours value={workingDays} />
  },
}

const columnsReason = {
  title: <IntlMessages id="components.leavesColumns.reason" />,
  dataIndex: 'reason',
  key: 'reason',
  width: 300,
  render: (reason) => {
    return <div dangerouslySetInnerHTML={{__html: reason}}></div>
  },
}

const columnsDenyReason = {
  title: <IntlMessages id="components.leavesColumns.denyReason" />,
  dataIndex: 'statusReason',
  key: 'statusReason',
  render: (statusReason) => {
    return <div dangerouslySetInnerHTML={{__html: statusReason}}></div>
  },
}

const columnsStatus = (formatMessage) => ({
  title: <IntlMessages id="app.status" />,
  dataIndex: 'status',
  key: 'status',
  width: 102,
  // eslint-disable-next-line react/display-name
  render: (status, row) => {
    let statusColor = ''
    let statusText = ''
    if (status === 'APPROVED') {
      statusColor = 'green'
      statusText = capitalize(formatMessage({ id: 'app.approved' }))
    } else if (status === 'DENIED') {
      statusColor = 'red'
      statusText = capitalize(formatMessage({ id: 'app.denied' }))
    } else if (status === 'CANCELLED') {
      statusColor = 'orange'
      statusText = capitalize(formatMessage({ id: 'app.cancelled' }))
    } else if (status === 'EXPIRED') {
      statusColor = 'orange'
      statusText = capitalize(formatMessage({ id: 'app.expired' }))
    } else if (status === 'DELETED') {
      statusColor = 'magenta'
      statusText = capitalize(formatMessage({ id: 'app.deleted' }))
    } else {
      statusColor = 'black'
      statusText = capitalize(formatMessage({ id: 'app.expired' }))
    }

    if (row.isResent) {
      const resentStatusColor = 'blue'
      const resentStatusText = capitalize(formatMessage({ id: 'app.resent' }))
      return (
        <>
          <Tag style={{marginBottom: '5px'}} color={statusColor}>{statusText}</Tag>
          <Tag color={resentStatusColor}>{resentStatusText}</Tag>
        </>
      )
    }
    return (
      <Tag color={statusColor}>{statusText}</Tag>
    )
  },
})

const columnsApprover = {
  title: <IntlMessages id="components.leavesColumns.reviewedby" />,
  dataIndex: 'approver',
  key: 'approver',
  render: (approver, row) => {
    if (row.autoApproved) {
      return <IntlMessages id="app.autoApproved" />
    } else if(row.cancelledBeforeReview) {
      return <IntlMessages id="components.leavesColumns.cancelledBeforeReview" />
    } else {
      return (
        <>
          {approver?.name ? approver?.name : row.approverId}
          <div>
            {row.approvedBySubstituteApprover && (
              <strong>
                {'('}
                <IntlMessages id='app.substituteApprover' />
                {')'}
              </strong>
            )}
          </div>
        </>
      )

    }
  },
}

export {
  columnsIndex,
  columnsStartDate,
  columnsStartEndDate,
  columnsLeaveTypeName,
  columnsDuration,
  columnsReason,
  columnsDenyReason,
  columnsStatus,
  columnsApprover
}