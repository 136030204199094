import { AccrualType, AccrualTypeEnum } from '../types/leave-policy'


interface IGetToAccrueDaysAndHoursReturn {
  toAccrueDays: number
  toAccrueHours: number
}
const getToAccrueDaysAndHours = (accrualType: AccrualType , defaultDaysPerYear: number): IGetToAccrueDaysAndHoursReturn => {
  const result = {
    toAccrueDays: 0,
    toAccrueHours: 0,
  }
  switch (accrualType) {
    case AccrualTypeEnum.monthly: {
      result.toAccrueDays = defaultDaysPerYear / 12
      result.toAccrueHours = result.toAccrueDays * 8
      break
    }
    case AccrualTypeEnum.semiMonthly: {
      result.toAccrueDays = defaultDaysPerYear / 24
      result.toAccrueHours = result.toAccrueDays * 8
      break
    }
    case AccrualTypeEnum.biWeekly: {
      result.toAccrueDays = defaultDaysPerYear / 26
      result.toAccrueHours = result.toAccrueDays * 8
      break
    }
    default:
      result.toAccrueDays = NaN
      result.toAccrueHours = NaN
      break
  }
  return result
}

export default getToAccrueDaysAndHours