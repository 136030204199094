import { SubscriptionPlan } from './company'
import { ICoreEvent } from './core-event'

export type AccrualType = 'NONE' | 'MONTHLY' | 'SEMI-MONTHLY' | 'BI-WEEKLY'
export enum AccrualTypeEnum {
  none = 'NONE',
  monthly = 'MONTHLY',
  semiMonthly = 'SEMI-MONTHLY',
  biWeekly =  'BI-WEEKLY'
}
export enum ShortestLeaveIntervalEnum {
  fullDay = 'FULL-DAY',
  halfDay = 'HALF-DAY',
  oneHour = 'ONE-HOUR',
}

export type BroughtForwardExpirationSettings = {
  enabled: true
  month?: number
  day?: number
  afterDays?: number
} | {
  enabled: false
}

export interface ILeavePolicy {
  id: string // locationId#leaveTypeId
  leaveTypeId: string
  locationId: string
  companyId: string
  daysPerYear: number
  maxRolloverDays: number
  isApprovalRequired: boolean
  hasUnlimitedDays: boolean
  allowHalfDays?: boolean
  shortestLeaveInterval?: ShortestLeaveIntervalEnum
  hideLeaveType: boolean
  isReasonRequired: boolean
  accrualType: AccrualType
  negativeBallanceAllowed: boolean
  isActive: boolean
  firstEarningDate?: string
  accrualPeriodStart?: string
  allowLeaveInPast?: boolean
  accrualsLocked?: boolean
  accrualCapRate?: number
  enableRolloverExpiry?: boolean
  rolloverExpiryMonth?: number
  rolloverExpiryDay?: number
  rolloverExpiryAfterDays?: number
  allowAdvanceAccrualUsage?: boolean
  pauseAccrualsWhenUserIsDeactivated?: boolean
  broughtForwardExpirationSettings?: BroughtForwardExpirationSettings
  applyBroughtForwardSettingsToThePastYear?: boolean
  toil: boolean
  toilShortestInterval?: ShortestLeaveIntervalEnum
  toilRequestsAllowedForUsers?: boolean
  toilExpiration?: boolean
  toilExpirationDate?: string
  toilExpirationMonths?: number
}

export type ILeavePolicyMap = { [key: string]: ILeavePolicy }

export interface ILeavePolicyEnabledEventV1 extends ICoreEvent {
  eventGroup: 'LEAVE_POLICY'
  eventType: 'LEAVE_POLICY_ENABLED'
  leaveTypeId: string
  locationId: string
  daysPerYear: number
  maxRolloverDays: number
  isApprovalRequired: boolean
  hasUnlimitedDays: boolean
  allowHalfDays?: boolean
  hideLeaveType: boolean
  isReasonRequired: boolean
  accrualType: AccrualType
  negativeBallanceAllowed: boolean
  cancelUserSettings?: boolean
  firstEarningDate?: string
  accrualPeriodStart?: string
  subscriptionPlan?: SubscriptionPlan
  allowLeaveInPast?: boolean
  enableRolloverExpiry?: boolean
  rolloverExpiryMonth?: number
  rolloverExpiryDay?: number
  rolloverExpiryAfterDays?: number
}

export interface ILeavePolicyEnabledEvent extends ICoreEvent {
  eventGroup: 'LEAVE_POLICY'
  eventType: 'LEAVE_POLICY_ENABLED'
  leaveTypeId: string
  locationId: string
  daysPerYear: number
  maxRolloverDays: number
  isApprovalRequired: boolean
  hasUnlimitedDays: boolean
  allowHalfDays?: boolean
  shortestLeaveInterval: ShortestLeaveIntervalEnum
  hideLeaveType: boolean
  isReasonRequired: boolean
  accrualType: AccrualType
  negativeBallanceAllowed: boolean
  cancelUserSettings?: boolean
  firstEarningDate?: string
  accrualPeriodStart?: string
  accrualCapRate?: number
  subscriptionPlan?: SubscriptionPlan
  allowLeaveInPast?: boolean
  enableRolloverExpiry?: boolean
  rolloverExpiryMonth?: number
  rolloverExpiryDay?: number
  rolloverExpiryAfterDays?: number
  allowAdvanceAccrualUsage?: boolean
  pauseAccrualsWhenUserIsDeactivated?: boolean
  broughtForwardExpirationSettings?: BroughtForwardExpirationSettings
  applyBroughtForwardSettingsToThePastYear?: boolean
  updatePreviousBroughtForwardSettingsOnly?: boolean
  toil: boolean
  toilShortestInterval?: ShortestLeaveIntervalEnum
  toilRequestsAllowedForUsers?: boolean
  toilExpiration?: boolean
  toilExpirationDate?: string
  toilExpirationMonths?: number
}

export interface ILeavePolicyDisabledEvent extends ICoreEvent {
  eventGroup: 'LEAVE_POLICY'
  eventType: 'LEAVE_POLICY_DISABLED'
  leaveTypeId: string
  locationId: string
}

export type ILeavePolicyEvent = ILeavePolicyEnabledEvent | ILeavePolicyDisabledEvent