import { Platform } from '@vacationtracker/shared/types/core-event'
import { IEmailInvite, IImportUser } from '@vacationtracker/shared/types/user'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
export interface ICreateCompany {
  contactEmail: string
  name: string
  platform?: Platform
  country: string
  state?: string
  hasUnlimitedDays: boolean
  daysPerYear: number
  plan: SubscriptionPlan | null
  announceNewUsers?: boolean
  survey?: string
}

export interface ICreateUser {
  name: string
  mail: string
  id?: string
  platform?: Platform
  tenantId?: string
  teamName?: string
  isOwner?: boolean
  slackBotToken?: string
  slackUserToken?: string
  slackTeamId?: string
  imageUrl?: string
  slackUserId?: string
  msUserId?: string
  slackOrgTeamId?: string
  slackBotId?: string
  timezone?: string
  userName?: string
  userPassword?: string
  userRepeatPassword?: string
}

export interface ICreateCompanyState {
  createUser: ICreateUser
  createCompany: ICreateCompany
  currentStep: number
  isLoading: boolean
  allUsers: IImportUser[]
  selectedUsers: IImportUser[]
  emailInvites: IEmailInvite[]
  importAllUsers: boolean
  isCreateCompanyLoader: boolean
  agreeToTerms: boolean
  allUsersLoaded: boolean
  skipSelectPlan: boolean
}

export enum CreateCompanyActionsEnum {
  setCurrentStep = 'SET_CURRENT_STEP',
  onNextStepChange = 'ON_NEXT_STEP',
  onPrevStepChange = 'ON_PREV_STEP',
  setCreateCompanyDetails = 'SET_CREATE_COMPANY_DETAILS',
  setAnnounceNewUsers = 'SET_ANNOUNCE_NEW_USERS',
  setCreateUser = 'SET_CREATE_USER',
  loadAllUsersRequest = 'LOAD_ALL_USERS_REQUEST',
  loadAllUsersSuccess = 'LOAD_ALL_USERS_SUCCESS',
  onSelectDeselectAllUsers = 'ON_SELECT_DESELECT_ALL_USERS',
  onSelectUser = 'ON_SELECT_USER',
  onAddEmailInvite = 'ON_ADD_EMAIL_INVITE',
  onRemoveEmailInvite = 'ON_REMOVE_EMAIL_INVITE',
  setEmailInvites = 'SET_EMAIL_INVITES',
  createCompanyRequest = 'CREATE_COMPANY_REQUEST',
  createCompanySuccess = 'CREATE_COMPANY_SUCCESS',
  createCompanyError = 'CREATE_COMPANY_ERROR',
  isCreateCompanyLoader = 'IS_CREATE_COMPANY_LOADER',
  allUsersLoaded = 'ALL_USERS_LOADED',
  insertLoadedUsers = 'INSERT_LOADED_USERS',
  setSubscriptionPlan = 'SET_SUBSCRIPTION_PLAN',
  setSkipSelectPlan = 'SET_SKIP_SELECT_PLAN',
}

export type SignupVariation = 'PLAN_ACCOUNT_USERS' | 'ACCOUNT_USERS_PLAN' | 'ACCOUNT_USERS' | 'NOT_SET'
