import { FormInstance } from 'antd'
import { WelcomeMessageOptions } from '../../routes/Users/ImportUsersPage/types'

export enum ConfigureImportSourceEnum {
  importUsersPage = 'import users page',
  generalSettingsPage = 'general settings page'
}

export interface ISelectedLeaveType {
  name: string
  id: string
  locations: {
    locationId: string
  }[]
}

export interface IConfigureImportUsersOptionsForm<Values> {
  form: FormInstance<Values>
  welcomeMessageOpts: WelcomeMessageOptions[]
  source: ConfigureImportSourceEnum
  onChangeGuestUsersImport: (v) => void
  setWelcomeMessageOpts: (v) => void
  setSelectedWelcomeMessageOpt?: (v) => void
  setSelectedDepartment?: (v) => void
  setSelectedLocation?: (v) => void
  setSelectedLeaveTypes?: (v) => void
  shouldShowAnnounce?: boolean
  shouldShowLocation?: boolean
  shouldShowDepartment?: boolean
  shouldShowProrate?: boolean
  shouldShowImportGuestUsers?: boolean
}
