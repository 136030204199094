import React, { useState, CSSProperties } from 'react'
import { Button, notification } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { API } from 'aws-amplify'
import { useIntl } from 'react-intl'

import IntlMessages from '../../util/IntlMessages'
import { FeedbackModal } from '@vacationtracker/shared/components/feedback-modal'
import { track } from '../../services/analytics/analytics'

interface IAutomationFeedback {
  page: string
  buttonStyle?: CSSProperties
}

const AutomationFeedback = ({
  page,
  buttonStyle,
}: IAutomationFeedback) => {
  const { formatMessage } = useIntl()
  const [showAutomationContactForm, setShowAutomationContactForm] = useState(false)

  const automationContactForm = () => {
    track('AUTOMATION_ANYTHING_MISSING_CLICKED', {
      buttonLocation: page,
    })
    setShowAutomationContactForm(true)
  }

  const sendAutomationContactForm = async (value) => {
    track('AUTOMATION_ANYTHING_MISSING_SUBMITTED', {
      buttonLocation: page,
    })
    try {
      await API.post('CoreEvent', '/core/event', {
        body: {
          ...value,
          eventGroup: 'FEEDBACK',
          eventType: 'AUTOMATION_FEEDBACK',
        },
      })
      setShowAutomationContactForm(false)
      notification.success({
        message: formatMessage({ id: 'automation.feedback.thankYou' }),
        icon: <CommentOutlined style={{ color: '#7f00ff' }} />,
      })
    } catch (error) {
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  return (
    <>
      <Button style={buttonStyle} type="link" onClick={automationContactForm}>
        <IntlMessages id="automations.anythingMissing" />
      </Button>
      <FeedbackModal
        visible={showAutomationContactForm}
        onCancel={() => {setShowAutomationContactForm(false)}}
        onSave={sendAutomationContactForm}
        emailRequired={true}
      />
    </>
  )
}
export default AutomationFeedback
