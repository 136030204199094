import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Form, Button, notification, Row, Col, Space, Switch, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { API, graphqlOperation } from 'aws-amplify'
import { getCompanyInfo, getCompanyBillingInfo } from '../../graphql/custom-queries'

import { track } from '../../services/analytics/analytics'
import { wait } from '@vacationtracker/shared/functions/wait'
import { getBucketPlanInfo, PlanPricePerUser } from '../../util/get-price-plan-bucket-info'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { selectAuthCompanySlice, setAuthCompany } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { notificationStore } from '../../context/notificationsContext/store'
import { logout } from '../../services/auth/logout-handler'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import BillingForm from '../../components/billing-form'
import PricePlanCard from '../../components/price-plan-card'
import BillingCouponCode from '../../components/billing-coupon-code'

import { IBillingAddress, SubscriptionPeriod, USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'
import { IGetCompanyBillingInfoData } from '../../types/company'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { IGetCompanyInfoData } from '../../types/custom-queries'
import { IData } from '../../types/data'


// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = process.env.REACT_APP_STRIPE_KEY ? loadStripe(process.env.REACT_APP_STRIPE_KEY) : Promise.reject('No Stripe Key')

const { Title } = Typography

interface IOnStateChangeProp {
  onStateChange: Function
}

const getCurrentPlanMessage = (
  period: string,
  plan: string,
  noOfActiveUsers: number
) => {
  const { price } = getBucketPlanInfo(noOfActiveUsers, plan, true)

  if (period === 'annual') {
    return <IntlMessages
      id="subscription.annual.info"
      values={{
        plan,
        period: `${period && period.charAt(0).toUpperCase() + period.slice(1)}`,
        price,
      }}
    />
  }

  if (period === 'monthly') {
    if (noOfActiveUsers > USER_PLAN_LIMIT) {
      return <IntlMessages
        id="subscription.monthly.info"
        values={{
          plan,
          period: `${period && period.charAt(0).toUpperCase() + period.slice(1)}`,
          usersPrice: plan === 'Complete'
            ? noOfActiveUsers * PlanPricePerUser.CompleteFull
            : noOfActiveUsers * PlanPricePerUser.Core,
          noOfActiveUsers,
          dollarValue: plan === 'Complete' ? `$${PlanPricePerUser.CompleteFull}` : `$${PlanPricePerUser.Core}`,
        }}
      />
    } else {
      return <IntlMessages
        id="subscription.monthly.infoUnderUserPLanLimit"
        values={{
          plan,
          period: `${period && period.charAt(0).toUpperCase() + period.slice(1)}`,
          usersPrice: plan === 'Complete'
            ? USER_PLAN_LIMIT * PlanPricePerUser.CompleteFull
            : USER_PLAN_LIMIT * PlanPricePerUser.Core,
        }}
      />
    }
  }
}

const CheckoutForm = ({ onStateChange }: IOnStateChangeProp) => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [customerPortalLink, setCustomerPortalLink] = useState<string | undefined>()
  const [correlationId, setCorrelationId] = useState('')
  const [newPlan, setNewPlan] = useState<SubscriptionPlan>('Core')
  const [currentPeriod, setCurrentPeriod] = useState<SubscriptionPeriod>('monthly')
  const [newPeriod, setNewPeriod] = useState<SubscriptionPeriod>('monthly')
  const [numberOfUsers, setNumberOfUsers] = useState<number>(0)
  const [showPlans, setShowPlans] = useState<boolean>(false)
  const [isCouponDirty, setIsCouponDirty] = useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    track('RESUBSCRIPTION_PAGE_VIEWED', {})
    fetchBilling()
  }, [])

  useEffect(() => {
    if (Array.isArray(actionNotifications) &&
      (
        correlationId &&
        !actionNotifications.includes(correlationId)
      )) {
      getCompanyData()
    }
  }, [actionNotifications])

  useEffect(() => {
    if(authCompany?.subscriptionStatus === 'active' || authCompany?.subscriptionStatus === 'trialing') {
      history.push('/app/dashboard')
    }
  },[authCompany])

  useEffect(() => {
    if (stripeCustomerId) {
      getCustomerPortalLink()
        .then((link: string) => {
          setCustomerPortalLink(link)
        })
    }
  }, [stripeCustomerId])

  const fetchBilling = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyBillingInfo)) as IData<IGetCompanyBillingInfoData>
      const billing = response.data.getCompany.billing
      setNumberOfUsers(response.data.getCompany.numberOfActiveUsers || 1)

      setCurrentPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
      setNewPlan(billing.nextSubscriptionPlan || billing.subscriptionPlan || 'Core')
      setNewPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
      setSubmitLoader(false)

      if (billing) {
        form.setFieldsValue({
          billingName: billing.billingName,
          billingEmails: billing.billingEmails,
          city: billing.address && billing.address.city,
          country: billing.address && billing.address.country,
          state: billing.address && billing.address.state,
          address: billing.address && billing.address.address,
          addressLine2: billing.address && billing.address.addressLine2,
        })
      }

      setStripeCustomerId(billing?.stripeCustomerId)
      setIsLoading(false)
    } catch (err) {
      console.log('error fetching billing', err)
      setSubmitLoader(false)
    }
  }

  const submit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    try {
      const values = await form.validateFields()
      setSubmitLoader(true)

      let stripePaymentMethodId

      if(!values.sendInvoice) {
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
          return
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error || !paymentMethod) {
          console.log('[error]', error)
          setSubmitLoader(false)
          throw error
        } else {
          console.log('[PaymentMethod]', paymentMethod)
        }

        stripePaymentMethodId = paymentMethod.id
      }

      const address: IBillingAddress = {
        city: values.city,
        country: values.country,
        state: values.state,
        address: values.address,
      }
      if (values.addressLine2) {
        address.addressLine2 = values.addressLine2
      }

      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'BILLING_UPDATED',
          eventGroup: 'BILLING',
          paymentProcessor: 'stripe',
          billingName: values.billingName,
          billingEmails: values.billingEmails,
          address,
          stripePaymentMethodId,
          stripeCouponId: values.promoCode,
          sendInvoice: values.sendInvoice,
          subscriptionPlan: newPlan,
          subscriptionPeriod: newPeriod,
          keepLegacyPlan: false,
          activeUsers: numberOfUsers,
        },
      })

      track('RESUBSCRIPTION_PAGE_SUBMIT_BUTTON_CLICKED', {})
      track('CARD_ADDED_TOTAL', {})

      setCorrelationId(response.correlationId)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'subscription.updateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])

    } catch (error) {
      if (error.message) {
        notification.error({
          message: error.message,
        })
      } else {
        notification.error({
          message: JSON.stringify(error) ? JSON.stringify(error) : error,
        })
      }
      setSubmitLoader(false)
    }
  }

  const getCustomerPortalLink = async (): Promise<string> => {
    const portalSession = await API.post('CoreEvent', '/stripe/create-customer-portal-session', {
      body: {
        stripeCustomerId,
        redirectUrl: window.location.href,
      },
    })

    return portalSession.url
  }

  const onLogout = () => {
    logout({
      onStateChange,
      history,
      reduxDispatch: dispatch,
      userId: authUser.id,
    })
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  }

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  }

  let numberOfRetry = 1
  const getCompanyData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyInfo)) as IGetCompanyInfoData
      if (response.data.getCompany.subscriptionStatus !== 'active' && numberOfRetry < 8) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyData()
      }
      if (numberOfRetry >= 8) {
        throw new Error('Get company data failed')
      }
      dispatch(setAuthCompany(response.data.getCompany))
    } catch (err) {
      setSubmitLoader(false)
      console.error('ERROR GET COMPANY DATA', err)
    }
  }

  const changePeriod = (period) => {
    setNewPeriod(period ? 'annual' : 'monthly')
  }

  const changePlanHandler = (plan: SubscriptionPlan) => {
    setNewPlan(plan)
  }

  return (
    <section className="ant-layout ant-layout-has-sider app-layout">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper">
            <div className="main-content">
              <div className="main-content-header">
                <div className="main-content-header-title">
                  <span><IntlMessages id="subscription.title" /></span>
                </div>
                <div className="ant-card-extra">
                  <Button type="text" onClick={() => onLogout()}><IntlMessages id="app.logout" /></Button>
                </div>
              </div>
              <div className="main-content-body">
                <div className="billing-body">
                  {isLoading ? <CircularProgress /> :
                    <Form
                      form={form}
                      layout="horizontal"
                      name="billingForm"
                      onFinish={submit}
                      {...layout}
                    >
                      <Title level={4}>
                        <IntlMessages id="components.billingForm.planTitle" />
                      </Title>

                      <Form.Item label colon={false}>
                        {getCurrentPlanMessage(newPeriod, newPlan, numberOfUsers)}
                        <Button style={{marginLeft: '10px'}} type="primary" disabled={isLoading} onClick={() => setShowPlans(!showPlans)}>
                          <IntlMessages id={showPlans ? 'subscription.hidePlansButton' : 'subscription.showPlansButton'}/>
                        </Button>
                      </Form.Item>


                      {showPlans && <>
                        <Row justify='center' style={{ marginBottom: 20 }}>
                          <Col>
                            <Space>
                              <IntlMessages id="app.monthly" />
                              <Switch
                                style={{ backgroundColor: 'grey' }}
                                checked={newPeriod === 'annual'}
                                onChange={changePeriod}
                              />
                              <IntlMessages id="app.billing.periodAnnual" />
                            </Space>
                          </Col>
                        </Row>

                        <Row justify='center' style={{ marginBottom: 10 }} gutter={16}>
                          <Col xxl={{ span: 7, offset: 0 }} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                            <PricePlanCard
                              plan='Core'
                              totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers : USER_PLAN_LIMIT}
                              totalUsers={numberOfUsers}
                              pricePerUser={PlanPricePerUser.Core}
                              currentPeriod={currentPeriod}
                              newPeriod={newPeriod}
                              currentPlan={newPlan}
                              showRevertingInfo={true}
                              isSignup={false}
                              isResubscribe={true}
                              onSelectPLan={() => changePlanHandler('Core')}
                              isTrialPeriod={false}
                            />
                          </Col>
                          <Col xxl={7} xl={8} lg={14} md={14} sm={24} xs={24} style={{ display: 'flex' }}>
                            <PricePlanCard
                              plan='Complete'
                              totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers * PlanPricePerUser.CompleteFull : USER_PLAN_LIMIT * PlanPricePerUser.CompleteFull}
                              totalUsers={numberOfUsers}
                              pricePerUser={PlanPricePerUser.CompleteFull}
                              currentPeriod={currentPeriod}
                              newPeriod={newPeriod}
                              currentPlan={newPlan}
                              isNewPlan={true}
                              showRevertingInfo={true}
                              isSignup={false}
                              isResubscribe={true}
                              onSelectPLan={() => changePlanHandler('Complete')}
                              isTrialPeriod={false}
                            />
                          </Col>
                        </Row>
                      </>
                      }

                      <Title level={4}>
                        <IntlMessages id="components.billingInfo.title" />
                      </Title>

                      <BillingForm form={form}/>

                      <BillingCouponCode
                        subscriptionCanceled={true}
                        isApplingCoupon={submitLoader}
                        isCouponDirty={isCouponDirty}
                        setIsCouponDirty={setIsCouponDirty}
                      />

                      {customerPortalLink &&
                        <Button type="link" href={customerPortalLink} size='large'>
                          <IntlMessages id="subscription.stripeCustomerPortalLink" />
                        </Button>
                      }

                      <Form.Item {...tailLayout}>
                        <Button type="primary" loading={submitLoader} htmlType="submit" disabled={submitLoader}>
                          <IntlMessages id="subscription.subscribe" />
                        </Button>
                      </Form.Item>
                    </Form>
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </section>
  )
}

const SubscriptionPage = ({ onStateChange }: IOnStateChangeProp) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm onStateChange={onStateChange} />
    </Elements>
  )
}

export default SubscriptionPage
