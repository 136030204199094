/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Alert, Button, Typography } from 'antd'
import { WindowsFilled } from '@ant-design/icons'

import { actions } from '../actions'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { track } from '../../../services/analytics/analytics'

import IntlMessages from '../../../util/IntlMessages'
import ManageAssignLicenses from '../../../components/manage-assign-licenses'
import CircularProgress from '../../../components/circular-progress'

import { IMicrosoftUser } from '../../../types/microsoft'
import { IMicrosoftSaasCreateCompanyState } from '../types'
import { ParentComponentEnum } from '../../../components/manage-assign-licenses/types'
import { PlatformEnum } from '@vacationtracker/shared/data/platforms'

interface IAssignLicensesProps {
  state: IMicrosoftSaasCreateCompanyState
  msAuth: MicrosoftAuth
  dispatch: Function
  platform: string
  variation: string
  paymentProcessor: string
}

const { Title, Text, Paragraph } = Typography

export const AssignLicenses = ({ state, msAuth, dispatch, platform, variation, paymentProcessor }: IAssignLicensesProps): React.ReactElement => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [loginInProgress, setLoginInProgress] = useState(false)
  const [showManageAssignLicenses, setShowManageAssignLicenses] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertErrorTitle, setAlertErrorTitle] = useState<React.ReactElement>()
  const [alertErrorDescription, setAlertErrorDescription] = useState<React.ReactElement>()
  const [alertErrorType, setAlertErrorType] = useState<'error' | 'success' | 'info' | 'warning' | undefined>('error')

  const { numberOflicenses, createUser, selectedUsers, totalUsers, importAllUsers, createCompany } = state

  useEffect(() => {
    checkMicrosoftToken()
  }, [])

  const onSelectUser = (user: IMicrosoftUser) => {
    dispatch(actions.onSelectUser(user))
  }

  const handleChangeRole = (role:string, userId:string) => {
    dispatch(actions.changeUserRole(role, userId))
  }

  const handleChangeAnnounce = (announce: 'none' | 'email' | 'chat', userId: string) => {
    dispatch(actions.changeUserAnnounce(announce, userId))
  }

  const assignLicenses = () => {
    track('SIGNUP_IMPORT_USERS_FORM_USERS_IMPORTED', {
      importedUsers: selectedUsers.length,
      totalUsers,
      importAllUsers: selectedUsers.length === totalUsers ? true : false,
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    track('SIGNUP_IMPORT_USERS_FORM_COMPLETED', {
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    dispatch(actions.onNextStepChange())
    dispatch(actions.setStepsAssignLicenses(true))
    history.push('/microsoft-saas/create-company/setup-ms-bot')
  }

  const backToWelcomePage = () => {
    track('SIGNUP_IMPORT_USERS_FORM_BACK', {
      platform,
      variation,
      paymentProcessor,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    dispatch(actions.backtToWelcomePage())
    history.push('/microsoft-saas/create-company/welcome')
  }

  const handleBulkSelect = (importAllUsers, users) => {
    dispatch(actions.handleBulkSelect({ importAllUsers, users }))
  }

  const handleImportAllUsers = (importAllUsers) => {
    dispatch(actions.handleImportAllUsers(importAllUsers))
  }

  const handleBulkAnnounceAction = (action: 'none' | 'email' | 'chat') => {
    dispatch(actions.handleBulkAnnounceAction(action))
  }

  const handleTotalUsers = async (token: string) => {
    const totalUsers = await msAuth.getTotalUsers(token)
    track('SIGNUP_IMPORT_USERS_FORM_VIEWED', {
      platform,
      variation,
      paymentProcessor,
      totalUsers: totalUsers,
      plan: createCompany.plan,
      email: createUser.mail,
    })
    dispatch(actions.setTotalUSers(totalUsers))
  }

  const checkMicrosoftToken = async () => {
    try {
      const msTokens = msAuth.getTokens()
      const user = await msAuth.getUser()
      const vtMSState = JSON.parse(localStorage.getItem('vtMSState') || '{}')

      if (vtMSState.createUser && vtMSState.createUser.id === user.id) {
        dispatch(actions.setCreateUser(vtMSState.createUser))
      } else {
        const userData = {
          name: (user.givenName || user.surname) ? `${user.givenName || ''} ${user.surname || ''}`.trim() : user.displayName as string,
          mail: user.mail || user.userPrincipalName as string,
          id: `${PlatformEnum.Universal}-${user.id}`,
        }
        dispatch(actions.setCreateUser(userData))
      }
      handleTotalUsers(msTokens.accessToken)
      setShowManageAssignLicenses(true)
      setIsLoading(false)
    } catch (error) {
      track('SIGNUP_IMPORT_USERS_CONNECT_WITH_MSTEAMS', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
      setIsLoading(false)
      setShowLoginForm(true)
    }
  }

  const logInWithDifferentAccount = () => {
    track('MS_SAAS_SETUP_ERROR_WRONG_ACCOUNT_CLICK', {
      platform,
      variation,
      paymentProcessor,
      button: 'Log in with different account',
    })
    connectWithMicrosoft()
  }

  const wrongMicrosoftAccount = () => {
    track('MS_SAAS_SETUP_ERROR_WRONG_ACCOUNT', {
      platform,
      variation,
      paymentProcessor,
    })
    msAuth.removeTokens()
    setAlertErrorTitle(<IntlMessages id="error.microsoft.wrongAccount" />)
    setAlertErrorDescription(() => {
      return (<>
        <Paragraph><IntlMessages id="errors.microsoft.wrongMicrosoftAccountDescription" values={{ email: createUser.mail}} /></Paragraph>
        <Button onClick={logInWithDifferentAccount}><IntlMessages id="error.microsoft.logInWithDifferentAccount" /></Button>
      </>)
    })
    setAlertErrorType('error')
    setShowLoginForm(false)
    setShowAlertError(true)
  }

  const loginWithMicrosoft = () => {
    track('MS_SAAS_SETUP_ERROR_NO_PERMISSIONS', {
      platform,
      paymentProcessor,
      button: 'Login with microsoft',
    })
    connectWithMicrosoft()
  }

  const connectWithMicrosoft = (interactionRequired = false) => {
    setLoginInProgress(true)
    setShowAlertError(false)
    // This requires promises, not async/await because window.open requires sync not async flow
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'], interactionRequired)
      .then(([tokens, msUser, tenantId]) => {
        if (tenantId !== state.tenantId || msUser.id !== createUser.msUserId) {
          wrongMicrosoftAccount()
          return
        }
        const userData = {
          name: (msUser.givenName || msUser.surname) ? `${msUser.givenName || ''} ${msUser.surname || ''}`.trim() : msUser.displayName,
          mail: msUser.mail || msUser.userPrincipalName,
        }
        dispatch(actions.setCreateUser({ ...createUser, ...userData}))
        handleTotalUsers(tokens.accessToken)
        setIsLoading(false)
        setShowLoginForm(false)
        setShowManageAssignLicenses(true)
      })
      .catch((e) => {
        track('MS_SAAS_SETUP_ERROR_NO_PERMISSIONS', {
          platform,
          paymentProcessor,
          error: JSON.stringify(e),
        })
        setAlertErrorTitle(<IntlMessages id="error.microsoft.microsoftPermissionsNotGranted" />)
        setAlertErrorDescription(() => {
          return (<>
            <Paragraph><IntlMessages id="errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription" /></Paragraph>
            <Button onClick={loginWithMicrosoft}><IntlMessages id="app.logInWithMicrosoft" /></Button>
          </>)
        })
        setAlertErrorType('error')
        setShowLoginForm(false)
        setShowAlertError(true)
      })
  }

  return (
    <div className="assign-licenses">
      <Title level={5} style={{ marginBottom: 25 }}><IntlMessages id="microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses" /></Title>

      {isLoading && <CircularProgress style={{ height: 'calc(100vh - 92px)' }} /> }

      {showLoginForm &&
        <>
          <Paragraph style={{ marginTop: 30}}>
            <IntlMessages id="microsoft.manageLicenses.loginMicrosoft" />
          </Paragraph>
          <Paragraph>
            <Button icon={<WindowsFilled />} size="large" type="primary" disabled={loginInProgress} loading={loginInProgress} onClick={() => connectWithMicrosoft()}>
              <IntlMessages id="app.logInWithMicrosoft" />
            </Button>
          </Paragraph>
          <Paragraph>
            <IntlMessages id="microsoft.manageLicenses.loginMicrosoftInfo"  />
          </Paragraph>
          <Paragraph>
            <IntlMessages id="microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry"  />
          </Paragraph>
          <div className="footer">
            <Button onClick={backToWelcomePage}>
              <IntlMessages id="app.back" />
            </Button>
          </div>
        </>
      }

      {showAlertError && <>
        <Alert
          message={alertErrorTitle}
          description={alertErrorDescription}
          type={alertErrorType}
          showIcon
        />
        <div className="footer">
          <Button onClick={backToWelcomePage}>
            <IntlMessages id="app.back" />
          </Button>
        </div>
      </>}

      {showManageAssignLicenses && <>
        <Text>
          <IntlMessages id="microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo" values={{ totalLicenses: numberOflicenses }} /><br/>
          <IntlMessages id="microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater"  />
        </Text>
        <ManageAssignLicenses
          numberOflicenses={numberOflicenses}
          msAuth={msAuth}
          onSelectUser={onSelectUser}
          curentUser={{
            id: createUser.id,
            mail: createUser.mail,
            displayName: createUser.name,
          }}
          importAllUsers={importAllUsers}
          selectedUsers={selectedUsers}
          totalUsersProp={totalUsers}
          handleChangeRole={handleChangeRole}
          handleChangeAnnounce={handleChangeAnnounce}
          assignLicenses={assignLicenses}
          parentComponent={ParentComponentEnum.createCompany}
          handleBack={backToWelcomePage}
          handleBulkSelect={handleBulkSelect}
          handleBulkAnnounceAction={handleBulkAnnounceAction}
          handleImportAllUsers={handleImportAllUsers}
        />
      </>}
    </div>
  )
}
