import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { install as installResizeObserverPolyfill } from 'resize-observer'
import { Avatar, Button, Divider, Empty, List, Space, Spin, Tag, Typography } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { GiKite } from 'react-icons/gi'
import { zipObject } from 'lodash'
import dayjs from 'dayjs'

import { HeatmapChartMonth } from './month'
import LeavesBox from '@vacationtracker/shared/components/leaves-box'
import { getNumberOfDaysInMonth, isToday } from './helpers'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'

import IntlMessages from '../../util/IntlMessages'

import { IHeatMapLeaves } from '../../types/dashboard'
import { IHeatmapChartProps, IDaysData, HeatmapEventType, HeatmapEntry, IHeatmapLeave } from './types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { IHeatmapLeaveRequests } from '../../types/custom-queries'

const { Text, Title } = Typography

const monthNames = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

if (!window.ResizeObserver) {
  // Setup polyfill for Safari and browsers without resize observer
  installResizeObserverPolyfill()
}

const HeatmapChart = ({ 
  leaves,
  holidays,
  workingDays,
  onPreviousMonth,
  onNextMonth,
  month,
  year,
  role,
  isHeatmapLoading,
  onCancelLeave,
  approverToUsers,
  hourlyLeaveAccounting,
  locale,
}: IHeatmapChartProps): ReactElement => {
  const today = new Date()
  const [selectedDay, setSelectedDay] = useState(today.getDate())
  const [days, setDays] = useState<HeatmapEntry[][] | null>(null)
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    const numberOfDays = getNumberOfDaysInMonth(year, month)
    const daysArray = Array.from(Array(numberOfDays + 1).keys()).slice(1)
    const tmpDays: IDaysData = zipObject(daysArray, Array(numberOfDays).fill('').map(() => ([])))

    holidays.forEach(holiday => {
      const date = new Date(getDateInUserTimezone(holiday.date))
      const holidayYear = date.getFullYear()
      const holidayMonth = date.getMonth()
      if(month === holidayMonth && year === holidayYear) {
        const holidayDate = date.getDate()
        tmpDays[holidayDate].push(holiday)
      }
    })

    leaves.forEach((leaveDay: IHeatMapLeaves, index) => {
      const date = new Date(year, month, index + 1)
      const leaveDate = date.getDate()

      if (!tmpDays[leaveDate]) {
        tmpDays[leaveDate] = []
      }

      tmpDays[leaveDate].push(...leaveDay.leaves.map((leave: IHeatmapLeaveRequests) => {
        const isHiddenLeaveType = leave.leavePolicy.hideLeaveType
        const isAdminOrUsersApprover = role === 'Admin' || (role === 'Approver' && approverToUsers.find(user => user.id === leave.user.id))
        const hideThisLeaveType = isHiddenLeaveType && !isAdminOrUsersApprover
        const leaveTypeName = hideThisLeaveType ? 'Hidden Leave Type' : leave.leavePolicy.leaveType.name

        return {
          id: leave.id,
          type: HeatmapEventType.leave,
          startDate: dayjs(getDateInUserTimezone(leave.startDate)).format('YYYY-MM-DD'),
          endDate: dayjs(getDateInUserTimezone(leave.endDate)).format('YYYY-MM-DD'),
          isPartDay: leave.isPartDay,
          name: leaveTypeName,
          color: hideThisLeaveType ? 'rgb(119, 119, 119)' : leave.leavePolicy.leaveType.color,
          userId: leave.user.id,
          userImage: leave.user.imageUrl,
          userName: leave.user.name,
          status: leave.status,
          approverName: leave.approver?.name,
          workingDays: leave.workingDays,
          autoApproved: leave.autoApproved,
          partDayStartHour: leave.partDayStartHour,
          partDayEndHour: leave.partDayEndHour,
          hideLeaveType: leave.leavePolicy.hideLeaveType,
          reason: leave.reason,
          approvedBySubstituteApprover: leave.approvedBySubstituteApprover,
        } as IHeatmapLeave
      }))
    })
    setDays(Object.values(tmpDays))
  }, [month, year, holidays])

  const onDateSelect = (day: number) => {
    setSelectedDay(day)
  }

  return (
    <div>
      { (days && selectedDay) ? (
        <>
          <div className="heatmap-navigation">
            <Button type="link" onClick={() => onPreviousMonth()}><LeftOutlined /><IntlMessages id="components.heatmap.previousMonth" /></Button>
            <Text strong className="vt-current-month">
              <IntlMessages id={`app.${monthNames[month]}`} /> {year}
            </Text>
            <Button type="link" onClick={() => onNextMonth()}><IntlMessages id="components.heatmap.nextMonth" /><RightOutlined /></Button>
          </div>
          <Spin spinning={isHeatmapLoading}>
            <HeatmapChartMonth
              month={month}
              year={year}
              workingDays={workingDays}
              data={days}
              onSelect={onDateSelect}
            />
          </Spin>
          <div className="heatmap-leaves">
            <Title level={5} className="heatmap-selected-date">
              <IntlMessages id={`app.${monthNames[month]}`} /> {selectedDay}, {year} {
                isToday(year, month, selectedDay) && <Tag color="#27C24C"><IntlMessages id="components.heatmap.today" /></Tag>
              } {!workingDays.includes(new Date(year, month, selectedDay).getDay()) &&
                <Tag color="#787878"><IntlMessages id="components.heatmap.nonWorkingDay" /></Tag>
              }
            </Title>
            <List>
              {days[selectedDay - 1] && days[selectedDay - 1].length ? (
                days[selectedDay - 1].map((item, index) =>  {
                  if (!showAll && index > 2) {
                    return null
                  }
                  return item.type === HeatmapEventType.holiday ?
                    <Fragment key={index}>
                      <div className="leaves-box">
                        <div className="avatar">
                          <Avatar shape="square" size={70} icon={<GiKite />} />
                        </div>
                        <div className="content">
                          <div className="info">
                            <div className="user">
                              <Tag color={'rgb(114, 102, 186)'}>
                                <IntlMessages id="components.heatmap.holiday" />
                                {item.isHalfDay && <IntlMessages id="app.halfDayInParenthesis" />}
                              </Tag>
                            </div>
                          </div>
                          <div className="leaves-info">
                            <Space className="leaves-text" size={5} direction="vertical">
                              <Text>
                                <IntlMessages id="components.heatmap.holidayDescription" values={{ location: item.locationName, holiday: item.name }} />
                                {item.isHalfDay && <IntlMessages id="app.halfDayInParenthesis" />}
                              </Text>
                            </Space>
                          </div>
                        </div>
                      </div>
                      <Divider style={{ margin: '10px' }} />
                    </Fragment> :
                    <LeavesBox
                      key={index}
                      leaveRequestId={item.id}
                      userId={item.userId}
                      userImage={item.userImage}
                      userName={item.userName}
                      leaveTypeName={item.name}
                      leaveTypeColor={item.color}
                      leaveStatus={item.status}
                      type={item.type}
                      startDate={item.startDate}
                      endDate={item.endDate}
                      workingDays={item.workingDays}
                      approver={item.approverName}
                      autoApproved={item.autoApproved}
                      isPartDay={item.isPartDay}
                      partDayStartHour={item.partDayStartHour}
                      partDayEndHour={item.partDayEndHour}
                      reason={item.reason}
                      role={role}
                      isHiddenLeaveType={Boolean(item.hideLeaveType)}
                      onCancelLeave={onCancelLeave}
                      handleDenyWithReason={() => {
                        console.log('unused atm')
                      }}
                      approverToUsers={approverToUsers}
                      hourlyLeaveAccounting={hourlyLeaveAccounting}
                      locale={locale as LocaleEnum}
                      approvedBySubstituteApprover={item.approvedBySubstituteApprover}
                    />
                })
              ) : (
                <Empty
                  description={<IntlMessages id="dashboard.noScheduledLeavesHolidays" />}
                  image={<img src={require('../../assets/images/empty.png')} />}
                  imageStyle={{ width: '200px', height: '200px', margin: '0 auto' }}
                />
              )}
              {days[selectedDay - 1] && days[selectedDay - 1].length > 3 && <>
                <Button type="text" block onClick={() => setShowAll(!showAll)}>
                  {!showAll ?
                    <IntlMessages id="components.heatmap.showMore" values={{ amount: days[selectedDay - 1].length - 3 }} /> :
                    <IntlMessages id="app.showLess" />
                  }
                </Button>
              </>}
            </List>
          </div>
        </>
      ) : (<IntlMessages id="components.heatmap.loading" values={{ day: selectedDay }} />) }
    </div>
  )
}

export default HeatmapChart
