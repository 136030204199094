import React, { useReducer, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { Button, Col, Modal, notification, Row, Steps, Typography } from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { orderBy, chunk, isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'
import IntlMessages from '../../util/IntlMessages'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setAuthUser, setIsSigningUp } from '../../store/auth-user-slice'
import { selectLocaleSlice, setLocale } from '../../store/locale-slice'
import { setUserId } from '../../store/user-id-slice'
import { getCookieByName, parseAndPrepareUtmCookie } from '../../util/get-and-parse-cookie'

import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../services/auth/slack/slack'
import { GoogleAuth } from '../../services/auth/google/google'
import { signup } from '../../services/api/companies'
import countries from '@vacationtracker/shared/data/countries'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { CompanyDetails } from './steps/company-details'
import { ImportUsers } from './steps/import-users'
import { SelectPlan } from './steps/select-plan'
import { actions } from './actions'
import { initialState, reducer } from './reducer'
import { setCrispSessionInfoForSignupProcess } from './helpers'
import { wait } from '@vacationtracker/shared/functions/wait'
import { SignupVariation as SignupVariationType } from '@vacationtracker/shared/types/company'

import { logout } from '../../services/auth/logout-handler'
import { availableLanguages } from '@vacationtracker/shared/i18n'

import { identify, track, trackConversionEvent } from '../../services/analytics/analytics'
import { ISignupRequest } from '../../services/api/companies.types'
import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { ICreateCompany, ICreateUser, SignupVariation } from './types'

import { IImportUser } from '@vacationtracker/shared/types/user'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'
import { FrontendUrls } from '../../types/urls'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { UserExistsException } from '@vacationtracker/shared/errors/users'
import { openSupportChat } from '../../util/open-support-chat'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { originalPlatformVtId } from '@vacationtracker/shared/functions/user-id'

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

interface ICreateCompanyProps {
  onStateChange: (e) => void
}

const { Step } = Steps
const { Paragraph, Text } = Typography
const supportLink = 'https://vacationtracker.crisp.help/en/article/google-workspace-editing-permissions-15pdkie/'

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

export const notAllCompanyDataSet = state => {
  return !state.createCompany.name
    || !state.createCompany.contactEmail
    || !state.createCompany.plan
    || !state.createCompany.daysPerYear
    || !state.createCompany.country
    || (state.createCompany.country && countries.find(country => country.iso === state.createCompany.country)?.states?.length as number > 0 && !state.createCompany.state)
}

const CreateCompany = ({ onStateChange }: ICreateCompanyProps): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const reduxDispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const [showEnableGoogleApiModal, setShowEnableGoogleApiModal] = useState(false)
  const [showEnableGoogleApiModalPermissionDenied, setShowEnableGoogleApiModalPermissionDenied] = useState(false)
  const [subscriptionPlan] = useState(() => {
    const plan = localStorage.getItem('vtSubscriptionPlan')
    if (plan && (plan === 'Core' || plan === 'Complete')) {
      dispatch(actions.setSubscriptionPlan(plan))
      dispatch(actions.setSkipSelectPlan())
      return plan
    }
  })
  const [variation, setVariation] = useState<SignupVariation>('NOT_SET')

  useEffect(() => {
    const createUser = JSON.parse(localStorage.getItem('vtCreateUser') || '{}')
    setCrispSessionInfoForSignupProcess(createUser.platform)

    if (createUser && createUser.platform === 'email') {
      const emailInvites = JSON.parse(localStorage.getItem('vtEmailInvites') || '{}')
      if (emailInvites) {
        dispatch(actions.setEmailInvites(emailInvites))
      }
    }

    const preselectedPlan = localStorage.getItem('vtSubscriptionPlan')
    let currentVariation: SignupVariation
    if (preselectedPlan) {
      currentVariation = 'ACCOUNT_USERS'
    } else {
      currentVariation = 'ACCOUNT_USERS_PLAN'
    }
    setVariation(currentVariation)

    dispatch(actions.setCreateUser(createUser))
    fetchUsers(createUser.platform, createUser.msUserId || createUser.slackUserId || createUser.googleUserId)

    const createCompany = JSON.parse(localStorage.getItem('vtCreateCompany') || '{}')

    // if (isEmpty(createCompany)) {
    //   track('SIGNUP_STARTED', {
    //     platform: createUser.platform,
    //     variation: currentVariation,
    //     plan: preselectedPlan || 'NOT_SELECTED_YET',
    //     email: createUser.mail,
    //     locale: locale.locale,
    //     signupVariation: getSignupVariation(),
    //     timestamp: new Date().toISOString(),
    //     source: 'app',
    //     status: 'started',
    //   })
    // }
    prepareCompanyForStateAndDispatchSetter(createUser, createCompany)
  }, [])

  useEffect(() => {
    const path = history.location.pathname

    if (path === '/create-company/step1') {
      dispatch(actions.setCurrentStep(0))
    }

    if (path === '/create-company/step2') {
      dispatch(actions.setCurrentStep(1))
    }

    if (path === '/create-company/step3') {
      if (variation === 'PLAN_ACCOUNT_USERS') {
        dispatch(actions.setCurrentStep(1))
      }
      dispatch(actions.setCurrentStep(2))
    }
  }, [history.location.pathname])

  const getSignupVariation = (): SignupVariationType => {
    try {
      return localStorage.getItem('signup-flow')?.slice(-1).toUpperCase() as SignupVariationType
    } catch {
      return 'A'
    }
  }

  const prepareCompanyForStateAndDispatchSetter = (data: ICreateUser, companyFromStorage: ICreateCompany) => {
    const newCompany = {
      contactEmail: data.mail,
      name: '',
      platform: data.platform,
      country: '',
      state: '',
      hasUnlimitedDays: false,
      daysPerYear: 20,
      plan: null,
    }

    if (data.platform === 'microsoft' && data.mail.includes('onmicrosoft.com')) {
      newCompany.contactEmail = ''
    }
    if (data.platform === 'slack') {
      newCompany.name = data.teamName || ''
    }

    dispatch(actions.setCreateCompanyDetails(isEmpty(companyFromStorage) ? newCompany : companyFromStorage))
  }

  let isFirstLoadOfUsers = true
  const getSlackUsers = async (initialUserId: string, nextCursor?: string) => {
    const slackUsers = await slackAuth.getUserListPagination(nextCursor)
    const users = slackUsers.members
      .filter(user =>
        user.id !== 'USLACKBOT' && !user.is_bot && !user.deleted && !user.is_ultra_restricted && (user.profile.real_name || user.name)
      )
      .filter(user => user.id !== initialUserId)
      .map(user => {
        return {
          name: user.profile.real_name || user.name,
          email: user.profile.email,
          slackId: user.id,
          isOwner: user.is_owner,
          isAdmin: user.is_admin,
          image: user.profile.image_24,
          id: user.id,
        }
      }) as IImportUser[]

    if (slackUsers.members.length < 200 && isFirstLoadOfUsers) {
      dispatch(actions.insertLoadedUsers(orderBy(users, [u => u.name.toLowerCase()], ['asc'])))
    } else {
      dispatch(actions.insertLoadedUsers(users))
      isFirstLoadOfUsers = false
    }

    if(slackUsers.nextCursor) {
      await getSlackUsers(initialUserId, slackUsers.nextCursor)
    } else {
      dispatch(actions.loadAllUsersSuccess())
    }
  }

  const getMicrosoftUsers = async (accessToken: string, initialUserId: string, nextCursor?: string) => {
    const msUsers = await msAuth.getUserListPagination(accessToken, nextCursor)
    const users = msUsers.members
      .filter(user => user.mail || user.userPrincipalName)
      .filter(user => user.givenName || user.surname || user.displayName)
      .filter(user => user.id !== initialUserId)
      .map(user => {
        return {
          name: (user.givenName || user.surname) ? `${user.givenName || ''} ${user.surname || ''}` : user.displayName,
          email: user.mail || user.userPrincipalName,
          id: user.id,
        }
      }) as IImportUser[]

    if (msUsers.members.length < 100 && isFirstLoadOfUsers) {
      dispatch(actions.insertLoadedUsers(orderBy(users, [u => u.name.toLowerCase()], ['asc'])))
    } else {
      dispatch(actions.insertLoadedUsers(users))
      isFirstLoadOfUsers = false
    }

    if(msUsers.nextPage) {
      await getMicrosoftUsers(accessToken, initialUserId, msUsers.nextPage)
    } else {
      dispatch(actions.loadAllUsersSuccess())
    }
  }

  const getGoogleUsers = async (initialUserId: string, nextPageToken?: string) => {
    const usersResult = await googleAuth.getUserListForImportPaged(nextPageToken)
    const users: IImportUser[] = usersResult.users
      .filter(user => Boolean(user.email) && user.googleId !== initialUserId)

    if (usersResult.users.length < 200 && isFirstLoadOfUsers) {
      dispatch(actions.insertLoadedUsers(orderBy(users, [u => u.name.toLowerCase()], ['asc'])))
    } else {
      dispatch(actions.insertLoadedUsers(users))
      isFirstLoadOfUsers = false
    }

    if(usersResult.nextPageToken) {
      await getGoogleUsers(initialUserId, usersResult.nextPageToken)
    } else {
      dispatch(actions.loadAllUsersSuccess())
    }
  }

  const fetchUsers = async (platform, initialUserId) => {
    try {
      onStateChange('signUp')

      if (platform === 'slack') {
        slackAuth.getBotToken()
        await getSlackUsers(initialUserId)
      }

      if (platform === 'microsoft') {
        const tokens = msAuth.getTokens()
        await getMicrosoftUsers(tokens.accessToken, initialUserId)
      }

      if (platform === 'google') {
        await getGoogleUsers(initialUserId)
      }
    } catch (error) {
      if (error.message === 'GOOGLE_DIRECTORY_SHARING_DISABLED') {
        track('GOOGLE_ERROR_DIRECTORY_SHARING_DISABLED', {
          page: 'create-company',
          platform: 'google',
          error: error.message,
          plan: state.createCompany.plan,
        })
        setShowEnableGoogleApiModal(true)
        return
      }
      if (error.message === 'GOOGLE_DIRECTORY_PERMISSION_DENIED') {
        track('GOOGLE_DIRECTORY_PERMISSION_DENIED', {
          page: 'create-company',
          platform: 'google',
          error: error.message,
          plan: state.createCompany.plan,
        })
        setShowEnableGoogleApiModalPermissionDenied(true)
        return
      }
      console.log('error', error.message)
      localStorage.clear()
      sessionStorage.clear()
      // TODO: Show notification error
      onStateChange('signIn')
      history.push(FrontendUrls.signin)
      Sentry.captureException(error)
    }
  }

  const clearSignupStorage = () => {
    localStorage.removeItem('vtCreateUser')
    localStorage.removeItem('vtCreateCompany')
    localStorage.removeItem('vtSelectedUsers')
    localStorage.removeItem('vtEmailInvites')
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        reduxDispatch(setAuthCompany(response.data.getCompany))
        reduxDispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          reduxDispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error, numberOfRetry, location.pathname)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange,
          history,
          reduxDispatch,
          userId: id,
        })
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const authSignIn = async (username: string, token: string, platform: string, plan: string) => {
    const signInResponse = await Auth.signIn(username)
    if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
      const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: platform })
      localStorage.setItem('userId', cognitoResponse.username)
      const { selectedUsers, importAllUsers, createUser, allUsers } = state

      let importUsers: IImportUser[] = []
      let platformToken
      let refreshToken
      if (createUser.platform === 'microsoft') {
        const msTokens = msAuth.getTokens()
        platformToken = msTokens.accessToken
        refreshToken = msTokens.refreshToken
        importUsers = selectedUsers
          .filter(user => (user.id !== createUser.msUserId) && (user.msUserId !== createUser.msUserId))
          .map(user => ({
            email: user.email,
            userId: user.id,
            name: user.name,
          }))

      }
      if (createUser.platform === 'slack') {
        platformToken = slackAuth.getBotToken()
        importUsers = selectedUsers
          .filter(user => user.id !== createUser.slackUserId)
          .map(user => user.id)
      }
      if (createUser.platform === 'google') {
        platformToken = googleAuth.getAccessToken()
        refreshToken = googleAuth.getRefreshToken()

        importUsers = selectedUsers
          .filter(user => user.id !== createUser.id)
          .map(user => user.googleId)
      }

      await getCompanyAndUser(cognitoResponse.username)


      track('SIGNUP_COMPLETED', {
        platform,
        variation,
        plan,
        timestamp: new Date().toISOString(),
        activeUsers: importAllUsers ? allUsers.length : importUsers.length === 0 ? 1 : importUsers.length,
        source: 'app',
        status: 'completed',
      })
      trackConversionEvent('SIGNUP_COMPLETED')
      if (cognitoResponse.attributes['custom:companyId'] && typeof cognitoResponse.attributes['custom:companyId'] === 'string') {
        identify(cognitoResponse.attributes['custom:companyId'], {
          totalUsers: allUsers.length,
          activeUsers: importAllUsers ? allUsers.length : importUsers.length === 0 ? 1 : importUsers.length,
        })
      }

      if (importAllUsers || importUsers.length > 0) {
        try {
          if (importAllUsers) {
            let params = {
              eventType: 'IMPORT_USERS',
              eventGroup: 'BULK_ACTIONS',
              users: [],
              organizationId: createUser.tenantId || createUser.slackTeamId || createUser.googleDomain,
              msTeamId: createUser.tenantId,
              token: platformToken,
              refreshToken,
              importAllUsers,
              totalUsers: allUsers.length,
              source: 'create-company',
              version: 3,
            }
            params = platform !== 'slack' ? { ...params, refreshToken } : params
            await API.post('CoreEvent', '/core/event', {
              body: params,
            })
          } else {
            await Promise.all(
              chunk(importUsers, 300)
                .map(async batchUserIds => {
                  let params = {
                    eventType: 'IMPORT_USERS',
                    eventGroup: 'BULK_ACTIONS',
                    users: batchUserIds,
                    organizationId: createUser.tenantId || createUser.slackTeamId || createUser.googleDomain,
                    msTeamId: createUser.tenantId,
                    token: platformToken,
                    refreshToken,
                    importAllUsers: false,
                    totalUsers: allUsers.length,
                    source: 'create-company',
                    version: 3,
                  }
                  params = platform !== 'slack' ? { ...params, refreshToken } : params
                  return await API.post('CoreEvent', '/core/event', {
                    body: params,
                  })
                })
            )
          }
        } catch (error) {
          const errorDescription = error.response?.data ? error.response?.data : error.message ? error.message : JSON.stringify(error)
          track('IMPORT_USERS_ERROR', {
            platform,
            errorMessage: errorDescription,
            plan: state.createCompany.plan,
          })
        }
      }

      reduxDispatch(setUserId(cognitoResponse.username))
      clearSignupStorage()
      onStateChange('signedIn')
      history.push('/app/dashboard?tour=true')
    }
  }

  const goToLogin = (state?: string) => {
    onStateChange('signIn')
    const url = FrontendUrls.signin + (state ? `?${state}=true` : '')
    history.push(url)
  }

  const onFinishSetup = async (plan?: string) => {
    dispatch(actions.isCreateCompanyLoader(true))
    const { createCompany, createUser } = state
    const platform = createUser.platform as Platform
    const currentPlan = createCompany.plan || plan

    try {
      const data: ISignupRequest = {
        signupVariation: getSignupVariation(),
        user: {
          id: createUser.id,
          name: createUser.name,
          email: createUser.mail || createCompany.contactEmail,
          timezone: createUser.timezone,
          imageUrl: createUser.imageUrl,
          platform: platform,
          isAdmin: true,
          locale: locale.locale || LocaleEnum.en,
        },
        company: {
          name: createCompany.name,
          contactEmail: createCompany.contactEmail,
          platform: platform,
          country: countries.find(country => country.iso === createCompany.country)?.name as string,
          daysPerYear: createCompany.daysPerYear ?? 20,
          hasUnlimitedDays: createCompany.hasUnlimitedDays ?? false,
          announceNewUsers: createCompany.announceNewUsers,
          state: createCompany.state,
          plan: currentPlan,
          timezone: getTimeZoneOrDefaultToUtc(),
          paymentProcessor: 'stripe',
          affiliateCode: localStorage.getItem('vtAffiliateCode') || undefined,
          survey: createCompany.survey,
        },
      }

      if(platform === 'microsoft') {
        data.token = msAuth.getTokens().accessToken
        data.ms = {
          msTeamId: createUser.tenantId,
          organizationId: createUser.tenantId,
          msUserId: createUser.msUserId,
        }
      }

      if(platform === 'slack') {
        data.slack = {
          slackBotToken: createUser.slackBotToken,
          slackUserToken: createUser.slackUserToken,
          slackUserId: createUser.slackUserId,
          slackTeamId: createUser.slackTeamId,
          slackOrgTeamId: createUser.slackOrgTeamId ? createUser.slackOrgTeamId : createUser.slackTeamId,
          isOwner: createUser.isOwner || false,
          slackBotId: createUser.slackBotId,
        }
        data.token = slackAuth.getUserToken()
      }

      if(platform === 'google') {
        data.token = googleAuth.getAccessToken()
        data.google = {
          googleDomain: createUser.googleDomain,
          googleUserId: originalPlatformVtId(createUser.googleUserId as string),
        }
      }

      const utmCookie = getCookieByName('_vt_sc')
      if (utmCookie) {
        try {
          const utmCookieParsed = parseAndPrepareUtmCookie(utmCookie)
          data.utmCookie = utmCookieParsed
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      const response = await signup(data)
      await wait(1000)
      if (data.token) {
        // Log in with token
        await authSignIn(createUser.id, data.token, platform, currentPlan)
      } else if (platform === 'email') {
        reduxDispatch(setIsSigningUp(true))
        const userId = response.userId
        if (response.temporaryPassword) {
        // Log in with password
          const temporaryPassword = response.temporaryPassword
          const cognitoResponse = await Auth.signIn(userId, temporaryPassword)

          if (cognitoResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await Auth.completeNewPassword(cognitoResponse, createUser.userPassword)
          } else {
            await Auth.changePassword(cognitoResponse, temporaryPassword, createUser.userPassword)
          }
          await Auth.signIn(userId, createUser.userPassword)
        }
        if (state.emailInvites.length > 0) {
          await API.post('CoreEvent', '/core/event', {
            body: {
              eventType: 'USERS_INVITED',
              eventGroup: 'USER',
              userId,
              emails: state.emailInvites.map(invite => invite.email),
            },
          })
        }
        localStorage.setItem('userId', userId)
        reduxDispatch(setUserId(userId))
        await getCompanyAndUser(userId)
        track('SIGNUP_COMPLETED', {
          platform,
          variation,
          plan: state.createCompany.plan,
          timestamp: new Date().toISOString(),
          source: 'app',
          status: 'completed',
        })
        trackConversionEvent('SIGNUP_COMPLETED')
        clearSignupStorage()
        onStateChange('signedIn')
        reduxDispatch(setIsSigningUp(false))
        history.push('/app/dashboard?tour=true')
      }
    } catch(error) {
      dispatch(actions.isCreateCompanyLoader(false))
      if (error.response?.data?.code === UserExistsException.code) {
        const { data } = error.response.data
        track('SIGNUP_ERROR', {
          platform,
          errorCode: UserExistsException.code,
          errorMessage: UserExistsException.message,
          plan: state.createCompany.plan,
        })
        notification.error({
          message: formatMessage({ id: 'error.signup.alreadySignedUp.title' }),
          description: data.status.toUpperCase() === 'ACTIVE' ? (<>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUp.descriptionLine1' }, {
              email: data.email,
              text: (chunks) => <Text keyboard>{...chunks}</Text>,
            })}</Paragraph>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUp.descriptionLine2' })}</Paragraph>
          </>) : (<>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUpInactive.descriptionLine1' }, {
              email: data.email,
              text: (chunks) => <Text keyboard>{...chunks}</Text>,
            })}</Paragraph>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUpInactive.descriptionLine2' })}</Paragraph>
            <Paragraph code copyable>{ JSON.stringify({ cid: data.companyId, uid: data.id }, null, 4) }</Paragraph>
          </>),
          key: UserExistsException.code,
          btn: data.status.toUpperCase() === 'ACTIVE' ? (
            <Button onClick={() => {
              notification.close(UserExistsException.code)
              goToLogin()
            }}>{ formatMessage({ id: 'app.login' }) }</Button>
          ) : (
            <Button onClick={() => {
              notification.close(UserExistsException.code)
              openSupportChat()
            }}>{ formatMessage({ id: 'app.contactSupport' }) }</Button>
          ),
        })
        return
      }
      const errorDescription = error.response?.data ? error.response?.data : error.message ? error.message : JSON.stringify(error)
      track('SIGNUP_ERROR', {
        platform,
        errorMessage: errorDescription,
        plan: state.createCompany.plan,
      })
      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        description: errorDescription,
      })
      if(error.response?.data.includes('ValidationError')) {
        history.push('/create-company/step1')
        dispatch(actions.setCurrentStep(0))
      } else {
        clearSignupStorage()
        onStateChange('signIn')
      }
      reduxDispatch(setIsSigningUp(false))
    }
  }

  // size of steps (1 || 3) depends on A/B testing (see the SignupVariation type)
  const steps = [
    {
      id: 'companyForm',
      content: <CompanyDetails state={state} variation={variation} dispatch={dispatch} onStateChange={onStateChange} signupVariationType={getSignupVariation()} />,
    },
    {
      id: 'selectUsers',
      content: <ImportUsers
        state={state}
        dispatch={dispatch}
        variation={variation}
        onFinishSetup={onFinishSetup}
        isGoogleDirectoryApiEnabled={!showEnableGoogleApiModal || !showEnableGoogleApiModalPermissionDenied}
      />,
    },
  ]
  const selectPlan = {
    id: 'selectPlan',
    content: <SelectPlan
      state={state}
      dispatch={dispatch}
      onFinishSetup={onFinishSetup}
      variation={variation}
      notAllCompanyDataSet={notAllCompanyDataSet}
      onStateChange={onStateChange}
    />,
  }
  if(!subscriptionPlan) {
    if (variation === 'ACCOUNT_USERS_PLAN') {
      steps.push(selectPlan)
    } else {
      steps.unshift(selectPlan)
    }
  }

  const connectWithGoogle = () => {
    const platform = 'google'
    googleAuth.signin()
      .then(async () => {
        const googleUser = googleAuth.getSignedInUser()
        if (!googleUser.hd) {
          notification.error({
            message: formatMessage({ id: 'connect.google.notWorkspaceUserTitle' }),
            description: formatMessage({ id: 'connect.google.notWorkspaceUserDescription' }),
            duration: 0,
          })
          return
        }
        try {
          setShowEnableGoogleApiModalPermissionDenied(false)
          await fetchUsers(platform, `google-${googleUser.sub}`)
        } catch (error) {
          history.push(FrontendUrls.signup + location.search)
        }
      })
      .catch(e => {
        history.push(FrontendUrls.signup + location.search)
      })
  }

  const small = { span: 24, offset: 0 }
  const medium = { span: 24, offset: 0 }
  const large = { span: 20, offset: 2 }
  const extraLarge = { span: 18, offset: 3 }

  return (
    <section className="ant-layout ant-layout-has-sider app-layout create-company">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper">
            <div className="main-content">
              <div className="main-content-body">
                <Row>
                  <Col span={24} offset={0} xs={small} md={medium} lg={large} xl={extraLarge} xxl={extraLarge}>
                    <Steps style={{ paddingBottom: 20 }} size="small" current={state.currentStep}>
                      {steps.map(step => (
                        <Step key={step.id.toString()} />
                      ))}
                    </Steps>
                    <div className="steps-content">
                      {steps[state.currentStep].content}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </section>
      {showEnableGoogleApiModal &&
        <Modal
          title={formatMessage({ id: 'error.google.directoryApiDisabledTitle' })}
          visible={showEnableGoogleApiModal}
          footer={null}
          onCancel={() => {
            setShowEnableGoogleApiModal(false)
          }}
        >
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP1" /></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP2" /></Paragraph>
          <Paragraph copyable={{text: supportLink}}><a href={supportLink} target="_blank" rel="noopener noreferrer">{supportLink}</a></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP3" /></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP4" /></Paragraph>

        </Modal>
      }
      {showEnableGoogleApiModalPermissionDenied &&
        <Modal
          title={formatMessage({ id: 'error.google.directoryApiPermissionDeniedTitle' })}
          visible={showEnableGoogleApiModalPermissionDenied}
          onCancel={() => {
            setShowEnableGoogleApiModalPermissionDenied(false)
            history.push(FrontendUrls.signup + location.search)
          }}
          onOk={connectWithGoogle}
          okText={<IntlMessages id="connect.signInWithGoogleVerification" />}
          width={640}
        >
          <Paragraph><IntlMessages id="error.google.directoryApiPermissionDeniedP1" /></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiPermissionDeniedP2" /></Paragraph>
          <img style={{ height: '18%', width: '100%', marginBottom: '20px' }} src={require('../../assets/images/google-signin-modal.png')} />
          <Paragraph><IntlMessages id="error.google.directoryApiPermissionDeniedP3" /></Paragraph>
        </Modal>
      }
    </section>
  )
}

export default CreateCompany
