import { Platform } from '@vacationtracker/shared/types/core-event'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'

export interface IAutocompleteOption {
  label: string
  value: string
}

export interface IAutocompleteOptionValue {
  id: string
  name: string
  mail: string
  imageUrl?: string
  realName?: string
}

export interface IImportUser {
  userId: string
  name: string
  email: string
  imageUrl?: string
}

export interface IImportData {
  newUsers: IImportUser[]
  importAllUsers: boolean
  importUsersAutomatically: boolean
  shouldProrate: boolean
  selectedLeaveTypes: string[]
  selectedLocation: string
  selectedDepartment: string
  sendWelcomeMessageTo: boolean
}

export interface IImportUsersPageFormProps {
  totalNumberOfUsers: number
  platform: Platform
  showAdminConsentWarning: boolean
  isMsAdminConsentRequired: boolean
  adminConsentUrl: string | null
  selectedUsers: IAutocompleteOptionValue[]
  notImportedUsers: any[]
  activeStep: ImportUsersStepEnum
  importAllUsers: boolean
  noOfUsersToImport: number
  totalNumberOfUsersAfterImport: number
  upcomingInvoiceInfo: IUpcomingInvoiceInformation | null
  isLoadingUpcomingInvoiceInfo: boolean
  // TODO: fix any for passed date from configure form
  onSubmit: (importAllUsers: boolean, selectedUsers: IAutocompleteOptionValue[], data: any) => Promise<void>
  onSearchUsers: (value: string) => Promise<IAutocompleteOption[]>
  setSelectedUsers: Function
  setSteps: (step: ImportUsersStepEnum, activeStep: number) => void
  onSetImportAllUsers: (v) => void
  onSetImportUsersAutomatically: () => void
  setNotImportedUsers: (v) => void
  shouldEnableImportAll: boolean
  shouldImportUsersAutomatically: boolean
  submitBilling: (seats: number) => Promise<void>
  setShowMsLoginRequiredModal?: (v) => void
  setShowGoogleLoginRequiredModal?: (v) => void
  disableImportUsersAutomatically?: Function
  numberOfActiveUsers?: number
}

export enum ImportUsersStepEnum {
  selectUsers = 'SELECT_USERS',
  configure = 'CONFIGURE',
  confirm = 'CONFIRM',
}

export interface IConfigureImportUsersOptionsProps {
  activeStep: ImportUsersStepEnum
  noOfUsersToImport: number
  totalNumberOfUsersAfterImport: number
  importAllUsers: boolean
  importUsersAutomatically: boolean
  selectedUsers: IAutocompleteOptionValue[]
  welcomeMessageOpts: WelcomeMessageOptions[]
  upcomingInvoiceInfo: IUpcomingInvoiceInformation | null
  isLoadingUpcomingInvoiceInfo: boolean
  isAnnualBilling: boolean
  setSteps: (step: ImportUsersStepEnum, activeStep: number) => void
  onChangeGuestUsersImport: (v) => void
  setWelcomeMessageOpts: (v) => void
  setShowMsLoginAdditionalScopesRequiredModal: (v) => void
  onSubmit: (importAllUsers: boolean, selectedUsers: IAutocompleteOptionValue[], data: any) => Promise<void>
  setSelectedWelcomeMessageOpt?: (v) => void
}

export interface IImportUsersNavigationProps {
  platform: Platform
  importAllUsers: boolean
  submitInProgress: boolean
  noOfUsersToImport: number
  totalNumberOfUsersAfterImport: number
  activeStep: ImportUsersStepEnum
  upcomingInvoiceInfo: IUpcomingInvoiceInformation | null
  isLoadingUpcomingInvoiceInfo: boolean
  isAnnualBilling: boolean
  locale: LocaleEnum
  showAdditionalBillingInfo: boolean
  handleNext: () => void
  nextButtonTitle: string
  isTrial: boolean
  stripeCurrentPeriodEnd: string
  numberOfActiveUsers?: number
  backButtonTitle?: string
  handleBack?: () => void
  submitBilling?: (seats: number) => Promise<void>
}

export type WelcomeMessageOptions = {
  platform: 'microsoft' | 'slack' | 'email' | 'none' | string
  value: string
  channel: string | undefined
}

export interface IUserToImportData {
  label: string
  value: string
}

export interface IUserToImport {
  email: string
  name: string
  userId: string
}
export interface IImportUsersEventBody {
  eventType: string
  eventGroup: string
  users: IUserToImport[]
  organizationId: string
  importAllUsers: boolean
  totalUsers: number
  defaultLocation: string
  defaultDepartment: string
  announceNewUsers: boolean
  refreshToken?: string
  importUsersAutomatically?: boolean
  shouldProrate?: boolean
  shouldImportGuestUsers?: boolean
  prorateLeaveTypes?: string[]
  version?: number
}
