import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Form, Input, InputRef, Modal, notification, Row, Spin, Typography } from 'antd'
import Icon, { GoogleOutlined, SlackOutlined, WindowsFilled, MailOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import qs from 'qs'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'

import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../services/auth/slack/slack'
import { getUserId as getSlackUserId, updateSlackToken } from '../../services/api/slack'
import { getUserId as getMsUserId } from '../../services/api/microsoft'
import { getUserId as getGoogleUserId } from '../../services/api/google'
import { GoogleAuth } from '../../services/auth/google/google'
import { track } from '../../services/analytics/analytics'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setUserId } from '../../store/user-id-slice'
import { setAuthUser, selectAuthUserSlice } from '../../store/auth-user-slice'
import { logout } from '../../services/auth/logout-handler'
import { setLocale } from '../../store/locale-slice'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'

import IntlMessages from '../../util/IntlMessages'
import CompanyAlreadySignedUpModal from '../../components/company-already-signed-up-modal'
import { SeoTags } from '../../components/seo-tags'
import VtLogo from '../../assets/images/logo-purple.svg'

import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { wait } from '@vacationtracker/shared/functions/wait'
import { ICheckUserId } from '@vacationtracker/shared/types/company'

import { ISignin, IUpdateToken } from './types'
import { FrontendUrls } from '../../types/urls'
import { FieldData } from 'rc-field-form/lib/interface'
import { Platform } from '@vacationtracker/shared/types/core-event'
import DontHaveAnAccountModal from '../../components/dont-have-an-account-modal'
import { setCrispSessionInfo } from '../../util/set-crisp-session-info'
import { CrispSegmentsEnum } from '../../types/crisp'
import { IContactAdminData } from '../../types/auth'
import CompanyExistsModal from '../../components/modal-company-exists'
import ForgotPasswordModal from '../../components/modal-forgot-password'
import { GoogleLogo } from '../../components/custom-svg-icons'
import MFACodeModal from '../../components/mfa-code-modal'
import { vtUserId } from '@vacationtracker/shared/functions/user-id'
import { PlatformEnum } from '@vacationtracker/shared/data/platforms'
import { getCognitoErrors } from '../../components/mfa-code/errors'
import { ISlackOpenidTokenResponse } from '@vacationtracker/shared/types/repository/slack-api-repository'

const { Paragraph, Title, Link } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const Signin = ({ onStateChange, location, authState }: ISignin): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [redirectUrl, setRedirectUrl] = useState('/app/dashboard')
  const [platform, setPlatform] = useState<Platform | null>(null)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [userWorkspaceName, setUserWorkspaceName] = useState('')
  const [signinInProgress, setSigninInProgress] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [companyExists, setCompanyExists] = useState(false)
  const [contactAdminModal, setContactAdminModal] = useState<IContactAdminData | null>(null)
  const [createAccountModalVisible, showCreateAccountModal] = useState(false)
  const [existsOnAnotherPlatform, setExistsOnAnotherPlatform] = useState('')
  const [companyAlreadySignedUpShowModal, setCompanyAlreadySignedUpShowModal] = useState(false)
  const [username, setUsername] = useState<string | null>(null)
  const [forgotPasswordModalVisible, showForgotPasswordModal] = useState(false)
  const [authInfoAbortController, setAuthInfoAbortController] = useState<AbortController | null>(null)
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const [passwordRequired, setPasswordRequired] = useState(false)
  const [showMFAModal, setShowMFAModal] = useState<boolean>(false)
  const [cognitoResponse, setCognitoResponse] = useState<any>()

  const emailInputRef = useRef<InputRef | null>(null)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    }
  }, [emailInputRef.current?.input?.id])

  useEffect(() => {
    if (userEmail) {
      getUsersByEmail(userEmail)
      sessionStorage.setItem('vtUserEmail', userEmail)
    }
  }, [userEmail])

  useEffect(() => {
    if (queryParams?.redirect) {
      setRedirectUrl(queryParams.redirect as string)
    }
    if (queryParams?.platform) {
      setPlatform(queryParams.platform as Platform)
    }

    if (queryParams?.subscriptionPlan && (queryParams.subscriptionPlan === 'Core' || queryParams.subscriptionPlan === 'Complete')) {
      localStorage.setItem('vtSubscriptionPlan', queryParams.subscriptionPlan)
    }

    if (queryParams?.forgotpassword) {
      showForgotPasswordModal(true)
    }

    if (authUser.id && authState === 'signedIn') {
      history.push(queryParams?.redirect as string || redirectUrl)
    } else {
      history.push(FrontendUrls.signin + location.search)

    }
  }, [authState, authUser])

  useEffect(() => {
    track('CONNECT_PAGE_VIEWED', {})
    document.body.classList.remove('flow-b-theme')
    localStorage.removeItem('vtCreateUser')
    localStorage.removeItem('vtCreateCompany')
    localStorage.removeItem('vtSelectedUsers')
    if (queryParams?.error === 'subscription_expired') {
      // Subscription expired
      notification.error({
        message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
        description: formatMessage({ id: 'error.subscriptionExpired' }),
        duration: 0,
      })
    }
    setCrispSessionInfo(
      [CrispSegmentsEnum.chat, CrispSegmentsEnum.support, CrispSegmentsEnum.dashboard, CrispSegmentsEnum.onLoginPage],
      { loginScreen: ''}
    )
    const emailFromSessionStorage = sessionStorage.getItem('vtUserEmail')
    if (emailFromSessionStorage) {
      form.setFieldsValue({ email: emailFromSessionStorage })
      getUsersByEmail(emailFromSessionStorage)
    }
  }, [])

  const getUsersByEmail = async (email: string) => {
    setIsCheckingEmail(true)
    if (authInfoAbortController) {
      authInfoAbortController.abort()
    }
    try {
      await form.validateFields()
      const controller = new AbortController()
      setAuthInfoAbortController(controller)
      notification.close('USER_INACTIVE')
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, {
        email,
      }, {
        signal: controller.signal,
      })
      setUsername(res.data.username)
      const platform = res.data.platform
      setSelectedPlatform(platform)
      if (platform !== 'email') {
        setExistsOnAnotherPlatform(platform)
      }
      setAuthInfoAbortController(null)
      setIsCheckingEmail(false)
    } catch(err) {
      if (err.errorFields?.length === 0 && err.outOfDate === true) {
        setIsCheckingEmail(false)
        return
      }
      if (err?.response?.data?.code === 'EmailPlatformUserInactive') {
        notification.error({
          message: formatMessage({ id: 'error.signin.inactiveUser' }),
          key: 'USER_INACTIVE',
          description: formatMessage({ id: 'error.auth.companyExists.line1' }),
          duration: 0,
        })
        return
      }
      if (err.message !== 'canceled') {
        setIsCheckingEmail(false)
      }
      setUsername(null)
      setSelectedPlatform(null)
      setAuthInfoAbortController(null)
    }
  }

  const getSlackTokens = () => {
    setSigninInProgress(true)
    slackAuth.signin(
      SLACK_REQUIRED_BOT_SCOPES,
      SLACK_REQUIRED_USER_SCOPES
    ).then(async (response) => {
      setUserWorkspaceName(response.team.name)
      slackAuth.setBotToken(response.access_token)
      
      const slackUserToken = response.authed_user.access_token as string
      const botToken: string | undefined = response.access_token
      slackAuth.setUserToken(slackUserToken)
      if (authUser.id) {
        await updateSlackToken(slackUserToken, botToken)
      }
      setShowSlackLoginRequiredModal(false)
      let userId = localStorage.getItem('userId') as string
      if (!userId) {
        const userData = await getSlackUserId(response.authed_user.id, slackUserToken)
        userId = `slack-${userData.vacationTrackerId}`
      }
      dispatch(setUserId(userId))
      if (!authUser.id) {
        await authSigin(userId, slackUserToken, 'slack', botToken)
      } else {
        onStateChange('signedIn')
      }
      setSigninInProgress(false)
    }).catch(error => {
      setSigninInProgress(false)
      notification.error({
        message: formatMessage({ id: 'error.notificationGeneral' }),
        description: `Slack error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`,
        duration: 0,
      })
    })
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        if (response.data.getUser.status !== 'ACTIVE') {
          logout({
            onStateChange,
            history,
            reduxDispatch: dispatch,
            userId: id,
          })
        }
        dispatch(setAuthCompany(response.data.getCompany))
        dispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
        Sentry.setUser({
          id: response.data.getUser.id,
          companyId: response.data.getCompany.id,
        })
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.debug('ERROR GET COMPANY AND USER', error)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange,
          history,
          reduxDispatch: dispatch,
        })
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const authSigin = async (username: string, token: string, platform: string, botToken?: string) => {
    const signInResponse = await Auth.signIn(username)
    if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
      const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: platform })
      localStorage.setItem('userId', cognitoResponse.username)

      if (platform === 'slack') {
        try {
          await updateSlackToken(token, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setSigninInProgress(false)
            setShowSlackLoginRequiredModal(true)
            return
          }
        }
      }
      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      onStateChange('signedIn')
      history.push(redirectUrl)
    }
  }

  const goToSignup = () => {
    setSigninInProgress(false)
    onStateChange('signUp')
    history.push(FrontendUrls.signup)
  }

  const handleUserData = (userData: ICheckUserId, platform, otherCreateUserData) => {
    setUserEmail(userData.mail || '')
    setSelectedPlatform(platform)
    if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
      notification.error({
        message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
        description: formatMessage({ id: 'error.subscriptionExpired' }),
        duration: 0,
      })
      return
    }

    if(userData.type === 'USER_NOT_FOUND') {
      setContactAdminModal({
        platform,
        organizationName: userData.organizationName || '',
        contactEmail: userData.contactEmail || '',
        adminContacts: userData.adminContacts || [],
      })
      track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData.organizationName })
      setCompanyExists(true)
      setSigninInProgress(false)
      return
    }

    if (userData.type === 'COMPANY_NOT_FOUND') {
      localStorage.setItem('vtCreateUser', JSON.stringify({
        name: userData.name,
        // current fix since we are making changes only for MS
        id: platform === PlatformEnum.Microsoft ? `${PlatformEnum.Universal}-${userData.id}` : `${platform}-${userData.id}`,
        mail: userData.mail,
        platform,
        ...otherCreateUserData,
      }))

      if (userData.existsOnAnotherPlatform && userData.existingOrganizationName) {
        track('ORG_EXISTS_ON_ANOTHER_PLATFORM_OPEN_MODAL', { platform, existingOrganizationName: userData.existingOrganizationName })
        setExistsOnAnotherPlatform(userData.existsOnAnotherPlatform)
        setCompanyAlreadySignedUpShowModal(true)
        return
      }
      showCreateAccountModal(true)
    }
  }

  const handleSigninError = (error, platform) => {
    setSigninInProgress(false)
    const errorMessage = (typeof error === 'object' && !(error instanceof Error)) ? (
      error.error ? error.error : JSON.stringify(error, null, 2)
    ) : (
      typeof error === 'string' ? error : error.toString()
    )
    let platformText
    if (platform === 'microsoft') {
      track('CONNECT_WITH_MICROSOFT_ERROR', { errorMessage, platform })
      platformText = 'Microsoft Teams'
    } else if (platform === 'google') {
      track('CONNECT_WITH_GOOGLE_ERROR', { errorMessage, platform })
      platformText = 'Google'
    } else if (platform === 'slack') {
      track('CONNECT_WITH_SLACK_ERROR', { errorMessage, platform })
      platformText = 'Slack'
    }

    // This can turn out to be a switch statement if we encounter more error cases
    // that require different actions/descriptions
    if (errorMessage.includes('interaction_required') || errorMessage.includes('invalid_grant')) {
      notification.error({
        message: formatMessage({ id: 'connect.interactionRequiredMSErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription1' }) }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription2' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
        </div>,
        duration: 0,
        btn: <Button onClick={() => connectWithMicrosoft(true)}>{ formatMessage({ id: 'connect.login' }) }</Button>,
      })
    } else {
      notification.error({
        message: formatMessage({ id: 'connect.platformErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription1' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription2' }, { platform: platformText }) }</Paragraph>
        </div>,
        duration: 0,
      })
      // Do not track Slack "access_denied" in Sentry, as it's handled above
      if (!errorMessage.includes('access_denied')) {
        Sentry.captureException(error)
      }
    }
  }

  const connectWithSlack = () => {
    const platform = 'slack'
    setSigninInProgress(true)
    track('CONNECT_WITH_SLACK_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    slackAuth.connect()
      .then(async (response: ISlackOpenidTokenResponse) => {
        const slackUserToken: string | undefined = response.authed_user.access_token
        if (!slackUserToken) {
          throw new Error('Slack user token is required')
        }
        slackAuth.setUserToken(slackUserToken)
        let userData: ICheckUserId | undefined = undefined
        try {
          userData = await getSlackUserId(response.authed_user.id, slackUserToken)
          localStorage.setItem('userId', `slack-${userData.vacationTrackerId}`)
          if (userData.vacationTrackerId) {
            const areScopesValid = await slackAuth.areScopesValid({
              userToken: slackUserToken,
              requiredUserScopes: SLACK_REQUIRED_USER_SCOPES,
            })
            if (!areScopesValid) {
              throw 'missing_scope'
            }
            await authSigin(vtUserId(userData.vacationTrackerId, PlatformEnum.Slack), slackUserToken, platform)
          } else {
            throw 'Handle user data'
          }
        } catch (error) {
          if (error === 'missing_scope' || error?.response?.data === 'missing_scope') {
            setShowSlackLoginRequiredModal(true)
            setSigninInProgress(false)
            return
          }

          // TODO: not sure how to handle this at the moment
          if (userData) {
            handleUserData(userData, platform, {
              teamName: null,
              slackBotToken: null,
              slackUserToken: slackUserToken,
              slackOrgTeamId: response.enterprise?.id,
              slackBotId: null,
              isOwner: userData?.slackUser?.isOwner,
              isAdmin: userData?.slackUser?.isAdmin,
              slackTeamId: userData?.slackUser?.slackTeamId,
              imageUrl: userData?.slackUser?.imageUrl,
              slackUserId: userData?.slackUser?.slackUserId,
              timezone: userData?.slackUser?.timezone,
            })
          }

          if (error?.code === 'NotAuthorizedException' && (userData?.contactEmail || userData?.adminContacts)) {
            setContactAdminModal({
              platform,
              organizationName: userData?.organizationName || '',
              contactEmail: userData?.contactEmail || '',
              adminContacts: userData?.adminContacts || [],
            })
            track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData?.organizationName })
            setCompanyExists(true)
            setSigninInProgress(false)
            return
          }
        }
      })
      .catch(e => handleSigninError(e, 'slack'))
  }

  const connectWithMicrosoft = (interactionRequired = false) => {
    const platform = 'microsoft'
    setSigninInProgress(true)
    track('CONNECT_WITH_MICROSOFT_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    // This requires promises, not async/await because window.open requires sync not async flow
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], interactionRequired)
      .then(async ([tokens, msUser, tenantId]) => {
        let userData: ICheckUserId | undefined
        try {
          userData = await getMsUserId(tenantId, tokens.accessToken)
          const userId = userData.id ? vtUserId(userData.id, PlatformEnum.Universal) : vtUserId(msUser.id, PlatformEnum.Microsoft)
          await authSigin(userId, tokens.accessToken, platform)
          msAuth.setTokens(tokens)
        } catch (error) {
          if (userData) {
            handleUserData(userData, platform, { tenantId, msUserId: userData.microsoftUserId })
          }
        }
      })
      .catch(e => handleSigninError(e, 'microsoft'))
  }

  const connectWithGoogle = () => {
    const platform = 'google'
    track('LOGIN_PAGE_LOGIN_WITH_GOOGLE_BUTTON_CLICKED', { platform })
    track('LOGIN_STARTED', {
      platform,
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    setSigninInProgress(true)
    googleAuth.signin()
      .then(async () => {
        const googleUser = googleAuth.getSignedInUser()
        if (!googleUser.hd) {
          notification.error({
            message: formatMessage({ id: 'connect.google.notWorkspaceUserTitle' }),
            description: formatMessage({ id: 'connect.google.notWorkspaceUserDescription' }),
            duration: 0,
          })
          return
        }
        let userData: ICheckUserId | undefined
        try {
          const userId = vtUserId(googleUser.sub, PlatformEnum.Google)
          userData = await getGoogleUserId(googleAuth.getIdToken())
          const id = userData.vacationTrackerId ? userData.vacationTrackerId : userId
          await authSigin(id, googleAuth.getAccessToken() , platform)
        } catch (error) {
          if (userData) {
            handleUserData(userData, platform, {
              googleDomain: googleUser.hd,
              imageUrl: googleUser.picture,
              googleUserId: googleUser.sub,
            })  
          }
        }
      })
      .catch(e => handleSigninError(e, 'google'))
  }

  const connectWithEmail = () => {
    track('LOGIN_STARTED', {
      platform: 'email',
      timestamp: new Date().toISOString(),
      source: 'app',
      status: 'started',
    })
    history.push(FrontendUrls.signin + '?platform=email')
    setPlatform('email')
  }

  const backToLogin = () => {
    history.push(FrontendUrls.signin)
    setPlatform(null)
  }

  const onEnterMFACode = async (code: string) => {
    try {
      await Auth.confirmSignIn(
        cognitoResponse,
        code,
        cognitoResponse.challengeName
      )
      localStorage.setItem('userId', cognitoResponse?.username)
      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      onStateChange('signedIn')
      history.push(redirectUrl)
      setSigninInProgress(false)
    } catch (e) {
      notification.error({ 
        message: formatMessage({ id: 'app.mfaLoginFailed' }),
        description: formatMessage({ id: getCognitoErrors(e.code as string) }),
        duration: 10,
      })
      setShowMFAModal(false)
      Sentry.captureException(e)
    }
  }

  const connectWithMail = async () => {
    track('LOGIN_PAGE_LOGIN_WITH_EMAIL_AND_PASSWORD_BUTTON_CLICKED', { platform })

    if (!selectedPlatform) {
      showCreateAccountModal(true)
      return
    }

    if (selectedPlatform && selectedPlatform !== 'email') {
      track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CLOSE_MODAL', {
        platform: selectedPlatform,
      })
      setCompanyAlreadySignedUpShowModal(true)
      return
    }

    setSigninInProgress(true)
    try {
      const values = await form.validateFields()
      const cognitoResponse = await Auth.signIn(username || '', values.password)
      setCognitoResponse(cognitoResponse)
      if (['SMS_MFA', 'SOFTWARE_TOKEN_MFA'].includes(cognitoResponse.challengeName)) {
        setShowMFAModal(true)
        return
      } else if (cognitoResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Ask for the new password, then do this:
        // const loggedUser = await Auth.completeNewPassword(
        //   cognitoResponse, // the Cognito User Object
        //   newPassword, // the new password
        // )
      } else if (cognitoResponse.challengeName === 'MFA_SETUP') {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        // Auth.setupTOTP(cognitoResponse)
      }
      localStorage.setItem('userId', cognitoResponse.username)

      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
      })
      onStateChange('signedIn')
      history.push(redirectUrl)
      setSigninInProgress(false)
    } catch(err) {
      setSigninInProgress(false)
      if (err.code === 'NotAuthorizedException') {
        notification.error({
          message: formatMessage({ id: 'error.incorrectPassword' }),
          key: 'INCORRECT_PASSWORD',
          description: formatMessage({ id: 'error.incorrectPasswordMessage' }),
          btn: (<Button onClick={() => {
            showForgotPasswordModal(true)
            notification.close('INCORRECT_PASSWORD')
          }}>{ formatMessage({ id: 'app.resetPassword' }) }</Button>),
          duration: 0,
        })
        return
      } else if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      }
      Sentry.captureException(err)
    }
  }

  const connectWithPlatform = (platform: string) => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CHOOSE_EXISTING', { platform })
    localStorage.removeItem('createUser')
    setCompanyAlreadySignedUpShowModal(true)
    if (platform === 'slack') {
      connectWithSlack()
    } else if (platform === 'microsoft') {
      connectWithMicrosoft()
    } else if (platform === 'google') {
      connectWithGoogle()
    }
  }

  const onFormFieldsChange = (changedFields: FieldData[]) => {
    const emailField = changedFields && changedFields.find(field => Array.isArray(field.name) && field.name.includes('email'))
    if (!emailField?.errors || emailField.errors.length === 0) {
      setUserEmail(emailField?.value as string)
    }
    const password = changedFields && changedFields.find(field => Array.isArray(field.name) && field.name.includes('password'))
    setPasswordRequired(changedFields.length === 0 || password?.value === '')
  }

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signin'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48},{ xs: 16, lg: 48}]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          { (platform && platform !== 'email') &&
            <Col span={16}>
              <Title level={4} style={{ textAlign: 'center' }} >
                <IntlMessages id='connect.pleaseLogIn' />
              </Title>
              { platform === 'slack' &&
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithSlack()}>
                  <IntlMessages id="connect.signInWithSlack" />
                </Button>
              }
              {platform === 'microsoft' &&
                <Button icon={<WindowsFilled />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithMicrosoft()}>
                  <IntlMessages id="connect.signInWithMS" />
                </Button>
              }
              {platform === 'google' && process.env.REACT_APP_GOOGLE_VERIFICATION !== 'true' &&
                <Button icon={<GoogleOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => connectWithGoogle()}>
                  <IntlMessages id="connect.signInWithGoogle" />
                </Button>
              }
              {platform === 'google' && process.env.REACT_APP_GOOGLE_VERIFICATION === 'true' &&
                <Button
                  icon={<Icon component={GoogleLogo} />}
                  size="large"
                  type="primary"
                  block
                  onClick={() => connectWithGoogle()}
                  className='google-btn vt-auth'
                >
                  <IntlMessages id="connect.signInWithGoogleVerification" />
                </Button>
              }
              {(!platform) &&
                <Button icon={<MailOutlined />} size='large' className="vt-auth" type="primary" block onClick={() => connectWithEmail()}>
                  <IntlMessages id="connect.signUpWithEmail" />
                </Button>
              }
            </Col>
          }
          {!platform &&
            <>
              <Col xs={{ span: 20 }} lg={{ span: 12, order: 2 }} >
                <Title level={4} style={{ textAlign: 'center' }} >
                  <IntlMessages id='connect.pleaseLogIn' />
                </Title>
                <Spin spinning={signinInProgress}>
                  <div className="auth-sidebar-content-button">
                    <Button icon={<SlackOutlined />} size="large" data-qa="slack-connect" className="vt-auth" type="primary" block onClick={() => connectWithSlack()}>
                      <IntlMessages id="connect.signInWithSlack" />
                    </Button>
                    <Button icon={<WindowsFilled />} size="large" data-qa="microsoft-connect" className="vt-auth" type="primary" block onClick={() => connectWithMicrosoft()}>
                      <IntlMessages id="connect.signInWithMS" />
                    </Button>
                    {process.env.REACT_APP_GOOGLE_VERIFICATION === 'true' ? <Button
                      icon={<Icon component={GoogleLogo} />}
                      size="large"
                      type="primary"
                      block
                      onClick={() => connectWithGoogle()}
                      className="google-btn vt-auth"
                    >
                      <IntlMessages id="connect.signInWithGoogleVerification" />
                    </Button> : <Button
                      icon={<GoogleOutlined />}
                      size='large'
                      data-qa='google-connect'
                      type="primary"
                      className="vt-auth"
                      block
                      onClick={() => connectWithGoogle()}
                    >
                      <IntlMessages id="connect.signInWithGoogle" />
                    </Button>
                    }
                    <Button icon={<MailOutlined />} size='large' data-qa="email-connect" className="vt-auth" type="primary" block onClick={() => connectWithEmail()}>
                      <IntlMessages id="connect.signInWithEmail" />
                    </Button>
                  </div>
                </Spin>
              </Col>
              <Col xs={{ span: 20 }} lg={{ span: 12, order: 1 }}>
                <Title level={2} className="create-account-title">
                  <IntlMessages id='connect.dontHaveAnAccount' />
                </Title>
                <Button size="large" className="create-account-btn" type="primary" onClick={() => history.push(FrontendUrls.signup)}>
                  <IntlMessages id="connect.createAnOrganization" />
                </Button>
              </Col>
            </>
          }
          {platform && platform === 'email' &&
            <Col xs={{ span: 20 }} lg={{ span: 12, order: 2 }} >
              <Paragraph>
                <Title style={{ textAlign: 'center', fontWeight: 400 }} level={5}><IntlMessages id="connect.connectWithYourWorkEmail" /></Title>
                <Form
                  name="connect-with-email"
                  form={form}
                  layout="horizontal"
                  onFinish={connectWithMail}
                  onFieldsChange={onFormFieldsChange}
                >
                  <Form.Item
                    name="email"
                    style={{ width: '100%' }}
                    rules={[{ required: true, type: 'email', message: (<IntlMessages id="connect.pleaseInputValidEmail" />) }]}
                  >
                    <Input
                      className="email-input data-hj-allow"
                      size="large"
                      data-qa='email-login-mail'
                      placeholder={formatMessage({ id: 'app.enterWorkEmail' })}
                      ref={emailInputRef}
                    />
                  </Form.Item>
                  { selectedPlatform === 'email' &&
                    <Form.Item
                      name="password"
                      style={{ width: '100%' }}
                      rules={[{ required: true }]}
                    >
                      <Input.Password size="large" data-qa='email-login-password'  placeholder={formatMessage({ id: 'connect.inputPasswordPlaceholder' })}/>
                    </Form.Item>
                  }
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={isCheckingEmail || passwordRequired}
                      data-qa='email-login-submit'
                      block
                      style={{ wordWrap: 'break-word', whiteSpace: 'normal', height:'auto'}}
                    >
                      <IntlMessages id="connect.connectWithYourWorkEmail" />
                    </Button>
                  </Form.Item>
                </Form>
                <Link onClick={() => showForgotPasswordModal(true)}>
                  <IntlMessages id="connect.forgotYourPassword" />
                </Link>
                <br/>
                <br/>
                <Link onClick={() => backToLogin()}>
                  <IntlMessages id="connect.backToLoginWithOtherPlatofrm" />
                </Link>
              </Paragraph>
            </Col>
          }
        </Row>
      </div>

      {companyExists && contactAdminModal && userEmail &&
        <CompanyExistsModal
          companyExists={companyExists}
          setCompanyExists={setCompanyExists}
          contactAdminModal={contactAdminModal}
          userEmail={userEmail}
          userWorkspaceName={userWorkspaceName}
        />
      }

      { showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          visible={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          cancelText={formatMessage({ id: 'app.cancel'})}
          okText={formatMessage({ id: 'connect.slack.usersLoginRequiredButton'})}
          onCancel={() => {
            dispatch(setUserId(localStorage.getItem('userId') as string))
            setShowSlackLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="connect.slack.usersLoginRequiredDescription1" /></p>
        </Modal>
      }

      { companyAlreadySignedUpShowModal &&
        <CompanyAlreadySignedUpModal
          showModal={companyAlreadySignedUpShowModal}
          existsOnAnotherPlatform={existsOnAnotherPlatform}
          connectWithPlatform={connectWithPlatform}
          handleCloseCompanyAlreadySignedUpModal={() => setCompanyAlreadySignedUpShowModal(false)}
        />
      }

      { createAccountModalVisible &&
        <DontHaveAnAccountModal
          showCreateAccountModal={showCreateAccountModal}
          createAccountModalVisible={createAccountModalVisible}
          goToSignup={goToSignup}
        />
      }

      { forgotPasswordModalVisible &&
        <ForgotPasswordModal
          forgotPasswordModalVisible={forgotPasswordModalVisible}
          showForgotPasswordModal={showForgotPasswordModal}
        />
      }

      { showMFAModal && <MFACodeModal onSave={onEnterMFACode} />}
    </div>
  )
}

export default Signin