import annualPriceComplete from '@vacationtracker/shared/data/stripe/annual-price-complete.json'
import annualPriceComplete2 from '@vacationtracker/shared/data/stripe/annual-price-complete2.json'
import annualPriceCore from '@vacationtracker/shared/data/stripe/annual-price-core.json'

export const USER_PLAN_LIMIT = 25
export const USER_PLAN_LIMIT_SECOND = 35

export enum PlanPricePerUser {
  Core = 1,
  Complete = 2,
  CompleteFull = 3,
}

export const getBucketPlanInfo = (totalUsers, plan, isNewPlan) => {
  const completePlanTiers = isNewPlan ? annualPriceComplete2.tiers : annualPriceComplete.tiers
  const buckets = plan === 'Core' ? annualPriceCore.tiers : completePlanTiers
  const currentBucket = buckets.filter(bucket => bucket.up_to > totalUsers)[0]
  const price = Math.floor(currentBucket.flat_amount / 100)

  return { currentBucket, price}
}
