import find from 'lodash/find'
import filter from 'lodash/filter'
import { IMicrosoftSaasCreateCompanyState } from './types'

export const SELECTED_USERS_DEFAULT_LIMIT = 50

export const initialState: IMicrosoftSaasCreateCompanyState = {
  currentStep: 0,
  createUser: {
    name: '',
    id: '',
    mail: '',
  },
  createCompany: {
    contactEmail: '',
    name: '',
    platform: 'microsoft',
    country: '',
    state: '',
    hasUnlimitedDays: false,
    daysPerYear: 20,
    plan: '',
  },
  numberOflicenses: 25,
  selectedUsers: [],
  importAllUsers: false,
  isCreateCompanyLoader: false,
  agreeToTerms: false,
  token: '',
  msPurchaseToken: '',
  steps: {
    createCompany:  false,
    assignLicenses: false,
    setupBotAndTabs: false,
  },
  totalUsers: 1,
  tenantId: '',
}

export const reducer = (state: IMicrosoftSaasCreateCompanyState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      }
    case 'ON_NEXT_STEP':
      return {
        ...state,
        currentStep: state.currentStep + 1,
      }
    case 'BACK_TO_WELCOME_PAGE':
      return {
        ...state,
        currentStep: 0,
      }
    case 'SET_PURCHASE_TOKEN':
      return {
        ...state,
        msPurchaseToken: action.payload,
      }
    case 'SET_NUMBER_OF_LICENSES':
      return {
        ...state,
        numberOflicenses: action.payload,
      }
    case 'SET_CREATE_COMPANY_DETAILS': {
      const createCompany = {
        ...state.createCompany,
        contactEmail: action.payload.contactEmail,
        name: action.payload.name,
        country: action.payload.country,
        state: action.payload.state,
        hasUnlimitedDays: action.payload.hasUnlimitedDays,
        daysPerYear: action.payload.daysPerYear,
      }
      localStorage.setItem('vtMSState', JSON.stringify({ ...state, createCompany, agreeToTerms: action.payload.agreeToTerms  }))
      return {
        ...state,
        createCompany,
        agreeToTerms: action.payload.agreeToTerms,
      }
    }
    case 'SET_CREATE_COMPANY_PLAN':
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          plan: action.payload,
        },
      }
    case 'SET_CREATE_USER': {
      const createUser = {
        id: action.payload.id,
        name: action.payload.name,
        mail: action.payload.mail,
        msUserId: action.payload.msUserId,
        tenantId: action.payload.tenantId,
      }
      localStorage.setItem('vtMSState', JSON.stringify({ ...state, createUser }))
      return {
        ...state,
        createUser,
      }
    }
    case 'IS_CREATE_COMPANY_LOADER':
      return {
        ...state,
        isCreateCompanyLoader: action.payload,
      }
    case 'STEPS_CREATE_COMPANY': {
      const newState = {
        ...state,
        steps: {
          ...state.steps,
          createCompany: action.payload,
        },
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'STEPS_ASSIGN_LICENSES': {
      const newState = {
        ...state,
        steps: {
          ...state.steps,
          assignLicenses: action.payload,
        },
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'SET_BOT_AND_TABS_INSTALLED': {
      const newState = {
        ...state,
        steps: {
          ...state.steps,
          setupBotAndTabs: true,
        },
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'ON_SELECT_USER': {
      
      const selectedUser = find(state.selectedUsers, user => user.id === action.payload.id)
      const selectedUsers = selectedUser ? filter(state.selectedUsers, user => user.id !== selectedUser.id) : [...state.selectedUsers, { ...action.payload, announce: 'chat' }]

      const data = {
        ...state,
        selectedUsers,
      }
      localStorage.setItem('vtMSState', JSON.stringify(data))

      return data
    }
    case 'CHANGE_USER_ROLE': {
      const selectedUser = find(state.selectedUsers, user => user.id === action.payload.userId)
      if (selectedUser) {
        const selectedUsers = state.selectedUsers.map(user => {
          if (user.id === action.payload.userId) {
            user.isAdmin = action.payload.role === 'admin' ? true : false
          }
          return user
        })
        localStorage.setItem('vtMSState', JSON.stringify({
          ...state,
          selectedUsers,
        }))

        return {
          ...state,
          selectedUsers,
        }
      }

      return state
    }
    case 'CHANGE_USER_ANNOUNCE': {
      const selectedUser = find(state.selectedUsers, user => user.id === action.payload.userId)
      if (selectedUser) {
        const selectedUsers = state.selectedUsers.map(user => {
          if (user.id === action.payload.userId) {
            user.announce = action.payload.announce
          }
          return user
        })
        localStorage.setItem('vtMSState', JSON.stringify({
          ...state,
          selectedUsers,
        }))

        return {
          ...state,
          selectedUsers,
        }
      }

      return state
    }
    case 'HANDLE_BULK_SELECT': {
      const users = action.payload.users.filter(user => user.id !== state.createUser.id)
      const newState = {
        ...state,
        importAllUsers: action.payload.importAllUsers,
        steps: {
          ...state.steps,
          assignLicenses: action.payload.importAllUsers,
        },
        selectedUsers: action.payload.importAllUsers ? users : [],
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'HANDLE_IMPORT_ALL_USERS':
      return {
        ...state,
        importAllUsers: action.payload,
      }
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUsers: action.payload,
      }
    case 'SET_TOTAL_USERS': {
      const newState = {
        ...state,
        totalUsers: action.payload,
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'SET_TENANT_ID': {
      const newState = {
        ...state,
        tenantId: action.payload,
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    case 'CHANGE_BULK_ANNOUNCE': {
      const selectedUsers = state.selectedUsers.filter(user => {
        user.announce = action.payload
        return user
      })
      const newState = {
        ...state,
        selectedUsers: selectedUsers,
      }
      localStorage.setItem('vtMSState', JSON.stringify(newState))

      return newState
    }
    default:
      return {
        ...state,
      }
  }
}