export enum FeatureFlagEnum {
  qaMode = 'qa-mode',
  shortestLeaveInterval = 'shortest-leave-interval',
  accruals = 'accruals',
  labels = 'labels',
  scheduledReports = 'scheduled-reports',
  hours = 'hours',
  calculationStartYear = 'calculation-start-year',
  companyLogs = 'company-logs',
  importUsers = 'import-users',
  aiBotHelper = 'ai-bot-helper',
  noLimitTeams = 'no-limit-teams',
  noLimitLocation = 'no-limit-location',
  // Automation:
  automation = 'automation', // This is the feature flag for all automations
  blackOutPeriod = 'blackout-period',
  entitlementByRole = 'entitlement-by-role',
  seniorityEntitlement = 'seniority-entitlement',
  syncSharedCalendars = 'sync-shared-calendars',
  substituteApprovers = 'substitute-approvers',
}
