import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Alert, Breadcrumb, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { API, graphqlOperation } from 'aws-amplify'
import { isWithinInterval, addYears } from 'date-fns'
import * as Sentry from '@sentry/react'

import { getUsersForApprover, getOverlappingUsersInfo, getUsersForAdminWithPagination, getUserForLeaveRequest } from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectUserIdSlice } from '../../../store/user-id-slice'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { displayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { shouldShowFutureAccruedDaysInfo } from '@vacationtracker/shared/functions/should-show-future-accrued-days-info'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import LeaveForm from '@vacationtracker/shared/components/leave-form'

import {
  IGetUserForLeaveRequest,
  IGetUserForLeaveReueqstUserData,
  IGetUserForLeaveReueqstUserLeaveDays,
  IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes,
  IGetUsersForAdminAddLeaveData,
  IGetUsersForApproverData,
  IUserIdAndName,
  IUserLeaveReuqestLeavePolicy
} from '../../../types/custom-queries'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import {
  getLeavePolicyFromQuota,
  LeaveRequestOverlappingInfo,
  LeaveRequestQuota,
  LeaveRequestSummary
} from '@vacationtracker/shared/components/add-request-leave-additional-info'
import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { IApprover } from '@vacationtracker/shared/types/leave-request'
import { IGetUserOverlappingInfo, IGetUserOverlappingInfoData } from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { AccrualType } from '@vacationtracker/shared/types/leave-policy'
import { IData } from '../../../types/data'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

interface IAddLeaveProps {
  history: {
    push: Function
    goBack: Function
  }
}

const AddLeavePage = ({ history }: IAddLeaveProps) => {
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { userId } = useAppSelector(selectUserIdSlice)
  const shouldEnableSubstituteApprovers = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.substituteApprovers)

  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)

  const [createLoader, setCreateLoader] = useState(false)
  const [userList, setUserList] = useState<IGetUserForLeaveReueqstUserData[] | IUserIdAndName[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState<IGetUserForLeaveReueqstUserData | null>(null)
  const [isLoadingOverlappingUsersInfo, setIsLoadingOverlappingUsersInfo] = useState(false)
  const [overlappingUsersInfo, setOverlapingUsersInfo] = useState<IGetUserOverlappingInfo | null>(null)
  const [checkForOverlappingUsers, setCheckForOverlappingUsers] = useState(false)
  const [startDate, setStartDate] = useState()
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveReuqestLeavePolicy | null>(null)
  const [endDate, setEndDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [currentQuota, setCurrentQuota] = useState<IGetUserForLeaveReueqstUserLeaveDays | null>(null)
  const [nextYearQuota, setNextYearQuota] = useState<IGetUserForLeaveReueqstUserLeaveDays | null>(null)
  const [showSummary, setShowSummary] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  const [currentLeavePolicy, setCurrentLeavePolicy] = useState<IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes | null>(null)
  const [nextYearLeavePolicy, setNextYearLeavePolicy] = useState<IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes | null>(null)
  const {
    setCheckIfSubstituteNeeded,
    suggestedSubstituteApprovers,
    shouldShowSubstituteApprovers,
    isSubstituteListLoading,
    setShouldShowSubstituteApprovers,
  } = useSubstituteApprover(selectedUser?.id, startDate, endDate)

  useEffect(() => {
    setCurrentLeavePolicy(getLeavePolicyFromQuota(selectedLeaveType?.id as string, currentQuota as IGetUserForLeaveReueqstUserLeaveDays))
    setNextYearLeavePolicy(getLeavePolicyFromQuota(selectedLeaveType?.id as string, nextYearQuota as IGetUserForLeaveReueqstUserLeaveDays))
  }, [currentQuota, nextYearQuota, selectedLeaveType])

  useEffect(() => {
    if (isLoading && userId) {
      getUsersList(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (checkForOverlappingUsers) {
      getOverlappingUsers()
    }
  }, [checkForOverlappingUsers])

  useEffect(() => {
    if (leaveRequestActionEvent && correlationId === leaveRequestActionEvent.correlationId) {
      setShowSummary(true)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  const getOverlappingUsers = async () => {
    setIsLoadingOverlappingUsersInfo(true)
    const overlappingResponse = await API.graphql(graphqlOperation(getOverlappingUsersInfo, {
      teamId: selectedUser?.team?.id,
      leaveRequest: {
        startDate,
        endDate,
        userId: selectedUser?.id || userId,
        isPartDay: selectedLeaveType?.id.includes('|part-day'),
        partDayStartHour: startTime,
        partDayEndHour: endTime,
        leaveTypeId: selectedLeaveType?.id,
      },
    })) as IGetUserOverlappingInfoData
    setOverlapingUsersInfo(overlappingResponse?.data?.getOverlappingUsersInfo)
    setCheckForOverlappingUsers(false)
    setIsLoadingOverlappingUsersInfo(false)
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const resetLeaveRequestForm = () => {
    setShowSummary(false)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
    setCurrentLeavePolicy(null)
    setNextYearLeavePolicy(null)
    setCheckIfSubstituteNeeded(false)
    setShouldShowSubstituteApprovers(false)
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    selectedUser?.leaveDays?.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
    setCheckForOverlappingUsers(true)
    if (shouldEnableSubstituteApprovers) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const handleOnStartDateSelected = (startDate) => {
    selectedUser?.leaveDays?.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(startDate)
    setCheckForOverlappingUsers(true)
  }

  const handleOnTimeSelected = ({
    startTime,
    endTime,
  }) => {
    setStartTime(startTime)
    setEndTime(endTime)
    setCheckForOverlappingUsers(true)
  }

  const getUsersWithPagination = async (limit: number, nextToken?: string, accumulatedResults: IUserIdAndName[] = []) => {
    const response = await API.graphql(graphqlOperation(getUsersForAdminWithPagination, { limit, nextToken })) as IData<IGetUsersForAdminAddLeaveData>

    const { users, nextToken: newNextToken } = response.data.getActiveUsersWithPagination

    const updatedResults = [...accumulatedResults, ...users]

    if (newNextToken) {
      return getUsersWithPagination(limit, newNextToken, updatedResults)
    } else {
      return updatedResults
    }
  }

  const getUsersList = async (id) => {
    try {
      let users: IGetUserForLeaveReueqstUserData[] | IUserIdAndName[] = []
      if (authUser.role === 'Admin') {
        users = await getUsersWithPagination(300)
      }
      if (authUser.role === 'Approver') {
        const response = await API.graphql(graphqlOperation(getUsersForApprover, { id })) as IData<IGetUsersForApproverData>
        users = response.data.getUser.approverTo
      }

      setUserList(users.sort((a: IGetUserForLeaveReueqstUserData | IUserIdAndName, b: IGetUserForLeaveReueqstUserData | IUserIdAndName) => a.name < b.name ? -1 : 1))
      setIsLoading(false)
    } catch (err) { 
      Sentry.captureException(err)
      setIsLoading(false)
      console.log('error fetching users list', err) 
    }
  }

  const handleOnSelectUser = async (userId: string) => {
    const user = userList.find(user => user.id === userId)
    if (user && 'leaveDays' in user) {
      setSelectedUser(user as IGetUserForLeaveReueqstUserData)
      resetLeaveRequestForm()
    } else {
      const response = await API.graphql(graphqlOperation(getUserForLeaveRequest, { id: userId })) as IData<IGetUserForLeaveRequest>
      setSelectedUser(response.data.getUser as IGetUserForLeaveReueqstUserData)
      resetLeaveRequestForm()
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLoader(true)
    setCorrelationId(null)
    let response
    try {
      response = await API.post('CoreEvent', '/core/leave-request-validate', {
        body: {
          eventType: 'LEAVE_REQUEST_ADDED',
          eventGroup: 'USER_LEAVE_REQUEST',
          ...data,
        },
      })
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LEAVE_REQUEST_ADDED',
          eventGroup: 'USER_LEAVE_REQUEST',
          ...data,
        },
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'addLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setCorrelationId(response.correlationId)
      setCreateLoader(false)
    } catch (error) {
      setCorrelationId(null)
      console.log('error handle sumbit', error)
      setCreateLoader(false)

      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }


  return (
    <div className="main-content">
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="app.addLeave"/>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item><IntlMessages id="app.home" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.addLeave" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        {!isLoading && showSummary ? <LeaveRequestSummary
          isLeaveAddition
          userId={userId}
          leaveRequest={{
            requestedDays: overlappingUsersInfo?.year1Days as number,
            currentDays:currentLeavePolicy?.currentDays as number,
            requestedNextDays: overlappingUsersInfo?.year2Days as number,
            nextDays: nextYearLeavePolicy?.currentDays as number,
            leaveTypeName: selectedLeaveType?.leaveType?.name as string,
            hasUnlimitedDays: Boolean(currentLeavePolicy?.hasUnlimitedDays),
            hasUnlimitedDaysNextYear: Boolean(nextYearLeavePolicy?.hasUnlimitedDays),
            leavePeriod: getLeavePeriod({
              isPartDay: selectedLeaveType?.id.includes('|part-day'),
              startDate,
              endDate,
              partDayStartHour: startTime,
              partDayEndHour: endTime,
              formatMessage,
              locale: selectedUser?.locale,
              leaveRequestWorkingDays: overlappingUsersInfo?.calendarDays,
              hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
            }),
          }}
          approvers={selectedUser?.team.approvers as IApprover[]}
          resetLeaveRequestForm={resetLeaveRequestForm}
          futureAccruals={{
            allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
            endDate: endDate as unknown as string,
            daysOnLeaveEndDates: overlappingUsersInfo?.daysOnLeaveEndDates,
          }}
          locale={authUser?.locale as LocaleEnum}
        /> : null}
        {isLoading ? <CircularProgress /> : showSummary ? null
          : <LeaveForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            hourFormat={authUser.hourFormat}
            loading={createLoader}
            listOfUsers={userList}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onLeaveTypeSelected={handleOnLeaveTypeSelected}
            onEndDateSelected={handleOnEndDateSelected}
            onStartDateSelected={handleOnStartDateSelected}
            onHandleTimeSelected={handleOnTimeSelected}
            onCancel={() => { history.goBack() }}
            formType={'add'}
            onSelectUserToAddLeaveRequest={handleOnSelectUser}
            selectedUser={selectedUser}
            shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
            substituteApprovers={suggestedSubstituteApprovers}
            isSubstituteListLoading={isSubstituteListLoading}
          />
        }
        {!showSummary && 
          <LeaveRequestOverlappingInfo
            isLoadingOverlappingUsersInfo={isLoadingOverlappingUsersInfo}
            overlappingUsersInfo={overlappingUsersInfo}
          />
        }
        {!showSummary &&
          <LeaveRequestQuota
            currentLeavePolicy={currentLeavePolicy}
            nextYearLeavePolicy={nextYearLeavePolicy}
            currentQuota={currentQuota}
            nextYearQuota={nextYearQuota}
            user={selectedUser}
            overlappingUsersInfo={overlappingUsersInfo}
            authUserLocale={authUser?.locale}
          />
        }

        {!isLoadingOverlappingUsersInfo && !showSummary && selectedLeaveType?.allowAdvanceAccrualUsage && shouldShowFutureAccruedDaysInfo({
          accrualType: selectedLeaveType?.accrualType as AccrualType,
          allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
          currentYearDays: overlappingUsersInfo?.daysOnLeaveEndDates,
          leaveRequestEndDate: endDate,
          currentDays: currentLeavePolicy?.currentDays,
        }) && <Alert
          message={formatMessage(
            { id: 'email.notification.accruedDaysInfoForApprover' },
            { amount: displayLeaveInDaysAndHours({ value: currentLeavePolicy?.currentDays as number, formatMessage }),
              name: selectedUser?.name,
              accrued:  displayLeaveInDaysAndHours({ value: overlappingUsersInfo?.daysOnLeaveEndDates as number, formatMessage }),
              earningDate: formatDateToLocale(endDate as unknown as string, 'dddd, MMM D, YYYY', authUser?.locale),
            })}
          type='info' />}
      </div>
    </div>
  )
}

export default AddLeavePage
