import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompanyInfo, SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { RootState } from './configure'

interface CompanyState {
  authCompany: ICompanyInfo | null
}

const authCompanyFromStorage = localStorage.getItem('company')
const authCompany = authCompanyFromStorage ? JSON.parse(authCompanyFromStorage) : null

const initialState: CompanyState = {
  authCompany,
}

const paymentProcessorSelector = (state: RootState) => state.authCompanySlice.authCompany?.billing?.paymentProcessor

export const isStripePayment = createSelector([paymentProcessorSelector], paymentProcessor => paymentProcessor === 'stripe')
export const isMicrosoftPayment = createSelector([paymentProcessorSelector], paymentProcessor => paymentProcessor === 'microsoft-billing')

export const authCompanySlice = createSlice({
  name: 'authCompany',
  initialState,
  reducers: {
    setAuthCompany: (state, action: PayloadAction<ICompanyInfo>) => {
      if (action.payload) {
        state.authCompany = action.payload
      }
    },
    setAuthCompanyPlan: (state, action: PayloadAction<SubscriptionPlan>) => {
      if (state.authCompany?.plan) {
        state.authCompany.plan = action.payload
        state.authCompany.billing = {
          ...state.authCompany.billing,
          subscriptionPlan: action.payload,
        }
      }
    },
  },
})

export const { setAuthCompany, setAuthCompanyPlan } = authCompanySlice.actions

export const selectAuthCompanySlice = (state: RootState) => state.authCompanySlice

export default authCompanySlice.reducer
