import React from 'react'
import { Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'

const { Paragraph, Text, Title } = Typography

interface IBillingInvoice {
  upcomingInvoiceInfoMessage?: string | React.ReactElement
  isLoadingUpcomingInvoiceInfo: boolean
  customerPortalLink?: string
}

const BillingInvoice = ({ customerPortalLink, upcomingInvoiceInfoMessage, isLoadingUpcomingInvoiceInfo }: IBillingInvoice): React.ReactElement => {

  return (
    <>
      <Title level={4}>
        <IntlMessages id="components.billingInvoice.title" />
      </Title>
      <Paragraph>
        <IntlMessages id="components.billingInvoice.description" values={{
          link: (...chunks) => customerPortalLink ? <a href={customerPortalLink} target="_blank" rel="noopener noreferrer">{chunks}</a> : '',
          creatingLink: (...chunks) => !customerPortalLink ? <Text disabled><LoadingOutlined /> {chunks}</Text> : '',
        }} />
      </Paragraph>
      {!isLoadingUpcomingInvoiceInfo && upcomingInvoiceInfoMessage && <Paragraph>{upcomingInvoiceInfoMessage}</Paragraph>}
    </>
  )
}

export default BillingInvoice
