import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, Table, Dropdown, Menu, Modal, notification, Tag, Alert } from 'antd'
import { useIntl } from 'react-intl'
import { EllipsisOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { orderBy } from 'lodash'
import { getTeams } from '../../../../graphql/custom-queries'

import { useAppSelector } from '../../../../store/hooks'
import { selectDateFormatSlice } from '../../../../store/date-format-slice'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'

import { notificationStore } from '../../../../context/notificationsContext/store'
import IntlMessages from '../../../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { wait } from '@vacationtracker/shared/functions/wait'
import { FrontendUrls } from '../../../../types/urls'
import { NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

import { IGetTeamListData, IGetTeamList, ITeamUsers } from '../../../../types/teams'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'

interface ISortedInfo {
  columnKey?: string
  order?: string
}

const { confirm } = Modal

const TeamsPage = () => {
  const { formatMessage } = useIntl()

  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sortedInfo, setSortedInfo] = useState<ISortedInfo | any>({})
  const [teamList, setTeamList] = useState<IGetTeamList[]>([])
  const [correlationId, setCorrelationId] = useState('')
  const [deletedTeamId, setDeletedTeamId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)

  useEffect(() => {
    if (loading) {
      fetchTeamList()
    }
  }, [loading])

  useEffect(() => {
    if (!correlationExist) {
      setCorrelationExist(actionNotifications.find(value => value === correlationId) ? true : false)
    }
    if (deletedTeamId.length > 0 && correlationExist && !actionNotifications.find(value => value === correlationId)) {
      setDeletedTeamId('')
      setCorrelationExist(false)
      fetchTeamList()
    }
  }, [actionNotifications, correlationExist, correlationId, deletedTeamId, teamList])

  let numberOfRetry = 0
  const fetchTeamList = async () => {
    const response = await API.graphql(graphqlOperation(getTeams)) as IGetTeamListData
    const orderedTeams = orderBy(response.data.getTeamList, [team => team.isDefault,'name'], ['desc', 'asc'])

    try {
      for (const team of orderedTeams) {
        if (team.approvers.length === 0) {
          throw new Error('Team without approvers')
        }
      }

      setTeamList(orderedTeams)
      setLoading(false)
    } catch (error) {
      if (numberOfRetry >= 5) {
        setTeamList(orderedTeams)
        setLoading(false)
        // handleErrorNotification(error, undefined, formatMessage({ id: 'app.tryLateOrContactSupport'}, { code: 'ERR_001'} ))
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await fetchTeamList()
      }
    }
  }

  const getFewUsers = (users: ITeamUsers[], teamsId: string) => {
    return (<>
      {users.map((user: ITeamUsers, index: number) => {
        if (index < 4 && user) {
          return (
            <span className="user-avatar" key={user.id}>
              <UserAvatar id={user.id} name={user.name} avatar={user.imageUrl} isTooltip={true} isLinkToPage={true} shape="circle" />
            </span>
          )
        }
        return ''
      })}
      {users.length > 4 ? <Link className="more-users" to={`/app/settings/departments/${teamsId}`}>+{users.length-4}</Link> : ''}
    </>)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationErrorId?: string, title?: string): void => {
    console.log('error', error)
    const description = correlationErrorId ?
      formatMessage({ id: 'teams.handleDeleteError' }, { correlationId: correlationErrorId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.somethingWentWrong' }),
      description,
      duration: 0,
    })
  }

  const deleteTeam = async (teamId) => {
    let response
    try {
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'TEAM_DELETED',
          eventGroup: 'TEAM',
          teamId,
        },
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'teams.deleteInProgress' }, { teamName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setCorrelationId(response.correlationId)
      setDeletedTeamId(teamId)
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      handleErrorNotification(error, response?.correlationId, formatMessage({ id: 'teams.handleDeleteErrorTitle' }))
    }
  }

  const showConfirmDelete = (teamId, name) => {
    confirm({
      title: formatMessage({ id: 'teams.deleteTeamTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'teams.deleteTeamConfirm' }, { name }),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteTeam(teamId)
      },
    })
  }

  const handleMenuClick = (event, teamId, name) => {
    if (event.key === 'deleteTeam') {
      showConfirmDelete(teamId, name)
    }
  }

  const menu = (teamId, name, isDefaultTeam) => (
    <Menu onClick={(event) => handleMenuClick(event, teamId, name)}>
      <Menu.Item>
        <Link to={`/app/settings/departments/${teamId}/edit`}><IntlMessages id="teams.editTeam" /></Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={`/app/settings/departments/${teamId}`}><IntlMessages id="departments.viewDepartment" /></Link>
      </Menu.Item>
      {!isDefaultTeam &&
        <Menu.Item key="deleteTeam">
          <IntlMessages id="app.delete" />
        </Menu.Item>
      }
    </Menu>
  )

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)
  }

  const columns = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
    // eslint-disable-next-line react/display-name
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    // eslint-disable-next-line react/display-name
    render: (name, row) => {
      return (<>
        <Link to={`/app/settings/departments/${row.id}`}>{name}</Link>
        {row.isDefault && <Tag
          key="default"
          color="purple"
          style={{marginLeft: 4}}
        >
          <IntlMessages id="locations.default"/>
        </Tag>}
        {row.isDefault && <p className="m-0"><IntlMessages id="teams.defaultTeam" /></p>}
      </>)
    },
  }, {
    title: <IntlMessages id="teams.users" />,
    dataIndex: 'users',
    className: 'users',
    key: 'users',
    // eslint-disable-next-line react/display-name
    render: (users, row) => {
      if(users.length > 0) {
        return getFewUsers(users, row.id)
      } else {
        return <IntlMessages id="team.hasNoUsers" />
      }
    },
  }, {
    title: <IntlMessages id="teams.approvers" />,
    dataIndex: 'approvers',
    className: 'users',
    key: 'approvers',
    // eslint-disable-next-line react/display-name
    render: (approvers, row) => {
      if (approvers.length > 0) {
        return getFewUsers(approvers, row.id)
      } else {
        return ''
      }
    },
  }, {
    title: <IntlMessages id="app.created" />,
    dataIndex: 'timestamp',
    key: 'timestamp',
    // eslint-disable-next-line react/display-name
    sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
    sortOrder: sortedInfo.columnKey === 'timestamp' && sortedInfo.order,
    // eslint-disable-next-line react/display-name
    render: (timestamp) => <FormattedDate value={timestamp} format={dateFormat} />,
  }, {
    title: '',
    className: 'action',
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (id, row) => (
      <Dropdown overlay={() => menu(id, row.name, row.isDefault)} trigger={['click']} placement="bottomRight">
        <EllipsisOutlined />
      </Dropdown>
    ),
  }]

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.departments" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.departments" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        <div className="teams-body">
          <div className="btn-actions">
            <Link to="/app/settings/departments/create" className="ant-btn ant-btn-default"><IntlMessages id="teams.createNewTeam" /></Link>
          </div>
          <Table
            loading={loading}
            dataSource={teamList}
            columns={columns}
            rowKey={record => record.id}
            onChange={handleTableChange}
            showSorterTooltip={false}
            pagination={false}
          />
          {authCompany?.plan === SubscriptionPlanEnum.core && teamList.length >= NUMBER_OF_TEAMS_LIMIT &&
            <Alert 
              style={{ marginTop: 20 }} 
              message={
                <IntlMessages id="teams.teamsLimitReached" 
                  values={{
                    limitNumber: NUMBER_OF_TEAMS_LIMIT,
                    billingPage: (...chunks) => (<Link to="/app/settings/billing">{chunks}</Link>),
                  }} 
                />} 
              type="warning"
            />
          }
        </div>
      </div>
    </div>
  )
}

export default TeamsPage
