import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PrivateRoute } from '../components/private-route'
import { allPlatforms } from '@vacationtracker/shared/data/platforms'

import DashboardPage from './DashboardPage'
import RequestLeavePage from './Leaves/RequestLeavePage'
import RequestToilPage from './Toil/RequestToilPage'
import EditRequestToilPage from './Toil/EditRequestToilPage'
import EditLeavePage from './Leaves/EditLeavePage'
import AddLeavePage from './Leaves/AddLeavePage'
import LeaveRequestsPage from './Leaves/LeaveRequestsPage'
import CalendarPage from './CalendarPage'
import WallchartPage from './Wallchart'
import TeamsFormPage from './Settings/Teams/TeamsFormPage'
import TeamPage from './Settings/Teams/TeamPage'
import TeamsPage from './Settings/Teams/TeamsPage'
import LocationFormPage from './Settings/Locations/LocationFormPage'
import LocationPage from './Settings/Locations/LocationPage'
import LocationsPage from './Settings/Locations/LocationsPage'
import LeavePoliciesPage from './Settings/LeavePoliciesPage'
import LeaveTypesPage from './Settings/LeaveTypesPage'
import HolidaysPage from './HolidaysPage'
import UserPage from './Users/UserPage'
import UsersPage from './Users/UsersPage'
import GeneralPage from './Settings/GeneralPage'
import ExportPage from './Reports/ExportPage'
import CompanyLogsPage from './Reports/CompanyLogsPage'
import ScheduledReportFormPage from './Reports/ScheduledReports/ScheduledReportFormPage'
import ScheduledReportsPage from './Reports/ScheduledReports/ScheduledReportsPage'
import NotificationsPage from './Notifications/NotificationsPage'
import NotificationsFormPage from './Notifications/NotificationsFormPage'
import BillingPage from './Settings/BillingPage'
import AccountSettingsPage from './Settings/AccountSettings'
import MyProfilePage from './MyProfilePage'
import NotFoundPage from './NotFoundPage'
import BotUpdatePermissionsPage from './BotUpdatePermissionsPage'
import ManageMicrosoftLicences from './Users/ManageMicrosoftLicences'
import ImportUsersPage from './Users/ImportUsersPage'
import AutomationsPage from './Automations/AutomationsPage'
import AutomationsFormPage from './Automations/AutomationsFormPage'
import ImportHolidaysPage from './Settings/Locations/ImportHolidaysPage'
import OutOfOffice from './OutOfOffice'
import ImportPage from './ImportPage'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import CalendarSyncPage from './CalendarSyncPage'

interface IApp {
  match: {
    url: string
  }
  onStateChange: Function
}


const App = ({ match, onStateChange }: IApp): React.ReactElement => {
  return (
    <div className="main-content-wrapper">
      <Switch>
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/request-leave`}
          onStateChange={onStateChange}
          component={RequestLeavePage}
        />
        <PrivateRoute
          roles={['User']}
          platform={allPlatforms}
          path={`${match.url}app/request-leave`}
          onStateChange={onStateChange}
          component={RequestLeavePage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/:id/edit`}
          onStateChange={onStateChange}
          component={EditLeavePage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/add-leave`}
          onStateChange={onStateChange}
          component={AddLeavePage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/leave-requests/:leaveRequestId`}
          onStateChange={onStateChange}
          component={LeaveRequestsPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/toil/:id/edit`}
          plan={SubscriptionPlanEnum.complete}
          onStateChange={onStateChange}
          component={EditRequestToilPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/leaves/request-toil`}
          plan={SubscriptionPlanEnum.complete}
          onStateChange={onStateChange}
          component={RequestToilPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/request-toil`}
          plan={SubscriptionPlanEnum.complete}
          onStateChange={onStateChange}
          component={RequestToilPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/dashboard`}
          onStateChange={onStateChange}
          component={DashboardPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/ical/create`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/ical/summary`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/google/connect`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/google/reconnect`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/google/create`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/google/update`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/outlook/connect`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/outlook/reconnect`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/outlook/create`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync/outlook/update`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar/sync`}
          onStateChange={onStateChange}
          component={CalendarSyncPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/calendar`}
          onStateChange={onStateChange}
          component={CalendarPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/wallchart`}
          onStateChange={onStateChange}
          component={WallchartPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/departments/create`}
          onStateChange={onStateChange}
          component={TeamsFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/departments/:id/edit`}
          onStateChange={onStateChange}
          component={TeamsFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/departments/:id`}
          onStateChange={onStateChange}
          component={TeamPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/departments`}
          onStateChange={onStateChange}
          component={TeamsPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/locations/create`}
          onStateChange={onStateChange}
          component={LocationFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/locations/:id/edit`}
          onStateChange={onStateChange}
          component={LocationFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/locations/:id/:type`}
          onStateChange={onStateChange}
          component={LocationPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/location/:locationId/leave-policies/:leaveTypeId`}
          onStateChange={onStateChange}
          component={LeavePoliciesPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/locations`}
          onStateChange={onStateChange}
          component={LocationsPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/leave-types`}
          onStateChange={onStateChange}
          component={LeaveTypesPage}
        />
        <PrivateRoute
          roles={['Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/holidays`}
          onStateChange={onStateChange}
          component={HolidaysPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/import-holidays/:id/:year?`}
          onStateChange={onStateChange}
          component={ImportHolidaysPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={['microsoft']}
          path={`${match.url}app/users/manage-licences`}
          onStateChange={onStateChange}
          component={ManageMicrosoftLicences}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={['microsoft', 'slack', 'google']}
          path={`${match.url}app/users/import`}
          onStateChange={onStateChange}
          component={ImportUsersPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/users/:id`}
          onStateChange={onStateChange}
          component={UserPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/users`}
          onStateChange={onStateChange}
          component={UsersPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/general`}
          onStateChange={onStateChange}
          component={GeneralPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/settings/personal`}
          onStateChange={onStateChange}
          component={AccountSettingsPage}
        />

        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/notifications/:id/edit`}
          onStateChange={onStateChange}
          component={NotificationsFormPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/notifications/create`}
          onStateChange={onStateChange}
          component={NotificationsFormPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/notifications`}
          onStateChange={onStateChange}
          component={NotificationsPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/automations/:automationType/:id/edit`}
          onStateChange={onStateChange}
          component={AutomationsFormPage}
          paymentProcessor="stripe"
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/automations/:automationType/create`}
          onStateChange={onStateChange}
          component={AutomationsFormPage}
          paymentProcessor="stripe"
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/automations/create`}
          onStateChange={onStateChange}
          component={AutomationsFormPage}
          paymentProcessor="stripe"
        />
        <PrivateRoute
          roles={['Admin', 'Approver']}
          platform={allPlatforms}
          path={`${match.url}app/automations`}
          onStateChange={onStateChange}
          component={AutomationsPage}
          paymentProcessor="stripe"
        />
        {
          <PrivateRoute
            roles={['Admin']}
            platform={allPlatforms}
            path={`${match.url}app/import`}
            onStateChange={onStateChange}
            component={ImportPage}
          />
        }
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/settings/billing`}
          onStateChange={onStateChange}
          component={BillingPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/reports/export`}
          onStateChange={onStateChange}
          component={ExportPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/reports/scheduled-reports/:id/edit`}
          onStateChange={onStateChange}
          component={ScheduledReportFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/reports/company-logs`}
          onStateChange={onStateChange}
          component={CompanyLogsPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/reports/scheduled-reports/create`}
          onStateChange={onStateChange}
          component={ScheduledReportFormPage}
        />
        <PrivateRoute
          roles={['Admin']}
          platform={allPlatforms}
          path={`${match.url}app/reports/scheduled-reports`}
          onStateChange={onStateChange}
          component={ScheduledReportsPage}
        />
        <PrivateRoute
          roles={['Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/my-profile`}
          onStateChange={onStateChange}
          component={MyProfilePage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={['slack']}
          path={`${match.url}app/bot-update-permissions`}
          onStateChange={onStateChange}
          component={BotUpdatePermissionsPage}
        />
        <PrivateRoute
          roles={['Admin', 'Approver', 'User']}
          platform={allPlatforms}
          path={`${match.url}app/out-of-office`}
          onStateChange={onStateChange}
          component={OutOfOffice}
        />
        <Route
          component={NotFoundPage}
        />
      </Switch>
    </div>
  )
}

export default App
