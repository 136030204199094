import React, { useState, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, notification, Alert, Button, Typography } from 'antd'
import qs from 'qs'
import { useIntl } from 'react-intl'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { initialState, reducer } from './reducer'
import { actions } from './actions'

import { wait } from '@vacationtracker/shared/functions/wait'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import { signup } from '../../services/api/companies'
import { handlePurchaseToken, cancelSubscription } from '../../services/api/microsoft-billing'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { getUserId as getMsUserId } from '../../services/api/microsoft'
import { track, trackConversionEvent } from '../../services/analytics/analytics'
import countries from '@vacationtracker/shared/data/countries'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { selectLocaleSlice, setLocale } from '../../store/locale-slice'

import { WelcomeScreen } from './steps/welcome-screen'
import { CompanyDetails } from './steps/company-details'
import { AssignLicenses } from './steps/assign-licenses'
import { SetupMsBot } from './steps/setup-ms-bot'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setAuthUser } from '../../store/auth-user-slice'
import { logoutAction } from '../../store/configure'
import { setUserId } from '../../store/user-id-slice'

import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { ISignupRequest } from '../../services/api/companies.types'
import { ICheckUserId } from '@vacationtracker/shared/types/company'
import { MicrosoftBillingResolvedResponse } from '@vacationtracker/shared/types/microsoft-billing'
import { FrontendUrls } from '../../types/urls'
import { PlatformEnum } from '@vacationtracker/shared/data/platforms'

interface IMicrosoftSaaSCreateCompany {
  onStateChange: (e) => void
}

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client IDs are required')
}

const isCompanyDataSet = state => {
  const isElementaryCompanyDataSet = state.name
    && state.contactEmail
    && state.daysPerYear
    && state.country

  if (isElementaryCompanyDataSet) {
    if (countries.find(country => country.iso === state.country)?.states?.length as number > 0) {
      return state.state
    }
    return true
  }
}

const { Paragraph } = Typography

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)

const MicrosoftSaaSCreateCompany = ({ onStateChange }: IMicrosoftSaaSCreateCompany): React.ReactElement => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const reduxDispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const [isLoadingToken, setIsLoadingToken] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showSteps, setShowSteps] = useState(false)

  const [showAlertError, setShowAlertError] = useState(false)
  const [alertErrorTitle, setAlertErrorTitle] = useState<React.ReactElement>()
  const [alertErrorDescription, setAlertErrorDescription] = useState<React.ReactElement>()
  const [alertErrorType, setAlertErrorType] = useState<'error' | 'success' | 'info' | 'warning' | undefined>('error')


  const platform = 'microsoft'
  const variation = 'ACCOUNT_USERS'
  const paymentProcessor = 'microsoft-saas'

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const vtMSState = JSON.parse(localStorage.getItem('vtMSState') || '{}')
    if (vtMSState.createCompany) {
      dispatch(actions.setCreateCompanyDetails({
        ...vtMSState.createCompany,
        agreeToTerms: vtMSState.agreeToTerms,
      }))
      if (isCompanyDataSet(vtMSState.createCompany)) {
        dispatch(actions.setStepsCreateCompany(true))
      }
    }

    if (vtMSState.createUser) {
      dispatch(actions.setCreateUser(vtMSState.createUser))
    }

    if (vtMSState.selectedUsers && vtMSState.selectedUsers.length > 0) {
      dispatch(actions.setStepsAssignLicenses(true))
      dispatch(actions.setSelectedUser(vtMSState.selectedUsers))
    }

    if (vtMSState.steps && vtMSState.steps.setupBotAndTabs) {
      dispatch(actions.setBotAndTabsInstalled())
    }
  }, [])

  useEffect(() => {
    if (queryParams.token && !isLoadingToken) {
      dispatch(actions.setPurchaseToken(queryParams.token as string))
      handleToken(queryParams.token)
      return
    }

    const msPurchaseToken = state.msPurchaseToken || localStorage.getItem('vtMSPurchaseToken')
    if (!queryParams?.token && !msPurchaseToken) {
      history.push(FrontendUrls.signup)
      return
    }
    if (msPurchaseToken && !isLoadingToken) {
      dispatch(actions.setPurchaseToken(msPurchaseToken))
      handleToken(msPurchaseToken)
    }
  }, [queryParams])

  useEffect(() => {
    const path = history.location.pathname

    switch (path) {
      case '/microsoft-saas/create-company/welcome':
        dispatch(actions.setCurrentStep(0))
        break
      case '/microsoft-saas/create-company/company-details':
        dispatch(actions.setCurrentStep(1))
        break
      case '/microsoft-saas/create-company/assign-licenses':
        dispatch(actions.setCurrentStep(2))
        break
      case '/microsoft-saas/create-company/setup-ms-bot':
        dispatch(actions.setCurrentStep(3))
        break
      default:
        dispatch(actions.setCurrentStep(0))
        break
    }
  }, [history.location.pathname])

  const handleToken = async (token) => {
    try {
      setIsLoadingToken(true)
      const response = await handlePurchaseToken(token)
      const resolvedtoken = response.resolvedToken
      let id = resolvedtoken.subscription.purchaser.objectId
      try {
        const getUserFromSystem = await checkUser(token, response)
        if (getUserFromSystem?.id) {
          id = getUserFromSystem.id
        }
      } catch (error) {
        setIsLoading(false)
        return
      }


      const vtMSState = JSON.parse(localStorage.getItem('vtMSState') || '{}')
      dispatch(actions.setCreateUser({
        id: `${PlatformEnum.Universal}-${id}`,
        msUserId: resolvedtoken.subscription.purchaser.objectId,
        mail: resolvedtoken.subscription.purchaser.emailId,
        name: state.createUser.name === '' ? (vtMSState?.createUser?.name || '') : state.createUser.name,
      }))

      localStorage.setItem('vtMSPurchaseToken', token)
      const plan = `${resolvedtoken.planId.charAt(0).toUpperCase()}${resolvedtoken.planId.slice(1)}`
      dispatch(actions.setCreateCompanyPlan(plan))
      dispatch(actions.setNumberOfLicenses(resolvedtoken.quantity))
      dispatch(actions.setTenantId(resolvedtoken.subscription.purchaser.tenantId))
      setIsLoading(false)
      setShowSteps(true)
    } catch (error) {
      if (error?.response?.data && error?.response?.data?.code === 'InvalidValue') {
        if (error.response.data.message.includes('Token is expired')) {
          track('SIGNUP_SETUP_PURCHASE_TOKEN_EXPIRED', {
            platform,
            paymentProcessor,
          })
          setAlertErrorTitle(<IntlMessages id="microsoftSass.createCompany.alertError.tokenExpiredTitle" />)
          setAlertErrorDescription(() => {
            return (<>
              <Paragraph><IntlMessages id="microsoftSass.createCompany.alertError.tokenExpiredDescription" /></Paragraph>
              <Button onClick={goToAdminCenter}><IntlMessages id="microsoftSass.createCompany.alertError.goToAdminCenter" /></Button>
            </>)
          })
        } else {
          track('MS_SAAS_SETUP_ERROR_PURCHASE_TOKEN_INVALID', {
            platform,
            paymentProcessor,
          })
          setAlertErrorTitle(<IntlMessages id="microsoftSass.createCompany.alertError.tokenInvalidTitle" />)
          setAlertErrorDescription(() => {
            return (<>
              <Paragraph><IntlMessages id="microsoftSass.createCompany.alertError.tokenInvalidDescription" /></Paragraph>
              <Button onClick={goToAdminCenter} style={{ marginRight: 15 }}><IntlMessages id="microsoftSass.createCompany.alertError.goToAdminCenter" /></Button>
              <Button href={FrontendUrls.signup}><IntlMessages id="microsoftSass.createCompany.alertError.goToConnectPage" /></Button>
            </>)
          })
        }
      } else {
        setAlertErrorTitle(<IntlMessages id="microsoftSass.createCompany.alertError.errorTitle" />)
        setAlertErrorDescription(error?.response?.data?.message)
      }
      setAlertErrorType('error')
      setShowAlertError(true)
      setIsLoading(false)
    }
  }

  const contactSupport = () => {
    if (window && window.$crisp) {
      track('MS_SAAS_SETUP_ERROR_CANCELLED_SUBSCRIPTION_CLICK', {
        platform,
        paymentProcessor,
        button: 'Contact support',
      })
      window.$crisp.push(['do', 'chat:open'])
    }
  }

  const connectWithAnotherPlatform = (platform) => {
    track('MS_SAAS_SETUP_ERROR_USER_HAS_ACCOUNT_CLICK', {
      paymentProcessor,
      platform,
      button: `Log in with ${platform}`,
    })
    onStateChange('signIn')
    history.push(`${FrontendUrls.signin}?platform=${platform}`)
  }

  const checkUser = async (purchasetoken: string, response: MicrosoftBillingResolvedResponse) => {
    const tenantId = response.resolvedToken.subscription.purchaser.tenantId
    const userData: ICheckUserId = await getMsUserId(tenantId, undefined, purchasetoken)

    if (response.status === 'RESUBSCRIBED') {
      track('MS_SAAS_SETUP_RESUBSCRIBED', {
        paymentProcessor,
        platform,
      })
      setAlertErrorTitle(<IntlMessages id="microsoftSass.createCompany.alertError.resubscribedTitle" />)
      setAlertErrorDescription(() => {
        return (<>
          <Paragraph><IntlMessages id="microsoftSass.createCompany.alertError.resubscribedDescription"
            values={{ companyName: response.organizationName }}
          /></Paragraph>
          <Button onClick={() => connectWithAnotherPlatform('microsoft')}>
            <IntlMessages id="microsoftSass.createCompany.alertError.logInWithPlatform" values={{ platform: 'Microsoft' }} />
          </Button>
        </>)
      })
      setAlertErrorType('success')
      setShowAlertError(true)
      throw 'MS_SAAS_SETUP_RESUBSCRIBED'
    }

    if (response.status === 'COMPANY_CANCELED_NOT_ADMIN') {
      track('MS_SAAS_SETUP_ERROR_CANCELLED_SUBSCRIPTION', {
        platform,
        paymentProcessor,
      })
      setAlertErrorTitle(<IntlMessages id="error.subscriptionExpiredTitle" />)
      setAlertErrorDescription(() => {
        return (<>
          <Paragraph><IntlMessages id="errors.microsoft.subscriptionExpiredDescription" /></Paragraph>
          <Button onClick={contactSupport}><IntlMessages id="microsoftSass.createCompany.alertError.contactSupport" /></Button>
        </>)
      })
      setAlertErrorType('error')
      setShowAlertError(true)
      cancelSubscription(purchasetoken)
      throw 'MS_SAAS_SETUP_ERROR_CANCELLED_SUBSCRIPTION'
    }

    if (response.status === 'USER_NOT_FOUND' || response.status === 'USER_INACTIVE') {
      track('MS_SAAS_SETUP_ERROR_USER_INACTIVE', {
        platform,
        paymentProcessor,
      })
      setAlertErrorTitle(<IntlMessages id="error.companyExists" />)
      setAlertErrorDescription(() => {
        let admins =  ''
        if (userData.adminContacts && userData.adminContacts.length > 0) {
          userData.adminContacts.map((admin) => {
            admins += `${admin.name}: ${admin.email}\n`
          })
        } else if (response.adminContacts && response.adminContacts.length > 0) {
          response.adminContacts.map((admin) => {
            admins += `${admin.name}: ${admin.email}\n`
          })
        } else if (!userData.adminContacts || userData.adminContacts.length === 0) {
          admins += `${userData.organizationName}: ${userData.contactEmail}\n`
        }
        const email = response.resolvedToken.subscription.purchaser.emailId
        return (<>
          <Paragraph>
            <IntlMessages id="microsoftSass.createCompany.alertError.companyAlreadyExistsDescription"
              values={{
                email,
                admins,
              }}
            />
          </Paragraph>
          <Button style={{ marginLeft: '12px' }} onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            <IntlMessages id="app.contactSupport" />
          </Button>
        </>)
      })
      setAlertErrorType('error')
      setShowAlertError(true)
      // cancelSubscription(purchasetoken)
      throw 'MS_SAAS_SETUP_ERROR_USER_INACTIVE'
    }

    if (response.status === 'EXISTS_ON_ANOTHER_PLATFORM' || response.status == 'ACTIVE_COMPANY_EXISTS') {
      track('MS_SAAS_SETUP_ERROR_USER_HAS_ACCOUNT', {
        platform: userData.existsOnAnotherPlatform || 'microsoft',
        paymentProcessor,
      })
      setAlertErrorTitle(<IntlMessages id="microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle" />)
      setAlertErrorDescription(() => {
        const platform = userData.existsOnAnotherPlatform ? userData.existsOnAnotherPlatform.charAt(0).toUpperCase() + userData.existsOnAnotherPlatform.slice(1) : 'Microsoft'
        return (<>
          <Paragraph><IntlMessages id="microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription"
            values={{
              companyName: userData.existingOrganizationName || userData.organizationName,
              platform: platform,
            }}
          /></Paragraph>
          <Button onClick={() => connectWithAnotherPlatform(userData.existsOnAnotherPlatform || 'microsoft')}>
            <IntlMessages id="microsoftSass.createCompany.alertError.logInWithPlatform" values={{ platform: platform }} />
          </Button>
          <Button style={{ marginLeft: '12px' }} onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            <IntlMessages id="app.contactSupport" />
          </Button>
        </>)
      })
      setAlertErrorType('warning')
      setShowAlertError(true)
      cancelSubscription(purchasetoken)
      throw 'MS_SAAS_SETUP_ERROR_USER_HAS_ACCOUNT'
    }

    return userData
  }

  let numberOfRetries = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        reduxDispatch(setAuthCompany(response.data.getCompany))
        reduxDispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error, numberOfRetries, location.pathname)
      if (numberOfRetries >= 10) {
        localStorage.clear()
        sessionStorage.clear()
        await Auth.signOut()
        reduxDispatch(logoutAction)
        history.push(FrontendUrls.signin)
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetries++
        await wait(200 * numberOfRetries)
        return await getCompanyAndUser(id)
      }
    }
  }

  const clearStorage = () => {
    localStorage.removeItem('vtMSPurchaseToken')
    localStorage.removeItem('vtMSState')
  }

  const authSigin = async (username: string, token: string) => {
    const signInResponse = await Auth.signIn(username)
    if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
      const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: platform })
      localStorage.setItem('userId', cognitoResponse.username)
      const { createCompany, createUser, selectedUsers, totalUsers } = state

      await getCompanyAndUser(cognitoResponse.username)

      track('SIGNUP_COMPLETED', {
        platform,
        variation,
        paymentProcessor,
        plan: createCompany.plan,
        email: createUser.mail,
      })
      trackConversionEvent('SIGNUP_COMPLETED')
      if (selectedUsers.length > 0) {
        try {
          await API.post('CoreEvent', '/core/event', {
            body: {
              eventType: 'ASSIGN_LICENSES',
              eventGroup: 'BULK_ACTIONS',
              activeUsers: selectedUsers.map(msUser => {
                return {
                  platformUserId: msUser.id.replace('microsoft-', ''),
                  isAdmin: msUser.isAdmin,
                  announce: msUser.announce,
                }
              }),
              token: msAuth.getTokens().accessToken,
              totalUsers: totalUsers,
            },
          })
        } catch (error) {
          const errorDescription = error.response?.data ? error.response?.data : error.message ? error.message : JSON.stringify(error)
          track('IMPORT_USERS_ERROR', {
            platform,
            errorMessage: errorDescription,
            plan: state.createCompany.plan,
          })
        }
      }

      reduxDispatch(setUserId(cognitoResponse.username))
      clearStorage()
      onStateChange('signedIn')
      history.push('/app/dashboard?tour=true')
    }
  }

  const onFinishSetup = async () => {
    dispatch(actions.isCreateCompanyLoader(true))
    const { createCompany, createUser, tenantId, numberOflicenses, selectedUsers } = state
    try {
      const tokens = msAuth.getTokens()
      const data: ISignupRequest = {
        user: {
          id: createUser.id,
          name: createUser.name,
          email: createUser.mail || createCompany.contactEmail,
          platform,
          isAdmin: true,
          locale: locale.locale,
        },
        company: {
          name: createCompany.name,
          contactEmail: createCompany.contactEmail,
          platform,
          daysPerYear: createCompany.daysPerYear,
          hasUnlimitedDays: createCompany.hasUnlimitedDays,
          country: countries.find(country => country.iso === createCompany.country)?.name as string,
          state: createCompany.state,
          plan: createCompany.plan,
          timezone: getTimeZoneOrDefaultToUtc(),
          paymentProcessor: 'microsoft-billing',
        },
        ms: {
          msUserId: createUser.msUserId,
          msTeamId: tenantId,
          organizationId: tenantId,
          msPurchaseToken: state.msPurchaseToken,
        },
        token: tokens.accessToken,
      }

      if (Number(selectedUsers.length) + 1 > numberOflicenses) {
        return
      }
      await signup(data)
      await wait(1000)
      await authSigin(createUser.id, tokens.accessToken)
    } catch(error) {
      const errorDescription = error.response?.data ? error.response?.data : error.message ? error.message : JSON.stringify(error)
      track('SIGNUP_ERROR', {
        platform,
        errorMessage: errorDescription,
        plan: state.createCompany.plan,
      })
      dispatch(actions.isCreateCompanyLoader(false))
      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        description: errorDescription,
      })
      if(error.response?.data.includes('ValidationError')) {
        history.push('/microsoft-saas/create-company/welcome')
        dispatch(actions.setCurrentStep(0))
      } else {
        clearStorage()
        onStateChange('signIn')
      }
    }
  }

  const goToAdminCenter = () => {
    track('SIGNUP_SETUP_PURCHASE_TOKEN_EXPIRED_CLICK', {
      platform,
      paymentProcessor,
    })
    window.open('https://admin.microsoft.com/Adminportal/Home#/subscriptions','_self')
  }

  const steps = [
    {
      id: 'welcome',
      content: <WelcomeScreen state={state} dispatch={dispatch} onFinishSetup={onFinishSetup} />,
    },
    {
      id: 'companyForm',
      content: <CompanyDetails state={state} dispatch={dispatch} platform={platform} variation={variation} paymentProcessor={paymentProcessor} />,
    },
    {
      id: 'assignLicen',
      content: <AssignLicenses state={state} dispatch={dispatch} msAuth={msAuth} platform={platform} variation={variation} paymentProcessor={paymentProcessor}
      />,
    },
    {
      id: 'setupMsBot',
      content: <SetupMsBot state={state} dispatch={dispatch} msAuth={msAuth} platform={platform} variation={variation} paymentProcessor={paymentProcessor} />,
    },
  ]

  const small = { span: 24, offset: 0 }
  const medium = { span: 24, offset: 0 }
  const large = { span: 22, offset: 1 }
  const extraLarge = { span: 18, offset: 3 }

  return (
    <section className="ant-layout ant-layout-has-sider app-layout create-company microsoft-saas-create-company">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper">
            <Row style={{ height: '100%' }}>
              <Col span={24} offset={0} xs={small} md={medium} lg={large} xl={extraLarge} xxl={extraLarge}>
                <div className="main-content">
                  <div className="main-content-body">
                    {isLoading && <CircularProgress style={{ height: 'calc(100vh - 92px)' }} />}
                    {showSteps && <>{steps[state.currentStep].content}</>}
                    {showAlertError &&
                      <Alert
                        message={alertErrorTitle}
                        description={alertErrorDescription}
                        type={alertErrorType}
                        showIcon
                      />
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </main>
      </section>
    </section>
  )
}

export default MicrosoftSaaSCreateCompany
