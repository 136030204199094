import React, { Fragment } from 'react'
import { Form } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { IBillingInfo } from '../../types/company'

interface IBillingInformation {
  billingInfo: IBillingInfo
}

const BillingInformation = ({
  billingInfo,
}: IBillingInformation): React.ReactElement => {
  const { billingEmails, billingName, address } = billingInfo

  return (
    <Fragment>
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="general.companyName" />}>
        {billingName}
      </Form.Item>

      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billing.email" />}>
        {billingEmails.join(', ')}
      </Form.Item>

      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="components.billingInfo.address" />}>
        {address.address}
        {address.addressLine2 &&
          <>
            <br />
            {address.addressLine2}
          </>
        }
        <br />
        {address.city}, {address.country}{address.state ? (', ' + address.state) : ''}
      </Form.Item>
    </Fragment>
  )
}

export default BillingInformation
