import { Platform } from '../types/core-event'

export enum PlatformNameEnum {
  'microsoft'= 'Microsoft Active Directory',
  'slack' = 'Slack Workspace',
  'google' = 'Google Workspace'
}

export const getIntegrationPlatformName = (platform?: Platform): PlatformNameEnum | string => {
  switch (platform) {
    case 'microsoft':
      return PlatformNameEnum.microsoft
    case 'slack':
      return PlatformNameEnum.slack
    case 'google':
      return PlatformNameEnum.google
    default:
      return ''
  }
}