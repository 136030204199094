import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Breadcrumb, Button, message, Modal, notification, Switch, Table } from 'antd'
import { DeleteOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { uniq } from 'lodash'
import { API, graphqlOperation } from 'aws-amplify'
import { getAutomations } from '../../../graphql/custom-queries'

import { selectDateFormatSlice } from '../../../store/date-format-slice'
import { selectFeatureFlagsSlice } from '../../../store/feature-flags-slice'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { useAppSelector } from '../../../store/hooks'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'

import CircularProgress from '../../../components/circular-progress'
import AutomationFeedback from '../../../components/automation-feedback'
import EmptyData from '../../../components/empty-data'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { IAutomations, IGetAutomations, ILeaveTypeListShort } from '../../../types/custom-queries'
import { IData } from '../../../types/data'
import { track } from '../../../services/analytics/analytics'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'

interface ISortedInfo {
  columnKey?: string
  order?: string
}

const { confirm } = Modal

const AutomationsPage: React.FC = () => {
  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const history = useHistory()
  const { formatMessage } = useIntl()

  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const amIAdmin = authUser.role.toLowerCase() === 'admin'

  const [isLoading, setIsLoading] = useState(true)
  const [automations, setAutomations] = useState<IAutomations[] | []>([])
  const [leaveTypeList, setLeaveTypeListShort] = useState<Pick<ILeaveTypeListShort, 'id' | 'name'>[] | []>([])
  const [sortedInfo, setSortedInfo] = useState<ISortedInfo | any>({})
  const [activeAutomationStatus, setActiveAutomationStatus] = useState<{id: string; isActive: boolean} | null>(null)
  const [subscribedAutomationTypes, setSubscribedAutomationType] = useState<string[]>([])

  const shouldEnableAutomation = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.automation, true)
  const shouldEnableBlackOutPeriod = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.blackOutPeriod, true)
  const shouldEnableAntitlementByRole =  useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.entitlementByRole, true)
  const shouldEnableSeniorityEntitlement =  useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.seniorityEntitlement, true)

  useEffect(() => {
    if (isLoading) {
      // Only for analytics
      track('AUTOMATIONS_ACTIVE_VIEWED', {})
    }
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [actionNotifications, featureFlags])

  useEffect(() => {
    setSubscribedAutomationType(automationTypes => {
      const newAutomationTypes = [...automationTypes]
      if (shouldEnableBlackOutPeriod || shouldEnableAutomation) {
        newAutomationTypes.push('BLACKOUT_PERIOD')
      }
      if (shouldEnableAntitlementByRole || shouldEnableAutomation) {
        newAutomationTypes.push('ENTITLEMENT_BY_ROLE')
      }
      if (shouldEnableSeniorityEntitlement || shouldEnableAutomation) {
        newAutomationTypes.push('SENIORITY_ENTITLEMENT')
      }
      return uniq(newAutomationTypes as string[])
    })
  }, [shouldEnableAutomation, shouldEnableBlackOutPeriod, shouldEnableAntitlementByRole, shouldEnableSeniorityEntitlement])

  const fetchData = async () => {
    const response = await API.graphql(graphqlOperation(getAutomations)) as IData<IGetAutomations>
    const autoamtions = response.data.getAutomations.map(automation => {
      let billingStatus = response.data.getAddonsSubscription?.automations.find(subscribedAutomation => subscribedAutomation.type === automation.automationType)?.status
      if(!billingStatus) {
        billingStatus = featureFlags.includes(automation.automationType.toLowerCase().replace(/_/g, '-')) || featureFlags.includes('automation') ? 'GRANT_PERIOD' : 'CANCELED'
      }
      return {
        ...automation,
        billingStatus: billingStatus,
      }
    })
    const subscribedAutomation = response.data.getAddonsSubscription?.automations || []
    const activeAutomation = subscribedAutomation.map(automation => automation.type)
    setSubscribedAutomationType((automationTypes) => {
      return uniq([...automationTypes, ...activeAutomation] as string[])
    })
    if (activeAutomationStatus) {
      const updatedAutomation = autoamtions.find(automation => automation.id === activeAutomationStatus.id)
      if (updatedAutomation && updatedAutomation.isActive === activeAutomationStatus.isActive) {
        setActiveAutomationStatus(null)
      }
    }
    setAutomations((autoamtions).sort((a, b) => b.createdAt.localeCompare(a.createdAt)))
    setLeaveTypeListShort(response.data.getLeaveTypeList)
    setIsLoading(false)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)
  }

  const confirmUpdateAutomations = (row, checked) => {
    if(!checked) {
      confirm({
        title: formatMessage({ id: 'notifications.updateAutomationTitle' }),
        icon: <ExclamationCircleOutlined />,
        content: formatMessage({ id: 'notifications.updateAutomationConfirm' }),
        okText: formatMessage({ id: 'app.ok' }),
        okType: 'danger',
        width: 500,
        maskClosable: true,
        onOk() {
          updateAutomations(row, checked)
        },
      })
    } else {
      updateAutomations(row, checked)
    }
  }

  const updateAutomations = async (row, status) => {
    let response
    let automation
    setActiveAutomationStatus({
      id: row.id,
      isActive: status,
    })
    track('AUTOMATION_TOGGLE_CHANGED', {
      automationType: row.automationType,
      automationStatus: status ? 'enabled' : 'disabled',
    })
    switch (row.automationType) {
      case 'BLACKOUT_PERIOD': {
        response = await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'BLACKOUT_PERIOD_UPDATED',
            eventGroup: 'AUTOMATION',
            name: row.name,
            leaveTypeIds: row.leaveTypeIds,
            startDate: row.startDate,
            endDate: row.endDate,
            recurring: row.recurring,
            reason: row.reason,
            locations: row.locations,
            teams: row.teams,
            labels: row.labels,
            automationId: row.id,
            isActive: status,
          },
        })
        automation = 'blackoutPeriod'

        break
      }
      case 'SENIORITY_ENTITLEMENT': {
        response = await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'SENIORITY_ENTITLEMENT_UPDATED',
            eventGroup: 'AUTOMATION',
            name: row.name,
            leaveTypeId: row.leaveTypeId,
            calculateFrom: row.calculateFrom ? row.calculateFrom : 'TODAY',
            periods: row.periods,
            locations: row.locations,
            teams: row.teams,
            labels: row.labels,
            prorate: row.prorate,
            automationId: row.id,
            isActive: status,
          },
        })
        automation = 'seniorityEntitlement'

        break
      }
      case 'ENTITLEMENT_BY_ROLE': {
        response = await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'ENTITLEMENT_BY_ROLE_UPDATED',
            eventGroup: 'AUTOMATION',
            name: row.name,
            leaveTypeId: row.leaveTypeId,
            startDate: row.startDate,
            days: row.days,
            labelId: row.labelId,
            automationId: row.id,
            isActive: status,
          },
        })
        automation = 'entitlementByRole'

        break
      }
    }

    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: `automations.${automation}.updateInProgress` }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const deleteAutomation = async (automationId: string, name: string, automationType: string) => {
    let response
    try {
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: `${automationType}_DELETED`,
          eventGroup: 'AUTOMATION',
          automationId,
        },
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'automations.deleteInProgress' }, { automationName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      if (error.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else if (error.message === 'Request failed with status code 400') {
        notification.error({
          message: formatMessage({ id: 'error.automations.insufficientPrivileges.title' }),
          description: formatMessage({ id: 'error.automations.insufficientPrivileges.description' }),
          duration: 0,
        })
      } else {
        const description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const showConfirmDelete = (row: IAutomations, event) => {
    event.stopPropagation()

    let title
    let content
    switch (row.automationType) {
      case 'BLACKOUT_PERIOD': 
        title = formatMessage({ id: 'notifications.deleteAutomationTitle' })
        content = formatMessage(
          { id: 'notifications.deleteAutomationConfirm' },
          { name: row.name, strong: (...chunks) => <strong>{chunks}</strong> })
        break
      case 'SENIORITY_ENTITLEMENT': 
        title = formatMessage({ id: 'notifications.deleteAutomationTitle' })
        content = formatMessage(
          { id: 'notifications.deleteSeniorityEntitlementAutomationConfirm' },
          { leaveTypeName: leaveTypeList.find(lt => lt.id === row.leaveTypeId)?.name })
        break
      case 'ENTITLEMENT_BY_ROLE': 
        title = formatMessage({ id: 'notifications.deleteAutomationTitle' })
        content = formatMessage(
          { id: 'notifications.deleteEntitlementByRoleAutomationConfirm' },
          { leaveTypeName: leaveTypeList.find(lt => lt.id === row.leaveTypeId)?.name })
        break
      default:
        break
    }

    confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      content,
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      width: 500,
      maskClosable: true,
      onOk() {
        deleteAutomation(row.id as string, row.name as string, row.automationType as string)
      },
    })
  }
  
  const automationsColumns = [
    {
      title: <IntlMessages id="app.active" />,
      dataIndex: 'isActive',
      key: 'isActive',
      render: (id: string, row) => {
        return (
          <Switch checked={row.isActive}
            loading={Boolean(activeAutomationStatus && activeAutomationStatus.id === row.id)}
            onClick={(checked, event) => {
              event.stopPropagation()
              if (subscribedAutomationTypes.includes(row.automationType)) {
                confirmUpdateAutomations(row, checked)
              }
            }}
            disabled={(!amIAdmin && authUser.id !== row.owner?.id) || !subscribedAutomationTypes.includes(row.automationType)}
          />
        )
      },
    },
    {
      title: <IntlMessages id="app.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <IntlMessages id="automations.automationType" />,
      dataIndex: 'automationType',
      key: 'automationType',
      sorter: (a, b) => a.automationType.localeCompare(b.automationType),
      sortOrder: sortedInfo.columnKey === 'automationType' && sortedInfo.order,
      render: (automationType) => <IntlMessages id={`automations.${automationType}`} />,
    },
    {
      title: <IntlMessages id="app.created" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
      render: (createdAt) => <FormattedDate value={createdAt.split('T')[0]} format={dateFormat} />,
    },
    {
      title: <IntlMessages id="app.billingStatus" />,
      dataIndex: 'billingStatus',
      key: 'billingStatus',
      render: (billingStatus) => {
        let color = 'green'
        if (billingStatus === 'CANCELED') color = '#ff4d4f'
        if (billingStatus === 'CANCEL_AT_PERIOD_END') color = '#fa8c15'
        if (billingStatus === 'GRANT_PERIOD') color = '#7f00ff'

        return <span style={{ color: color }}><IntlMessages id={`app.billingStatus.${billingStatus}`} /></span>
      },
    },
    {
      title: '',
      className: 'action',
      width: 78,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row: IAutomations) => (
        <Button
          type="link"
          disabled={!amIAdmin && authUser.id !== row.owner?.id}
          onClick={(event) => showConfirmDelete(row, event)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ]

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <div className='main-content'>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span>
                <IntlMessages id="app.automations" />
              </span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="app.automations" /></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="main-content-body">
            <div>
              <Link to="/app/automations/create" className="ant-btn ant-btn-default">
                <IntlMessages id="automations.add" />
              </Link>
              <AutomationFeedback buttonStyle={{ float: 'right' }} page="automation-page-list" />
            </div>
            <br/>
            {automations.length > 0 ?
              <Table
                dataSource={automations}
                columns={automationsColumns}
                className="clickable-table"
                onChange={handleTableChange}
                rowClassName={(record) => {
                  if(!subscribedAutomationTypes.includes(record.automationType)) {
                    return 'disabled-row'
                  }
                  return ''
                }}
                onRow={(record) => {
                  if(!subscribedAutomationTypes.includes(record.automationType)) {
                    return {onClick: () => { return }}
                  }
                  if (amIAdmin || authUser.id === record.owner?.id) {
                    return {
                      onClick: () => history.push(`/app/automations/${record.automationType.replace(/_/g, '-').toLowerCase()}/${record.id}/edit`),
                    }
                  } else {
                    return {onClick: () => {
                      message.error(formatMessage({ id: 'automations.cantOpenAutomations' }))
                    }}
                  }
                }}
                rowKey="id"
                pagination={false}
              /> :
              <EmptyData 
                title={<IntlMessages id="automations.emptyViewTitle" />}
                subTitle={<IntlMessages id="automations.emptyViewMessage" />}
                buttonText={<IntlMessages id="automations.emptyViewButton"/>}
                buttonLink="/app/automations/create"
              />
            }
          </div>
        </div>
      }
    </>
  )
}

export default AutomationsPage
