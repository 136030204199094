import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Form, Input, InputNumber, Button, notification, Select, Alert } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { useIntl } from 'react-intl'
import { notificationStore } from '../../context/notificationsContext/store'
import { getAutomationsById } from '../../graphql/custom-queries'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'

import { IGetAutomationsByIdData, ILeaveTypeListShort, IEntitlementRoleAutomation } from '../../types/custom-queries'
import { IData } from '../../types/data'
import { IAutomationEntitlementByRoleForm } from '@vacationtracker/shared/types/automations'
import { openSupportChat } from '../../util/open-support-chat'
import { IGetLabelsShort } from '../../types/labels'
import { getDaysOrHours, setDaysOrHours } from '@vacationtracker/shared/functions/get-days-or-hours'


const { Text } = Typography
const { Option } = Select

interface IEntitlementByRoleForm {
  leaveTypes: ILeaveTypeListShort[]
  labels: IGetLabelsShort[]
  optionLabels: IGetLabelsShort[]
  backToAutomations: () => void
  automationId?: string
  isCorePlan: boolean
  hourlyLeaveAccounting: boolean
  shouldEnableLabels: boolean
}

const EntitlementByRoleForm = ({
  leaveTypes,
  labels,
  optionLabels,
  backToAutomations,
  automationId,
  isCorePlan,
  hourlyLeaveAccounting,
  shouldEnableLabels,
}: IEntitlementByRoleForm) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [automation, setAutomation] = useState<IAutomationEntitlementByRoleForm>({
    automationId: '',
    name: '',
    leaveTypeId: '',
    startDate: `${new Date().getFullYear()}-01-01`,
    isActive: true,
    labelId: '',
    days: hourlyLeaveAccounting ? 16 : 2,
  })
  const [startSeniorityEntitlement] = useState([
    { date: `${new Date().getFullYear()}-01-01`, name: formatMessage({ id: 'app.currentYear'}) },
  ])
  const [availableLabels, setAvailableLabels] = useState<IGetLabelsShort[]>(optionLabels)

  useEffect(() => {
    if (automationId) {
      fetchAutomations(automationId)
    }
  }, [automationId])

  const fetchAutomations = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getAutomationsById, { id })) as IData<IGetAutomationsByIdData>
      const getAutomationData = response.data.getAutomation as IEntitlementRoleAutomation

      setAutomation({
        automationId: getAutomationData.id,
        name: getAutomationData.name,
        leaveTypeId: getAutomationData.leaveTypeId,
        startDate: getAutomationData.startDate,
        isActive: getAutomationData.isActive,
        labelId: getAutomationData.labelId,
        days: getDaysOrHours(getAutomationData.days, hourlyLeaveAccounting),
      } as IAutomationEntitlementByRoleForm)

      const label = labels.find(label => label.id === getAutomationData.labelId)
      if (label) {
        setAvailableLabels([
          ...optionLabels,
          label,
        ])
      }

      setIsLoading(false)
    } catch (err) {
      console.log('ERROR FETCH DATA', err)
    }
  }

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        setBtnLoading(true)
        let response
        try {
          const data = {
            name: values.name,
            leaveTypeId: values.leaveTypeId,
            startDate: values.startDate,
            labelId: values.labelId,
            days: setDaysOrHours(values.days, hourlyLeaveAccounting),
          }

          if(!automationId) {
            response = await API.post('CoreEvent', '/core/event', {
              body: {
                eventType: 'ENTITLEMENT_BY_ROLE_CREATED',
                eventGroup: 'AUTOMATION',
                ...data,
              },
            })
          } else {
            response = await API.post('CoreEvent', '/core/event', {
              body: {
                eventType: 'ENTITLEMENT_BY_ROLE_UPDATED',
                eventGroup: 'AUTOMATION',
                automationId,
                ...data,
                isActive: true,
              },
            })
          }
          
          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: automationId ? 'automations.entitlementByRole.updateInProgress' : 'automations.entitlementByRole.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])

          history.push('/app/automations')
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.message) {
            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description: formatMessage({ id: error.response.data.message }),
              duration: 0,
            })
          } else {
            const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info)
        setBtnLoading(false)
      })
  }

  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 11 }, lg: { span: 11 }, xl: { span: 7 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 13 }, lg: { span: 13 }, xl: { span: 12 } },
  }
  
  const tailLayout = {
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 12, offset: 7 }, lg: { span: 12, offset: 7 } },
  }

  const openCrisp = (e) => {
    e.preventDefault()
    openSupportChat()
  }

  return (
    <>
      {(isLoading && automationId) ? 
        <CircularProgress /> :
        <>
          {(isCorePlan && !shouldEnableLabels) &&
            <Alert
              message={<IntlMessages id="components.entitlementByRole.completePlanOnly"/>}
              type="warning"
              style={{ marginBottom: 20 }}
            />
          }
          {optionLabels.length === 0 && !(isCorePlan && !shouldEnableLabels) &&
            <Alert
              message={<IntlMessages id="components.entitlementByRole.noLabels"/>}
              type="warning"
              style={{ marginBottom: 20 }}
            />
          }
          <div style={{ marginBottom: 20 }}>
            <Text> <IntlMessages id="choiceAutomations.entitlementByRoleInfo" values={{ link: (...chunks) => <a onClick={openCrisp}>{chunks}</a> }}/></Text>
          </div>
          <Form
            layout="horizontal"
            form={form}
            name="automationsForm"
            initialValues={automation}
            onFinish={onFinish}
            disabled={optionLabels.length === 0 || (isCorePlan && !shouldEnableLabels)}
            scrollToFirstError
            className="automation-entitlement-by-role-form"
            {...layout}
          >
            <Form.Item
              label={<IntlMessages id="app.name" />}
              name="name"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Input style={{ maxWidth: 300 }}/>
            </Form.Item>

            <Form.Item 
              label={<IntlMessages id="components.entitlementByRole.applyToUsersWithLabel" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="labelId"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
              >
                {availableLabels.map((label) => (
                  <Option value={label.id} key={label.id}>
                    {label.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item 
              label={<IntlMessages id="app.leaveType" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="leaveTypeId"
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
              >
                {leaveTypes.map((leaveType) => (
                  <Option value={leaveType.id} key={leaveType.id}>
                    {leaveType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={<IntlMessages id="app.add" />}>
              <Form.Item name="days" noStyle>
                <InputNumber 
                  min={1}
                  max={365} 
                  width={70}
                  step={1}
                  parser={value => Math.round(Number(value))}
                />
              </Form.Item>
              <span className="ant-form-text" style={{ marginLeft: 8 }}>
                {hourlyLeaveAccounting ?
                  <IntlMessages id="components.entitlementByRole.additionalHours" /> :
                  <IntlMessages id="components.entitlementByRole.additionalDays" />
                }
              </span>
            </Form.Item>
            
            <Form.Item 
              label={<IntlMessages id="components.entitlementByRole.startApplyingEntitlementByRoleFrom" />}
                                                                                
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="startDate"
            >
              <Select
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
                disabled={true}
              >
                {startSeniorityEntitlement.map((item) => (
                  <Option value={item.date} key={item.date}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div>
                <Button type="default" style={{ marginRight: 10 }} onClick={backToAutomations}>
                  <IntlMessages id="app.cancel" />
                </Button>
                <Button type="primary" 
                  loading={btnLoading}
                  htmlType="submit"
                >
                  {automationId ? <IntlMessages id="app.update" /> : <IntlMessages id="app.create" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      }
    </>
  )
}
export default EntitlementByRoleForm
