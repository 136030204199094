import React from 'react'
import { Button, Typography } from 'antd'
import Icon, {
  ControlOutlined,
  AppstoreAddOutlined,
  UserSwitchOutlined,
  TagsOutlined,
  PhoneOutlined,
  ContainerOutlined,
  FileDoneOutlined,
  HomeOutlined,
  LaptopOutlined,
  HddOutlined,
  ClockCircleOutlined,
  ShareAltOutlined,
  ImportOutlined
} from '@ant-design/icons'

import { Notification } from '../../components/custom-svg-icons'
import IntlMessages from '../../util/IntlMessages'
import { getTextWidth } from '../../util/get-text-width'
import { IPricePlanCard, IPlanMonthlyHeader, IPlanAnnualHeader, ICurrencyProp } from './types'
import { getBucketPlanInfo } from '../../util/get-price-plan-bucket-info'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { BillingTypesEnum, USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'

const { Text } = Typography


const Currency = ({ isSignup }: ICurrencyProp): React.ReactElement | null => {
  if (isSignup) {
    return null
  }
  return <sup>US </sup>
}

const PricePlanCard = ({
  plan,
  totalPrice,
  newTotalPrice,
  currentPeriod,
  totalUsers,
  pricePerUser,
  newPricePerUser,
  currentPlan,
  newPeriod,
  isNewPlan,
  showRevertingInfo,
  onSelectPLan,
  billingType = BillingTypesEnum.payAsYouGo,
  isSignup = false,
  isResubscribe = false,
  signupButtonText,
  isTrialPeriod = false,
  forceDisable = false,
  disiableLimitTeam = false,
  disableLimitLocation = false,
}: IPricePlanCard): React.ReactElement => {

  return (
    <div className={currentPlan === plan ? 'current-plan price-plan-card' : 'price-plan-card' }>
      <div className="card-header">
        <div className="plan-name-wrap">
          <span className="plan-name">{plan}</span>
        </div>
        {plan !== 'Enterprise' ?
          <>
            {newPeriod === 'monthly' &&
              <PlanMonthlyHeader
                isSignup={isSignup}
                totalPrice={totalPrice}
                newTotalPrice={newTotalPrice}
                totalUsers={totalUsers}
                pricePerUser={pricePerUser}
                newPricePerUser={newPricePerUser}
                billingType={billingType}
              />
            }
            {newPeriod === 'annual' &&
              <PlanAnnualHeader
                isNewPlan={isNewPlan}
                totalUsers={totalUsers}
                plan={plan}
                newPricePerUser={newPricePerUser}
                isSignup={isSignup}
                billingType={billingType}
              />
            }
          </> :
          <p className="price-contact-us">
            <IntlMessages id="components.pricePlanCard.contactUs" />
          </p>
        }

      </div>
      <div className="card-body">
        {plan === 'Core' && <>
          <p><LaptopOutlined /> <IntlMessages id="components.pricePlanCard.managePTO" /></p>
          <p><HomeOutlined /> <IntlMessages id="components.pricePlanCard.coreDepartmentsAndLocations" /></p>
          <p><HddOutlined /> <IntlMessages id="components.pricePlanCard.customizeLeaveTypes" /></p>
          <p><FileDoneOutlined /> <IntlMessages id="components.pricePlanCard.reports" /></p>
          <p><Icon component={Notification} /> <IntlMessages id="components.pricePlanCard.notifications" /></p>
        </>}
        {plan === 'Complete' && <>
          <p><AppstoreAddOutlined /><IntlMessages id="components.pricePlanCard.EverythingInTheCorePlan" /></p>
          <p><HomeOutlined /> <IntlMessages id="components.pricePlanCard.unlimitedDepartmentsAndLocations" /></p>
          <p><ControlOutlined /> <IntlMessages id="components.pricePlanCard.accruals" /></p>
          <p><ClockCircleOutlined /> <IntlMessages id="components.pricePlanCard.hourlyLeaves" /></p>
          <p><TagsOutlined /> <IntlMessages id="components.pricePlanCard.labels" /></p>
          <p><ContainerOutlined /> <IntlMessages id="components.pricePlanCard.reportsSchedule" /></p>
          <p><PhoneOutlined /> <IntlMessages id="components.pricePlanCard.support" /></p>
          <p><ImportOutlined /> <IntlMessages id="app.timeOffInLieu" /></p>
          <p><ShareAltOutlined /> <IntlMessages id="components.pricePlanCard.sharedCalendars" /></p>
          <p><UserSwitchOutlined /> <IntlMessages id="components.pricePlanCard.substituteApprovers" /></p>
        </>}
      </div>
      <div className="card-footer">
        {plan !== 'Enterprise' && !isSignup &&
          <Button
            disabled={(currentPlan === plan && currentPeriod === newPeriod) || forceDisable || disiableLimitTeam || disableLimitLocation}
            size="large"
            type="primary"
            onClick={()=> onSelectPLan(currentPlan === 'Core' && currentPeriod === 'annual' && plan === 'Complete' && newPeriod === 'monthly' && !isTrialPeriod ? 'support' : plan)}
            className={currentPlan === plan && currentPeriod === newPeriod ? 'btn-disabled' : ''}
          >
            {currentPlan === 'Core' && currentPeriod === 'annual' && plan === 'Complete' && newPeriod === 'monthly' && !isTrialPeriod && !isResubscribe ?
              <IntlMessages id="components.pricePlanCard.contactUs"/> :
              <>
                {(currentPlan === plan && currentPeriod === newPeriod) ?
                  <IntlMessages id="components.pricePlanCard.currentPlan"/> :
                  <IntlMessages id="components.pricePlanCard.switchPlan" values={{
                    newPeriod: `${newPeriod && newPeriod.charAt(0).toUpperCase() + newPeriod.slice(1)}`,
                    period: (...chunks) => currentPeriod === newPeriod ? '' : chunks,
                  }}/>
                }
              </>
            }
          </Button>
        }
        <div>
          {disiableLimitTeam && plan === 'Core' && <IntlMessages id="components.pricePlanCard.disiableLimitTeam"/>}
        </div>
        <div>
          {disableLimitLocation && plan === 'Core' && <IntlMessages id="components.pricePlanCard.disableLimitLocation"/>}
        </div>

        {plan === 'Enterprise' &&
          <Button
            disabled={currentPlan === plan || forceDisable}
            size="large"
            type="primary"
            onClick={() => onSelectPLan(plan)}
            className={currentPlan === plan ? 'btn-disabled' : ''}>
            <IntlMessages id="components.pricePlanCard.scheduleAConsultation"/>
          </Button>
        }
        {isSignup &&
          <Button
            size="large"
            type="primary"
            data-qa={`select-${plan === SubscriptionPlanEnum.complete ? SubscriptionPlanEnum.complete : SubscriptionPlanEnum.core}-plan`}
            onClick={() => onSelectPLan(plan)}
          >
            {signupButtonText}
          </Button>
        }
        {!isSignup && !isResubscribe && showRevertingInfo &&
          <div className="change-price-info">
            <Text type="secondary" style={{ fontSize: 12 }}>
              {plan === 'Complete' && currentPlan !== plan && currentPeriod === newPeriod && !isTrialPeriod && <IntlMessages id="components.pricePlanCard.upgradePlan" />}
              {(currentPlan !== plan || newPeriod !== currentPeriod) && isTrialPeriod && plan !== 'Enterprise'  && <IntlMessages id="components.pricePlanCard.duringTheTrial" />}
              {plan === 'Core' && currentPlan !== plan && currentPeriod === newPeriod && !isTrialPeriod && <IntlMessages id="components.pricePlanCard.downgradePlan" />}
            </Text>
          </div>
        }
      </div>
    </div>
  )
}

const PlanMonthlyHeader = ({
  isSignup,
  totalPrice,
  newTotalPrice,
  totalUsers,
  pricePerUser,
  newPricePerUser,
  billingType,
}: IPlanMonthlyHeader): React.ReactElement=> {

  return (
    <>
      <div className="plan-price-header">
        <IntlMessages
          id={billingType === BillingTypesEnum.seatBased
            ? 'components.pricePlanCard.pricePerSeat'
            : 'components.pricePlanCard.pricePerUser'}
          values={{
            pricePerUser,
            newPricePerUser,
            price: (...chunks) => <span className="price"><Currency isSignup={isSignup} />{chunks}</span>,
            oldPrice: (...chunks) => newTotalPrice ?
              <span className="old-price">
                <span className="strikethrough">{chunks}</span>
              </span> :
              chunks,
            newPrice: (...chunks) => newPricePerUser ? <span className="new-price"> {chunks}</span> : '',
          }} />
      </div>
      {isSignup ?
        <p className="plan-price-total-minimum">
          <IntlMessages id="components.pricePlanCard.minimumPlanPrice" values={{
            totalPrice,
            newTotalPrice,
            price: (...chunks) => <span className="price">{chunks}</span>,
            oldPrice: (...chunks) => newTotalPrice ? <span className="old-price"><span className="strikethrough">{chunks}</span></span> : chunks,
            newPrice: (...chunks) => newTotalPrice ? <span className="new-price">{chunks}</span> : '',
          }} />
        </p> :
        <p className="plan-price-total-minimum">
          {totalUsers > USER_PLAN_LIMIT ?
            <IntlMessages
              id={billingType === BillingTypesEnum.seatBased
                ? 'components.pricePlanCard.totalPriceSeats'
                : 'components.pricePlanCard.totalPrice'}
              values={{
                totalUsers,
                totalPrice,
                newTotalPrice,
                price: (...chunks) => <span className="price">{chunks}</span>,
                oldPrice: (...chunks) => newTotalPrice ? <span className="old-price"><span className="strikethrough">{chunks}</span></span> : chunks,
                newPrice: (...chunks) => newTotalPrice ? <span className="new-price">{chunks}</span> : '',
              }} /> :
            <IntlMessages id="components.pricePlanCard.totalPriceForLessThan25Users" values={{
              totalPrice,
              newTotalPrice,
              price: (...chunks) => <span className="price">{chunks}</span>,
              oldPrice: (...chunks) => newTotalPrice ? <span className="old-price"><span className="strikethrough">{chunks}</span></span> : chunks,
              newPrice: (...chunks) => newTotalPrice ? <span className="new-price">{chunks}</span> : '',
            }}/>
          }
        </p>
      }

    </>
  )
}

const PlanAnnualHeader = ({
  totalUsers,
  plan,
  isNewPlan,
  newPricePerUser,
  billingType,
  isSignup = false,
}: IPlanAnnualHeader): React.ReactElement=> {
  const { currentBucket, price } = getBucketPlanInfo(totalUsers, plan, isNewPlan)
  const forUsers = currentBucket.up_to
  const totalPrice = newPricePerUser && plan === 'Complete' ? (price / 2) + price : price
  const newTotalPrice = newPricePerUser && plan === 'Complete' ? price : null

  const textStyle = totalUsers > 300 ? 'normal 28px NoirPro' : 'normal 31px NoirPro'

  return (
    <>
      <div className="plan-price-header">
        <IntlMessages id="components.pricePlanCard.totalPricePerUser" values={{
          totalPrice,
          newTotalPrice,
          price: (...chunks) => <span className="price" style={{ marginRight: totalUsers > 300 ? 2 : 6 }}>
            <Currency isSignup={isSignup} />{chunks}
          </span>,
          oldPrice: (...chunks) => newTotalPrice ?
            <span className="old-price" style={{ marginRight: Number(getTextWidth(`$${newTotalPrice}`, textStyle)) + 15, fontSize: totalUsers > 300 ? 20 : 24 }}>
              <span className="strikethrough">{chunks}</span>
            </span> :
            chunks,
          newPrice: (...chunks) => newTotalPrice ? <span className="new-price" style={{ fontSize: totalUsers > 300 ? 28 : 31 }}>{chunks}</span> : '',
        }} />
      </div>
      <p className="no-margin">
        <IntlMessages id={billingType === BillingTypesEnum.seatBased
          ? 'components.pricePlanCard.forSeats'
          : 'components.pricePlanCard.forUsers'
        } values={{ users: forUsers }} />
      </p>
    </>
  )
}

export default PricePlanCard
