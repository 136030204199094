import React, { useState } from 'react'
import { Button, Card, Form, Input, Modal, Row, Tag, Tooltip, Typography, notification } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { API } from 'aws-amplify'

import IntlMessages from '../../util/IntlMessages'
import RequestAutomation from '../request-automation'
import { track } from '../../services/analytics/analytics'
import { IAddonsAutomations } from '@vacationtracker/shared/types/addons'

interface IChoiceAutomations {
  onSelectAutomation: (automation) => void
  automationsEventTypes: {
    type: string
    description: string
    url: string
    icon: JSX.Element
    roles: string[]
  }[]
  disableSenioirtyEnitlement: boolean
  formatMessage: Function
  subscribedAutomation: IAddonsAutomations[]
  deactivateAddons: (eventType) => void
  userStatus: string
}

const ChoiceAutomations = ({
  subscribedAutomation,
  onSelectAutomation,
  automationsEventTypes,
  disableSenioirtyEnitlement,
  formatMessage,
  deactivateAddons,
  userStatus,
}: IChoiceAutomations) => {
  const [form] = Form.useForm()
  const [showRequestAutomation, setShowRequestAutomation] = useState(false)
  const [scheduleACall, setScheduleACall] = useState(false)
  const [isSending, setSending] = useState<boolean>(false)

  const sendScheduleACall = async () => {
    await form.validateFields().then(async value => {
      try {
        setSending(true)
        track('AUTOMATION_COMING_SOON_FORM_SUBMITTED', {})
        await API.post('CoreEvent', '/core/event', {
          body: {
            ...value,
            eventGroup: 'FEEDBACK',
            eventType: 'SCHEDULE_A_CALL_AUTOMATION_FEEDBACK',
          },
        })
        setScheduleACall(false)
        notification.success({
          message: formatMessage({ id: 'automation.feedback.thankYou' }),
          icon: <CommentOutlined style={{ color: '#7f00ff' }} />,
        })
        form.resetFields()
        setSending(false)
      } catch (error) {
        setSending(false)
        const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
        notification.error({
          message: formatMessage({ id: 'error.generic' }),
          description: errorDescription,
          duration: 0,
        })
      }
    })
  }
  
  const automationCardExtra = (item) => {
    if (item.url === 'coming-soon') {
      return <Tag style={{ margin: 0 }}><IntlMessages id="app.comingSoon" /></Tag>
    }
    const automation = subscribedAutomation.find(automation => automation.type === item.type)
    if (automation?.isFeatureFlag) {
      return <></>
    }
    if (automation?.status === 'ACTIVE') {
      return (
        <Button size="small" type="primary" danger onClick={() => {
          deactivateAddonsButton = true
          deactivateAddons(item.type)
        }}>
          <IntlMessages id="choiceAutomations.deacticate" />
        </Button>
      )
    }
    if (automation?.status === 'CANCEL_AT_PERIOD_END') {
      return (
        <Tooltip title={<IntlMessages id="choiceAutomations.cancelAtPeriodEndTooltip" />}>
          <Button size="small" type="primary" danger onClick={() => {
            onSelectAutomation(item.type)
          }}>
            <IntlMessages id="choiceAutomations.cancelAtPeriodEnd" />
          </Button>
        </Tooltip>
      )
    }
    return (
      <Button size="small" type="primary" onClick={() => {
        onSelectAutomation(item.type)
      }}>
        <IntlMessages id="choiceAutomations.acticate" />
      </Button>
    )
  }

  const style = { width: 400, marginBottom: 30, marginRight: 30, display: 'inline-block' }
  let deactivateAddonsButton = false

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 40, fontWeight: 'normal' }}><IntlMessages id="choiceAutomations.selectAutomations" /></Typography.Title>

      {subscribedAutomation.length > 0 && (
        <>
          <Typography.Text style={{ marginBottom: 20, fontWeight: 700, display: 'block' }}><IntlMessages id="choiceAutomations.activeSubscribedAutomations" /></Typography.Text>
          <Row className="choice-automations">
            {automationsEventTypes.map(item => {
              if(subscribedAutomation.find(automation => automation.type === item.type)) {
                return (
                  <div
                    className={`automation-card ${item.url === 'coming-soon' ? '' : `${item.roles.includes(userStatus) ? 'hoverable' : ''}`}`}
                    style={(item.type === 'SENIORITY_ENTITLEMENT' && disableSenioirtyEnitlement) ? { opacity: 0.6 } : {opacity: 1}}
                    onClick={() => {
                      if(!item.roles.includes(userStatus)) {
                        return
                      }
                      if(item.url === 'coming-soon' || (item.type === 'SENIORITY_ENTITLEMENT' && disableSenioirtyEnitlement)) {
                        return
                      }
                      if (deactivateAddonsButton) {
                        deactivateAddonsButton = false
                        return
                      }
                      onSelectAutomation(item)
                    }}
                    key={item.type}
                  >
                    <div className="automation-card-header">
                      <div className="automation-card-header-icon">
                        {item.icon}
                      </div>
                      <div className="automation-card-header-extra">
                        <div style={{ marginBottom: 8 }}>
                          {automationCardExtra(item)}
                        </div>
                        <IntlMessages 
                          id="choiceAutomations.automationPrice"
                          values={{
                            price: '0.50',
                          }}
                        />
                        <br/>
                        <IntlMessages 
                          id="choiceAutomations.accessibleTo"
                          values={{
                            approver: (...chunks) => item.roles.includes('Approver') ? chunks : '',
                            admin: (...chunks) => item.roles.includes('Admin') ? chunks : '',
                            and: (...chunks) => item.roles.length === 2 ? chunks : '',
                            only: (...chunks) => item.roles.length === 1 ? chunks : '',
                          }}
                        />
                      </div>
                    </div>
                    <div className="automation-card-title"><IntlMessages id={`automations.${item.type}`} /></div>
                    <div className="automation-card-description">
                      {item.description}
                    </div>
                  </div>
                )
              }
            })}
          </Row>
        </>
      )}
      {subscribedAutomation.length > 0 && (
        <>
          <Typography.Text style={{ fontWeight: 700, display: 'block' }}><IntlMessages id="choiceAutomations.notActivatedAddons" /></Typography.Text>
          <Typography.Text style={{ marginBottom: 20, display: 'block' }}><IntlMessages id="choiceAutomations.automationPriceInfo" /></Typography.Text>
        </>
      )}
      <Row className="choice-automations">
        {automationsEventTypes.map(item => {
          if(!subscribedAutomation.find(automation => automation.type === item.type)) {
            return (
              <div
                className={`automation-card ${item.url === 'coming-soon' ? '' : 'hoverable'}`}
                onClick={() => {
                  if(item.url === 'coming-soon') {
                    return
                  }
                  if (deactivateAddonsButton) {
                    deactivateAddonsButton = false
                    return
                  }
                  onSelectAutomation(item)
                }}
                key={item.type}
              >
                <div className="automation-card-header">
                  <div className="automation-card-header-icon">
                    {item.icon}
                  </div>
                  <div className="automation-card-header-extra">
                    {automationCardExtra(item)}
                    <IntlMessages 
                      id="choiceAutomations.automationPrice"
                      values={{
                        price: '0.50',
                      }}
                    />
                    <br/>
                    {item.roles.length > 0 && 
                      <IntlMessages 
                        id="choiceAutomations.accessibleTo"
                        values={{
                          approver: (...chunks) => item.roles.includes('Approver') ? chunks : '',
                          admin: (...chunks) => item.roles.includes('Admin') ? chunks : '',
                          and: (...chunks) => item.roles.length === 2 ? chunks : '',
                          only: (...chunks) => item.roles.length === 1 ? chunks : '',
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="automation-card-title"><IntlMessages id={`automations.${item.type}`} /></div>
                <div className="automation-card-description">
                  {item.description}
                </div>
              </div>
            )
          }
        })}
        <Card
          key={'MISSING_AUTOMATION'}
          title={<IntlMessages id={'automations.requestAutomationTitle'} />}
          size="small"
          className="request-automation"
          style={style}
        >
          <Button type="primary" size="large" onClick={() => { 
            setShowRequestAutomation(true) 
            track('AUTOMATION_REQUEST_FORM_VIEWED',{})
          }}>
            <IntlMessages id={'automations.requestAnAutomation'} />
          </Button>
        </Card>
      </Row>
      <RequestAutomation 
        visible={showRequestAutomation} 
        onCancel={() => { setShowRequestAutomation(false) }} 
      />
      <Modal
        title={<IntlMessages id="choiceAutomations.comingSoonScheduleCallTitle" />}
        visible={scheduleACall}
        onCancel={() => setScheduleACall(false)}
        onOk={sendScheduleACall}
        okText={<IntlMessages id="choiceAutomations.scheduleCall" />}
        cancelText={<IntlMessages id="app.close" />}
        confirmLoading={isSending}
        style={{ top: 20 }}
      >
        <Form
          key="scheduleACall"
          form={form}
          name="scheduleACall"
          layout='vertical'
        >
          <IntlMessages id="choiceAutomations.comingSoonScheduleCall" />
          <Form.Item
            name="email"
            rules={[
              { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
            ]}
            style={{ marginTop: '20px' }}
            label={<IntlMessages id="components.feedbackModal.yourEmail" />}
          >
            <Input type="email" placeholder={formatMessage({ id: 'components.billing.emailPlaceholder' })} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ChoiceAutomations
