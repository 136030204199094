import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Breadcrumb, Modal, Typography, notification, Skeleton } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CalendarDaysIcon, ClipboardDocumentListIcon, GlobeAltIcon, ScaleIcon, ClockIcon, DocumentPlusIcon } from '@heroicons/react/24/solid'
import uniqBy from 'lodash/uniqBy'
import { API, graphqlOperation } from 'aws-amplify'
import {
  getAutomations,
  getLocationsApproverTeamsAndLabels,
  getExtendLocationsTeamsAndLabelsShort,
  getUsersForNotifictions
} from '../../../graphql/custom-queries'

import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { notificationStore } from '../../../context/notificationsContext/store'

import BlackoutPeriodForm from '../../../components/automation-black-out-period-form'
import SeniorityEntitlementForm from '../../../components/automation-seniority-entitlement-form'
import EntitlementByRoleForm from '../../../components/automation-entitlement-by-role-form'
import ChoiceAutomations from '../../../components/choice-automations'
import CircularProgress from '../../../components/circular-progress'
import AutomationFeedback from '../../../components/automation-feedback'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'

import { IGetTeamsShort } from '../../../types/teams'
import { IGetLocationsShort } from '../../../types/locations'
import { IGetLabelsShort } from '../../../types/labels'
import { IData } from '../../../types/data'
import { IAutomationFormPage } from '@vacationtracker/shared/types/automations'
import {
  IAutomations,
  IGetAutomations,
  IGetLocationsApproverTeamsAndLabelsShort,
  IGetExtendLocationsTeamsAndLabelsShort,
  IGetUsersForNotifications,
  ILeaveTypeListShort
} from '../../../types/custom-queries'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { AddonsStatus, IAddonsAutomations } from '@vacationtracker/shared/types/addons'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { uniq } from 'lodash'

export interface ISelectedDestination {
  type: string
  values: string | string[]
}

const AutomationsFormPage = ({ match }: IAutomationFormPage): React.ReactElement => {
  const { authUser: { id, role } } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const automationsEventTypes = [
    {
      type: 'BLACKOUT_PERIOD',
      roles: ['Admin', 'Approver'],
      url: 'blackout-period',
      icon: <CalendarDaysIcon />,
      description: formatMessage({ id: 'choiceAutomations.blackoutPeriodInfo' }),
    },
    {
      type: 'SENIORITY_ENTITLEMENT',
      roles: ['Admin'],
      url: 'seniority-entitlement',
      icon: <DocumentPlusIcon />,
      description: formatMessage({ id: 'choiceAutomations.seniorityEntitlementShortInfo' }),
    },
    {
      type: 'ENTITLEMENT_BY_ROLE',
      roles: ['Admin'],
      url: 'entitlement-by-role',
      icon: <ClipboardDocumentListIcon />,
      description: formatMessage({ id: 'choiceAutomations.enitlementByRoleShortInfo' }),
    },
    {
      type: 'PROBATION_PERIOD_LEAVE_RESTRICTION',
      roles: [],
      url: 'coming-soon',
      icon: <GlobeAltIcon />,
      description: formatMessage({ id: 'choiceAutomations.employmentLeaveRestrictionInfo' }),
    },
    {
      type: 'TEAM_LEAVE_BALANCING',
      roles: [],
      url: 'coming-soon',
      icon: <ScaleIcon />,
      description: formatMessage({ id: 'choiceAutomations.teamLeaveBalancingInfo' }),
    },
    {
      type: 'LEAVE_DURATION_LIMIT',
      roles: [],
      url: 'coming-soon',
      icon : <ClockIcon />,
      description: formatMessage({ id: 'choiceAutomations.leaveDurationLimitInfo' }),
    },
  ]

  const [selectAutomation, setSelectAutomation] = useState<string | null>(() => {
    if (match?.params?.automationType) {
      const automation = automationsEventTypes.find(automation => {
        return automation.url === match.params.automationType.replace(/_/g, '-')
      })
      return automation?.type as string
    }
    return null
  })
  
  const [isLoading, setIsLoading] = useState(true)
  const [teams, setTeams] = useState<IGetTeamsShort[] | ITeamShort[]>([])
  const [locations, setLocations] = useState<IGetLocationsShort[]>([])
  const [seniorityEntitlementsLocations, setSeniorityEntitlementsLocations] = useState<IGetLocationsShort[]>([])
  const [entitlementsByRoleLabels, setEntitlementsByRoleLabels] = useState<IGetLabelsShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypeListShort[]>([])
  const [disableSeniorityEntitlements, setDisableSeniorityEntitlements] = useState<boolean>(false)
  const [subscribedAutomation, setSubscribedAutomation] = useState<IAddonsAutomations[]>([])
  const [visibleSubscibeAutomation, setVisibleSubscibeAutomation] = useState<boolean>(false)
  const [visibleUnsubscribeAutomation, setVisibleUnsubscribeAutomation] = useState<boolean>(false)
  const [isLoadingButtonAutomation, setIsLoadingButtonAutomation] = useState<boolean>(false)
  const [selectedAutomationType, setSelectedAutomationType] = useState<string | null>()
  const [upcomingInvoices, setUpcomingInvoices] = useState<any>(null)

  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })

  const [totalUsers, setTotalUsers] = useState<number>(0)
  const automationId = match.params.id

  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const shouldEnableAutomation = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.automation, true)
  const shouldEnableBlackOutPeriod = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.blackOutPeriod, true)
  const shouldEnableAntitlementByRole =  useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.entitlementByRole, true)
  const shouldEnableSeniorityEntitlement =  useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.seniorityEntitlement, true)

  const amIAdmin = role.toLowerCase() === 'admin'
  const amIApprover = role.toLowerCase() === 'approver'

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [actionNotifications])

  useEffect(() => {
    setSubscribedAutomation(automation => {
      const newAutomation = [...automation]
      if (shouldEnableBlackOutPeriod || shouldEnableAutomation) {
        newAutomation.push({
          type: 'BLACKOUT_PERIOD',
          status: 'ACTIVE' as AddonsStatus,
          isFeatureFlag: true,
        })
      }
      if (shouldEnableAntitlementByRole || shouldEnableAutomation) {
        newAutomation.push({
          type: 'ENTITLEMENT_BY_ROLE',
          status: 'ACTIVE' as AddonsStatus,
          isFeatureFlag: true,
        })
      }
      if (shouldEnableSeniorityEntitlement || shouldEnableAutomation) {
        newAutomation.push({
          type: 'SENIORITY_ENTITLEMENT',
          status: 'ACTIVE' as AddonsStatus,
          isFeatureFlag: true,
        })
      }
      return uniqBy(newAutomation, 'type')
    })
  }, [shouldEnableAutomation, shouldEnableBlackOutPeriod, shouldEnableAntitlementByRole, shouldEnableSeniorityEntitlement])

  useEffect(() => {
    // Allow read only access to automation ENTITLEMENT_BY_ROLE for core plan
    if (!isLoading && selectAutomation === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
      return
    }
    // If you open a page directly from a URL and we don't have that automation, we have to redirect to page creation
    if (!isLoading && selectAutomation && !subscribedAutomation.find(automation => automation.type === selectAutomation)) {
      setSelectAutomation(null)
      history.push('/app/automations/create')
    }
  }, [isLoading])

  const getTotalUsers = async (locationIds: string[] = [], teamIds: string[] = [], labelIds: string[] = []) => {
    const usersResponse = await API.graphql(graphqlOperation(getUsersForNotifictions, { locationIds, teamIds, labelIds })) as IData<IGetUsersForNotifications>
    setTotalUsers(usersResponse.data.getUsersFiltered.totalUsers)
  }
  
  const fetchData = async () => {
    try {
      let response
      if (amIAdmin) {
        response = await API.graphql(graphqlOperation(getExtendLocationsTeamsAndLabelsShort)) as IData<IGetExtendLocationsTeamsAndLabelsShort>
        setTeams(response.data.getTeamList as IGetTeamsShort[])
      }
      if (amIApprover) {
        response = await API.graphql(graphqlOperation(getLocationsApproverTeamsAndLabels, { id } )) as IGetLocationsApproverTeamsAndLabelsShort
        setTeams(response.data.getUser.approverToTeams as ITeamShort[])
      }

      if (!automationId) {
        getTotalUsers()
      }
      const automations = await API.graphql(graphqlOperation(getAutomations)) as IData<IGetAutomations>
      const subscribedAutomation = automations.data.getAddonsSubscription?.automations || []

      setSubscribedAutomation(automation => {
        return uniqBy([...subscribedAutomation, ...automation], 'type')
      })
      setLeaveTypes(automations.data.getLeaveTypeList.sort((a, b) => a.position < b.position ? -1 : 1) as ILeaveTypeListShort[])
      const seniorityEntitlementsData = automations.data.getAutomations.filter(automation => automation.automationType === 'SENIORITY_ENTITLEMENT') as IAutomations[]
      setDisableSeniorityEntitlements(() => {
        if (seniorityEntitlementsData.length === 0) {
          return false
        }
        if (seniorityEntitlementsData[0]?.locations && seniorityEntitlementsData[0]?.locations.length === 0) {
          return true
        }
        let locationsWithAutomations = 0
        seniorityEntitlementsData.map(() => {
          locationsWithAutomations++
        })
        if (locationsWithAutomations === locations.length) {
          return true
        }
        return false
      })
      
      let optionLocations: IGetLocationsShort[] = []

      if (seniorityEntitlementsData.length === 0) {
        optionLocations = response.data.getLocationList
      } else {
        optionLocations = response.data.getLocationList.filter(location => {
          return !seniorityEntitlementsData.some(automation =>
            automation.locations && automation.locations.includes(location.id)
          )
        })
      }
      
      const entitlementsByRoleData = automations.data.getAutomations.filter(automation => automation.automationType === 'ENTITLEMENT_BY_ROLE') as IAutomations[]
      let optionLabels: IGetLabelsShort[] = []

      if (entitlementsByRoleData.length === 0) {
        optionLabels = response.data.getLabels
      } else {
        optionLabels = response.data.getLabels.filter(label => {
          return entitlementsByRoleData.every(automation => automation.labelId !== label.id)
        })
      }

      setSeniorityEntitlementsLocations(uniqBy(optionLocations, 'id'))
      setLocations(response.data.getLocationList as IGetLocationsShort[])
      setEntitlementsByRoleLabels(uniqBy(optionLabels, 'id'))
      setLabels(response.data.getLabels as IGetLabelsShort[])

      setIsLoading(false)

      // Allow read only access to automation ENTITLEMENT_BY_ROLE for core plan
      if (selectAutomation === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
        return
      }

    } catch (err) {
      console.log('ERROR FETCH DATA', err)
    }
  }

  const handleFilter = (filterArg: IFilter) => {
    setFilter(filterArg)
    getTotalUsers(filterArg.locationIds, filterArg.teamIds, filterArg.labelIds)
  }

  const onSelectAutomation = async (automationType) => {
    const automation = subscribedAutomation.find(automation => automation.type === automationType.type)
    // Allow read only access to automation ENTITLEMENT_BY_ROLE for core plan
    if (!shouldEnableLabels && automationType.type === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
      setSelectAutomation(automationType.type as string)
      history.push(`/app/automations/${automationType.url}/create`)
      return
    }
    if (!automation || automation.status === 'CANCEL_AT_PERIOD_END') {
      if (
        shouldEnableAutomation ||
        (shouldEnableBlackOutPeriod && automationType.type === 'BLACKOUT_PERIOD') ||
        (shouldEnableAntitlementByRole && automationType.type === 'ENTITLEMENT_BY_ROLE') ||
        (shouldEnableSeniorityEntitlement && automationType.type === 'SENIORITY_ENTITLEMENT')
      ) {
        setSelectAutomation(automationType.type as string)
        history.push(`/app/automations/${automationType.url}/create`) 
      }

      setVisibleSubscibeAutomation(true)
      setSelectedAutomationType(automationType.type)
      const info = await API.post('StripeApi', '/upcoming-invoice-information', {
        body: {
          addonAutomationType: automationType.type,
          date: Math.ceil(new Date().getTime() / 1000),
        },
      })
      setUpcomingInvoices(info)
    } else {
      setSelectAutomation(automationType.type as string)
      history.push(`/app/automations/${automationType.url}/create`)
    }
  }

  const deactivateAddons = (automationType: string) => {
    setSelectedAutomationType(automationType)
    setVisibleUnsubscribeAutomation(true)
  }

  const automationAddonsUpdate = async (isActive: boolean) => {
    if (!amIAdmin) return
    try {
      setIsLoadingButtonAutomation(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'AUTOMATION_ADDONS_UPDATED',
          eventGroup: 'BILLING',
          automationType: selectedAutomationType,
          isActive,
        },
      })
      setVisibleUnsubscribeAutomation(false)
      setVisibleSubscibeAutomation(false)
      setIsLoadingButtonAutomation(false)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isActive ? 'automations.activateInProgress' : 'automations.deactivateInPgoress' }),
        description: formatMessage({ id: isActive ? 'automations.activateInProgressDescription' : 'automations.deactivateInPgoressDescription' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
      setIsLoadingButtonAutomation(false)
    }
  }
  
  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }

  return <div className='main-content'>
    <div className="main-content-header">
      <div className="main-content-header-title">
        {automationId ?
          <IntlMessages id="automations.update" /> :
          <IntlMessages id="automations.add" />
        }
        <AutomationFeedback buttonStyle={{marginLeft: '5px'}} page={automationId ? 'automation-page-update' : 'automation-page-create'} />
      </div>
      <div className="main-content-header-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/app/automations"><IntlMessages id="app.automations" /></Link>
          </Breadcrumb.Item>
          {!selectAutomation ?
            <Breadcrumb.Item>
              <IntlMessages id="automations.add" />
            </Breadcrumb.Item>
            :
            <Breadcrumb.Item>
              <IntlMessages id={`automations.${selectAutomation}`} />
            </Breadcrumb.Item>
          }
        </Breadcrumb>
      </div>
    </div>
    <div className="main-content-body">
      {isLoading ?
        <CircularProgress /> :
        <>
          {!selectAutomation &&
            <ChoiceAutomations 
              subscribedAutomation={subscribedAutomation}
              onSelectAutomation={onSelectAutomation} 
              automationsEventTypes={automationsEventTypes}
              disableSenioirtyEnitlement={disableSeniorityEntitlements}
              formatMessage={formatMessage}
              deactivateAddons={deactivateAddons}
              userStatus={amIApprover ? 'Approver' : 'Admin'}
            />
          }
          {selectAutomation === 'BLACKOUT_PERIOD' &&
            <BlackoutPeriodForm
              automationId={automationId}
              teams={teams}
              locations={locations}
              leaveTypes={leaveTypes}
              totalUsers={totalUsers}
              labels={labels}
              filter={filter}
              handleFilter={handleFilter}
              shouldEnableLabels={shouldEnableLabels}
              backToAutomations={() => history.push('/app/automations')}
              amIAdmin={amIAdmin}
            />
          }
          {selectAutomation === 'SENIORITY_ENTITLEMENT' &&
            <SeniorityEntitlementForm
              automationId={automationId}
              optionLocations={seniorityEntitlementsLocations}
              locations={locations}
              leaveTypes={leaveTypes}
              backToAutomations={() => history.push('/app/automations')}
              hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
            />
          }
          {selectAutomation === 'ENTITLEMENT_BY_ROLE' &&
            <EntitlementByRoleForm
              automationId={automationId}
              labels={labels}
              optionLabels={entitlementsByRoleLabels}
              leaveTypes={leaveTypes}
              backToAutomations={() => history.push('/app/automations')}
              isCorePlan={authCompany?.plan === SubscriptionPlanEnum.core}
              hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
              shouldEnableLabels={shouldEnableLabels}
            />
          }
        </>
      }
    </div>
    <Modal
      title={<IntlMessages id="automations.subscripteToAddOns" />}
      visible={visibleSubscibeAutomation}
      onCancel={() => {
        setVisibleSubscibeAutomation(false)
        setSelectedAutomationType(null)
      }}
      onOk={() => {
        if (amIAdmin) {
          automationAddonsUpdate(true)
        } else {
          setVisibleSubscibeAutomation(false)
        }
      }}
      okText={amIAdmin ? <IntlMessages id="app.confirm" /> : <IntlMessages id="app.ok" />}
      cancelText={<IntlMessages id="app.close" />}
      confirmLoading={isLoadingButtonAutomation}
    >
      {amIAdmin ? 
        <IntlMessages id="automations.addAddonsDescirptionForAdmin"
          values={{ 
            prorateAmount: 
              <span>
                {!upcomingInvoices ? <Skeleton.Input active style={{ height: 24, minWidth: 80, width: 80 }} /> : upcomingInvoices?.prorateAmount}
              </span>,
            nextInvoiceAmount: 
              <span>
                {!upcomingInvoices ? <Skeleton.Input active style={{ height: 24, minWidth: 80, width: 80 }} /> : upcomingInvoices?.nextInvoiceAmount}
              </span>,
          }}
        /> : 
        <IntlMessages id="automations.addAddonsDescirptionForApprover" />
      }
    </Modal>
    <Modal
      title={<IntlMessages id="automations.unsubscripteAddOns" />}
      visible={visibleUnsubscribeAutomation}
      onCancel={() => {
        setVisibleUnsubscribeAutomation(false)
        setSelectedAutomationType(null)
      }}
      onOk={() => {
        if (amIAdmin) {
          automationAddonsUpdate(false)
        } else {
          setVisibleUnsubscribeAutomation(false)
        }
      }}
      okText={amIAdmin ? <IntlMessages id="automations.removeAddons" /> : <IntlMessages id="app.ok" />}
      cancelText={<IntlMessages id="app.close" />}
      confirmLoading={isLoadingButtonAutomation}
    >
      {amIAdmin ? <IntlMessages id="automations.removeAddonsDescirptionForAdmin" /> : <IntlMessages id="automations.removeAddonsDescirptionForApprover" />}
    </Modal>
  </div>
}

export default AutomationsFormPage