import React, { ReactElement } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'

import YoutubeEmbed from '../youtube-embed'

const { Text, Title } = Typography

interface IEmptyDataProps {
  title: ReactElement
  subTitle: ReactElement
  helpDeskLink?: string
  buttonText?: ReactElement
  buttonLink?: string
  helpDesText?: ReactElement
  videoId?: string
  Action?: ReactElement
}

const EmptyData = ({
  title,
  subTitle,
  buttonText,
  buttonLink,
  helpDeskLink,
  helpDesText,
  videoId,
  Action,
}: IEmptyDataProps): React.ReactElement => {

  const actionsStyle: {
    marginBottom: number
    marginTop: number
    display?: string
  } = {
    display: 'flex',
    marginTop: 30,
    marginBottom: 30,
  }

  if (buttonLink && buttonText) {
    delete actionsStyle.display
  }

  return (
    <Row className="empty-data" gutter={16}>
      <Col xs={24} sm={24} md={24} lg={24} xl={videoId ? 12 : 24} xxl={videoId ? 14 : 24}>
        <Title style={{ marginTop: 20 }} level={4}>{title}</Title>
        <Text>{subTitle}</Text>
        <div style={actionsStyle}>
          {buttonLink && buttonText && <Link to={buttonLink}>
            <Button type="primary">
              {buttonText}
            </Button>
          </Link>}
          {Action && Action}
          {helpDeskLink &&
            <a href={helpDeskLink} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10 }}>
              <Button type="default">
                {helpDesText}
              </Button>
            </a>
          }
        </div>
      </Col>
      {videoId &&
        <Col xs={24} sm={24} md={24} lg={16} xl={12} xxl={10}>
          <YoutubeEmbed embedId={videoId} autoplay={true} mute={true} />
        </Col>
      }
    </Row>
  )
}

export default EmptyData