import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'
import { Breadcrumb, Tabs, notification, Alert, Typography, Checkbox, Tooltip } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { API, graphqlOperation } from 'aws-amplify'
import { getLocationByIdLeaveTypesAllLeavePolicies } from '../../../../graphql/custom-queries'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { useAppSelector } from '../../../../store/hooks'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import LocationGeneral from '../../../../components/location-general'
import LocationHolidays from '../../../../components/location-holidays'
import LocationLeavePolicies from '../../../../components/location-leave-policies'

import {
  IGetLocationLeaveTypesAllLeavePoliciesData,
  IGetLocationList,
  IGetLeaveTypeList,
  IGetLocationGeneral,
  ILocationGeneralLeavePolicies,
  IHolidaysByYear
} from '../../../../types/locations'
import { IHoliday } from '../../../../types/holidays'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { selectLocaleSlice } from '../../../../store/locale-slice'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const { TabPane } = Tabs
const { Text } = Typography

interface ILocationPage {
  match: {
    params: {
      id: string
      type: string
    }
  }
}

const LocationPage = ({ match }: ILocationPage): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { locale } = useAppSelector(selectLocaleSlice)

  const [isLoading, setIsLoading] = useState(true)
  const [showRenameWarning, setShowRenameWarning] = useState(false)
  const [location, setLocation] = useState<IGetLocationGeneral>({
    id: '',
    name: '',
    workWeek: [],
    timestamp: '',
    resetQuotas: '',
    rolloverNeverExpireDays: true,
    firstDayOfWeek: 0,
    yearStartDay: 1,
    yearStartMonth: 1,
    timezone: getTimeZoneOrDefaultToUtc(),
    users: [],
    holidays: [],
    holidaysAutoImport: [],
    isDefault: false,
    leavePolicies: [],
  })
  const [locationHolidays, setLocationHolidays] = useState<IHolidaysByYear[]>()
  const [leavePolicies, setLeavePolicies] = useState<ILocationGeneralLeavePolicies[]>([])
  const [allLeavePolicies, setAllLeavePolicies] = useState<ILocationGeneralLeavePolicies[]>([])
  const [leaveTypes, setLeaveTypes] = useState<IGetLeaveTypeList[]>([])
  const [selectedTab] = useState<string>(() => {
    return match.params.type || 'general'
  })
  const [autoImportHolidays, setAutoImportHolidays] = useState(false)
  const [autoImportCheckboxTouched, setAutoImportCheckboxTouched] = useState(false)

  useEffect(() => {
    fetchLocation(match.params.id)
  }, [match.params.id])

  useEffect(() => {
    fetchLocation(match.params.id)
  }, [actionNotifications])

  useEffect(() => {
    const shouldRename = location.name.includes('<Autodetected for Departments')
    if (shouldRename) {
      setShowRenameWarning(true)
    }
  }, [location])

  const fetchLocation = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getLocationByIdLeaveTypesAllLeavePolicies, { id })) as IGetLocationLeaveTypesAllLeavePoliciesData

      // Set leave types which not exist in this location
      setLeaveTypes(
        response.data.getLeaveTypeList
          .filter(lt => lt.isActive)
          .filter(({ id: leaveTypeId }) => {
            return !response.data.getLocation.leavePolicies
              .some(({ leaveType: locationLeaveType }) => locationLeaveType.id === leaveTypeId)
          })
          .sort((a, b) => a.position < b.position ? -1 : 1)
      )
      setLocation(response.data.getLocation)
      !autoImportCheckboxTouched && setAutoImportHolidays(response.data.getLocation.holidaysAutoImport?.length > 0 ?? false)
      const leavePoliciesData = response.data.getLocation.leavePolicies
        .filter((leavePolicy) => !leavePolicy.leaveType.deleted)
        .map((leavePolicy) => {
          return {
            ...leavePolicy,
            index: leavePolicy.leaveType.position,
            key: leavePolicy.leaveType.position,
          }
        })
        .filter(lp => lp.leaveType.isActive)
      setLeavePolicies(leavePoliciesData.sort((a,b) => a.leaveType.position - b.leaveType.position))

      let allLeavePoliciesData: ILocationGeneralLeavePolicies[] = []
      response.data.getLocationList.forEach((loc: IGetLocationList) => {
        const locationLeavePolicies: ILocationGeneralLeavePolicies[] = loc.leavePolicies
          .filter(lp => lp.isActive)
          .filter(lp => !lp.leaveType.deleted)
          .filter(lp => lp.leaveType.isActive)
          .map(lp => {
            lp.leaveType.name = `${lp.leaveType.name} (${loc.name})`
            return lp
          })

        allLeavePoliciesData = allLeavePoliciesData.concat(locationLeavePolicies)
      })
      setAllLeavePolicies(sortBy(allLeavePoliciesData, ['leaveType.position', 'leaveType.name']))

      const locationHolidaysData: IHolidaysByYear[] = response.data.getLocation.holidays
      response.data.getLocation.holidays.forEach((holidayData, index) => {
        const holidaysArray: IHoliday[] = []
        holidayData.holidays.forEach((single => {
          if (single.multiDayId !== null) {
            if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
              const filtered = locationHolidaysData[index].holidays
                .filter(holiday => holiday.multiDayId === single.multiDayId)
                .sort((a: IHoliday, b: IHoliday) => moment(a.date).format() < moment(b.date).format() ? -1 : 1)
              holidaysArray.push({ ...filtered[0], endDate: filtered[filtered.length - 1].date })
            }
          } else {
            delete single.multiDayId
            holidaysArray.push(single)
          }
        }))
        locationHolidaysData[index].holidays = holidaysArray
      })
      setLocationHolidays(locationHolidaysData)
      setIsLoading(false)
    } catch (err) { console.log('error fetching location', err) }
  }

  const onSaveHolidays = async (holidays) => {
    
    let brokenHolidays = false    
    holidays.holidays.forEach((holiday: IHoliday) => {
      const holidayYear = parseInt(holiday.date.substring(0,4))
      if (!holiday.multiDayId && holidayYear !== parseInt(holidays.year)){
        brokenHolidays = true
      }
    })
    if (brokenHolidays) {
      notification.open({
        key: 'test',
        message: formatMessage({ id: 'location.holidays.brokenHolidays' }),
        icon: (<ExclamationCircleOutlined />),
        duration: 0,
      })
      throw new Error()
    }
    
    const response = await API.post('CoreEvent', '/core/event', {
      body: {
        eventType: 'LOCATION_YEAR_HOLIDAYS_CHANGED',
        eventGroup: 'LOCATION',
        locationId: match.params.id,
        holidays: holidays.holidays,
        year: holidays.year,
      },
    })
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'location.holidays.updateInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })

    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const turnOffAutoImportHolidays = async (e: CheckboxChangeEvent) => {
    setAutoImportHolidays(e.target.checked)
    setAutoImportCheckboxTouched(true)
    if (e.target.checked === false) {
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED',
          eventGroup: 'LOCATION',
          locationId: match.params.id,
          holidays: [],
        },
      })      
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'location.holidays.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId, response.correlationId ])
    }
  }

  const operations = <Link to={`/app/settings/locations/${match.params.id}/edit`}><IntlMessages id="location.editLocation" /></Link>

  return (
    <div className='main-content'>
      {isLoading ? <CircularProgress /> :
        <div>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span><IntlMessages id="app.location" />: {location.name}</span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/app/settings/locations"><IntlMessages id="app.locations" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{location.name}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {showRenameWarning &&
            <Alert
              message="Please rename automatically generated location name *"
              description={
                <Fragment>
                  <Text>We automatically created locations for your organization by grouping the following teams: </Text>
                  <Text strong>{location.name.replace('<Autodetected for Departments: ', '').replace('>', '')}</Text>.
                  <Text> All leave policies are transferred, you just need to rename your locations.</Text>
                </Fragment>
              }
              type="warning"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          }
          <div className="main-content-body">
            <div className="location-body">
              {leavePolicies.length === 0 &&
                <Alert
                  message={<><ExclamationCircleOutlined style={{ color: 'red' }} /> <IntlMessages id="location.leavePoliciesNotExistInfo" /></>}
                  type="error"
                  closable
                />
              }
              <Tabs defaultActiveKey={selectedTab} tabBarExtraContent={operations}>
                <TabPane tab={<IntlMessages id="app.general" />} key="general">
                  <LocationGeneral location={location} locale={locale.locale || LocaleEnum.en} />
                </TabPane>
                <TabPane
                  tab={<><IntlMessages id="locations.leavePolicies" /> {leavePolicies.length === 0 && <ExclamationCircleOutlined style={{ color: 'red' }} /> }</>}
                  key="leave-policies"
                  className="leave-typades assign-leave-policies"
                >
                  <LocationLeavePolicies
                    leavePoliciesList={leavePolicies}
                    location={location}
                    leaveTypes={leaveTypes}
                    allLeavePolicies={allLeavePolicies}
                    hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
                  />
                </TabPane>
                <TabPane tab={<IntlMessages id="app.holidays" />} key="holidays" className="holidays">
                  <div>{autoImportHolidays
                    ? <><Checkbox checked={autoImportHolidays} onChange={turnOffAutoImportHolidays}>
                    </Checkbox>&nbsp;<IntlMessages id="holidays.importHolidaysAutomaticallyTurnedOn" />
                    <Tooltip className="info-tooltip" title={<IntlMessages id="holidays.importHolidaysAutomaticallyTooltip" />}>
                      <InfoCircleOutlined />
                    </Tooltip></>
                    : <IntlMessages id="holidays.importHolidaysAutomaticallyTurnedOff"/>}</div>
                  <LocationHolidays
                    holidays={locationHolidays}
                    onSaveHolidays={onSaveHolidays}
                    locationName={location.name}
                    locationId={location.id}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default withRouter(LocationPage)
