import React from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Form, Input, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'

import { IGetUserListShort } from '../../types/teams'

interface ITeamForm {
  team: {
    id: string
    name: string
    isDefault: boolean
    users: string[]
  }
  users: IGetUserListShort[]
  totalTeams: number
}

const TeamForm = ({ team, users, totalTeams }: ITeamForm): React.ReactElement => {
  const { formatMessage } = useIntl()

  const isRemovalFromTeamDisabled = (userId: string) => {
    if (!team.isDefault) {
      return false
    }
    return team.users.find(id => id === userId) !== undefined
  }

  return (
    <>
      <h4><IntlMessages id="components.teamForm.generalSettings" /></h4>
      <Form.Item
        name="name"
        label={<IntlMessages id="app.name" />}
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Input maxLength={100} style={{ width: 300 }} placeholder={`${formatMessage({ id: 'components.teamForm.pleaseEnterTeamName' })}`} data-qa='department-name' />
      </Form.Item>
      <Form.Item
        name="users"
        label={<>
          <span>{formatMessage({ id: 'app.users' })}</span>
          {team.isDefault && <Tooltip className='info-tooltip' title={<IntlMessages id="teams.removeUserInfo" />} ><InfoCircleOutlined/></Tooltip>}
        </>}
      >
        <Select
          mode="multiple"
          disabled={(team.id && totalTeams === 1) ? true : false}
          filterOption={filterOptions}
        >
          {users.map(user => <Select.Option disabled={isRemovalFromTeamDisabled(user.id)} key={user.id} value={user.id}>{user.name}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="approvers"
        label={`${formatMessage({ id: 'app.role.approvers' })}:`}
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Select
          mode="multiple"
          filterOption={filterOptions}
          data-qa='teams-approvers'
        >
          {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
        name="isDefault"
        label={`${formatMessage({ id: 'components.teamForm.isDefault' })}:`}
        valuePropName="checked"
        extra={`${formatMessage({ id: 'components.teamForm.isDefaultExtra' })}:`}
      >
        <Checkbox disabled={team.isDefault || (team.id && totalTeams === 1) ? true : false}/>
      </Form.Item>
    </>
  )
}

export default TeamForm
