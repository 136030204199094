import { filterXSS } from 'xss'
import sanitizeHtml from 'sanitize-html'

export function preventXss(value: string, options = {}): string {
  const excelFormulaRegex = /^([=+\-@])(.*)$/gi // Formula starts with +, -, = or @
  const filtered = filterXSS(value, {
    ...options,
    whiteList: {},
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['script'],
  })
  if (excelFormulaRegex.test(filtered)) {
    return filtered.replace(excelFormulaRegex, '\'$1$2\'')
  }
  return filtered
}

export function sanitizeInput(value: string) {
  const config = {
    allowedTags: ['&'],
    allowedAttributes: {},
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [],
    allowProtocolRelative: false,
    enforceHtmlBoundary: false,
    parseStyleAttributes: false,
    filterXSS: true,
  }
  const sanitized = sanitizeHtml(value, config)
  return sanitized
    .replace(/^[^a-zA-Z](.*)$/, '$1') // Remove leading non-alphabetic characters
    .replace(/:/g, '\\$&')
    .replace(/[";]/g, '')   // Remove double quotes dots and semicolons
    .replace(/</g, '')      // Remove less-than symbols
    .replace(/>/g, '')      // Remove greater-than symbols
    .replace(/\s+/g, ' ')   // Replace multiple spaces with a single space
    .replace(/&amp/g, '&') // allow the & character
    .trim()                 // Trim leading and trailing spaces
}