import { User } from '@microsoft/microsoft-graph-types'

/*
 * cf563174-5c6d-4b96-bcb6-0581ea498bdd -> microsoft-cf563174-5c6d-4b96-bcb6-0581ea498bdd
 */
/**
 @deprecated
 */
export function vtUserId(originalId?: string): string {
  return `microsoft-${originalId}`
}

export function getName(user: User): string {
  if (user.displayName) {
    return user.displayName
  }

  if (user.givenName || user.surname) {
    return `${user.givenName || ''} ${user.surname || ''}`.trim()
  }

  return ''
}

export function getEmail(user: User): string {
  return user.mail ?? user.userPrincipalName ?? ''
}
