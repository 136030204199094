import React, { useEffect, useState } from 'react'
import { Spin, Typography } from 'antd'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { History } from 'history'
import IntlMessages from '../../util/IntlMessages'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { useAppDispatch } from '../../store/hooks'
import { setAuthCompany } from '../../store/auth-company-slice'
import { setUserId } from '../../store/user-id-slice'
import { setAuthUser } from '../../store/auth-user-slice'
import { logout } from '../../services/auth/logout-handler'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { setLocale } from '../../store/locale-slice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { wait } from '@vacationtracker/shared/functions/wait'
import { FrontendUrls } from '../../types/urls'

const { Text } = Typography

interface IConnect {
  onStateChange: Function
  match: {
    params: {
      code: string
    }
  }
  location: {
    pathname: string
    search: string
  }
  history: History
}

const ExternalConnect = ({ onStateChange, location, history, match }: IConnect): React.ReactElement => {
  const dispatch = useAppDispatch()
  const [signinInProgress, setSigninInProgress] = useState(true)
  const [parseError, setParseError] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)


  useEffect(() => {
    if(!match.params.code) {
      history.push(FrontendUrls.signup)
    } else {
      getValueFromCode(match.params.code, location.search)
    }
  }, [match.params.code])

  const getValueFromCode = (code: string, qr: string) => {
    let query = '/'
    try {
      const values = JSON.parse(window.atob(code))
      if (values.userId, values.token && values.page) {
        if (values.leaveRequestId) {
          query = qr.length > 0 ? `${qr}&leaveRequestId=${values.leaveRequestId}` : `?leaveRequestId=${values.leaveRequestId}`
        }
        authSignin(values.userId, values.token, values.page, query)
      }
    } catch (error) {
      console.log('error', error)
      setParseError(true)
      setSigninInProgress(false)
    }
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        dispatch(setAuthCompany(response.data.getCompany))
        dispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.log('ERROR GET COMPANY AND USER', error)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange,
          history,
          reduxDispatch: dispatch,
          userId: id,
        })
      } else if (location.pathname !== '/external-connect') {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const authSignin = async (username: string, oneTimeToken: string, page: string, query: string) => {
    try {
      const signInResponse = await Auth.signIn(username)
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
        const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, oneTimeToken, { loginType: 'oneTimeToken' })
        localStorage.setItem('userId', cognitoResponse.username)
        dispatch(setUserId(cognitoResponse.username))

        await getCompanyAndUser(cognitoResponse.username)

        onStateChange('signedIn')
        history.push(`/app/${page}${query}`)
      }
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        setLoginFailed(true)
        setSigninInProgress(false)
        await wait(10000)
        history.push(`${FrontendUrls.signin}?redirect=/app/${page}${query}`)
      }
    }
  }

  return (
    <div className="auth-wrap">
      <div className="auth-container">
        <div className="auth-main-container">
          {signinInProgress &&
            <>
              <Spin spinning={signinInProgress}>
              </Spin>
              <Text style={{ textAlign: 'center' }}><IntlMessages id="externalConnect.pleaseWait" /></Text>
            </>
          }
          {parseError && <Text style={{ marginTop: 20, textAlign: 'center' }} type="danger"><IntlMessages id="externalConnect.parseError" /></Text> }
          {loginFailed && <Text style={{ marginTop: 20, textAlign: 'center' }} type="danger"><IntlMessages id="externalConnect.faildLogin" /></Text> }
        </div>
      </div>
    </div>
  )
}

export default ExternalConnect
