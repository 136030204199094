import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  Breadcrumb,
  Form,
  Button,
  Checkbox,
  notification,
  Tooltip,
  Input,
  Divider,
  Typography,
  Select,
  DatePicker,
  Space,
  Radio,
  Modal
} from 'antd'
import {
  WarningOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SlackOutlined,
  WindowsFilled,
  GoogleOutlined,
  MailOutlined
} from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import moment, { Moment, MomentInput } from 'moment'
import { useSelector } from 'react-redux'
import { isBoolean, isEqual, isNull, omit, sortBy } from 'lodash'
import { captureException } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { track } from '../../../services/analytics/analytics'
import { capitalize } from 'lodash'
import { addMinutes, isAfter } from 'date-fns'

import { getCompanyGeneral, getGoogleScopes } from '../../../graphql/custom-queries'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../../services/auth/slack/slack'
import { GoogleAuth } from '../../../services/auth/google/google'
import { AUTOMATIC_USERS_IMPORT_LIMIT, GOOGLE_READ_USER_PERMISSION } from '../../../constants'
import { openSupportChat } from '../../../util/open-support-chat'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { selectFeatureFlagsSlice } from '../../../store/feature-flags-slice'
import { selectAuthCompanySlice, setAuthCompany, isMicrosoftPayment } from '../../../store/auth-company-slice'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { notificationStore } from '../../../context/notificationsContext/store'
import { getIntegrationPlatformName }  from '@vacationtracker/shared/functions/get-integration-platform-name'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import ConfigureImportUsersOptionsForm, {
  getWelcomeMessageOptionsDefault,
  getDontSendWelcomeOption
} from '../../../components/configure-import-users-options-form'
import showConfirmCompanySettings from '../../../components/confirm-company-settings-and-import-users-update-modal'
import PlatformSwitchModal from '../../../components/platform-switch-modal'

import { ICompanySettingsBody, ICompanySettingsFormValues } from './types'
import { IGetCompanyGeneral, SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { WelcomeMessageOptions } from '../../Users/ImportUsersPage/types'
import { IGetLocationsShort } from '../../../types/locations'
import { IGetTeamsShort } from '../../../types/teams'
import { ISlackUser } from '@vacationtracker/shared/types/repository/slack-api-repository'
import { IData } from '../../../types/data'
import { IGetGoogleScopes } from '../../../types/custom-queries'
import { ConfigureImportSourceEnum, ISelectedLeaveType } from '../../../components/configure-import-users-options-form/types'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { CALCULATION_START_YEAR_LIMIT } from '../../../constants'
import { Platform, allPlatforms } from '@vacationtracker/shared/data/platforms'

const { Text, Paragraph } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) {
  throw new Error('Client IDs are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const iconPadding = {
  paddingRight: '5px',
}

const platformIconMap = {
  slack: <SlackOutlined style={iconPadding}/>,
  microsoft: <WindowsFilled style={iconPadding}/>,
  google: <GoogleOutlined style={iconPadding}/>,
  email: <MailOutlined style={iconPadding}/>,
}

const GeneralPage: React.FC = () => {
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const history = useHistory()

  const supportLink = 'https://vacationtracker.crisp.help/en/article/google-workspace-editing-permissions-15pdkie/'
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const shouldEnableHours = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.hours)
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0)
  const shouldEnableCalculationStartYear = useShouldEnableFeatures('Core', FeatureFlagEnum.calculationStartYear, true)

  const [submitLoader, setSubmitLoader] = useState(false)
  const [msTenantId, setMsTenantId] = useState('')
  const [showEnableGoogleApiModal, setShowEnableGoogleApiModal] = useState(false)
  const [companyValue, setCompanyValue] = useState<{
    importUsersAutomatically: boolean
    aiBotHelper: boolean
    name: string
    announceNewUsers?: boolean
    userEndDate?: boolean
    hourlyLeaveAccounting?: boolean
    contactEmail: string
    shouldProrate?: boolean
    proratedLeaveTypes?: string[]
    shouldImportGuestUsers?: boolean
    defaultDepartmentForAutomaticImport?: string
    defaultLocationForAutomaticImport?: string
    calculationStartYear: Moment | string | null
  }>({
    importUsersAutomatically: authCompany?.importUsersAutomatically || false,
    aiBotHelper: authCompany?.aiBotHelper || false,
    userEndDate: Boolean(authCompany?.userEndDate),
    announceNewUsers: Boolean(authCompany?.announceNewUsers) || false,
    name: authCompany?.name || '',
    contactEmail: authCompany?.contactEmail || '',
    shouldProrate: Boolean(authCompany?.shouldProrate),
    proratedLeaveTypes: authCompany?.proratedLeaveTypes,
    shouldImportGuestUsers: authCompany?.shouldImportGuestUsers,
    defaultDepartmentForAutomaticImport: authCompany?.defaultDepartmentForAutomaticImport,
    defaultLocationForAutomaticImport: authCompany?.defaultLocationForAutomaticImport,
    calculationStartYear: authCompany?.calculationStartYear || moment(),
  })
  const [isLoadingTotalNumberOfUsers, setIsLoadingTotalNumberOfUsers] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [correlationId, setCorrelationId] = useState<string | null>(null)
  const [importUsersAutomatically, setImportUsersAutomatically] = useState(authCompany?.importUsersAutomatically)
  const [aiBotHelper, setEnableBotHelper] = useState<boolean>(false)
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState(false)
  const [adminConsentUrl, setAdminConsentUrl] = useState<null | string>(null)
  const [isMsAdminConsentRequired, setMsAdminConsentRequired] = useState(false)
  const [showAdminConsentWarning, setShowAdminConsentWarning] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [welcomeMessageOpts, setWelcomeMessageOpts] = useState<WelcomeMessageOptions[]>(getWelcomeMessageOptionsDefault(formatMessage))
  const [shouldShowAnnuonce, setShouldShowAnnouncement] = useState(!isMicrosoftBillingPayment)
  const [shouldShowDepartment, setShouldShowDepartment] = useState(false)
  const [shouldShowLocation, setShouldShowLocation] = useState(false)
  const [shouldShowProrate, setShouldShowProrate] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState<IGetTeamsShort | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<IGetLocationsShort | null>(null)
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<ISelectedLeaveType[]>([])
  const [platformToSwitch, setPlatformToSwitch] = useState<typeof allPlatforms[number] | null>(null)
  const [showSwitchPlatformModal, setShowSwitchPlatformModal] = useState(false)
  const [showGoogleLoginRequiredModal, setShowGoogleLoginRequiredModal] = useState(false)
  const [shouldEnableImportUsersAutomaticallyForLargeCompanies, setShouldEnableImportUsersAutomaticallyForLargeCompanies] = useState(true)

  const dispatch = useAppDispatch()

  const radioOptions = [{
    value: true, label: formatMessage({ id: 'app.yes' }),
  }, {
    value: false, label: formatMessage({ id: 'app.no' }),
  }]

  useEffect(() => {
    if (authCompany?.platform === 'email') {
      setShouldShowDepartment(false)
      setShouldShowLocation(false)
      setShouldShowAnnouncement(false)
    }

    fetchCompany()
  }, [authCompany?.platform, authCompany?.shouldImportGuestUsers])

  useEffect(() => {
    if (Array.isArray(actionNotifications) &&
      (
        correlationId &&
        !actionNotifications.includes(correlationId || '')
      )) {
      setCorrelationId(null)
      fetchCompany()
    }
  }, [actionNotifications])

  useEffect(() => {
    if (msAuth) {
      const tenantId = msAuth.getTenantId() || 'common'
      setAdminConsentUrl(`https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`)
    }
  }, [msAuth])

  useEffect(() => {
    const importUsersFlag = featureFlags && featureFlags.includes(FeatureFlagEnum.importUsers)
    if (totalNumberOfUsers > AUTOMATIC_USERS_IMPORT_LIMIT) {
      setShouldEnableImportUsersAutomaticallyForLargeCompanies(importUsersFlag)
      setShouldShowDepartment(importUsersFlag)
      setShouldShowLocation(importUsersFlag)
      setShouldShowProrate(importUsersFlag)
    }
  }, [featureFlags, totalNumberOfUsers, isLoadingTotalNumberOfUsers])

  useEffect(() => {
    if (!shouldEnableImportUsersAutomaticallyForLargeCompanies) {
      notification.error({
        message: '',
        description: formatMessage({id: 'components.importUsersForm.importUsersAutomaticallyExceedingLimit'}),
        duration: 0,
        btn: <Button onClick={() => {
          openSupportChat()
        }}>{ formatMessage({ id: 'app.contactSupport' }) }</Button>,
      })
      form.setFieldsValue({ importUsersAutomatically: false })
      setImportUsersAutomatically(false)
      setShouldShowDepartment(false)
      setShouldShowLocation(false)
      setShouldShowProrate(false)
    }
  }, [shouldEnableImportUsersAutomaticallyForLargeCompanies])

  const onImportUsersAutomaticallySelect = (option) => {
    if (authCompany?.platform === 'microsoft') {
      getTotalUsersInMicrosoftTenant()
    }

    if (authCompany?.platform === 'slack') {
      fetchFromSlackApi(isBoolean(authCompany?.shouldImportGuestUsers) ? authCompany?.shouldImportGuestUsers : true)
    }

    if (authCompany?.platform === 'google') {
      fetchFromGoogleApi()
    }

    form.setFieldsValue({ importUsersAutomatically: option.target.value })
    setImportUsersAutomatically(option.target.value)
    setShouldShowDepartment(option.target.value)
    setShouldShowLocation(option.target.value)
    setShouldShowProrate(option.target.value)
    track('IMPORT_USERS_AUTOMATICALLY', {
      platform: authCompany?.platform,
      selectedValue: option.target.value,
      source: 'general settings page',
    })
  }

  const onChangeBotHelperSettings = (option) => {
    const enableBot = Boolean(option.target.value)
    form.setFieldsValue({ aiBotHelper: enableBot })
    setEnableBotHelper(enableBot)
    const botSettingsChanged = enableBot !== Boolean(authCompany?.aiBotHelper)
    if (botSettingsChanged && authCompany?.platform === 'microsoft') {
      //TODO ask permissions
    }
    if (botSettingsChanged && authCompany?.platform === 'slack') {
      setShowSlackLoginRequiredModal(true)
    }
  }

  const shouldDisableSave = () => {
    const {
      name,
      importUsersAutomatically,
      aiBotHelper,
      sendWelcomeMessageTo,
      userEndDate,
      hourlyLeaveAccounting,
      shouldProrate,
      shouldImportGuestUsers,
      calculationStartYear,
      contactEmail,
    } = form.getFieldsValue(true)
    if (isLoading || isLoadingTotalNumberOfUsers) {
      return true
    }

    const calculationYearCheck = isNull(authCompany?.calculationStartYear)
      ? true
      : authCompany?.calculationStartYear === calculationStartYear?.format('YYYY')

    const departmentCheck = isNull(authCompany?.defaultDepartmentForAutomaticImport)
      ? true
      : authCompany?.defaultDepartmentForAutomaticImport === selectedDepartment?.id

    const locationCheck = isNull(authCompany?.defaultLocationForAutomaticImport)
      ? true
      : authCompany?.defaultLocationForAutomaticImport === selectedLocation?.id

    const announceCheck = authCompany?.platform === 'email'
      ? true
      : authCompany?.announceNewUsers === (sendWelcomeMessageTo?.platform === authCompany?.platform)

    const guestUsersCheck = authCompany?.platform === 'slack'
      ? Boolean(authCompany?.shouldImportGuestUsers) === shouldImportGuestUsers
      : true

    const contactEmailCheck = authCompany?.contactEmail === contactEmail


    const simpleComparisonCheck = authCompany?.name === name
      && authCompany?.importUsersAutomatically === importUsersAutomatically
      && Boolean(authCompany?.aiBotHelper) === Boolean(aiBotHelper)
      && Boolean(authCompany?.shouldProrate) === shouldProrate
      && authCompany?.userEndDate === userEndDate
      && authCompany?.hourlyLeaveAccounting === hourlyLeaveAccounting
      && announceCheck
      && guestUsersCheck
      && departmentCheck
      && locationCheck
      && calculationYearCheck
      && contactEmailCheck

    const proratedLeaveTypesCheck = authCompany?.shouldProrate
      ? isEqual(sortBy(authCompany?.proratedLeaveTypes), sortBy(selectedLeaveTypes?.map(lt => lt.id)))
      : true


    return simpleComparisonCheck && proratedLeaveTypesCheck
  }

  const fetchCompany = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyGeneral)) as IGetCompanyGeneral
      if (response.data.getCompany?.platform === 'microsoft') {
        const tenantId = await API.get('CoreEvent', '/microsoft/get-tenant', {}) as string
        setMsTenantId(tenantId)
      }
      const companyData = response.data.getCompany
      if (companyData) {
        form.setFieldsValue({
          importUsersAutomatically: companyData.importUsersAutomatically,
          aiBotHelper: Boolean(companyData.aiBotHelper),
          sendWelcomeMessageTo: companyData?.announceNewUsers
            ? welcomeMessageOpts.find(opt => opt.platform === authCompany?.platform) as WelcomeMessageOptions
            : getDontSendWelcomeOption(formatMessage),
          userEndDate: companyData.userEndDate,
          name: companyData.name,
          contactEmail: companyData.contactEmail,
          hourlyLeaveAccounting: Boolean(companyData.hourlyLeaveAccounting),
          shouldImportGuestUsers: Boolean(companyData.shouldImportGuestUsers),
          shouldProrate: Boolean(companyData.shouldProrate),
          proratedLeaveTypes: companyData.proratedLeaveTypes,
          defaultDepartmentForAutomaticImport: companyData.defaultDepartmentForAutomaticImport,
          defaultLocationForAutomaticImport: companyData.defaultLocationForAutomaticImport,
          calculationStartYear: companyData.calculationStartYear ? moment(companyData.calculationStartYear) : moment(),
        })
        setCompanyValue({
          importUsersAutomatically: companyData.importUsersAutomatically || false,
          aiBotHelper: companyData.aiBotHelper || false,
          announceNewUsers: Boolean(companyData.announceNewUsers) || false,
          userEndDate: companyData.userEndDate || false,
          name: companyData.name,
          contactEmail: companyData.contactEmail,
          hourlyLeaveAccounting: Boolean(companyData.hourlyLeaveAccounting),
          shouldImportGuestUsers: Boolean(companyData.shouldImportGuestUsers),
          shouldProrate: Boolean(companyData.shouldProrate),
          proratedLeaveTypes: companyData.proratedLeaveTypes,
          defaultDepartmentForAutomaticImport: companyData.defaultDepartmentForAutomaticImport,
          defaultLocationForAutomaticImport: companyData.defaultLocationForAutomaticImport,
          calculationStartYear: companyData.calculationStartYear || null,
        })
      }
      if (authCompany) {
        dispatch(setAuthCompany({
          ...authCompany,
          importUsersAutomatically: companyData.importUsersAutomatically,
          announceNewUsers: Boolean(companyData.announceNewUsers),
          userEndDate: companyData.userEndDate,
          name: companyData.name,
          contactEmail: companyData.contactEmail,
          hourlyLeaveAccounting: Boolean(companyData.hourlyLeaveAccounting),
          shouldImportGuestUsers: Boolean(companyData.shouldImportGuestUsers),
          shouldProrate: Boolean(companyData.shouldProrate),
          proratedLeaveTypes: companyData.proratedLeaveTypes,
          defaultDepartmentForAutomaticImport: companyData.defaultDepartmentForAutomaticImport,
          defaultLocationForAutomaticImport: companyData.defaultLocationForAutomaticImport,
          calculationStartYear: companyData.calculationStartYear,
        }))
      }
      setShouldShowDepartment(Boolean(companyData.importUsersAutomatically))
      setShouldShowLocation(Boolean(companyData.importUsersAutomatically))
      setShouldShowProrate(Boolean(companyData.importUsersAutomatically))
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setSubmitLoader(false)
    }
  }

  const disableDate = (current) => {
    return current > moment(new Date() as MomentInput) || current < moment(CALCULATION_START_YEAR_LIMIT)
  }

  const onFinish = async () => {
    try {
      const values: ICompanySettingsFormValues = await form.validateFields()
      if (authCompany?.platform === 'microsoft' && values.importUsersAutomatically && isMsAdminConsentRequired && adminConsentUrl) {
        return setShowAdminConsentWarning(true)
      }

      if (authCompany?.platform === 'microsoft' && authCompany.importUsersAutomatically !== values.importUsersAutomatically) {
        if (!sessionStorage.getItem('msWebAppTokensExpiration') || isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string))) {
          return setShowMsLoginRequiredModal(true)
        }
      }

      if (authCompany?.platform === 'google' && authCompany.importUsersAutomatically !== values.importUsersAutomatically) {
        const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
        if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
          return setShowGoogleLoginRequiredModal(true)
        }
      }

      if (authCompany?.platform === 'google' && !sessionStorage.getItem('googleRefreshToken')) {
        return setShowGoogleLoginRequiredModal(true)
      }

      setShowAdminConsentWarning(false)

      if (typeof values.userEndDate !== 'boolean') {
        values.userEndDate = false
      }

      if (values.calculationStartYear) {
        const safeCalculationStartYear = moment(values.calculationStartYear as MomentInput).add(1, 'month')
        values.calculationStartYear = safeCalculationStartYear.format('YYYY')
      }

      const body: ICompanySettingsBody = {
        eventType: 'COMPANY_SETTINGS_UPDATED',
        eventGroup: 'COMPANY',
        ...omit(values, ['sendWelcomeMessageTo', 'selectedLocation', 'selectedLocation', 'selectedDepartment', 'selectedLeaveTypes']),
        importUsersAutomatically: Boolean(importUsersAutomatically),
        aiBotHelper: Boolean(aiBotHelper),
        announceNewUsers: values?.sendWelcomeMessageTo?.platform !== 'none',
        defaultLocation: values?.selectedLocation,
        defaultDepartment: values?.selectedDepartment,
        proratedLeaveTypes: values?.selectedLeaveTypes,
      }

      if (authCompany?.platform === 'slack') {
        body.shouldImportGuestUsers = isBoolean(values.shouldImportGuestUsers)
          ? values.shouldImportGuestUsers
          : true
      }

      if (importUsersAutomatically && values.shouldProrate && values?.selectedLeaveTypes?.length) {
        body.shouldProrate = true
        body.proratedLeaveTypes = values.selectedLeaveTypes
      } else {
        body.shouldProrate = false
        body.proratedLeaveTypes = []
      }

      if (authCompany?.platform === 'microsoft') {
        const refreshToken = sessionStorage.getItem('msWebAppTokensRefreshToken')
        if (refreshToken) {
          body.refreshToken = refreshToken as string
        }
      }
      if (authCompany?.platform === 'google') {
        const refreshToken = sessionStorage.getItem('googleRefreshToken')
        if (refreshToken) {
          body.refreshToken = refreshToken as string
        }
      }
      setSubmitLoader(true)

      if (authCompany?.platform === 'email') {
        delete body.announceNewUsers
        delete body.importUsersAutomatically
        delete body.aiBotHelper
        delete body.shouldProrate
        delete body.refreshToken
        delete body.proratedLeaveTypes
        delete body.defaultDepartment
        delete body.defaultLocation
        delete body.shouldImportGuestUsers
      }

      if (!shouldEnableImportUsersAutomaticallyForLargeCompanies) {
        delete body.shouldImportGuestUsers
        delete body.importUsersAutomatically
        delete body.shouldProrate
        delete body.proratedLeaveTypes
        delete body.defaultDepartment
        delete body.defaultLocation
        delete body.refreshToken
      }

      const response = await API.post('CoreEvent', '/core/event', { body })
      setCorrelationId(response.correlationId)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'general.updateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])

      setSubmitLoader(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchFromSlackApi = async (shouldImportGuestUsers = true, from?: 'guestUsers'): Promise<void> => {
    if (from !== 'guestUsers') {
      setIsLoadingTotalNumberOfUsers(true)
    }
    try {
      slackAuth.getUserToken()
      let slackUsers: ISlackUser[] = []
      try {
        slackAuth.getUserToken()
        slackUsers = await slackAuth.getUsersList()
      } catch (error) {
        console.log('ERROR', error)
        if (error?.error === 'not_authed' || error?.error === 'invalid_auth' || error?.error === 'missing_scope' || error?.error === 'token_revoked') {
          setShowSlackLoginRequiredModal(true)
        }
        return
      }
      const activeUsers = slackUsers
        .filter(user => !user.deleted)
        .filter(user => !user.is_bot)
        .filter(user => !user.is_ultra_restricted)
        .filter(user => user.id !== 'USLACKBOT')

      if (shouldImportGuestUsers) {
        setTotalNumberOfUsers(activeUsers.filter(user => !user.is_restricted).length)
      } else {
        setTotalNumberOfUsers(activeUsers.length)
      }

      setIsLoadingTotalNumberOfUsers(false)
      try {
        await API.get('SlackEvent', '/get-bot-token', {})
      } catch (error) {
        if(error.response?.data?.error && (error.response.data.error === 'error.botTokenNotExist' || error.response.data.error === 'error.botTokenNotValid')) {
          setShowSlackLoginRequiredModal(true)
          setIsLoadingTotalNumberOfUsers(false)
          return
        }

      }
    } catch (error) {
      console.log('ERROR FETCH FROM SLACK API', error)
      setIsLoadingTotalNumberOfUsers(false)
    }
  }

  const getGoogleTokens = (withConsent = false) => {
    googleAuth.signin(withConsent).then(() => {
      fetchFromGoogleApi()
    })
  }

  const fetchFromGoogleApi = async (): Promise<void> => {
    if (!authCompany) {
      throw new Error('Missing company')
    }
    setIsLoadingTotalNumberOfUsers(true)
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      setShowGoogleLoginRequiredModal(true)
    } else {
      setShowGoogleLoginRequiredModal(false)
      const googleScopesResponse = await API.graphql(graphqlOperation(getGoogleScopes, {
        accessToken: googleAuth.getAccessToken(),
      })) as IData<IGetGoogleScopes>
      const googleApiScopes = googleScopesResponse.data.getGoogleTokenInfo.scopes as string[]
      const vtGooogleIntegrationScopes = googleScopesResponse.data.getGoogleIntegrationSettings?.scopes
      if (googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION) && !vtGooogleIntegrationScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
        const userInfo = googleAuth.getSignedInUser()
        await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'GOOGLE_INTEGRATION',
            eventGroup: 'INTEGRATION',
            scope: googleApiScopes.join(' '),
            domainName: userInfo.hd,
          },
        })
      }

      try {
        const googleUsers = await googleAuth.getUserListForImportFull()

        setIsLoadingTotalNumberOfUsers(false)
        setTotalNumberOfUsers(googleUsers.length as number || 0)
      } catch (error) {
        Sentry.captureException(error)
        if (error.message === 'GOOGLE_DIRECTORY_SHARING_DISABLED' || error.message === 'GOOGLE_DIRECTORY_PERMISSION_DENIED') {
          track('GOOGLE_ERROR_DIRECTORY_SHARING_DISABLED', {page: 'ImportUsersPage', platform: 'google', error: error.message})
          setShowEnableGoogleApiModal(true)
          return
        }
        if (error.message === 'no_tokens') {
          errorNotificationHandler(error, 'google', getGoogleTokens)
          return
        }
        notification.error({
          message: `Google error: ${error}`,
          description: 'Please logout and login again',
          duration: 0,
        })
        setIsLoadingTotalNumberOfUsers(false)
      }
    }
    if (!authCompany) {
      throw new Error('Missing company')
    }
  }

  const wrongSlackAccount = () => {
    slackAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.slack.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({id: 'errors.slack.wrongSlackAccountDescription'})}</Paragraph>
        <Button onClick={() => getSlackTokens()}>{formatMessage({id: 'error.slack.logInWithDifferentAccount'})}</Button>
      </div>,
      duration: 0,
    })
    setShowSlackLoginRequiredModal(false)
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email', aiBotHelper ? 'im:history' : ''],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      if ((!response.enterprise && response.team.id !== authCompany?.organizationId) || (response.enterprise && response.enterprise?.id !== authCompany?.organizationId)) {
        wrongSlackAccount()
        return
      }
      slackAuth.setUserToken(response.authed_user.access_token)
      try {
        await API.post('SlackApi', '/update-tokens', {
          body: {
            token: response.authed_user.access_token,
            botToken: response.access_token,
          },
        })
      } catch (error) {
        console.log('UPDATE TOKEN ERROR', error.response)
      }
      setShowSlackLoginRequiredModal(false)
      await fetchFromSlackApi(isBoolean(authCompany?.shouldImportGuestUsers) ? authCompany?.shouldImportGuestUsers : true)
    }).catch(error => {
      console.log('SLACK SIGNIN ERROR', error)
      errorNotificationHandler(error, 'slack', () => { fetchFromSlackApi(isBoolean(authCompany?.shouldImportGuestUsers) ? authCompany?.shouldImportGuestUsers : true) })
    })
  }

  const getMsTokensForUserList = async (tenantId: string) => {
    const { accessToken } = await msAuth.getMSWebAppTokens(tenantId, addMinutes(new Date(), 15).toString())
    const numberOfUsers = await msAuth.getTotalUsers(accessToken as string)
    setTotalNumberOfUsers(numberOfUsers)
    setShowMsLoginRequiredModal(false)
    setIsLoadingTotalNumberOfUsers(false)
  }

  const getTotalUsersInMicrosoftTenant = async () => {
    setIsLoadingTotalNumberOfUsers(true)
    try {
      const tenantId = await API.get('CoreEvent', '/microsoft/get-tenant', {}) as string
      setMsTenantId(tenantId)

      if (!sessionStorage.getItem('msWebAppTokensExpiration') || isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string))) {
        setShowMsLoginRequiredModal(true)
      } else {
        const numberOfUsers = await msAuth.getTotalUsers(sessionStorage.getItem('msWebAppTokensAccessToken') as string)
        setTotalNumberOfUsers(numberOfUsers)
      }
      setIsLoadingTotalNumberOfUsers(false)
    } catch(err) {
      if (err?.data?.error?.code === 'InvalidAuthenticationToken'
      || err?.includes('FAILED CODE OR TOKEN FETCH')
      || err?.includes('invalid_grant')
      || err?.includes('invalid_request') && err?.includes('refresh_token')
      ) {
        return setShowMsLoginRequiredModal(true)
      }

      captureException(err)
      errorNotificationHandler(err, 'microsoft', () => { getTotalUsersInMicrosoftTenant() })
      setMsAdminConsentRequired(true)
      setIsLoadingTotalNumberOfUsers(false)
    }
  }

  const errorNotificationHandler = (error: Error, platform: Platform, onClickHandler: Function) => {
    const isTokenError = error.message === 'no_tokens'
    const errorMessageDescription = isTokenError ? formatMessage({ id: 'error.token.description' }, {platform} ) : error.message
    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError &&
        <Button onClick={() => {
          onClickHandler()
          notification.close('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, {platform} )}
        </Button>,
      duration: 0,
    })
  }

  const handleSwitchPlatform = () => {
    setShowSwitchPlatformModal(!showSwitchPlatformModal)
  }

  const handleChangeGuestUsersImport = async (v: boolean) => {
    await fetchFromSlackApi(v, 'guestUsers')
  }

  const saveSettings = async () => {
    try {
      const formData = await form.validateFields()

      if (selectedLeaveTypes?.length === 0 && authCompany?.platform !== 'email' && form.getFieldValue('shouldProrate')) {
        notification.error({
          message: formatMessage({ id: 'connect.platformErrorTitle' }),
          // eslint-disable-next-line max-len
          description: `Internal error. Selected leave types length (${selectedLeaveTypes?.length}) with prorate turned on and platform is ${authCompany?.platform}. Please contact support.`,
          duration: 0,
        })
        return
      }
      if (authCompany?.platform === 'microsoft'
        && importUsersAutomatically
        && (!sessionStorage.getItem('msWebAppTokensExpiration') || isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string)))
      ) {
        setShowMsLoginRequiredModal(true)
        return
      }
      try {
        showConfirmCompanySettings({
          onConfirm: () => {
            onFinish()
          },
          title: formatMessage({id: 'general.confirmModalTitle'}),
          okText: formatMessage({ id: 'components.modal.confirm' }),
          formatMessage,
          showInfoFor: {
            showNewNameInfo: true,
            showImportUsersAutomaticallyInfo: true,
            showAiBotHelperInfo: true,
            showUserEndDateInfo: true,
            showHourlyLeaveAccountingInfo: true,
            showWelcomeMessageToInfo: Boolean(formData?.sendWelcomeMessageTo?.platform !== 'none') !== companyValue?.announceNewUsers,
            showSlackMultichannelUsersImport: Boolean(authCompany?.platform === 'slack') && importUsersAutomatically,
            showCalculationStartYearInfo: true,
            showAlwaysDefaultDepartmentAndLocation: false,
            showMoreInfo: false,
            shownNOfUsersToImportInfo: false,
            showCompanyEmail: formData?.contactEmail !== companyValue?.contactEmail,
          },
          showAdditionalBillingInfo: false,
          upcomingInvoiceInfo: null,
          isLoadingUpcomingInvoiceInfo: false,
          locale: authUser?.locale as LocaleEnum,
          welcomeMessageOpts,
          authCompany,
          selectedDepartment,
          selectedLocation,
          selectedLeaveTypes,
          form,
          contactEmail: form.getFieldValue('contactEmail'),
        })
      } catch (error) {
        console.log('error', error)
        Sentry.captureException(JSON.stringify({
          ...error,
          message: 'Error running showConfirmCompanySettings()',
          formData,
          authCompany,
          authUser,
          companyValue,
          importUsersAutomatically,
        }))
      }
      return
    } catch (error) {
      return
    }
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  }

  const tailLayout = {
    wrapperCol: {xs: { offset: 0, span: 12 }, lg: { offset: 8, span: 12 }},
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.general" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.general" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        <div className="genereal-body">
          {isLoading ? <CircularProgress /> :
            <Form
              form={form}
              layout="horizontal"
              initialValues={companyValue}
              labelWrap
              {...layout}
            >
              <Form.Item
                name="name"
                label={<IntlMessages id="general.companyName" />}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              >
                <Input
                  maxLength={100}
                  style={{ width: 300 }}
                  placeholder={`${formatMessage({ id: 'general.pleaseEnterCompanyName' })}`}
                  data-qa='company-name'
                />
              </Form.Item>

              <Form.Item
                label={<IntlMessages id="createCompany.steps.companyForm.email" />}
                extra={<IntlMessages id="createCompany.steps.companyForm.contactEmailExtra" />}
                name="contactEmail"
                rules={[{
                  required: true, message: <IntlMessages id="createCompany.steps.companyForm.contactEmail.validation.required" />,
                }, {
                  type: 'email', message: <IntlMessages id="form.validEmailRequired" />,
                }]}
              >
                <Input type="email" style={{ width: 300 }} placeholder={`${formatMessage({ id: 'general.pleaseEnterContactEmail' })}`} />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <IntlMessages id="general.enableUserEndDate" />&nbsp;
                    <Tooltip title={<IntlMessages id="general.enableUserEndDateTooltip" />}
                      className='info-tooltip'>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name='userEndDate'
                valuePropName="checked"
                extra={<IntlMessages id="general.enableUserEndDateInfo" />}
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    <IntlMessages id="general.hourlyLeaveAccounting" />&nbsp;
                    <Tooltip title={<IntlMessages id="general.hourlyLeaveAccountingTooltip" />}
                      className='info-tooltip'>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name='hourlyLeaveAccounting'
              >
                <Select<Boolean> style={{ width: 100 }} disabled={!shouldEnableHours}>
                  <Select.Option value={false}><IntlMessages id="general.days" /></Select.Option>
                  <Select.Option value={true}><IntlMessages id="general.hours" /></Select.Option>
                </Select>
              </Form.Item>
              {authCompany?.platform === 'email' && <Form.Item
                label={
                  <span>
                    <IntlMessages id="general.connectToAnotherPlatform" />&nbsp;
                    <Tooltip title={<IntlMessages id="general.connectToAnotherPlatformTooltip" />}
                      className='info-tooltip'>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <Space wrap>
                  {
                    allPlatforms.filter(platform => platform !== authCompany?.platform).map((val) => {
                      return (
                        <Button key={val} icon={platformIconMap[val]} type="primary" onClick={() =>  {
                          setPlatformToSwitch(val)
                          handleSwitchPlatform()
                        }}>
                          <IntlMessages id="general.connectWith" values={{ platform: capitalize(val) }} />
                        </Button>
                      )
                    })
                  }
                </Space>
              </Form.Item>}
              {(!!companyValue?.calculationStartYear || shouldEnableCalculationStartYear) &&
                <Form.Item
                  label={
                    <span>
                      <IntlMessages id="general.calculationStartYear" />&nbsp;
                      <Tooltip
                        className='info-tooltip'
                        title={<div>
                          <IntlMessages id="general.calculationStartYearTooltipLine1" />
                          <br/><br/>
                          <IntlMessages id="general.calculationStartYearTooltipLine2" values={{
                            support: (...chunks) => <a onClick={() => openSupportChat('CALCULATION_START_YEAR')}>{chunks}</a>,
                          }} />
                        </div>}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name='calculationStartYear'
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <DatePicker disabledDate={disableDate} picker="year" />
                </Form.Item>}
              {featureFlags && featureFlags.includes(FeatureFlagEnum.aiBotHelper) && authCompany?.platform === 'slack' && <Form.Item
                name="aiBotHelper"
                className='wrap-label'
                style={{ marginBottom: '30px' }}
                label={<>
                  <IntlMessages id="components.generalSettings.aiBotHelperLabel"/>
                  <Tooltip className='info-tooltip' title={<IntlMessages id="components.generalSettings.aiBotHelperTooltip" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </>}
              >
                <Radio.Group optionType="button" name="aiBotHelper" onChange={onChangeBotHelperSettings}>
                  {
                    radioOptions.map(option => (
                      <Radio.Button
                        value={option.value}
                        key={option.label}
                        data-testid={option.label.toLowerCase()}
                      >{option.label}
                      </Radio.Button>)
                    )
                  }
                </Radio.Group>
              </Form.Item>}
              <>
                {authCompany?.platform !== 'email' &&
                  <div className="main-content-header-title" style={{ margin: '30px 0'}}>
                    <span><IntlMessages id="general.importUsersConfigSection" /></span>
                  </div>}
                {!isLoadingTotalNumberOfUsers && authCompany?.platform !== 'email' && <Form.Item
                  name="importUsersAutomatically"
                  className='wrap-label'
                  style={{ marginBottom: '30px' }}
                  labelCol={{ span: 6 }}
                  extra={!shouldEnableImportUsersAutomaticallyForLargeCompanies &&
                      <IntlMessages id="components.importUsersForm.importUsersAutomaticallyExceedingLimit" />
                  }
                  label={<>
                    <IntlMessages
                      id="components.importUsersForm.button.importUsersAutmaticallyFromPlatform"
                      values={{
                        platform: getIntegrationPlatformName(authCompany?.platform),
                      }}
                    />
                    <Tooltip
                      className='info-tooltip'
                      title={<IntlMessages
                        id="components.importUsersForm.importUsersAutomaticallyTooltip"
                        values={{ platform: getIntegrationPlatformName(authCompany?.platform) }}
                      />}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>}
                >
                  <Radio.Group
                    optionType="button"
                    name="importUsersAutomatically"
                    onChange={onImportUsersAutomaticallySelect}
                  >
                    {
                      radioOptions.map(option => (
                        <Radio.Button
                          value={option.value}
                          key={option.label}
                          data-testid={option.label.toLowerCase()}
                          disabled={!shouldEnableImportUsersAutomaticallyForLargeCompanies}
                        >{option.label}
                        </Radio.Button>)
                      )
                    }
                  </Radio.Group>
                </Form.Item>}

                {showAdminConsentWarning &&
                  <Text type="danger">
                    <WarningOutlined /> <IntlMessages id="components.importUsersForm.consent.warning" />
                  </Text>}
                {authCompany?.platform !== 'email' && isLoadingTotalNumberOfUsers && <CircularProgress />}
                {authCompany?.platform !== 'email' && !isLoadingTotalNumberOfUsers &&
                  <ConfigureImportUsersOptionsForm
                    form={form}
                    source={ConfigureImportSourceEnum.generalSettingsPage}
                    welcomeMessageOpts={welcomeMessageOpts}
                    onChangeGuestUsersImport={handleChangeGuestUsersImport}
                    setSelectedDepartment={setSelectedDepartment}
                    setSelectedLocation={setSelectedLocation}
                    setSelectedLeaveTypes={setSelectedLeaveTypes}
                    setWelcomeMessageOpts={setWelcomeMessageOpts}
                    shouldShowAnnounce={shouldShowAnnuonce}
                    shouldShowDepartment={shouldShowDepartment}
                    shouldShowLocation={shouldShowLocation}
                    shouldShowProrate={shouldShowProrate}
                    shouldShowImportGuestUsers={importUsersAutomatically && shouldEnableImportUsersAutomaticallyForLargeCompanies}
                  />}
                <Form.Item {...tailLayout} shouldUpdate>{() => {
                  return <Button
                    type="primary"
                    loading={submitLoader}
                    disabled={shouldDisableSave()}
                    data-qa='save-settings'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={saveSettings}
                  >
                    <IntlMessages id="app.save" />
                  </Button>
                }}

                </Form.Item>
              </>
            </Form>
          }
          {showSwitchPlatformModal &&
            <PlatformSwitchModal
              visible={showSwitchPlatformModal}
              onCancel={handleSwitchPlatform}
              platform={platformToSwitch || ''}
            />
          }
        </div>
        <Divider />
        <Text style={{display: 'block', marginBottom: '20px'}}>
          <IntlMessages id="general.deleteAccount" values={{
            mail: (...chunks) => <a href="mailto:hello@vacationtracker.io">{chunks}</a>,
          }} />
        </Text>
        <Text type="secondary">
          <IntlMessages id="general.usefulLinksTitle" />
        </Text>
        <IntlMessages id="general.usefulLinks"
          values={{
            // eslint-disable-next-line
            healthcheck: (...chunks) => {
              return <a href="https://app.vacationtracker.io/health-check/">
                {chunks}
              </a>
            },
            // eslint-disable-next-line
            helpdesk: (...chunks) => (
              <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
        <br/>
        <Text type="secondary" copyable={{ text: authCompany?.id }}>
          <IntlMessages id="general.companyId" /> {authCompany?.id}
        </Text>
      </div>

      {showMsLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
          visible={showMsLoginRequiredModal}
          onOk={() => getMsTokensForUserList(msTenantId)}
          onCancel={() => {
            setShowMsLoginRequiredModal(false)
            history.push('/app/dashboard')
          }}
        >
          <p><IntlMessages id="microsoft.usersLoginRequiredDescription1" /></p>
          <p><IntlMessages id="microsoft.usersLoginRequiredDescription2" /></p>
        </Modal>
      }
      {showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          visible={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          onCancel={() => {
            setShowSlackLoginRequiredModal(false)
            form.setFieldsValue({ aiBotHelper: Boolean(authCompany?.aiBotHelper) })
            setEnableBotHelper(Boolean(authCompany?.aiBotHelper))
          }}
        >
          <p><IntlMessages id="slack.usersLoginRequiredDescription1" /></p>
          <p><IntlMessages id="slack.notificationsLoginRequiredDescription2" /></p>
        </Modal>
      }
      {showGoogleLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.google.loginRequiredTitle' })}
          visible={showGoogleLoginRequiredModal}
          onOk={() => getGoogleTokens(true)}
          onCancel={() => {
            setShowGoogleLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="error.google.usersLoginRequiredDescription1" /></p>
          <p><IntlMessages id="error.google.usersLoginRequiredDescription2" /></p>
        </Modal>
      }
      {showEnableGoogleApiModal &&
        <Modal
          title={formatMessage({ id: 'error.google.directoryApiDisabledTitle' })}
          visible={showEnableGoogleApiModal}
          footer={null}
        >
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP1" /></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP2" /></Paragraph>
          <Paragraph copyable={{text: supportLink}}><a href={supportLink} target="_blank" rel="noopener noreferrer">{supportLink}</a></Paragraph>
          <Paragraph><IntlMessages id="error.google.directoryApiDisabledP4" /></Paragraph>
        </Modal>
      }
      <Modal
        title={formatMessage({ id: 'microsoft.notificationsLoginRequiredTitle' })}
        visible={Boolean(authCompany?.platform === 'microsoft'
          && isMsAdminConsentRequired
          && importUsersAutomatically
          && adminConsentUrl
        )}
        footer={null}
      >
        <Paragraph>
          <IntlMessages id="components.importUsersForm.consent.line1" />
        </Paragraph>
        <Paragraph copyable={{ text: adminConsentUrl as string}}>
          <a href={adminConsentUrl as string}>{adminConsentUrl}</a>
        </Paragraph>
      </Modal>
    </div>
  )
}

export default GeneralPage
