import React from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { isMicrosoftPayment, selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { logout } from '../../services/auth/logout-handler'
import { FrontendUrls } from '../../types/urls'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { PaymentProcessor } from '@vacationtracker/shared/types/billing'

interface IPrivateRoute {
  component: Function
  roles: string[]
  platform: string[]
  path: string
  plan?: SubscriptionPlanEnum
  onStateChange: Function
  paymentProcessor?: PaymentProcessor
}

export const PrivateRoute = ({ component: Component, roles, platform, onStateChange, path, paymentProcessor, plan }: IPrivateRoute): React.ReactElement => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const isMicrosoftBillingPayment = useSelector(isMicrosoftPayment)
  const dispatch = useAppDispatch()

  return (
    <Route path={path} render={(props: RouteComponentProps) => {
      if (!authUser.id) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: FrontendUrls.signin, state: { from: props.location } }} />
      }

      if (authCompany && !(authCompany?.subscriptionStatus === 'trialing' || authCompany?.subscriptionStatus === 'active' || authCompany?.subscriptionStatus === 'past_due')) {
        // subscription expired
        if (authUser.role === 'Admin') {
          return <Redirect to={{ pathname: FrontendUrls.subscription }} />
        } else if (isMicrosoftBillingPayment) {
          return <Redirect to={{ pathname: FrontendUrls.subscription }} />
        } else {
          logout({
            onStateChange,
            history: props.history,
            reduxDispatch: dispatch,
            redirectRoute: `${FrontendUrls.signin}?error=subscription_expired`,
            userId: authUser.id,
          })
        }
      }

      // If Admin try to open /app/my-profile page redirect to /app/users/userId page
      if (authUser.role === 'Admin' && path === '/app/my-profile') {
        return <Redirect to={{ pathname: `/app/users/${authUser.id}` }} />
      }

      if (roles && !roles.includes(authUser.role)) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }

      if (platform && !platform.includes(authUser?.platform)) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }

      if (typeof paymentProcessor === 'string' && paymentProcessor !== authCompany?.billing?.paymentProcessor) {
        // paymentProcessor not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }
      if (plan && authCompany?.plan !== plan) {
        return <Redirect to={{ pathname: '/' }} />
      }

      // authorised so return component
      return <Component {...props} />
    }} />
  )
}
